import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { campaignFormSelector, setSelectedPopUpAdv, storeAdvertisersData, storeSelectedAdvertiser } from '../../../Store/Slices/CreateAdvCampaignSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useAppSelector } from '../../../Store/hooks';
import { fetchAdvertiserDataAction } from '../../../Store/Actions';
import { AppDispatch, RootState } from '../../../Store';
import { setTcpaVertical } from '../../../Store/Slices/tcpaCampSlice';
import { fetchAllAdvertiserData, getAdvertiserByVertical } from '../../../service/Advertiser';
import { CircleLoader } from 'react-spinners';
import { isArray } from 'lodash';

interface CreateCampaignPopupProps {
  isOpen: boolean;
  onClose: () => void;
  onProceed: (selectedOption: string) => void;
}
const CreateCampaignPopup: React.FC<CreateCampaignPopupProps> = ({ isOpen, onClose, onProceed }) => {
  const [selectedOption, setSelectedOption] = useState<string>('');
  const campaignFormData = useAppSelector(campaignFormSelector)
  const { verticalList, loading, error } = useSelector((state: RootState) => state.verticals);
  const verticalOptions = verticalList.map((item) => ({
    verticalID: item.verticalID,
    verticalName: item.verticalName,
  }));


  const { pageLoading, advertisers, advertiserList, isEdit, isClone, selectedPopUpAdv } = campaignFormData
  const dispatch = useDispatch<AppDispatch>();

  const advAPIPath = "getAdvertiserByVertical";

  useEffect(() => {
    if (selectedOption) {
      const apiUrl = `${advAPIPath}?vertical=${selectedOption}`;
      console.log("apiUrl", apiUrl)
      //  getAdvByVertical(apiUrl);
      dispatch(fetchAdvertiserDataAction(apiUrl));
    }
  }, [dispatch, selectedOption]);

  const handleSelectChange = (event: any) => {
    const selectedVertical = verticalOptions.find(
      (option) => option.verticalID === event.target.value
    );
    console.log('selectedVertical', selectedVertical)
    if (selectedVertical) {
      dispatch(setSelectedPopUpAdv(""));
      setSelectedOption(selectedVertical.verticalID);
      dispatch(setTcpaVertical({
        verticalID: selectedVertical?.verticalID,
        verticalName: selectedVertical?.verticalName,
        verticalDisplay: selectedVertical?.verticalName.split(" (")[0],
      }));
    }
  };
  const handleSelectAdv = (event: any) => {
    // console.log('event.target.value',event.target.value)
    dispatch(setSelectedPopUpAdv(event.target.value));
  };

  const handleProceed = () => {
    if (selectedOption) {
      onProceed(selectedOption);
    }
  };

  const handleCancel = () => {
    setSelectedOption('');
    dispatch(setSelectedPopUpAdv(""));;
    onClose();
  }

  return (
    <Dialog open={isOpen} onClose={(event, reason) => {
      if (reason !== 'backdropClick') {
        onClose();
      }
    }} maxWidth="xs" fullWidth >


      {/* {true?<div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px',  height:'100%' }}><CircleLoader loading={true}  color="#00BFFF" size={60}  /></div> : 
        <> */}
      <DialogTitle>Select Vertical Type</DialogTitle>
      <DialogContent>
        {pageLoading ? <div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px', height: '100%' }}><CircleLoader loading={pageLoading} color="#00BFFF" size={60} /></div> :
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth sx={{ marginTop: '9px' }} >
                <InputLabel id="vertical-type-label">Vertical Type</InputLabel>
                <Select
                  labelId="vertical-type-label"
                  value={selectedOption} // This should match the `id` of the selected option
                  onChange={handleSelectChange}
                  label="Vertical Type"
                >
                  {/* Dynamically generate MenuItems from verticalOptions */}
                  {verticalOptions.map((option) => (
                    <MenuItem key={option.verticalID} value={option.verticalID}>
                      {option.verticalName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth sx={{ marginTop: '9px' }} >
                <InputLabel id="adv-type-label">Advertisers</InputLabel>
                <Select
                  disabled={!selectedOption}
                  labelId="adv-type-label"
                  value={selectedPopUpAdv}
                  onChange={handleSelectAdv}
                  label="Advertisers"
                >

                  {advertiserList
                    .map((item: any) => (
                      <MenuItem value={item.advertiserID} key={item.advertiserID}>
                        {item.advName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleProceed} disabled={!selectedPopUpAdv} variant="contained" color="primary">
          OK
        </Button>
        <Button onClick={handleCancel} variant="outlined">
          Cancel
        </Button>
      </DialogActions>
      {/* </>
} */}

    </Dialog>
  );
};

export default CreateCampaignPopup;
