import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Switch,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  Paper,
  TableBody,
  FormControl,
  Button,
  InputAdornment,
  CircularProgress,
  useMediaQuery,
} from '@mui/material';
import {
  useFormik,
  FormikProvider,
  FieldArray,
  ErrorMessage,
  getIn,
} from 'formik';
import { IOSSwitch } from '../../Component/Table/MMTable';
import * as Yup from 'yup';
import { baseURLConfig } from '../../config/envConfig';
import { generateHeader } from '../../service/Auth';
import axios from 'axios';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MuiAlert from '@mui/material/Alert';
import { useAppSelector } from '../../Store/hooks';
import { updatePublisher } from '../../Services/publisherTabApis';

interface EditBudgetDetails {
  campaignData: any[];
}

const frequencyValue = [
  { key: 1, value: 'Daily' },
  { key: 2, value: 'Weekly' },
  { key: 3, value: 'Monthly' },
];
type CPCThresholdSettings = {
  cpcThresholdFilteringStatus: boolean;
  cpcThresholdInput: string;
};

type CampaignData = {
  SORTID: string;
  ID: string;
  cpcThresholdSettings: CPCThresholdSettings;
};

type CPCThreshold = {
  [key: string]: {
      SORTID: string;
      ID: string;
      cpcThresholdFilteringStatus: boolean;
      cpcThresholdInput: string;
      cpcThresholdSettings: CPCThresholdSettings;
  };
};




const getCPCThreshold=(input:CampaignData[])=>{
  const cpcThreshold: CPCThreshold = input.reduce((acc, item) => {
  
        acc[item.SORTID] = {
            SORTID: item.SORTID,
            ID: item.ID,
            cpcThresholdFilteringStatus: item.cpcThresholdSettings.cpcThresholdFilteringStatus,
            cpcThresholdInput: item.cpcThresholdSettings.cpcThresholdInput,
            cpcThresholdSettings: item.cpcThresholdSettings
        };
    
    return acc;
  }, {} as CPCThreshold);

  return cpcThreshold

}
// console.log(cpcThreshold);

const thresHoldInputValidation = function (
  value: string | undefined,
  context: Yup.TestContext
) {
  const { cpcThresholdFilteringStatus } = context.parent;
  return cpcThresholdFilteringStatus
    ? Yup.string().required('CPC Threshold is mandatory').isValidSync(value)
    : true;
};

const validationSchema = Yup.object().shape({
  campaignData: Yup.array().of(
    Yup.object().shape({
      cpcThresholdSettings: Yup.object().shape({
        cpcThresholdInput: Yup.string()
          .test(
            'Threshold-input-validation',
            'CPC Threshold is mandatory',
            thresHoldInputValidation
          )
          .matches(/^\d+(\.\d{1,2})?$/, 'Invalid CPC Threshold Format'),
      }),
    })
  ),
});

const updateCpcThreshold = async (payload: any) => {
  try {
    const headers = await generateHeader();
    const response = await axios.put(
      `${baseURLConfig.baseURl}/update/publishers/campaignCpcThreshold`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const EditCpcThreshold = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isDesktop = useMediaQuery('(min-width:600px)');
  const [successMessage, setSuccessMessage] = useState('');
  const [isSnackbarOpen, setSnackbarOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { mPubId,ress } = location.state;
  const fullData = useAppSelector(
    state => state.publisherAccApi.fullCpcData
  );
  // console.log("mPubID", mPubId)
  // console.log("FullData", fullData)
  const formik = useFormik<EditBudgetDetails>({
    initialValues: {
      campaignData: ress?.map((item: any) => ({
        ...item,
        cpcThresholdSettings: {
          ...item.cpcThresholdSettings,
          cpcThresholdFilteringStatus: item?.cpcThresholdSettings?.cpcThresholdFilteringStatus || false,
          cpcThresholdInput: item?.cpcThresholdSettings?.cpcThresholdInput || '',
        },
      })),
    },
    onSubmit: values => {
      setIsSubmitting(true);
      let payload = values?.campaignData.map(
        ({ SORTID,ID,cpcThresholdSettings }) => ({
            SORTID,
            ID,
            cpcThresholdSettings,
        })
      );
const selectedPublisher=fullData.find((item:any)=>{
  return item.mPubID===mPubId
})


      // here I will make update publisher API call.

      const cpcThreshold=getCPCThreshold(payload)
const updatePublisherPayload={
  ...selectedPublisher,
  values:{
    ...selectedPublisher.values,
     "cpcThreshold":cpcThreshold
  }
}

delete updatePublisherPayload.campaignCount
      // console.log("CPC threshold", cpcThreshold)
      // console.log('updated Publishrer', updatePublisherPayload)
      const updateData = async () => {
        try {
          const response=await updatePublisher(updatePublisherPayload)
          // console.log("response s ", response)
          const getData = await updateCpcThreshold(payload);
          
          setIsSubmitting(false);
          setSuccessMessage('CPC Threshold Updated Successfully');
          setSnackbarOpen(true);
        } catch (error) {
          setIsSubmitting(false);
          setSnackbarOpen(false);
          console.error('Error fetching data:', error);
        }
      };
      updateData();
    // setIsSubmitting(false);
    },
    validationSchema: validationSchema,
  });

  useEffect(() => {
if(!ress){
  navigate('/cpc-threshold')
}
  }, []);

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    if (isSnackbarOpen) {
      setTimeout(() => {
        navigate('/cpc-threshold');
      }, 1000);
    }
  }, [isSnackbarOpen]);
  return (
    <>
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
        className="card"
      >
        <Box
          display="flex"
          justifyContent="space-between"
          p={3}
          sx={{ flexDirection: { xs: 'column', md: 'row' } }}
        >
          <Box>
            <Typography className="card-heading">
              {' '}
              CPC Threshold 
            </Typography>
            <Typography className="card-sub-heading">
              Edit CPC Threshold
            </Typography>
          </Box>
        </Box>
        <Box
         style={{color:'#151D48',fontSize:'20px', fontWeight:'600', marginLeft:'16px'}}>{ress[0]?.publisher.GroupName} - {ress[0]?.publisherID}</Box> 
        <Box className="no-more-tables responsive-tbl">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell key="Campaigns">Pub Campaigns</TableCell>
                <TableCell
                  key="status"
                  width="20%"
                  sx={{ textAlign: 'left'}}
                >Status
                </TableCell>
                <TableCell key="input" width="25%" sx={{ textAlign: 'center' }}>
                CPC Threshold
                </TableCell>
              </TableRow>
            </TableHead>
            <FormikProvider value={formik}>
              <TableBody>
                <FieldArray
                  name="campaignData"
                  render={arrayHelpers => (
                    <>
                      {formik.values.campaignData.map(
                        (campaign: any, index: number) => {
                        //   const touchedType = getIn(
                        //     formik.touched,
                        //     `campaignData.${index}.cpcThresholdSettings.budgetType`
                        //   );
                        //   const errorType = getIn(
                        //     formik.errors,
                        //     `campaignData.${index}.cpcThresholdSettings.budgetType`
                        //   );
                          const touchedInput = getIn(
                            formik.touched,
                            `campaignData.${index}.cpcThresholdSettings.cpcThresholdInput`
                          );
                          const errorInput = getIn(
                            formik.errors,
                            `campaignData.${index}.cpcThresholdSettings.cpcThresholdInput`
                          );

                          return (
                            <TableRow key={index}>
                              <TableCell data-title="Pub Campaigns">
                                {`${campaign.campaignname} - ${campaign.SORTID}` }
                              </TableCell>
                              <TableCell
                                // sx={{ textAlign: 'center' }}
                                data-title="Status"
                              >
                                <FormControlLabel
                                  control={
                                    <IOSSwitch
                                    sx={{marginLeft:'5px'}}
                                      checked={
                                        campaign.cpcThresholdSettings
                                          ?.cpcThresholdFilteringStatus || false
                                      }
                                      onChange={() => {
                                        formik.setFieldValue(
                                          `campaignData[${index}].cpcThresholdSettings.cpcThresholdFilteringStatus`,
                                          !campaign.cpcThresholdSettings
                                            ?.cpcThresholdFilteringStatus
                                        );
                                      }}
                                    />
                                  }
                                  label=""
                                />
                              </TableCell>
                              <TableCell
                                data-title="CPC Threshold"
                                align="center"
                              >
                                <TextField
                                  label="CPC Threshold"
                                  name={`campaignData[${index}].cpcThresholdInput`}
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: isDesktop ? '60%' : '100%',
                                    margin: 'auto',
                                  }}
                                  value={
                                    formik.values.campaignData[index]
                                      .cpcThresholdSettings?.cpcThresholdInput
                                  }
                                  //fullWidth
                                  disabled={
                                    !campaign.cpcThresholdSettings
                                      ?.cpcThresholdFilteringStatus
                                  }
                                  onChange={e => {
                                    const { name, value } = e.target;

                                    // Regex to allow only numbers and one decimal point
                                    if (
                                      /^[0-9]\d*$/.test(value) ||
                                      value === ''
                                    ) {
                                      formik.setFieldValue(
                                        `campaignData[${index}].cpcThresholdSettings.cpcThresholdInput`,
                                        e.target.value
                                      );
                                    }
                                  }}
                                  error={touchedInput && Boolean(errorInput)}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <AttachMoneyIcon
                                          sx={{ fontSize: 20 }}
                                        />
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                                {touchedInput && errorInput ? (
                                  <Box color="#db2424">{errorInput}</Box>
                                ) : null}
                              </TableCell>
                            </TableRow>
                          );
                        }
                      )}
                    </>
                  )}
                />
              </TableBody>
            </FormikProvider>
          </Table>
        </Box>
        <Box
          className="mProgram-btn-container"
          sx={{
            textAlign: 'right',
            paddingRight: '30px',
            marginTop: '10px',
            marginBottom: '10px',
          }}
        >
          <Button
            onClick={() => {
              navigate('/cpc-threshold');
            }}
            sx={{
              backgroundColor: '#FFF',
              border: '1px solid #0AE',
              boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
              borderRadius: '8px',
              color: '#00AAEE',
              width: '100px',
              height: '45px',
            }}
          >
            Back
          </Button>
          <Button
            onClick={() => formik.handleSubmit()}
            sx={{
              backgroundColor: ' #00AAEE',
              '&.MuiButtonBase-root:hover': {
                bgcolor: '#00AAEE !important',
              },
              '&:hover': { backgroundColor: '#00AAEE' },
              border: '1px solid #0AE',
              boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
              borderRadius: '8px',
              color: '#ffff',
              height: '45px',
              marginLeft: '10px',
            }}
            // disabled={isSubmitting}
          >
            {
            isSubmitting ? <CircularProgress color="inherit" size={24} /> :
  
             "Save"
          }
          </Button>
        </Box>
      </Paper>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isSnackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackbar}
          severity={successMessage ? 'success' : 'error'}
        >
          {successMessage || errorMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default EditCpcThreshold;
