import { visuallyHidden } from '@mui/utils';
import * as React from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';

import FilterListIcon from '@mui/icons-material/FilterList';
import { EnhancedTableProps, advTable } from './tableInterface';
import { excludeKeys } from './MMTable';

export function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler =
    (property: keyof advTable) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  //  console.log("head cells", headCells)
  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => {
          if (headCell?.isHidden) {
            return; 
          }
          if (!excludeKeys.includes(headCell.id)) {
            return (
              <TableCell
                key={headCell.id}
                style={{
                  textAlign: ['Vertical_Count', 'Publisher_Campaign_Count', 'caps_active_campaign_count',  "campaign_count", 'active_Budget_Campaign_Count'].includes(headCell.id) ? 'center' : 'left',
                }}
                //   align={headCell.numeric ? 'right' : 'left'}
                align={'left'}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            );
          } else {
            return null;
          }
        })}
      </TableRow>
    </TableHead>
  );
}
