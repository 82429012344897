import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Tooltip,
  TextField,
  Typography,
  Input,
  FormControlLabel,
  RadioGroup,
  Radio,
  TableBody,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableContainer,
  CircularProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import { styled } from '@mui/system';
import { IOSSwitch } from '../../../Component/Table/MMTable';
import { Label } from '@mui/icons-material';
import { getAdvertiserByVertical } from '../../../service/Advertiser';
import { generateHeader, generateMultipartHeader } from '../../../service/Auth';
import { baseURLConfig } from '../../../config/envConfig';
import axios from 'axios';
import { isDisabled } from '@testing-library/user-event/dist/utils';
import { uploadFileToS3 } from '../../../service/S3 Upload';

const filedType: any = {
  leadsBillable: ['leadSubmitted', 'leadValid', 'leadRevenue'],
  qualityBillable: [
    'leadContacted',
    'leadApplication',
    'leadEnrolled',
    'leadStarted',
  ],
};
const staticHeaderMapping: any = [
  {
    name: 'mClickId',
    isRequired: false,
    isHardCode: false,
    value: '',
    label: 'mClickId',
  },
  {
    name: 'email',
    isRequired: false,
    isHardCode: false,
    value: '',
    label: 'Email',
  },
];
const headerMapping: any = [
  {
    name: 'leadSubmitted',
    isRequired: false,
    isHardCode: false,
    value: '',
    label: 'Lead Submitted',
  },
  {
    name: 'leadValid',
    isRequired: false,
    isHardCode: false,
    value: '',
    label: 'Lead Valid',
  },
  {
    name: 'leadRevenue',
    isRequired: false,
    isHardCode: false,
    value: '',
    label: 'Lead Revenue',
  },
  {
    name: 'leadContacted',
    isRequired: false,
    isHardCode: false,
    value: '',
    label: 'Lead Contacted',
  },
  {
    name: 'leadApplication',
    isRequired: false,
    isHardCode: false,
    value: '',
    label: 'Lead Application',
  },
  {
    name: 'leadEnrolled',
    isRequired: false,
    isHardCode: false,
    value: '',
    label: 'Lead Enrolled',
  },
  {
    name: 'leadStarted',
    isRequired: false,
    isHardCode: false,
    value: '',
    label: 'Lead Started',
  },
];

const tableHeader: any = ['Required', 'Key', 'Type', 'Value'];

const UploadFile: React.FC<any> = props => {
  const {
    openPopup,
    closePopup,
    vendorList,
    verticalList,
    selectedRow,
    refetchData,
  } = props;
  const [advertiseList, setAdvertiseList] = useState<any>('');
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [csvHeadersValid, setCsvHeadersValid] = useState<boolean | null>(null);
  const [csvMissingHeaders, setCsvMissingHeaders] = useState<any>('');
  const [fileTypeList, setFileTypeList] = useState<any>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const getDynamicValidationSchema = () => {
    const selectedFields = headerMapping;
    const validationSchema: any = {
      vertical: Yup.object().required('Vertical is required'),
      vendor: Yup.object().required('Vendor is required'),
      fileType: Yup.string().required('File Type is required'),
      advertiser: Yup.object().required('Advertiser is required'),
      email: Yup.object({
        isRequired: Yup.boolean(),
        headerMapping: Yup.lazy((value, context) => {
          const isRequired = context.parent.isRequired;
          if (isRequired) {
            return Yup.string().required('Header mapping is required');
          }
          return Yup.string().notRequired();
        }),
      }),
      mClickId: Yup.object({
        isRequired: Yup.boolean(),
        headerMapping: Yup.lazy((value, context) => {
          const isRequired = context.parent.isRequired;
          if (isRequired) {
            return Yup.string().required('Header mapping is required');
          }
          return Yup.string().notRequired();
        }),
      }),
      fileUpload: Yup.mixed()
        .required('File is required')
        .test('fileType', 'Invalid file type', (value: any) => {
          const validTypes = [
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'text/csv',
          ];
          return value && validTypes.includes(value.type);
        }),
    };
    if (selectedFields && selectedFields.length > 0) {
      selectedFields.forEach((field: any) => {
        validationSchema[field.name] = Yup.object({
          isRequired: Yup.boolean(),
          headerMapping: Yup.lazy((value, context) => {
            const isRequired = context.parent.isRequired;
            if (isRequired) {
              return Yup.string().required('Header mapping is required');
            }
            return Yup.string().notRequired();
          }),
          isHardCode: Yup.boolean(),
        });
      });
    }
    return Yup.object(validationSchema);
  };
  const formik: any = useFormik({
    initialValues: {
      vertical: selectedRow?.vertical || '',
      vendor: selectedRow?.vendor || '',
      advertiser: '',
      fileType: '',
      fileUpload: null,
    },
    validationSchema: getDynamicValidationSchema(),
    onSubmit: async values => {
      // console.log('values', values);
      values.fileUpload && validateCsvHeaders(values.fileUpload);
    },
  });

  const handleCancel = () => {
    closePopup();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;

    if (
      file &&
      (file.type === 'text/csv' ||
        file.type === 'application/vnd.ms-excel' ||
        file.type ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
    ) {
      setSelectedFile(file);
      formik.setFieldValue('fileUpload', file);
    } else {
      setSelectedFile(null);
      formik.setFieldValue('fileUpload', null);
    }
  };

  const addAdvFileRecord = async (payload: any) => {
    try {
      const headers = await generateHeader();
      const response = await axios.post(
        `${baseURLConfig.fileUploadURL}/createAdvFileRecord`,
        payload,
        { headers }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const updateAdvFileRecord = async (payload: any) => {
    try {
      const headers = await generateHeader();
      const response = await axios.put(
        `${baseURLConfig.fileUploadURL}/updateAdvFileRecord`,
        payload,
        { headers }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const getFileExtension = (filename: any) => {
    const parts = filename?.split('.');
    return parts.length > 1 ? parts[parts.length - 1] : '';
  };
  const removeBracketsAndInnerValue = (str: any) => {
    // Remove everything from the first occurrence of "(" or "[" until the matching closing parenthesis or bracket.
    return str.replace(/[\(\[].*?[\)\]]/, '')?.trim();
  };
  const validateHeaders = async (headers: any) => {
    const fieldsToValidate = [
      ...new Set([
        ...(filedType[formik.values.fileType] || []),
        'email',
        'mClickId',
      ]),
    ];
    const validFieldValues = fieldsToValidate
      .filter(
        (fieldName: string) =>
          fieldName in formik.values &&
          formik.values[fieldName] &&
          formik.values[fieldName].isRequired &&
          !formik.values[fieldName].isHardCode
      )
      .map((fieldName: string) => {
        return {
          fieldName: fieldName,
          value: formik.values[fieldName].headerMapping,
        };
      });

    const invalidFields = validFieldValues
      ?.filter(field => field.fieldName && !headers.includes(field.value))
      ?.map(field => {
        formik.setFieldError(
          `${field.fieldName}.headerMapping`,
          `Missing field in CSV headers: ${field.value}`
        );
        return field.fieldName;
      });

    if (!invalidFields?.length) {
      setIsLoading(true);
      const fileExtension = getFileExtension(formik.values?.fileUpload.name);

      const bucketName = `${
        formik.values.vendor.GroupName
      }/${removeBracketsAndInnerValue(formik.values?.vertical?.verticalName)}/${
        formik.values.advertiser.advertiserName
      }/${formik.values.fileType}`;

      const key =await uploadFileToS3(
        formik.values.fileUpload,
        bucketName,
        fileExtension
      );
      console.log('key',key)
      delete formik.values?.lastModifiedDate;
      delete formik.values?.createdDate;
      delete formik.values?.fileUpload;
      if (selectedRow) {
        await updateAdvFileRecord({...formik.values,s3Key:key});
      } else {
        await addAdvFileRecord({...formik.values,s3Key:key});
      }
      refetchData();
      setIsLoading(false);
      closePopup();
    }
  };
  const validateCsvHeaders = async (file: File) => {
    const isExcelFile =
      file.type === 'application/vnd.ms-excel' ||
      file.type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    if (isExcelFile) {
      // Handle Excel files using the xlsx library
      const reader = new FileReader();

      // Define the onload function for the reader
      reader.onload = (e: any) => {
        const fileData = e.target.result;
        // if (!fileData) {
        //   console.error('File data is not valid');
        //   return;
        // }

        const data = new Uint8Array(fileData);

        try {
          const workbook = XLSX.read(data, { type: 'array' });

          const sheet = workbook.Sheets[workbook.SheetNames[0]];

          // if (!sheet) {
          //   console.error('No sheet found in the workbook');
          //   return;
          // }

          // Get the headers from the first row (if present)
          const headers = XLSX.utils.sheet_to_json(sheet, { header: 1 })[0];

          validateHeaders(headers);
          // Call validateHeaders if headers are found
          // if (headers && headers?.length > 0) {
          //   //validateHeaders(headers);
          // } else {
          //   console.error('No headers found in the sheet');
          // }
        } catch (error) {
          console.error('Error reading or parsing Excel file:', error);
        }
      };

      // Error handling for the FileReader
      reader.onerror = error => {
        console.error('Error reading the file:', error);
      };

      reader.readAsArrayBuffer(file);
    } else if (file.type === 'text/csv') {
      await Papa.parse(file, {
        complete: result => {
          const headers: any = result.data[0];
          validateHeaders(headers);
        },
        header: false, // Don't treat the first row as headers
        skipEmptyLines: true,
      });
    }
  };

  const renderTableBody = () => {
    const selectedFields = headerMapping.filter((item: any) =>
      filedType[formik.values.fileType]?.includes(item.name)
    );
    return (
      <TableBody>
        {[...staticHeaderMapping, ...selectedFields].map((field: any) => (
          <TableRow key={field.name}>
            <TableCell
              // style={{ padding: '4px', lineHeight: '1', paddingLeft: '20px', }}
              data-title="Required"
            >
              <FormControlLabel
                control={
                  <IOSSwitch
                    checked={formik.values[field.name]?.isRequired || false} // Switch mapped to `isRequired`
                    name={`${field.name}.isRequired`} // Name path for Formik
                    onChange={e => {
                      formik.setFieldValue(field.name, {
                        ...formik.values[field.name], // Spread previous values
                        isRequired: e.target.checked, // Update `isRequired` here
                      });
                    }}
                    sx={{ ml: 2 }}
                  />
                }
                label=""
                labelPlacement="start"
                sx={{ ml: 1 }}
              />
            </TableCell>
            <TableCell
              // style={{ padding: '4px', lineHeight: '1', paddingLeft: '20px', textAlign: 'center', }}
              data-title="Key"
            >
              {' '}
              <Typography variant="body1">{field.label}</Typography>
            </TableCell>
            <TableCell
              //   style={{ padding: '4px', lineHeight: '1', paddingLeft: '20px' }}
              data-title="Type"
            >
              <Button
                variant={
                  !formik.values[field.name]?.isHardCode
                    ? 'contained'
                    : 'outlined'
                }
                color={
                  !formik.values[field.name]?.isHardCode ? 'success' : undefined
                }
                onClick={e => {
                  formik.setFieldValue(field.name, {
                    ...formik.values[field.name],
                    isHardCode: false, // Update `isHardCode` here
                  });
                }}
                size="small"
              >
                Header
              </Button>
              <Button
                variant={
                  formik.values[field.name]?.isHardCode
                    ? 'contained'
                    : 'outlined'
                }
                color={
                  formik.values[field.name]?.isHardCode ? 'primary' : undefined
                }
                onClick={e => {
                  formik.setFieldValue(field.name, {
                    ...formik.values[field.name],
                    isHardCode: true, // Update `isHardCode` here
                  });
                }}
                size="small"
              >
                Hardcode
              </Button>
            </TableCell>
            <TableCell
              //  style={{ padding: '4px', lineHeight: '1', paddingLeft: '20px' }}
              data-title="Value"
            >
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  variant="outlined"
                  label={
                    formik.values[field.name]?.isHardCode
                      ? 'HardCode Value'
                      : 'Header Mapping'
                  }
                  name={`${field.name}.headerMapping`}
                  value={formik.values[field.name]?.headerMapping || ''}
                  onChange={formik.handleChange}
                  size="small"
                />
                {formik.touched[field.name]?.headerMapping &&
                  formik.errors[field.name]?.headerMapping && (
                    <Box
                      color="#db2424"
                      sx={{
                        fontSize: '15px',

                        top: '100%',
                        position: 'absolute',
                      }}
                    >
                      {formik.errors[field.name]?.headerMapping}
                    </Box>
                  )}
              </FormControl>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    );
  };
  const resetFormFiled = async (fileTypeValue: any) => {
    const selectedFields = headerMapping.filter((item: any) =>
      filedType[fileTypeValue]?.includes(item.name)
    );

    const updatedValues = selectedFields.reduce((acc: any, field: any) => {
      acc[field.name] = {
        headerMapping: '',
        isRequired: false,
        isHardCode: false,
      };
      return acc;
    }, {});

    await formik.setValues((prevValues: any) => {
      const updatedValuesCopy = { ...prevValues };

      const keysToDelete =
        filedType[
          fileTypeValue === 'qualityBillable'
            ? 'leadsBillable'
            : 'qualityBillable'
        ];

      keysToDelete.forEach((key: any) => {
        delete updatedValuesCopy[key];
      });

      return {
        ...updatedValuesCopy,
        ...updatedValues,
        email: {
          headerMapping: '',
          isRequired: false,
          isHardCode: false,
        },
        mClickId: {
          headerMapping: '',
          isRequired: false,
          isHardCode: false,
        },
      };
    });
  };

  const fetchData = async () => {
    const getList = await getAdvertiserByVertical(
      `getAdvertiserByVertical?vertical=${formik.values.vertical?.verticalID}&vendorName=${formik.values.vendor.GroupName}`
    );
    const list = getList?.result?.data?.map((val: any) => ({
      advertiserName: val.advertiserName,
      advertiserID: val.advertiserID,
    }));
    setAdvertiseList(list);
  };

  const checkFileTypeRecord = async (payload: any) => {
    try {
      const headers = await generateHeader();
      const response = await axios.post(
        `${baseURLConfig.fileUploadURL}/checkFileTypeRecord`,
        payload,
        { headers }
      );

      setFileTypeList([
        {
          key: 'leadsBillable',
          label: 'Lead Billable Uploads',
          isDisabled: response.data?.disableLeadBillable,
        },
        {
          key: 'qualityBillable',
          label: 'Lead Quality Uploads',
          isDisabled: response.data?.disableQualityBillable,
        },
      ]);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    if (formik.values.vertical && formik.values.vendor) {
      fetchData();
    }
  }, [formik.values.vertical, formik.values.vendor]);

  useEffect(() => {
    if (
      formik.values.vertical &&
      formik.values.vendor &&
      formik.values.advertiser
    ) {
      if (selectedRow) {
        setFileTypeList([
          {
            key: 'leadsBillable',
            label: 'Lead Billable Uploads',
            isDisabled: false,
          },
          {
            key: 'qualityBillable',
            label: 'Lead Quality Uploads',
            isDisabled: false,
          },
        ]);
      } else {
        checkFileTypeRecord({
          vendor: formik.values.vendor.GroupName,
          verticalID: formik.values.vertical.verticalID,
          advertiserID: formik.values.advertiser.advertiserID,
        });
      }
    }
  }, [formik.values.vertical, formik.values.vendor, formik.values.advertiser]);

  useEffect(() => {
    if (selectedRow) {
      Object.entries(selectedRow).forEach(([key, value]) => {
        if (!(key === 'advertiser' || key === 'fileType')) {
          formik.setFieldValue(key, value);
        }
      });
    }
  }, [selectedRow]);

  useEffect(() => {
    if (advertiseList && selectedRow) {
      formik.setFieldValue('advertiser', selectedRow?.advertiser);
    }
  }, [advertiseList]);

  useEffect(() => {
    if (fileTypeList && selectedRow) {
      formik.setFieldValue('fileType', selectedRow?.fileType);
    }
  }, [fileTypeList]);

  return (
    <Dialog open={openPopup} fullWidth maxWidth="md">
      <DialogTitle>Upload File</DialogTitle>
      <div className="close-button" onClick={closePopup}>
        <CloseIcon style={{ fontSize: '32px' }} />
      </div>
      <DialogContent className="Update-user">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth required>
              <InputLabel>Select Vendor</InputLabel>
              <Select
                label="Select Vendor"
                value={formik.values.vendor?.GroupName}
                name="vendor"
                // onChange={formik.handleChange}
                inputProps={{ readOnly: Boolean(selectedRow) }}
                onChange={e => {
                  formik.setFieldValue(
                    'vendor',
                    vendorList.find(
                      (val: any) => val.GroupName === e.target.value
                    )
                  );
                }}
                error={formik.touched.vendor && Boolean(formik.errors.vendor)}
              >
                {vendorList?.map((val: any) => (
                  <MenuItem key={val?.GroupName} value={val?.GroupName}>
                    {val.GroupName?.replace(/^Vendor-/, '')}
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.vendor && formik.errors.vendor && (
                <Typography color="error">{formik.errors.vendor}</Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth required>
              <InputLabel>Select Vertical</InputLabel>
              <Select
                label="Select Vertical"
                value={formik.values.vertical?.verticalID}
                name="vertical"
                inputProps={{ readOnly: Boolean(selectedRow) }}
                onChange={e => {
                  formik.setFieldValue(
                    'vertical',
                    verticalList.find(
                      (val: any) => val.verticalID === e.target.value
                    )
                  );
                }}
                error={
                  formik.touched.vertical && Boolean(formik.errors.vertical)
                }
              >
                {verticalList?.map((val: any) => (
                  <MenuItem key={val?.verticalID} value={val?.verticalID}>
                    {val?.verticalName}
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.vertical && formik.errors.vertical && (
                <Typography color="error">{formik.errors.vertical}</Typography>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth required>
              <InputLabel>Select Advertiser</InputLabel>
              <Select
                label="Select Advertiser"
                value={formik.values?.advertiser?.advertiserID || ''}
                name="advertiser"
                inputProps={{ readOnly: Boolean(selectedRow) }}
                //  onChange={formik.handleChange}
                onChange={e => {
                  console.log('advertiser', e.target.value);
                  formik.setFieldValue(
                    'advertiser',
                    advertiseList.find(
                      (val: any) => val.advertiserID === e.target.value
                    )
                  );
                }}
                error={
                  formik.touched.advertiser && Boolean(formik.errors.advertiser)
                }
              >
                {advertiseList &&
                  advertiseList.map((val: any) => (
                    <MenuItem key={val.advertiserID} value={val.advertiserID}>
                      {val.advertiserName}
                    </MenuItem>
                  ))}
              </Select>
              {formik.touched.advertiser && formik.errors.advertiser && (
                <Typography color="error">
                  {formik.errors.advertiser}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth required>
              <InputLabel>Select File Type</InputLabel>
              <Select
                label="Select File Type"
                value={formik.values.fileType}
                name="fileType"
                inputProps={{ readOnly: Boolean(selectedRow) }}
                onChange={e => {
                  resetFormFiled(e.target.value);
                  formik.setFieldValue('fileType', e.target.value);
                }}
                error={
                  formik.touched.fileType && Boolean(formik.errors.fileType)
                }
              >
                {fileTypeList &&
                  fileTypeList?.map((val: any) => (
                    <MenuItem
                      key={val.key}
                      value={val.key}
                      disabled={val.isDisabled}
                    >
                      {val.label}
                    </MenuItem>
                  ))}
              </Select>
              {formik.touched.fileType && formik.errors.fileType && (
                <Typography color="error">{formik.errors.fileType}</Typography>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Input
              type="file"
              id="fileUpload"
              name="fileUpload"
              inputProps={{ accept: '.csv, .xlsx, .xls' }}
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
            <Box display="flex" flexDirection="column">
              <Box display="flex" gap={1}>
                <Tooltip title="Upload CSV file" arrow>
                  <TextField
                    value={selectedFile ? selectedFile.name : ''}
                    variant="outlined"
                    fullWidth
                    label="Upload File*"
                    disabled
                    error={
                      formik.touched.fileUpload &&
                      Boolean(formik.errors.fileUpload)
                    }
                  />
                </Tooltip>

                <Button
                  variant="contained"
                  size="large"
                  sx={{
                    backgroundColor: '#1976d2',
                    '&:hover': {
                      backgroundColor: '#1976d2',
                    },
                    border: '1px solid #1976d2',
                    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                    borderRadius: '8px',
                    height: '45px',
                    margin: '5px',
                  }}
                  onClick={() => document.getElementById('fileUpload')?.click()}
                >
                  <Typography>Upload</Typography>
                </Button>
              </Box>
              {formik.touched.fileUpload && formik.errors.fileUpload && (
                <Typography color="error">
                  {formik.errors.fileUpload}
                </Typography>
              )}
              {csvMissingHeaders && (
                <Typography color="error">{csvMissingHeaders}</Typography>
              )}
            </Box>
          </Grid>
          {Boolean(formik.values.fileType) && (
            <Grid item xs={12}>
              <TableContainer style={{ position: 'relative' }}>
                <Box
                  sx={{ width: '100%' }}
                  className="no-more-tables responsive-tbl"
                >
                  <Table size="small" className="uploadTable">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          style={{
                            textAlign: 'center',
                          }}
                        >
                          Required
                        </TableCell>
                        <TableCell
                          style={{
                            textAlign: 'center',
                          }}
                        >
                          Key
                        </TableCell>
                        <TableCell
                          style={{
                            textAlign: 'center',
                          }}
                        >
                          Type
                        </TableCell>
                        <TableCell
                          style={{
                            textAlign: 'center',
                          }}
                        >
                          Value
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {renderTableBody()}
                  </Table>
                </Box>
              </TableContainer>
            </Grid>
          )}
        </Grid>
      </DialogContent>

      <DialogActions style={{ paddingBottom: '15px', paddingRight: '20px' }}>
        <Button onClick={closePopup} style={{ color: 'red' }}>
          CANCEL
        </Button>
        <Button
          variant="contained"
          onClick={() => formik.handleSubmit()}
          color="primary"
          disabled={isLoading}
        >
          {isLoading ? (
            <CircularProgress color="inherit" size={24} />
          ) : (
            'Create'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadFile;
