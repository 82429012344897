import React, { FC, useEffect, useState } from 'react'
import { generateMediaSourceDataForUI } from '../../../../Mock/MediaSourceData';
import MMTabs from '../../../../Component/Tabs/MMTabs';
import { useAppDispatch, useAppSelector } from '../../../../Store/hooks';
import { campaignFormSelector } from '../../../../Store/Slices/CreateAdvCampaignSlice';
import { ICampaignData } from '../../../../Store/sliceInteface';
import { fetchMediaSourceDataAction } from '../../../../Store/Actions';
import { Box } from '@mui/material';
import { CircleLoader } from 'react-spinners';
import { useLocation } from 'react-router-dom';
import { createTcpaCampAsync, setSnackbarMessage, setSnackbarOpen, setSnackbarType, setTcpaCreateJson, setTcpaEditJson } from '../../../../Store/Slices/tcpaCampSlice';
import { CAMPAIGN_CREATE_SUCCESS, CAMPAIGN_UPDATE_SUCCESS, TCPA_CREATE_SUCCESS, TCPA_UPDATE_SUCCESS } from '../../../../Mock/MessageContstant';
import { ErrorMessage } from 'formik';
// import { ICampaignData } from '../../../../Store/Slices/CreateAdvCampaignSlice';
// import { useMemo } from 'react';

// const MediaSource = ({ submitRef, handleNext }) => {
const MediaSource: FC<any> = ({ submitRef, handleNext }) => {
  const location = useLocation();
  const currentState = useAppSelector(campaignFormSelector)
  const [responseValue, setResponseValue] = useState<null | any>(null)
  const dispatch = useAppDispatch()
  const advCampaignFormState = useAppSelector(campaignFormSelector);
  const tcpaCampId = useAppSelector((state) => state.tcpaCampSlice.payloadTcpaId);

  const tcpaCampSlice = useAppSelector((state) => state.tcpaCampSlice);
  const { tcpaEditJsonData, tcpaEditFlag, tcpaCampaignIdOnEdit, tcpaCreateJsonData } = tcpaCampSlice

  //console.log(" media source page REDUX store", advCampaignFormState)
  const { mediaSourceTabLoaderPublisher, mediaSourceTabLoaderPublisherCampaigns, publisherLists, } = advCampaignFormState
  // console.log("")
  useEffect(() => {
    dispatch(fetchMediaSourceDataAction())

  }, [])

  // const truncateString=(item:any)=>{
  //   let spacePosition = item.label.indexOf(" ");
  //   if (spacePosition !== -1) {
  //     console.log("The first space is at position:", spacePosition);
  // } else {
  //   //  console.log("item.label.length",item.label.length )
  //   //  console.log("item.label not have spcae",item)
  //    if(item.label.length>25){
  //    const newLable= item.label.substring(0, 25) + "...";
  //    item.label=newLable
  //    }
  // } 
  // }
  // const [publisherList, setPublisherList]=useState<any[]>([]);
  let publisherList: any[] = []
  if (!mediaSourceTabLoaderPublisherCampaigns && !mediaSourceTabLoaderPublisher) {
    publisherList = generateMediaSourceDataForUI(publisherLists).sort((a, b) => a.label.localeCompare(b.label))
    const newP = publisherList.map(item => {
      return {
        ...item,
        label: item.label.length > 28 && !item.label.includes(" ") ? `${item.label.slice(0, 15)}...` : item.label,
        // value: item.value,

      }
    });
    publisherList = newP
    // console.log("publisher list ", publisherList)
    //   console.log("publisher list::::Updated string ", newP)
    //  publisherList.forEach(truncateString)
  }


  // console.log("publisher List ", publisherList);

  //  useEffect(()=>{

  //  }, [mediaSourceTabLoaderPublisherCampaigns ,mediaSourceTabLoaderPublisher])
  //console.log("publisher List for media souce data c", publisherList)
  const generateMediaSourceTabContent = (key: string = "1") => {
    if (publisherList.length > 1) {
      const value = publisherList[parseInt(key) - 1].value

      let jsonData: ICampaignData = (tcpaEditFlag && location.pathname === '/editTcpaCamp') ?
        (tcpaEditJsonData?.mediaSource || { ...advCampaignFormState.value.excludePublishers })
        : { ...advCampaignFormState.value.excludePublishers }


      //  console.log('tcpaCreateJsonData---',tcpaCreateJsonData)
      if (tcpaCreateJsonData?.mediaSource && !tcpaEditFlag) {
        // console.log('444',tcpaCreateJsonData)
        jsonData = tcpaCreateJsonData?.mediaSource;
      }
      // const jsonData: ICampaignData = { ...advCampaignFormState.value.excludePublishers} 

      // const jsonDataaa =     tcpaEditJsonData[0].mediaSource : []


      if (jsonData.hasOwnProperty(value)) {
        return [jsonData[value]];
      }
    }

  }

  useEffect(() => {
    if (location.pathname === '/createTcpaCamp' || location.pathname === '/editTcpaCamp') {
      submitRef.current = () => {
        handleSubmit();
      }
    }
  }, [currentState.value.excludePublishers]);

  const handleSubmit = async () => {
    const payload = {
      mediaSource: currentState.value.excludePublishers,
      campaignType: "tcpaCampaign",
      tcpaCampaignId: tcpaEditFlag ? tcpaCampaignIdOnEdit : tcpaCampId,
    }
    try {
      const response = await dispatch(createTcpaCampAsync({ payload: payload, method: 'put' }));
      if (response) {
        tcpaEditFlag && dispatch(setTcpaEditJson(response.payload.data))
        !tcpaEditFlag && dispatch(setTcpaCreateJson(response.payload.data))
        dispatch(setSnackbarOpen(true))
        dispatch(setSnackbarType("success"))
        dispatch(setSnackbarMessage(TCPA_UPDATE_SUCCESS))
        handleNext()
      }
    } catch (err) {
      console.error(err);
      dispatch(setSnackbarOpen(true))
      dispatch(setSnackbarType("error"))
      dispatch(setSnackbarMessage(ErrorMessage))
    }

  }




  // const memoizedGenerateMediaSourceTabContent = useMemo(() => generateMediaSourceTabContent, []);
  return (
    <React.Fragment>
      {mediaSourceTabLoaderPublisherCampaigns ? <Box className="loading-spinner-container">

        <CircleLoader color="#00BFFF" loading={mediaSourceTabLoaderPublisherCampaigns ? true : false} size={60} />
      </Box>
        :
        <MMTabs tabContent={publisherList} generateTabPanelContentHandler={generateMediaSourceTabContent} />}
    </React.Fragment>

  )
}

export default MediaSource