export const defaultTCPACSVConfig=[
    {
        "Status": "off",
        "parameter name": "General Business",
        "parameterType": "mProgram",
        "parameterId": "100",
        "advertiser_key": "",
        "categoryName": "Business & Finance",
        "categoryID": "1"
    },
    {
        "Status": "off",
        "parameter name": "Accounting",
        "parameterType": "mProgram",
        "parameterId": "101",
        "advertiser_key": "",
        "categoryName": "Business & Finance",
        "categoryID": "1"
    },
    {
        "Status": "off",
        "parameter name": "Business Administration",
        "parameterType": "mProgram",
        "parameterId": "102",
        "advertiser_key": "",
        "categoryName": "Business & Finance",
        "categoryID": "1"
    },
    {
        "Status": "off",
        "parameter name": "Business Analytics",
        "parameterType": "mProgram",
        "parameterId": "103",
        "advertiser_key": "",
        "categoryName": "Business & Finance",
        "categoryID": "1"
    },
    {
        "Status": "off",
        "parameter name": "Communications",
        "parameterType": "mProgram",
        "parameterId": "104",
        "advertiser_key": "",
        "categoryName": "Business & Finance",
        "categoryID": "1"
    },
    {
        "Status": "off",
        "parameter name": "Economics",
        "parameterType": "mProgram",
        "parameterId": "105",
        "advertiser_key": "",
        "categoryName": "Business & Finance",
        "categoryID": "1"
    },
    {
        "Status": "off",
        "parameter name": "Finance",
        "parameterType": "mProgram",
        "parameterId": "106",
        "advertiser_key": "",
        "categoryName": "Business & Finance",
        "categoryID": "1"
    },
    {
        "Status": "off",
        "parameter name": "Human Resources",
        "parameterType": "mProgram",
        "parameterId": "107",
        "advertiser_key": "",
        "categoryName": "Business & Finance",
        "categoryID": "1"
    },
    {
        "Status": "off",
        "parameter name": "Management",
        "parameterType": "mProgram",
        "parameterId": "108",
        "advertiser_key": "",
        "categoryName": "Business & Finance",
        "categoryID": "1"
    },
    {
        "Status": "off",
        "parameter name": "Digital Marketing",
        "parameterType": "mProgram",
        "parameterId": "109",
        "advertiser_key": "",
        "categoryName": "Business & Finance",
        "categoryID": "1"
    },
    {
        "Status": "off",
        "parameter name": "Project Management",
        "parameterType": "mProgram",
        "parameterId": "110",
        "advertiser_key": "",
        "categoryName": "Business & Finance",
        "categoryID": "1"
    },
    {
        "Status": "off",
        "parameter name": "Sports Management",
        "parameterType": "mProgram",
        "parameterId": "111",
        "advertiser_key": "",
        "categoryName": "Business & Finance",
        "categoryID": "1"
    },
    {
        "Status": "off",
        "parameter name": "Healthcare Administration",
        "parameterType": "mProgram",
        "parameterId": "112",
        "advertiser_key": "",
        "categoryName": "Business & Finance",
        "categoryID": "1"
    },
    {
        "Status": "off",
        "parameter name": "Entrepreneurship",
        "parameterType": "mProgram",
        "parameterId": "113",
        "advertiser_key": "",
        "categoryName": "Business & Finance",
        "categoryID": "1"
    },
    {
        "Status": "off",
        "parameter name": "General Criminal Justice",
        "parameterType": "mProgram",
        "parameterId": "200",
        "advertiser_key": "",
        "categoryName": "Criminal Justice & Legal Services",
        "categoryID": "2"
    },
    {
        "Status": "off",
        "parameter name": "Corrections",
        "parameterType": "mProgram",
        "parameterId": "201",
        "advertiser_key": "",
        "categoryName": "Criminal Justice & Legal Services",
        "categoryID": "2"
    },
    {
        "Status": "off",
        "parameter name": "Crime Scence Investigation",
        "parameterType": "mProgram",
        "parameterId": "202",
        "advertiser_key": "",
        "categoryName": "Criminal Justice & Legal Services",
        "categoryID": "2"
    },
    {
        "Status": "off",
        "parameter name": "Criminal Justice",
        "parameterType": "mProgram",
        "parameterId": "203",
        "advertiser_key": "",
        "categoryName": "Criminal Justice & Legal Services",
        "categoryID": "2"
    },
    {
        "Status": "off",
        "parameter name": "Criminology",
        "parameterType": "mProgram",
        "parameterId": "204",
        "advertiser_key": "",
        "categoryName": "Criminal Justice & Legal Services",
        "categoryID": "2"
    },
    {
        "Status": "off",
        "parameter name": "Cyber Security",
        "parameterType": "mProgram",
        "parameterId": "205",
        "advertiser_key": "",
        "categoryName": "Criminal Justice & Legal Services",
        "categoryID": "2"
    },
    {
        "Status": "off",
        "parameter name": "Forensic Science",
        "parameterType": "mProgram",
        "parameterId": "206",
        "advertiser_key": "",
        "categoryName": "Criminal Justice & Legal Services",
        "categoryID": "2"
    },
    {
        "Status": "off",
        "parameter name": "Homeland Security",
        "parameterType": "mProgram",
        "parameterId": "207",
        "advertiser_key": "",
        "categoryName": "Criminal Justice & Legal Services",
        "categoryID": "2"
    },
    {
        "Status": "off",
        "parameter name": "Law Enforcement",
        "parameterType": "mProgram",
        "parameterId": "208",
        "advertiser_key": "",
        "categoryName": "Criminal Justice & Legal Services",
        "categoryID": "2"
    },
    {
        "Status": "off",
        "parameter name": "Legal & Paralegal Studies",
        "parameterType": "mProgram",
        "parameterId": "209",
        "advertiser_key": "",
        "categoryName": "Criminal Justice & Legal Services",
        "categoryID": "2"
    },
    {
        "Status": "off",
        "parameter name": "Public Safety Administration",
        "parameterType": "mProgram",
        "parameterId": "211",
        "advertiser_key": "",
        "categoryName": "Criminal Justice & Legal Services",
        "categoryID": "2"
    },
    {
        "Status": "off",
        "parameter name": "Security",
        "parameterType": "mProgram",
        "parameterId": "212",
        "advertiser_key": "",
        "categoryName": "Criminal Justice & Legal Services",
        "categoryID": "2"
    },
    {
        "Status": "off",
        "parameter name": "Fire Science",
        "parameterType": "mProgram",
        "parameterId": "213",
        "advertiser_key": "",
        "categoryName": "Criminal Justice & Legal Services",
        "categoryID": "2"
    },
    {
        "Status": "off",
        "parameter name": "General Psychology",
        "parameterType": "mProgram",
        "parameterId": "300",
        "advertiser_key": "",
        "categoryName": "Psychology & Counseling",
        "categoryID": "3"
    },
    {
        "Status": "off",
        "parameter name": "Addictions & Recovery",
        "parameterType": "mProgram",
        "parameterId": "301",
        "advertiser_key": "",
        "categoryName": "Psychology & Counseling",
        "categoryID": "3"
    },
    {
        "Status": "off",
        "parameter name": "Applied Psychology",
        "parameterType": "mProgram",
        "parameterId": "302",
        "advertiser_key": "",
        "categoryName": "Psychology & Counseling",
        "categoryID": "3"
    },
    {
        "Status": "off",
        "parameter name": "Behavioral Psychology",
        "parameterType": "mProgram",
        "parameterId": "303",
        "advertiser_key": "",
        "categoryName": "Psychology & Counseling",
        "categoryID": "3"
    },
    {
        "Status": "off",
        "parameter name": "Child and Adolescent Psychology",
        "parameterType": "mProgram",
        "parameterId": "304",
        "advertiser_key": "",
        "categoryName": "Psychology & Counseling",
        "categoryID": "3"
    },
    {
        "Status": "off",
        "parameter name": "Counseling",
        "parameterType": "mProgram",
        "parameterId": "305",
        "advertiser_key": "",
        "categoryName": "Psychology & Counseling",
        "categoryID": "3"
    },
    {
        "Status": "off",
        "parameter name": "Forensic Psychology",
        "parameterType": "mProgram",
        "parameterId": "307",
        "advertiser_key": "",
        "categoryName": "Psychology & Counseling",
        "categoryID": "3"
    },
    {
        "Status": "off",
        "parameter name": "Human Services",
        "parameterType": "mProgram",
        "parameterId": "308",
        "advertiser_key": "",
        "categoryName": "Psychology & Counseling",
        "categoryID": "3"
    },
    {
        "Status": "off",
        "parameter name": "Organizational Psychology",
        "parameterType": "mProgram",
        "parameterId": "310",
        "advertiser_key": "",
        "categoryName": "Psychology & Counseling",
        "categoryID": "3"
    },
    {
        "Status": "off",
        "parameter name": "Sociology",
        "parameterType": "mProgram",
        "parameterId": "311",
        "advertiser_key": "",
        "categoryName": "Psychology & Counseling",
        "categoryID": "3"
    },
    {
        "Status": "off",
        "parameter name": "General Computers & IT",
        "parameterType": "mProgram",
        "parameterId": "400",
        "advertiser_key": "",
        "categoryName": "Computers & Technology",
        "categoryID": "4"
    },
    {
        "Status": "off",
        "parameter name": "Bootcamp Programs",
        "parameterType": "mProgram",
        "parameterId": "401",
        "advertiser_key": "",
        "categoryName": "Computers & Technology",
        "categoryID": "4"
    },
    {
        "Status": "off",
        "parameter name": "Computer Programming",
        "parameterType": "mProgram",
        "parameterId": "402",
        "advertiser_key": "",
        "categoryName": "Computers & Technology",
        "categoryID": "4"
    },
    {
        "Status": "off",
        "parameter name": "Data Science & Analytics",
        "parameterType": "mProgram",
        "parameterId": "403",
        "advertiser_key": "",
        "categoryName": "Computers & Technology",
        "categoryID": "4"
    },
    {
        "Status": "off",
        "parameter name": "Game Art Development",
        "parameterType": "mProgram",
        "parameterId": "404",
        "advertiser_key": "",
        "categoryName": "Computers & Technology",
        "categoryID": "4"
    },
    {
        "Status": "off",
        "parameter name": "Information Systems",
        "parameterType": "mProgram",
        "parameterId": "405",
        "advertiser_key": "",
        "categoryName": "Computers & Technology",
        "categoryID": "4"
    },
    {
        "Status": "off",
        "parameter name": "Information Technology",
        "parameterType": "mProgram",
        "parameterId": "406",
        "advertiser_key": "",
        "categoryName": "Computers & Technology",
        "categoryID": "4"
    },
    {
        "Status": "off",
        "parameter name": "IT Project Managment",
        "parameterType": "mProgram",
        "parameterId": "407",
        "advertiser_key": "",
        "categoryName": "Computers & Technology",
        "categoryID": "4"
    },
    {
        "Status": "off",
        "parameter name": "Network Security",
        "parameterType": "mProgram",
        "parameterId": "408",
        "advertiser_key": "",
        "categoryName": "Computers & Technology",
        "categoryID": "4"
    },
    {
        "Status": "off",
        "parameter name": "Product and Graphic Design",
        "parameterType": "mProgram",
        "parameterId": "409",
        "advertiser_key": "",
        "categoryName": "Computers & Technology",
        "categoryID": "4"
    },
    {
        "Status": "off",
        "parameter name": "Software Engineering",
        "parameterType": "mProgram",
        "parameterId": "410",
        "advertiser_key": "",
        "categoryName": "Computers & Technology",
        "categoryID": "4"
    },
    {
        "Status": "off",
        "parameter name": "Web Development",
        "parameterType": "mProgram",
        "parameterId": "411",
        "advertiser_key": "",
        "categoryName": "Computers & Technology",
        "categoryID": "4"
    },
    {
        "Status": "off",
        "parameter name": "CyberSecurity",
        "parameterType": "mProgram",
        "parameterId": "412",
        "advertiser_key": "",
        "categoryName": "Computers & Technology",
        "categoryID": "4"
    },
    {
        "Status": "off",
        "parameter name": "General Healthcare",
        "parameterType": "mProgram",
        "parameterId": "500",
        "advertiser_key": "",
        "categoryName": "Healthcare & Medical Assisting",
        "categoryID": "5"
    },
    {
        "Status": "off",
        "parameter name": "Certified Nursing Assistant (CNA)",
        "parameterType": "mProgram",
        "parameterId": "501",
        "advertiser_key": "",
        "categoryName": "Healthcare & Medical Assisting",
        "categoryID": "5"
    },
    {
        "Status": "off",
        "parameter name": "Allied Health",
        "parameterType": "mProgram",
        "parameterId": "502",
        "advertiser_key": "",
        "categoryName": "Healthcare & Medical Assisting",
        "categoryID": "5"
    },
    {
        "Status": "off",
        "parameter name": "Emergency Management (EMT)",
        "parameterType": "mProgram",
        "parameterId": "503",
        "advertiser_key": "",
        "categoryName": "Healthcare & Medical Assisting",
        "categoryID": "5"
    },
    {
        "Status": "off",
        "parameter name": "Health Science",
        "parameterType": "mProgram",
        "parameterId": "505",
        "advertiser_key": "",
        "categoryName": "Healthcare & Medical Assisting",
        "categoryID": "5"
    },
    {
        "Status": "off",
        "parameter name": "Healthcare Administration",
        "parameterType": "mProgram",
        "parameterId": "506",
        "advertiser_key": "",
        "categoryName": "Healthcare & Medical Assisting",
        "categoryID": "5"
    },
    {
        "Status": "off",
        "parameter name": "Healthcare Management",
        "parameterType": "mProgram",
        "parameterId": "507",
        "advertiser_key": "",
        "categoryName": "Healthcare & Medical Assisting",
        "categoryID": "5"
    },
    {
        "Status": "off",
        "parameter name": "Pharmacy Technician",
        "parameterType": "mProgram",
        "parameterId": "508",
        "advertiser_key": "",
        "categoryName": "Healthcare & Medical Assisting",
        "categoryID": "5"
    },
    {
        "Status": "off",
        "parameter name": "Medical Billing & Coding",
        "parameterType": "mProgram",
        "parameterId": "509",
        "advertiser_key": "",
        "categoryName": "Healthcare & Medical Assisting",
        "categoryID": "5"
    },
    {
        "Status": "off",
        "parameter name": "Medical Office Assistant",
        "parameterType": "mProgram",
        "parameterId": "510",
        "advertiser_key": "",
        "categoryName": "Healthcare & Medical Assisting",
        "categoryID": "5"
    },
    {
        "Status": "off",
        "parameter name": "Nutition, Fitness, and Sports Science",
        "parameterType": "mProgram",
        "parameterId": "511",
        "advertiser_key": "",
        "categoryName": "Healthcare & Medical Assisting",
        "categoryID": "5"
    },
    {
        "Status": "off",
        "parameter name": "Public Health",
        "parameterType": "mProgram",
        "parameterId": "512",
        "advertiser_key": "",
        "categoryName": "Healthcare & Medical Assisting",
        "categoryID": "5"
    },
    {
        "Status": "off",
        "parameter name": "Veterinary Technician",
        "parameterType": "mProgram",
        "parameterId": "513",
        "advertiser_key": "",
        "categoryName": "Healthcare & Medical Assisting",
        "categoryID": "5"
    },
    {
        "Status": "off",
        "parameter name": "X-Ray/Radiologic Technician",
        "parameterType": "mProgram",
        "parameterId": "514",
        "advertiser_key": "",
        "categoryName": "Healthcare & Medical Assisting",
        "categoryID": "5"
    },
    {
        "Status": "off",
        "parameter name": "Dental Assistant",
        "parameterType": "mProgram",
        "parameterId": "515",
        "advertiser_key": "",
        "categoryName": "Healthcare & Medical Assisting",
        "categoryID": "5"
    },
    {
        "Status": "off",
        "parameter name": "Surgical Technician",
        "parameterType": "mProgram",
        "parameterId": "516",
        "advertiser_key": "",
        "categoryName": "Healthcare & Medical Assisting",
        "categoryID": "5"
    },
    {
        "Status": "off",
        "parameter name": "Medical Assisting",
        "parameterType": "mProgram",
        "parameterId": "517",
        "advertiser_key": "",
        "categoryName": "Healthcare & Medical Assisting",
        "categoryID": "5"
    },
    {
        "Status": "off",
        "parameter name": "General Education",
        "parameterType": "mProgram",
        "parameterId": "600",
        "advertiser_key": "",
        "categoryName": "Education & Teaching",
        "categoryID": "6"
    },
    {
        "Status": "off",
        "parameter name": "Adult Education/Learning",
        "parameterType": "mProgram",
        "parameterId": "601",
        "advertiser_key": "",
        "categoryName": "Education & Teaching",
        "categoryID": "6"
    },
    {
        "Status": "off",
        "parameter name": "Early Childhood Development",
        "parameterType": "mProgram",
        "parameterId": "602",
        "advertiser_key": "",
        "categoryName": "Education & Teaching",
        "categoryID": "6"
    },
    {
        "Status": "off",
        "parameter name": "Educational Administration",
        "parameterType": "mProgram",
        "parameterId": "603",
        "advertiser_key": "",
        "categoryName": "Education & Teaching",
        "categoryID": "6"
    },
    {
        "Status": "off",
        "parameter name": "K-12 Education",
        "parameterType": "mProgram",
        "parameterId": "604",
        "advertiser_key": "",
        "categoryName": "Education & Teaching",
        "categoryID": "6"
    },
    {
        "Status": "off",
        "parameter name": "Social Studies",
        "parameterType": "mProgram",
        "parameterId": "605",
        "advertiser_key": "",
        "categoryName": "Education & Teaching",
        "categoryID": "6"
    },
    {
        "Status": "off",
        "parameter name": "Special Education",
        "parameterType": "mProgram",
        "parameterId": "606",
        "advertiser_key": "",
        "categoryName": "Education & Teaching",
        "categoryID": "6"
    },
    {
        "Status": "off",
        "parameter name": "Teacher Liscensure",
        "parameterType": "mProgram",
        "parameterId": "607",
        "advertiser_key": "",
        "categoryName": "Education & Teaching",
        "categoryID": "6"
    },
    {
        "Status": "off",
        "parameter name": "General Trade, Vocational, Career",
        "parameterType": "mProgram",
        "parameterId": "700",
        "advertiser_key": "",
        "categoryName": "Trade, Vocational, Career",
        "categoryID": "7"
    },
    {
        "Status": "off",
        "parameter name": "Automotive focused",
        "parameterType": "mProgram",
        "parameterId": "701",
        "advertiser_key": "",
        "categoryName": "Trade, Vocational, Career",
        "categoryID": "7"
    },
    {
        "Status": "off",
        "parameter name": "Aviation focused",
        "parameterType": "mProgram",
        "parameterId": "702",
        "advertiser_key": "",
        "categoryName": "Trade, Vocational, Career",
        "categoryID": "7"
    },
    {
        "Status": "off",
        "parameter name": "Certified Nursing Assistant (CNA)",
        "parameterType": "mProgram",
        "parameterId": "703",
        "advertiser_key": "",
        "categoryName": "Trade, Vocational, Career",
        "categoryID": "7"
    },
    {
        "Status": "off",
        "parameter name": "Construction management",
        "parameterType": "mProgram",
        "parameterId": "704",
        "advertiser_key": "",
        "categoryName": "Trade, Vocational, Career",
        "categoryID": "7"
    },
    {
        "Status": "off",
        "parameter name": "Electrical Technican",
        "parameterType": "mProgram",
        "parameterId": "705",
        "advertiser_key": "",
        "categoryName": "Trade, Vocational, Career",
        "categoryID": "7"
    },
    {
        "Status": "off",
        "parameter name": "Fire Science",
        "parameterType": "mProgram",
        "parameterId": "706",
        "advertiser_key": "",
        "categoryName": "Trade, Vocational, Career",
        "categoryID": "7"
    },
    {
        "Status": "off",
        "parameter name": "Truck Driver (CDL)",
        "parameterType": "mProgram",
        "parameterId": "707",
        "advertiser_key": "",
        "categoryName": "Trade, Vocational, Career",
        "categoryID": "7"
    },
    {
        "Status": "off",
        "parameter name": "HVAC",
        "parameterType": "mProgram",
        "parameterId": "708",
        "advertiser_key": "",
        "categoryName": "Trade, Vocational, Career",
        "categoryID": "7"
    },
    {
        "Status": "off",
        "parameter name": "Welding",
        "parameterType": "mProgram",
        "parameterId": "709",
        "advertiser_key": "",
        "categoryName": "Trade, Vocational, Career",
        "categoryID": "7"
    },
    {
        "Status": "off",
        "parameter name": "General Liberal Arts",
        "parameterType": "mProgram",
        "parameterId": "800",
        "advertiser_key": "",
        "categoryName": "Liberal Arts",
        "categoryID": "8"
    },
    {
        "Status": "off",
        "parameter name": "Creative Writing & English",
        "parameterType": "mProgram",
        "parameterId": "801",
        "advertiser_key": "",
        "categoryName": "Liberal Arts",
        "categoryID": "8"
    },
    {
        "Status": "off",
        "parameter name": "Communications",
        "parameterType": "mProgram",
        "parameterId": "802",
        "advertiser_key": "",
        "categoryName": "Liberal Arts",
        "categoryID": "8"
    },
    {
        "Status": "off",
        "parameter name": "History",
        "parameterType": "mProgram",
        "parameterId": "803",
        "advertiser_key": "",
        "categoryName": "Liberal Arts",
        "categoryID": "8"
    },
    {
        "Status": "off",
        "parameter name": "Religious Studies",
        "parameterType": "mProgram",
        "parameterId": "804",
        "advertiser_key": "",
        "categoryName": "Liberal Arts",
        "categoryID": "8"
    },
    {
        "Status": "off",
        "parameter name": "Socialogy",
        "parameterType": "mProgram",
        "parameterId": "805",
        "advertiser_key": "",
        "categoryName": "Liberal Arts",
        "categoryID": "8"
    },
    {
        "Status": "off",
        "parameter name": "Humanities",
        "parameterType": "mProgram",
        "parameterId": "806",
        "advertiser_key": "",
        "categoryName": "Liberal Arts",
        "categoryID": "8"
    },
    {
        "Status": "off",
        "parameter name": "Political Science",
        "parameterType": "mProgram",
        "parameterId": "807",
        "advertiser_key": "",
        "categoryName": "Liberal Arts",
        "categoryID": "8"
    },
    {
        "Status": "off",
        "parameter name": "General",
        "parameterType": "mProgram",
        "parameterId": "900",
        "advertiser_key": "",
        "categoryName": "General",
        "categoryID": "9"
    },
    {
        "Status": "off",
        "parameter name": "General Nursing",
        "parameterType": "mProgram",
        "parameterId": "1000",
        "advertiser_key": "",
        "categoryName": "Nursing",
        "categoryID": "10"
    },
    {
        "Status": "off",
        "parameter name": "Entry Level",
        "parameterType": "mProgram",
        "parameterId": "1001",
        "advertiser_key": "",
        "categoryName": "Nursing",
        "categoryID": "10"
    },
    {
        "Status": "off",
        "parameter name": "RN Degree Programs",
        "parameterType": "mProgram",
        "parameterId": "1002",
        "advertiser_key": "",
        "categoryName": "Nursing",
        "categoryID": "10"
    },
    {
        "Status": "off",
        "parameter name": "RN to BSN Bridge Programs",
        "parameterType": "mProgram",
        "parameterId": "1003",
        "advertiser_key": "",
        "categoryName": "Nursing",
        "categoryID": "10"
    },
    {
        "Status": "off",
        "parameter name": "MSN Programs",
        "parameterType": "mProgram",
        "parameterId": "1004",
        "advertiser_key": "",
        "categoryName": "Nursing",
        "categoryID": "10"
    },
    {
        "Status": "off",
        "parameter name": "General Multi-Media & Communications",
        "parameterType": "mProgram",
        "parameterId": "1100",
        "advertiser_key": "",
        "categoryName": "Media & Communications",
        "categoryID": "11"
    },
    {
        "Status": "off",
        "parameter name": "Advertising",
        "parameterType": "mProgram",
        "parameterId": "1101",
        "advertiser_key": "",
        "categoryName": "Media & Communications",
        "categoryID": "11"
    },
    {
        "Status": "off",
        "parameter name": "Animation & Video Graphic",
        "parameterType": "mProgram",
        "parameterId": "1102",
        "advertiser_key": "",
        "categoryName": "Media & Communications",
        "categoryID": "11"
    },
    {
        "Status": "off",
        "parameter name": "Commercial & Advertising Art",
        "parameterType": "mProgram",
        "parameterId": "1103",
        "advertiser_key": "",
        "categoryName": "Media & Communications",
        "categoryID": "11"
    },
    {
        "Status": "off",
        "parameter name": "Computer Media Applications",
        "parameterType": "mProgram",
        "parameterId": "1104",
        "advertiser_key": "",
        "categoryName": "Media & Communications",
        "categoryID": "11"
    },
    {
        "Status": "off",
        "parameter name": "Design & Visual Communication",
        "parameterType": "mProgram",
        "parameterId": "1105",
        "advertiser_key": "",
        "categoryName": "Media & Communications",
        "categoryID": "11"
    },
    {
        "Status": "off",
        "parameter name": "Film/Video & Cinematography",
        "parameterType": "mProgram",
        "parameterId": "1106",
        "advertiser_key": "",
        "categoryName": "Media & Communications",
        "categoryID": "11"
    },
    {
        "Status": "off",
        "parameter name": "Graphic Design",
        "parameterType": "mProgram",
        "parameterId": "1107",
        "advertiser_key": "",
        "categoryName": "Media & Communications",
        "categoryID": "11"
    },
    {
        "Status": "off",
        "parameter name": "Intermedia/Multimedia",
        "parameterType": "mProgram",
        "parameterId": "1108",
        "advertiser_key": "",
        "categoryName": "Media & Communications",
        "categoryID": "11"
    },
    {
        "Status": "off",
        "parameter name": "Photography",
        "parameterType": "mProgram",
        "parameterId": "1109",
        "advertiser_key": "",
        "categoryName": "Media & Communications",
        "categoryID": "11"
    },
    {
        "Status": "off",
        "parameter name": "Recording Arts Technology",
        "parameterType": "mProgram",
        "parameterId": "1110",
        "advertiser_key": "",
        "categoryName": "Media & Communications",
        "categoryID": "11"
    },
    {
        "Status": "off",
        "parameter name": "Web Design",
        "parameterType": "mProgram",
        "parameterId": "1111",
        "advertiser_key": "",
        "categoryName": "Media & Communications",
        "categoryID": "11"
    },
    {
        "Status": "off",
        "parameter name": "General Visual Arts & Graphic Design",
        "parameterType": "mProgram",
        "parameterId": "1200",
        "advertiser_key": "",
        "categoryName": "Visual Arts & Graphic Design",
        "categoryID": "12"
    },
    {
        "Status": "off",
        "parameter name": "Design & Visual Communications",
        "parameterType": "mProgram",
        "parameterId": "1201",
        "advertiser_key": "",
        "categoryName": "Visual Arts & Graphic Design",
        "categoryID": "12"
    },
    {
        "Status": "off",
        "parameter name": "Graphic Design",
        "parameterType": "mProgram",
        "parameterId": "1202",
        "advertiser_key": "",
        "categoryName": "Visual Arts & Graphic Design",
        "categoryID": "12"
    },
    {
        "Status": "off",
        "parameter name": "Industrial Design",
        "parameterType": "mProgram",
        "parameterId": "1203",
        "advertiser_key": "",
        "categoryName": "Visual Arts & Graphic Design",
        "categoryID": "12"
    },
    {
        "Status": "off",
        "parameter name": "Interior Design",
        "parameterType": "mProgram",
        "parameterId": "1204",
        "advertiser_key": "",
        "categoryName": "Visual Arts & Graphic Design",
        "categoryID": "12"
    },
    {
        "Status": "off",
        "parameter name": "Photography",
        "parameterType": "mProgram",
        "parameterId": "1205",
        "advertiser_key": "",
        "categoryName": "Visual Arts & Graphic Design",
        "categoryID": "12"
    },
    {
        "Status": "off",
        "parameter name": "Visual Arts",
        "parameterType": "mProgram",
        "parameterId": "1206",
        "advertiser_key": "",
        "categoryName": "Visual Arts & Graphic Design",
        "categoryID": "12"
    },
    {
        "Status": "off",
        "parameter name": "General Religious Studies",
        "parameterType": "mProgram",
        "parameterId": "1300",
        "advertiser_key": "",
        "categoryName": "Religious Studies",
        "categoryID": "13"
    },
    {
        "Status": "off",
        "parameter name": "Christian Studies",
        "parameterType": "mProgram",
        "parameterId": "1301",
        "advertiser_key": "",
        "categoryName": "Religious Studies",
        "categoryID": "13"
    },
    {
        "Status": "off",
        "parameter name": "Ministry",
        "parameterType": "mProgram",
        "parameterId": "1302",
        "advertiser_key": "",
        "categoryName": "Religious Studies",
        "categoryID": "13"
    },
    {
        "Status": "off",
        "parameter name": "Religious Studies",
        "parameterType": "mProgram",
        "parameterId": "1303",
        "advertiser_key": "",
        "categoryName": "Religious Studies",
        "categoryID": "13"
    },
    {
        "Status": "off",
        "parameter name": "Theology",
        "parameterType": "mProgram",
        "parameterId": "1304",
        "advertiser_key": "",
        "categoryName": "Religious Studies",
        "categoryID": "13"
    },
    {
        "Status": "off",
        "parameter name": "Either",
        "parameterType": "Audience Targeting",
        "parameterId": "1",
        "advertiser_key": "",
        "categoryName": "campus Type",
        "categoryID": "1"
    },
    {
        "Status": "off",
        "parameter name": "Online",
        "parameterType": "Audience Targeting",
        "parameterId": "2",
        "advertiser_key": "",
        "categoryName": "campus Type",
        "categoryID": "1"
    },
    {
        "Status": "off",
        "parameter name": "Campus",
        "parameterType": "Audience Targeting",
        "parameterId": "3",
        "advertiser_key": "",
        "categoryName": "campus Type",
        "categoryID": "1"
    },
    {
        "Status": "on",
        "parameter name": "Unknown",
        "parameterType": "Audience Targeting",
        "parameterId": "4",
        "advertiser_key": "null",
        "categoryName": "campus Type",
        "categoryID": "1"
    },
    {
        "Status": "off",
        "parameter name": "High School Diploma",
        "parameterType": "Audience Targeting",
        "parameterId": "1",
        "advertiser_key": "",
        "categoryName": "Highest Education",
        "categoryID": "2"
    },
    {
        "Status": "off",
        "parameter name": "No high school diploma",
        "parameterType": "Audience Targeting",
        "parameterId": "2",
        "advertiser_key": "",
        "categoryName": "Highest Education",
        "categoryID": "2"
    },
    {
        "Status": "off",
        "parameter name": "GED",
        "parameterType": "Audience Targeting",
        "parameterId": "3",
        "advertiser_key": "",
        "categoryName": "Highest Education",
        "categoryID": "2"
    },
    {
        "Status": "off",
        "parameter name": "0-12 college credits",
        "parameterType": "Audience Targeting",
        "parameterId": "4",
        "advertiser_key": "",
        "categoryName": "Highest Education",
        "categoryID": "2"
    },
    {
        "Status": "off",
        "parameter name": "more than 12 college credit",
        "parameterType": "Audience Targeting",
        "parameterId": "5",
        "advertiser_key": "",
        "categoryName": "Highest Education",
        "categoryID": "2"
    },
    {
        "Status": "off",
        "parameter name": "associates degree",
        "parameterType": "Audience Targeting",
        "parameterId": "6",
        "advertiser_key": "",
        "categoryName": "Highest Education",
        "categoryID": "2"
    },
    {
        "Status": "off",
        "parameter name": "bachelors degree",
        "parameterType": "Audience Targeting",
        "parameterId": "7",
        "advertiser_key": "",
        "categoryName": "Highest Education",
        "categoryID": "2"
    },
    {
        "Status": "off",
        "parameter name": "Graduate Degree",
        "parameterType": "Audience Targeting",
        "parameterId": "8",
        "advertiser_key": "",
        "categoryName": "Highest Education",
        "categoryID": "2"
    },
    {
        "Status": "on",
        "parameter name": "unknown",
        "parameterType": "Audience Targeting",
        "parameterId": "9",
        "advertiser_key": "null",
        "categoryName": "Highest Education",
        "categoryID": "2"
    },
    {
        "Status": "off",
        "parameter name": "Certificate",
        "parameterType": "Audience Targeting",
        "parameterId": "1",
        "advertiser_key": "",
        "categoryName": "degree of Interest",
        "categoryID": "3"
    },
    {
        "Status": "off",
        "parameter name": "Associates",
        "parameterType": "Audience Targeting",
        "parameterId": "2",
        "advertiser_key": "",
        "categoryName": "degree of Interest",
        "categoryID": "3"
    },
    {
        "Status": "off",
        "parameter name": "Bachelors",
        "parameterType": "Audience Targeting",
        "parameterId": "3",
        "advertiser_key": "",
        "categoryName": "degree of Interest",
        "categoryID": "3"
    },
    {
        "Status": "off",
        "parameter name": "Graduate Programs(Certifications, Master & Doctorate)",
        "parameterType": "Audience Targeting",
        "parameterId": "4",
        "advertiser_key": "",
        "categoryName": "degree of Interest",
        "categoryID": "3"
    },
    {
        "Status": "on",
        "parameter name": "Unknown",
        "parameterType": "Audience Targeting",
        "parameterId": "5",
        "advertiser_key": "null",
        "categoryName": "degree of Interest",
        "categoryID": "3"
    },
    {
        "Status": "off",
        "parameter name": "immediately",
        "parameterType": "Audience Targeting",
        "parameterId": "1",
        "advertiser_key": "",
        "categoryName": "degree start Time frame",
        "categoryID": "4"
    },
    {
        "Status": "off",
        "parameter name": "1-3 Months",
        "parameterType": "Audience Targeting",
        "parameterId": "2",
        "advertiser_key": "",
        "categoryName": "degree start Time frame",
        "categoryID": "4"
    },
    {
        "Status": "off",
        "parameter name": "4-6 Months",
        "parameterType": "Audience Targeting",
        "parameterId": "3",
        "advertiser_key": "",
        "categoryName": "degree start Time frame",
        "categoryID": "4"
    },
    {
        "Status": "off",
        "parameter name": "7-12 Months",
        "parameterType": "Audience Targeting",
        "parameterId": "4",
        "advertiser_key": "",
        "categoryName": "degree start Time frame",
        "categoryID": "4"
    },
    {
        "Status": "off",
        "parameter name": "Later than 1 year",
        "parameterType": "Audience Targeting",
        "parameterId": "5",
        "advertiser_key": "",
        "categoryName": "degree start Time frame",
        "categoryID": "4"
    },
    {
        "Status": "on",
        "parameter name": "Unknown",
        "parameterType": "Audience Targeting",
        "parameterId": "6",
        "advertiser_key": "null",
        "categoryName": "degree start Time frame",
        "categoryID": "4"
    },
    {
        "Status": "off",
        "parameter name": "None",
        "parameterType": "Audience Targeting",
        "parameterId": "1",
        "advertiser_key": "",
        "categoryName": "military Affiliation",
        "categoryID": "5"
    },
    {
        "Status": "off",
        "parameter name": "Active Duty",
        "parameterType": "Audience Targeting",
        "parameterId": "2",
        "advertiser_key": "",
        "categoryName": "military Affiliation",
        "categoryID": "5"
    },
    {
        "Status": "off",
        "parameter name": "Veteran",
        "parameterType": "Audience Targeting",
        "parameterId": "3",
        "advertiser_key": "",
        "categoryName": "military Affiliation",
        "categoryID": "5"
    },
    {
        "Status": "off",
        "parameter name": "Guard",
        "parameterType": "Audience Targeting",
        "parameterId": "4",
        "advertiser_key": "",
        "categoryName": "military Affiliation",
        "categoryID": "5"
    },
    {
        "Status": "off",
        "parameter name": "Spouse",
        "parameterType": "Audience Targeting",
        "parameterId": "5",
        "advertiser_key": "",
        "categoryName": "military Affiliation",
        "categoryID": "5"
    },
    {
        "Status": "off",
        "parameter name": "Dependent",
        "parameterType": "Audience Targeting",
        "parameterId": "6",
        "advertiser_key": "",
        "categoryName": "military Affiliation",
        "categoryID": "5"
    },
    {
        "Status": "on",
        "parameter name": "Unknown",
        "parameterType": "Audience Targeting",
        "parameterId": "7",
        "advertiser_key": "null",
        "categoryName": "military Affiliation",
        "categoryID": "5"
    },
    {
        "Status": "off",
        "parameter name": "Social",
        "parameterType": "Audience Targeting",
        "parameterId": "1",
        "advertiser_key": "",
        "categoryName": "media Type",
        "categoryID": "6"
    },
    {
        "Status": "off",
        "parameter name": "Email",
        "parameterType": "Audience Targeting",
        "parameterId": "2",
        "advertiser_key": "",
        "categoryName": "media Type",
        "categoryID": "6"
    },
    {
        "Status": "off",
        "parameter name": "Organic",
        "parameterType": "Audience Targeting",
        "parameterId": "3",
        "advertiser_key": "",
        "categoryName": "media Type",
        "categoryID": "6"
    },
    {
        "Status": "off",
        "parameter name": "SEM",
        "parameterType": "Audience Targeting",
        "parameterId": "4",
        "advertiser_key": "",
        "categoryName": "media Type",
        "categoryID": "6"
    },
    {
        "Status": "off",
        "parameter name": "SMS",
        "parameterType": "Audience Targeting",
        "parameterId": "5",
        "advertiser_key": "",
        "categoryName": "media Type",
        "categoryID": "6"
    },
    {
        "Status": "off",
        "parameter name": "Display",
        "parameterType": "Audience Targeting",
        "parameterId": "6",
        "advertiser_key": "",
        "categoryName": "media Type",
        "categoryID": "6"
    },
    {
        "Status": "off",
        "parameter name": "Affiliate",
        "parameterType": "Audience Targeting",
        "parameterId": "7",
        "advertiser_key": "",
        "categoryName": "media Type",
        "categoryID": "6"
    },
    {
        "Status": "on",
        "parameter name": "Unknown",
        "parameterType": "Audience Targeting",
        "parameterId": "8",
        "advertiser_key": "null",
        "categoryName": "media Type",
        "categoryID": "6"
    },
    {
        "Status": "off",
        "parameter name": "Thank you page",
        "parameterType": "Audience Targeting",
        "parameterId": "1",
        "advertiser_key": "",
        "categoryName": "inventory Type",
        "categoryID": "7"
    },
    {
        "Status": "off",
        "parameter name": "No Match",
        "parameterType": "Audience Targeting",
        "parameterId": "2",
        "advertiser_key": "",
        "categoryName": "inventory Type",
        "categoryID": "7"
    },
    {
        "Status": "off",
        "parameter name": "Content Page",
        "parameterType": "Audience Targeting",
        "parameterId": "3",
        "advertiser_key": "",
        "categoryName": "inventory Type",
        "categoryID": "7"
    },
    {
        "Status": "off",
        "parameter name": "Lightbox",
        "parameterType": "Audience Targeting",
        "parameterId": "4",
        "advertiser_key": "",
        "categoryName": "inventory Type",
        "categoryID": "7"
    },
    {
        "Status": "on",
        "parameter name": "Unknown",
        "parameterType": "Audience Targeting",
        "parameterId": "5",
        "advertiser_key": "null",
        "categoryName": "inventory Type",
        "categoryID": "7"
    },
    {
        "Status": "off",
        "parameter name": "Male",
        "parameterType": "Audience Targeting",
        "parameterId": "1",
        "advertiser_key": "",
        "categoryName": "gender",
        "categoryID": "8"
    },
    {
        "Status": "off",
        "parameter name": "Female",
        "parameterType": "Audience Targeting",
        "parameterId": "2",
        "advertiser_key": "",
        "categoryName": "gender",
        "categoryID": "8"
    },
    {
        "Status": "on",
        "parameter name": "Unknown",
        "parameterType": "Audience Targeting",
        "parameterId": "3",
        "advertiser_key": "null",
        "categoryName": "gender",
        "categoryID": "8"
    },
    {
        "Status": "off",
        "parameter name": "Anytime",
        "parameterType": "Audience Targeting",
        "parameterId": "1",
        "advertiser_key": "",
        "categoryName": "best contact time",
        "categoryID": "9"
    },
    {
        "Status": "off",
        "parameter name": "Morning",
        "parameterType": "Audience Targeting",
        "parameterId": "2",
        "advertiser_key": "",
        "categoryName": "best contact time",
        "categoryID": "9"
    },
    {
        "Status": "off",
        "parameter name": "Afternoon",
        "parameterType": "Audience Targeting",
        "parameterId": "3",
        "advertiser_key": "",
        "categoryName": "best contact time",
        "categoryID": "9"
    },
    {
        "Status": "on",
        "parameter name": "Unknown",
        "parameterType": "Audience Targeting",
        "parameterId": "4",
        "advertiser_key": "null",
        "categoryName": "best contact time",
        "categoryID": "9"
    },
    {
        "Status": "off",
        "parameter name": "1",
        "parameterType": "Audience Targeting",
        "parameterId": "1",
        "advertiser_key": "",
        "categoryName": "interest Level",
        "categoryID": "10"
    },
    {
        "Status": "off",
        "parameter name": "2",
        "parameterType": "Audience Targeting",
        "parameterId": "2",
        "advertiser_key": "",
        "categoryName": "interest Level",
        "categoryID": "10"
    },
    {
        "Status": "off",
        "parameter name": "3",
        "parameterType": "Audience Targeting",
        "parameterId": "3",
        "advertiser_key": "",
        "categoryName": "interest Level",
        "categoryID": "10"
    },
    {
        "Status": "off",
        "parameter name": "4",
        "parameterType": "Audience Targeting",
        "parameterId": "4",
        "advertiser_key": "",
        "categoryName": "interest Level",
        "categoryID": "10"
    },
    {
        "Status": "off",
        "parameter name": "5",
        "parameterType": "Audience Targeting",
        "parameterId": "5",
        "advertiser_key": "",
        "categoryName": "interest Level",
        "categoryID": "10"
    },
    {
        "Status": "off",
        "parameter name": "6",
        "parameterType": "Audience Targeting",
        "parameterId": "6",
        "advertiser_key": "",
        "categoryName": "interest Level",
        "categoryID": "10"
    },
    {
        "Status": "off",
        "parameter name": "7",
        "parameterType": "Audience Targeting",
        "parameterId": "7",
        "advertiser_key": "",
        "categoryName": "interest Level",
        "categoryID": "10"
    },
    {
        "Status": "off",
        "parameter name": "8",
        "parameterType": "Audience Targeting",
        "parameterId": "8",
        "advertiser_key": "",
        "categoryName": "interest Level",
        "categoryID": "10"
    },
    {
        "Status": "on",
        "parameter name": "Unknown",
        "parameterType": "Audience Targeting",
        "parameterId": "9",
        "advertiser_key": "null",
        "categoryName": "interest Level",
        "categoryID": "10"
    },
    {
        "Status": "off",
        "parameter name": "Yes",
        "parameterType": "Audience Targeting",
        "parameterId": "1",
        "advertiser_key": "",
        "categoryName": "has Internet",
        "categoryID": "11"
    },
    {
        "Status": "off",
        "parameter name": "No",
        "parameterType": "Audience Targeting",
        "parameterId": "2",
        "advertiser_key": "",
        "categoryName": "has Internet",
        "categoryID": "11"
    },
    {
        "Status": "on",
        "parameter name": "Unknown",
        "parameterType": "Audience Targeting",
        "parameterId": "3",
        "advertiser_key": "null",
        "categoryName": "has Internet",
        "categoryID": "11"
    },
    {
        "Status": "off",
        "parameter name": "Yes",
        "parameterType": "Audience Targeting",
        "parameterId": "1",
        "advertiser_key": "",
        "categoryName": "has RN",
        "categoryID": "12"
    },
    {
        "Status": "off",
        "parameter name": "No",
        "parameterType": "Audience Targeting",
        "parameterId": "2",
        "advertiser_key": "",
        "categoryName": "has RN",
        "categoryID": "12"
    },
    {
        "Status": "on",
        "parameter name": "Unknown",
        "parameterType": "Audience Targeting",
        "parameterId": "3",
        "advertiser_key": "null",
        "categoryName": "has RN",
        "categoryID": "12"
    },
    {
        "Status": "off",
        "parameter name": "Yes",
        "parameterType": "Audience Targeting",
        "parameterId": "1",
        "advertiser_key": "",
        "categoryName": "has Teaching",
        "categoryID": "13"
    },
    {
        "Status": "off",
        "parameter name": "No",
        "parameterType": "Audience Targeting",
        "parameterId": "2",
        "advertiser_key": "",
        "categoryName": "has Teaching",
        "categoryID": "13"
    },
    {
        "Status": "on",
        "parameter name": "Unknown",
        "parameterType": "Audience Targeting",
        "parameterId": "3",
        "advertiser_key": "null",
        "categoryName": "has Teaching",
        "categoryID": "13"
    }
]