import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Snackbar, Switch, TextField, createTheme } from '@mui/material'
import './AdvertiserList.css'
import { deleteAdvertiserAsync, deleteAdvertiserCampAsync, setFlag, setCampFlag as setAdvCampFlag, setIsoLoading, setSearchInput, resetFiltersCamp } from '../../Store/Slices/advertiserTabApisData';
import { useAppDispatch, useAppSelector } from '../../Store/hooks';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { fetchAdvertAccApiData, fetchSearchResult, filterAdvertiserSearch, updateAdvertiser } from '../../Services/advertiserTabApis';
import { deletePublisherAsync, deletePublisherCampAsync, setCampFlag, setFlag as setPubAccFlag } from '../../Store/Slices/PublisherTabApisData';
import MuiAlert from '@mui/material/Alert';
import { fetchCompApiData, fetchPublisherApiData, filterPublisherSearch, searchPublisherCampaigns, updatePublisher } from '../../Services/publisherTabApis';
import { useLocation } from 'react-router-dom';
import { deleteMbrandAsync, resetFiltersBrand, setSearchMbrandInput } from '../../Store/Slices/ExternalApiSlice';
import { deleteTcpaCampAsync, resetSearchFilterData, resetverticalFilter, setTcpaSearchInput } from '../../Store/Slices/tcpaCampSlice';
import { resetFilters } from '../../Store/Slices/PublisherCampaignSlice';
import { resetFiltersAdv } from '../../Store/Slices/CreateAdvButtonDialogSlice';
import { deleteTcpaCamp } from '../../Services/tcpaTabApis';
import { removeCpcThresholdKey, removeFromBudgetInfoAndCapsFilter } from '../../Utils/utils';

const EditIconn = (props: any) => {

  // Theme configuration
  const theme = createTheme({
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            textAlign: 'center', // Center-align text in cells
          },
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            fontWeight: 'bold',
            backgroundColor: "#EAECF0",
            color: "#737791",
            text: 'center', // Center-align text in header cells
          },

        },
      },
    },
  });

  const { openPopup, setOpenPopup } = props;
  return (
    <div>
      <Dialog open={openPopup} BackdropProps={{ style: { opacity: '0.2' } }}>
        <DialogTitle>Update Advertiser</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField label="Advertiser Name*" fullWidth />
            </Grid>
            <Grid item xs={6}>
              <TextField label="Advertiser Description*" fullWidth variant="outlined" rows={4} multiline />
            </Grid>
            <Grid item xs={6}>
              <TextField label="Advertiser Address*" fullWidth variant="outlined" rows={4} multiline />
            </Grid>
            <Grid item xs={6}>
              <TextField label="Contact Person Name" fullWidth />
            </Grid>
            <Grid item xs={6}>
              <TextField label="City" fullWidth />
            </Grid>
            <Grid item xs={6}>
              <TextField label="Phone Number" fullWidth />
            </Grid>
            <Grid item xs={6}>
              <TextField label="State" fullWidth />
            </Grid>
            <Grid item xs={6}>
              <TextField label="Website" fullWidth />
            </Grid>
            <Grid item xs={6}>
              <TextField label="RPL" fullWidth />
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                control={<Switch defaultChecked />}
                label="Status"
                labelPlacement="end" // You can change this to "start", "top", or "bottom" as needed
              />
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                control={<Switch />}
                label="Pay Per Lead"
                labelPlacement="end"
              />
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                control={<Switch />}
                label="Lead Flag"
                labelPlacement="end"
              />
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                control={<Switch />}
                label="Application Flag"
                labelPlacement="end"
              />
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                control={<Switch />}
                label="Enrollment Flag"
                labelPlacement="end"
              />
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                control={<Switch />}
                label="Start Flag"
                labelPlacement="end"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenPopup(false)}>CANCEL</Button>
          <Button variant="contained" color="primary">UPDATE</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default EditIconn
let userSortid: any
userSortid = localStorage.getItem("userSortid")
export const DeleteIconPop = (props: any) => {

  // Theme configuration
  const location = useLocation();

  const { openDeletePopup, setDeletePopup, rowData, delPayload, fetchData, searchInputDe } = props
  const dispatch = useAppDispatch();
  const [errorMessage, setErrorMessage] = useState('');
  const [isSnackbarOpen, setSnackbarOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const searchFilterData = useAppSelector((state) => state.advertiserCampApi.searchFilterData);

  useEffect(() => {
    dispatch(setFlag(false));
    dispatch(setPubAccFlag(false));
    dispatch(setCampFlag(false));
    dispatch(setAdvCampFlag(false));
    // console.log('searchInputDesearchInputDesearchInput',searchInputDe)
    if (rowData && successMessage) {
      if (searchFilterData.length > 0 && rowData.campaignType === 'advertiserCampaign') {
        const payload = {
          "mPubIds": searchFilterData
        }

        searchFilterData.length > 0 && filterAdvertiserSearch(payload, dispatch);
      } else if (searchInputDe && rowData.campaignType === 'advertiserCampaign') {
        fetchSearchResult(searchInputDe, dispatch, "campaign", null)
      } else if (searchFilterData.length > 0 && rowData.campaignType === 'publisherCampaign') {
        const payload = {
          "mPubIds": searchFilterData
        }

        searchFilterData.length > 0 && filterPublisherSearch(payload, dispatch);
      } else if (searchInputDe && rowData.campaignType === 'publisherCampaign') {
        searchPublisherCampaigns(searchInputDe, dispatch, 'campaign', null)

      }
      else if (location.pathname === "/publisherCampaigns" && rowData.campaignType === 'publisherCampaign') {
        let lastFiveCharacters = userSortid.slice(-5);
        const payload = {
          "mPubIds": [lastFiveCharacters]
        }
        lastFiveCharacters.length > 0 && filterPublisherSearch(payload, dispatch);
      }
      setTimeout(() => {
        fetchData();
      }, 700)

    }
  }, [successMessage]);

  const handleDelete = async () => {
    dispatch(setIsoLoading(true));
    setDeletePopup(false);
    // console.log("rowData", rowData)
    if (delPayload) {
      try {
        if (rowData.ID === 'advertiser') {
          // Execute this block if rowData.ID is 'advertiser'
          await dispatch(deleteAdvertiserAsync({ data: delPayload }));
          setSuccessMessage(`Advertiser ${rowData.advertiserName} Deleted Successfully!`);
        }
        else if (rowData.campaignType === 'publisherCampaign') {
          await dispatch(deletePublisherCampAsync(delPayload));

          const pub = rowData.publisher;
          // console.log("rowData pub", pub)
          // console.log("delPayload", delPayload)
          const publisherPayload = removeCpcThresholdKey(pub, delPayload)
          const response = await updatePublisher(publisherPayload);
          // console.log("rowData updated pubisher payload", publisherPayload)
          setSuccessMessage(`Campaign ${rowData.campaignname} Deleted Successfully!`);
        }
        else if (rowData.ID === 'publisher') {
          await dispatch(deletePublisherAsync({ data: delPayload }));
          setSuccessMessage(`Publisher ${rowData.publisherName} Deleted Successfully!`);
          // dispatch(setData([]));
          // fetchPublisherApiData(dispatch, null);
          // fetchCompApiData(dispatch);
        }
        else if (rowData.mBrandId) {
          await dispatch(deleteMbrandAsync(delPayload));
          setSuccessMessage(`MBrand - ${rowData.mBrandName} Deleted Successfully!`);
          dispatch(setSearchMbrandInput(''));
          dispatch(resetFiltersBrand());
        }
        else if (rowData.tcpaCampaign_id) {
          // console.log("Track delete TCPA")
          // // await dispatch(deleteTcpaCampAsync(delPayload));
          const response = await deleteTcpaCamp(delPayload);
          //  alert("Delete success")
          setSuccessMessage(`TCPA Campaign - ${rowData.tcpaCampaign_name} Deleted Successfully!`);
          // await dispatch(deleteTcpaCampAsync(delPayload));
          // setSuccessMessage(`TcpaCamp - ${rowData.tcpaCampaign_name} Deleted Successfully!`);
          dispatch(setTcpaSearchInput(''))
          dispatch(resetverticalFilter())
          dispatch(resetSearchFilterData())
        }
        else {
          await dispatch(deleteAdvertiserCampAsync(delPayload));
          const advertiser = rowData.advertiser;
          // console.log("advertiser ", advertiser)
          // console.log("delete Payload", delPayload);
          const advPayload = removeFromBudgetInfoAndCapsFilter(advertiser, delPayload)
          // console.log('advPayload', advPayload)
          const response = await updateAdvertiser(advPayload);
          // console.log('response', response);
          setSuccessMessage(`Campaign ${rowData.campaignname} Deleted Successfully!`);
          dispatch(setSearchInput(''))
          dispatch(resetFiltersCamp());
        }
        setSnackbarOpen(true);

      } catch (error) {
        setSnackbarOpen(false);
        setErrorMessage('Failed to create publisher');
        console.error('Error deleting advertiser:', error);
      }
      // setDeletePopup(false);
      // setTimeout(() => {
      //   setSuccessMessage(null); // Clear the success message after a delay
      // }, 3000);
    } else {
      console.error('Invalid delPayload ');
    }
    dispatch(setIsoLoading(false));
    if (rowData.ID === 'advertiser') {
      dispatch(setSearchInput(''));
      dispatch(resetFiltersAdv());
    } else if (rowData.ID === 'publisher' || rowData.campaignType === 'publisherCampaign') {

      setTimeout(() => {
        dispatch(setSearchInput(''));
        dispatch(resetFilters());
      }, 1000);
    }
    //  dispatch(setSearchInput(''));
  }

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <div>
      <Dialog open={openDeletePopup} >
        <DialogTitle className="dialogTitle">ALERT</DialogTitle>
        <DialogContent >
          {rowData?.tcpaCampaign_id ? (<p>Are you sure you want to delete this campaign - {rowData?.tcpaCampaign_name}&nbsp;?</p>) :

            ((rowData?.ID === 'advertiser' || rowData?.ID === 'publisher') ?
              <p>Are you sure you want to delete this {rowData?.ID} - {rowData?.publisherName || rowData?.advertiserName} ?</p> :
              ((rowData?.mBrandId) ?
                <p>Are you sure you want to delete this brand - {rowData?.mBrandName}&nbsp;?</p> :
                <p>Are you sure you want to delete this {rowData?.ID} - {rowData?.campaignname}&nbsp;?</p>))
          }
          {/* {(rowData?.ID === 'advertiser' || rowData?.ID === 'publisher') ? <p>Are you sure you want to delete this {rowData?.ID} - {rowData?.publisherName || rowData?.advertiserName} ?</p> :
            <p>Are you sure you want to delete this {rowData?.ID} - {rowData?.campaignname}&nbsp;?</p>} */}
        </DialogContent>
        <DialogActions className="dialogActions">
          <Button onClick={() => setDeletePopup(false)} color="primary">
            CANCEL
          </Button>
          <Button onClick={handleDelete} color="error" variant="contained">
            CONFIRM
          </Button>
        </DialogActions>
      </Dialog>
      {/* {successMessage && (
        <div className='delMessage'>
          {successMessage}
        </div>
      )} */}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isSnackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackbar}
          severity={successMessage ? 'success' : 'error'}
        >
          {successMessage || errorMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  )
}




