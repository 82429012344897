import {Paper, Typography, Box, Button, FormControl, CircularProgress,} from '@mui/material'
import React ,{ useState, useEffect} from 'react'
import AddIcon from '@mui/icons-material/Add';
import '../../Publisher/PublisherList.css'
import Search from '../../../Component/Search/Search';
import { useAppDispatch, useAppSelector } from '../../../Store/hooks';
import { storeResData , storeLoadingData} from '../../../Store/Slices/Publisher-role/campaignSlice';
import { fetchPubUserCampaignByPubID } from '../../../service/Publisher';
import { RootState } from '../../../Store';
import { publiCampaignHeadCells } from '../../../tableInterface';
import PresentationalTable from '../../../Component/Table/PresentionalTable';
 const PubUserCampaigns=()=>{
    let userSortid=localStorage.getItem("userSortid")
    const dispatch=useAppDispatch()
    const data=useAppSelector((state:RootState)=>state.publisherUserCampaignData)
    console.log("Data::::", data)
    const [tableData,setTableData]=useState([]);
    const [tableHeadCell, setTableHeadCell]=useState<any>(publiCampaignHeadCells)
    
    // console.log("tableHeadCell", tableHeadcell)

const fetchData=async ()=>{
    try{
        if (userSortid) {
            // Extract the last 5 characters dynamically
           let  lastFiveCharacters = userSortid.slice(-5);
          dispatch(storeLoadingData(true)) 
        const response:any=await  fetchPubUserCampaignByPubID(lastFiveCharacters)
        dispatch(storeResData(response))
        const tableData=response.map((item:any, index:number)=>{
      return {
        Status: item.status,
        campaign_id: item?.SORTID,
        campaign_name: item?.campaignname,
        Publisher_ID: item?.publisherID,
        Publisher_Name: item?.publisherName,
        vertical: item?.vertical?.verticalName,
      }

        })
        console.log('tableData',tableData)
       setTableData(tableData)
       dispatch( storeLoadingData(false))
        }else{
            alert("Missing publisher ID!")
        }
    }catch(error){
        console.log("error", error)
    }
   
    
}

// console.log("publiCampaignHeadCells", publiCampaignHeadCells);
useEffect(()=>{
 fetchData()
}, [])
    return (
        <>
            <Paper elevation={3} className="card">
        <Box display="flex" justifyContent="space-between" p={3}   sx={{ flexDirection: { xs: 'column', md: 'row' } }} >
          <Box>
            <Typography className="card-heading">Publisher Campaign</Typography>
            <Typography className="card-sub-heading">
              Publisher Campaigns Summary
            </Typography>
          </Box>
          <Box display="flex" gap="16px"  sx={{ flexDirection: { xs: 'column', sm: 'row' }, mt: { xs: '16px', md: '0' }, alignItems: { xs: 'flex-start', md: 'center' } }}> 
            <Search value={''} searchHandler={()=>{}} />
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => {
                // setOpenPopup(true);
                // dispatch(setEditFlag(false));
              }}
              sx={{backgroundColor:"#0ae"}}
            >
              Create Campaign
            </Button>
          </Box>
        </Box>
        <Box display="flex" gap="16px">
        <FormControl style={{ width: '98.3%' , marginBottom:'0.4%',paddingLeft:'1.3%'}}>
  </FormControl>
</Box>
{/* {data.pageLoading ? <CircularProgress />:
<PresentationalTable 
tableData={tableData}
tableHeadCell={ tableHeadCell}
isLoading={data.pageLoading}
/>
 } */}

<PresentationalTable 
tableData={tableData}
tableHeadCell={ tableHeadCell}
isLoading={data.pageLoading}
/>
        {/* <MMTable
          tableData={publiCampaignTableData}
          tableHeadCell={publiUserCampaignHeadCells}
          fetchMoreData={() => fetchMoreData()}
          hasMoreData={hasMoreData}
          isLoadingMore={isLoading}
          fetchInitialData={fetchInitialCampData}
          searchInput={debouncedInputValue}
        /> */}
      </Paper>
        </>
    )
}

// ONe table component will be the componet


export default PubUserCampaigns;