import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { IOSSwitch } from '../../Component/Table/MMTable'
import { SelectChangeEvent } from '@mui/material/Select';
import { createUser, fetchVendor, fetchPublisher } from '../../service/User';
import SnackbarMessage from '../../Component/Snackbar';
import { CAMPAIGN_CREATE_SUCCESS, USER_CREATE_SUCCESS, USER_UPDATE_SUCCESS } from '../../Mock/MessageContstant';
import { CircleLoader } from 'react-spinners';
import CloseIcon from '@mui/icons-material/Close';
import { getDisplayName } from './Vendors/Vendors';
interface userFormValue {
  firstName: string
  lastName: string
  email: string
  userId: string
  isAdmin: boolean;
  groupType: string;
  groupData: {
    publisher: any[],
    advertiser: any[]
  }
}



interface IcreateUser {
  refreshHandler: () => void;
  modalCloseHandler: () => void;
  modalOpenHandler: () => void
  userList: any,
  userRole?: string
  userGroupName?: string
  snackBarDataHandler: (type: string, isOpen: boolean, message: string) => void;
}

export const isValidEmail = (email: string): boolean => {
  // Basic email validation (you might want to use a more robust validation library)
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};
const CreateUser: React.FC<IcreateUser> = (props) => {
  console.log("props", props)
  const [loading, setLoading] = useState(false);
  const [advData, setAdvData] = useState([]);
  const [pubData, setPubData] = useState([])
  const [dropDown2Menus, setDropdown2Menus] = useState([]);
  const [dropDown2Value, setDropdown2Value] = useState("")
  useEffect(() => {

    const fetchData = async () => {
      setLoading(true)

      Promise.all([fetchPublisher(), fetchVendor()]).then(([pubRes, advRes]) => {
        // setData(userRes.result)
        const pub = pubRes.result.data?.sort((a: any, b: any) => a.publisherName.localeCompare(b.publisherName))?.map((item: any) => {
          return {
            label: item.publisherName,
            value: item.SORTID
          }
        })
        const adv = advRes.result.map((item: any) => {
          return {
            label: getDisplayName(item?.GroupName),
            value: item?.GroupName
          }
        })
        setPubData(pub)
        setAdvData(adv)
        setLoading(false)
        setDropdown2Menus(pub)

      }).catch((error) => {
        setLoading(true)
      })
    }
    fetchData()

    if (props.userRole === 'Publisher') {
      if (props.userGroupName) {
        setDropdown2Value(props.userGroupName)
      }
    }
  }, [])

  const initialValue: userFormValue = {
    firstName: "",
    lastName: '',
    email: "",
    userId: "",
    isAdmin: false,
    groupData: {
      publisher: pubData,
      advertiser: advData
    },
    groupType: "Publisher"
  }
  const [userFormValues, setUserFormValues] = useState<userFormValue>(initialValue)
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    userId: '',
    groupType: "",
    groupValue: '',
    ddError: ""
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked } = event.target;
    let error = '';
    if (name === 'firstName' && !value.trim()) {
      error = 'First Name is required';
    } else if (name === 'lastName' && !value.trim()) {
      error = 'Last Name is required';
    } else if (name === 'email') {
      if (!value.trim()) {
        error = 'Email is required';
      } else if (!isValidEmail(value)) {
        error = 'Enter a valid email address';
      }
      // error = 'Email is required';
    } else if (name === 'userId') {
      // error = 'User ID is required';
      // const usernameRegex = /^[a-zA-Z0-9_]+$/;
      //  const lowerCaseValue= value.toLocaleLowerCase();
      //   if (!lowerCaseValue.trim()) {
      //     error = 'Username is required';
      //   } else if (lowerCaseValue.includes(' ')) {
      //     error = 'Username must not  contain  space';
      //   }
    }
    console.log("Checked", checked)

    setErrors((prev) => ({ ...prev, [name]: error }));
    // if (name === 'isAdmin') {
    //   setUserFormValues((prev) => {
    //     return {
    //       ...prev, [name]: checked
    //     }
    //   })
    // }

    setUserFormValues((prev) => {
      return {
        ...prev, [name]: value
      }
    })



  }
  const handleGroup = (event: React.ChangeEvent<HTMLInputElement>) => {
    // console.log("handel ", event.target.checked)
    const { name, checked } = event.target
    setUserFormValues((prev) => {
      return {
        ...prev, [name]: checked
      }
    })

  }

  const handleSelectChange = (event: SelectChangeEvent) => {
    const { name, value } = event.target
    setUserFormValues((prev) => {
      return {
        ...prev, [name]: value
      }
    })

    if (value === "Publisher") {
      setDropdown2Menus(pubData)
    } else {
      setDropdown2Menus(advData)
    }
  }


  const handelSecondDropdown = (event: SelectChangeEvent) => {
    if (event.target.value) {
      setDropdown2Value(event.target.value)
      setErrors((prev) => ({ ...prev, ddError: '' }));
    } else {
      setErrors((prev) => ({ ...prev, ddError: "Please Select one options" }));
    }

  }
  const handelCreate = async () => {
    props.modalOpenHandler()
    let isDuplicateGroup = false;
    const { firstName, lastName, email, userId } = errors
    if (firstName.length > 1 || email.length > 1 || lastName.length > 1 || userId.length > 1) {
      return;
    }

    if (!userFormValues.firstName || !userFormValues.email || !userFormValues.lastName || !userFormValues.userId) {

      if (!userFormValues.firstName) {
        // error = 'First Name is required';
        setErrors((prev) => ({ ...prev, firstName: 'First Name is required' }));
      }
      if (!userFormValues.lastName) {
        // error = 'Last Name is required';
        setErrors((prev) => ({ ...prev, lastName: 'Last Name is required' }));
      }
      if (!userFormValues.email) {

        // error = 'Email is required';
        setErrors((prev) => ({ ...prev, email: 'Email is required' }));
        // error = 'Email is required';
      }
      if (!userFormValues.userId) {
        // error = 'User ID is required';
        // const usernameRegex = /^[a-zA-Z0-9_]+$/;

        // error = 'Username is required';
        setErrors((prev) => ({ ...prev, userId: 'Username is required' }));
      }




      return;
    }


    if (!userFormValues.isAdmin)
      if (!dropDown2Value) {
        // error = 'User ID is required';
        // const usernameRegex = /^[a-zA-Z0-9_]+$/;

        // error = 'Username is required';
        setErrors((prev) => ({ ...prev, ddError: 'Please select one option' }));
        return;
      }
    const body = {
      GroupName: userFormValues.isAdmin ? "admin" : dropDown2Value, // Set admin, if admin or set publisher or Advertiser ID
      Username: userFormValues.userId || userFormValues.email, // setting email as default, incase username is not provided
      UserAttributes: [
        {
          Name: "email",
          Value: userFormValues.email,
        },
        {
          Name: "custom:first_name",
          Value: userFormValues.firstName,
        },
        {
          Name: "custom:last_name",
          Value: userFormValues.lastName,
        },
        {
          Name: "custom:group",
          Value: userFormValues.isAdmin ? "admin" : dropDown2Value,
        },
        {
          Name: "custom:groupType",
          Value: userFormValues.isAdmin ? "admin" : userFormValues.groupType,
        },
        {
          Name: "name",
          Value: userFormValues.userId,
        },

      ],
    }
    createUser(body).then((res) => {

      props.snackBarDataHandler("success", true, USER_CREATE_SUCCESS)
      props.refreshHandler()
      props.modalCloseHandler()
    }).catch((error) => {
      props.snackBarDataHandler("error", true, error.response.data.error)
    })

  }
  return (<div>
    <Dialog open={true} onClose={props.modalCloseHandler} fullWidth>
      {
        loading ? <Box className="loading-spinner-container" sx={{
          minHeight: "500px"
        }}>

          <CircleLoader color="#00BFFF" loading={loading ? true : false} size={60} />
        </Box> :
          <Box >
            <DialogTitle>Create User</DialogTitle>
            <div className="close-button" onClick={props.modalCloseHandler}>
              <CloseIcon style={{ fontSize: '32px' }} />
            </div>
            <DialogContent>
              <Grid container spacing={3.5}>
                <Grid item xs={12} lg={6}>
                  <InputField label="First Name" name='firstName' value={userFormValues.firstName}
                    changeHandler={handleChange} error={errors.firstName} hidden={false} required={true} />

                </Grid>
                <Grid item xs={12} lg={6}>
                  <InputField label="Last Name" name='lastName' value={userFormValues.lastName}
                    changeHandler={handleChange} error={errors.lastName} hidden={false} required={true} />
                </Grid>

                {/* <Box className="row-1">
                  <InputField label="First Name" name='firstName' value={userFormValues.firstName} changeHandler={handleChange} error={errors.firstName} hidden={false} required={true} />
                  <InputField label="Last Name" name='lastName' value={userFormValues.lastName} changeHandler={handleChange} error={errors.lastName} hidden={false} required={true} />
                </Box> */}
                <Grid item xs={12}>
                  <Box className="row-2">
                    <InputField label="Email" name='email' value={userFormValues.email} changeHandler={handleChange} error={errors.email} hidden={false} required={true} />
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Box className="row-3">
                    <InputField label="User ID" name='userId' value={userFormValues.userId} changeHandler={handleChange} error={errors.userId} hidden={false} required={true} />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  {!(props.userRole === 'Publisher') &&
                    <Box className="row-4">

                      <FormGroup sx={{ width: "169px", p: 1, }} className='select-all-toggle-container'>
                        <FormControlLabel
                          control={<IOSSwitch sx={{ m: 1 }} name='isAdmin' checked={userFormValues.isAdmin} onChange={handleGroup} />}
                          label="Admin"
                          className='select-all-toggle-container-label'
                          sx={{ flexDirection: "row" }}
                        // hidden={props.userRole==='Publisher'?true:false}

                        />
                      </FormGroup>

                      {/* <FormGroup sx={{ m: 1, width: "169px", p: 1, }} className='select-all-toggle-container'>
                    <FormControlLabel
                      control={<IOSSwitch sx={{ m: 1 }} name='isSuperAdmin' checked={userFormValues.isSuperAdmin} onChange={handleGroup} />}
                      label="Super Admin"
                      className='select-all-toggle-container-label'
                      sx={{ flexDirection: "row" }}

                    />
                  </FormGroup> */}

                    </Box>
                  }
                </Grid>
                {!userFormValues.isAdmin &&
                  <>
                    <Grid item xs={12} lg={6}>
                      <FormControl fullWidth  >
                        <InputLabel id="demo-simple-select-label">Group</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={userFormValues.groupType}
                          //   label="Age"
                          onChange={handleSelectChange}
                          label="Group"
                          name='groupType'
                          disabled={props.userRole === 'Publisher' ? true : false}
                        >
                          <MenuItem value={"Publisher"}>Publisher</MenuItem>
                          <MenuItem value={"Vendor"}>Vendor</MenuItem>

                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormControl fullWidth error={errors.ddError.length > 1}>
                        <InputLabel id="demo-simple-select-label">{userFormValues.groupType === "Publisher" ? "Publisher*" : "Vendor*"}</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={dropDown2Value}
                          onChange={handelSecondDropdown}
                          label="Group"
                          // error={errors.ddError.length>1}
                          // helperText={errors.ddError}
                          disabled={props.userRole === 'Publisher' ? true : false}
                        >
                          {dropDown2Menus.map((item: any) => {
                            return (
                              <MenuItem value={item.value}>{item.label}</MenuItem>
                            )
                          })}

                        </Select>
                        {errors.ddError.length > 1 && <FormHelperText>{errors.ddError}</FormHelperText>}
                      </FormControl>
                    </Grid>
                  </>
                }
              </Grid>
            </DialogContent>
            <DialogActions style={{ padding: "15px" }}>
              <Button style={{ color: 'red' }} onClick={props.modalCloseHandler}>CANCEL</Button>
              <Button variant="contained" color="primary" onClick={handelCreate}>CREATE</Button>
            </DialogActions>
          </Box>
      }
    </Dialog>
  </div>
  )
}

export default CreateUser


interface inputField {
  label: string
  value: string
  changeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void
  error: string
  name: string
  hidden: boolean
  required: boolean

}
export const InputField: React.FC<inputField> = ({ label, value, changeHandler, error, name, hidden, required }) => {
  return (
    <TextField
      label={label}
      id="outlined-required"
      value={value}
      // defaultValue="Hello World"
      error={error?.length > 1}
      helperText={error}
      name={name}
      onChange={changeHandler}
      disabled={hidden}
      required={required}
      fullWidth
    />
  )
}