import React, { useEffect, useMemo, useState } from 'react'
import { Box, Button, Checkbox, Chip, FormControl, InputAdornment, InputLabel, ListItemText, MenuItem, OutlinedInput, Paper, Select, Tooltip, Typography } from '@mui/material'
import MMTable from '../../Component/Table/MMTable'
import AddIcon from '@mui/icons-material/Add';
import { advCampaignHeadCells } from '../../Component/Table/tableInterface';
import './AdvertiserList.css'
import { useAppDispatch, useAppSelector } from '../../Store/hooks';
import { resetFiltersCamp, setAdvCampData, setBlockScroll, setCampFlag, setFields, setFrontEndSearchInput, setNoDataFlag, setScrollNext, setSearchFilterData, setSearchInput } from '../../Store/Slices/advertiserTabApisData';
import Search from '../../Component/Search/Search';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { fetchAdvertiserCampApiData, fetchAdvComCampApiData, fetchSearchResult, debounce, fetchAdvertAccApiData, fetchCompApiData, filterAdvertiserSearch } from '../../Services/advertiserTabApis';
import { DeleteIconPop } from './Action';
import DeleteIcon from '@mui/icons-material/Delete';
import { updateStateForCloneAction, updateStateForEditAction } from '../../Store/Slices/CreateAdvCampaignSlice';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { setfilterSearchFlag } from '../../Store/Slices/PublisherTabApisData';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopy';
import CreateCampaignPopup from './Stepper Form/clicksCampVerticalPopup';
import { RootState } from '../../Store';
import { useSelector } from 'react-redux';
import { fetchVerticals } from '../../Services/verticalList';



export const fetchInitialCampData = (dispatch: any) => {
  fetchAdvertiserCampApiData(dispatch, null).then(() => {
    dispatch(setScrollNext(true));
  });
};
const Campaigns = () => {

  const dispatch = useAppDispatch();
  const advCampaignState = useAppSelector((state) => state.advertiserCampApi);
  const advCampState = useAppSelector((state) => state.advertiserAccApi);
  const advCampaignTableData = useAppSelector((state) => state.advertiserCampApi.datacamp);
  const isLoading = useAppSelector((state) => state.advertiserCampApi.campLoading);
  const currentCursor = useAppSelector((state) => state.advertiserCampApi.currentCampCursor);
  const hasMoreData = useAppSelector((state) => state.advertiserCampApi.hasCampMoreData);
  const advCampaignsLimitData = useAppSelector((state) => state.advertiserCampApi.campaignDataWithLimits);
  const [debouncedInputValue, setDebouncedInputValue] = useState<string>('');
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isMouseOverDelete, setIsMouseOverDelete] = useState(false);
  const [VerticalMouseOverDelete, setVerticalMouseOverDelete] = useState(false);
  const [filterSearchInput, setFilterSearchInput] = useState<string>('');
  const filterSearchFlag = useAppSelector((state) => state.publisherCampApi.filterSearchFlag);
  const { searchFilterData, filterSearchAdvData, FrontEndSearchInput, verticalFilter } = advCampaignState;
  const { searchInput, fullData } = advCampState;
  const location = useLocation();
  const navigate = useNavigate();
  const isReload = useAppSelector((state) => state.advertiserCampApi.isReload);
  let updatedFilterData: any
  let userSortid = localStorage.getItem("userSortid")

  // const verticalOptions: string[] = ["AUTO (Auto Insurance)", "EDU (Higher Education)"];
  const { verticalList, loading, error } = useSelector((state: RootState) => state.verticals);
  const verticalOptions = verticalList.map((item) => ({
    id: item.verticalID,
    name: item.verticalName,
  }));
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const openPopup = () => {
    setIsPopupOpen(true);
  };
  const closePopup = () => {
    setIsPopupOpen(false);
  };
  const proceedToLink = (selectedOption: string) => {
    closePopup();
    // if (selectedOption === 'EDU') {
    //   navigate('/createAdvertiserCampaigns')
    //   // window.location.href = '/createAdvertiserCampaigns'; // Adjust this to your actual route
    // } else if (selectedOption === 'AUTO') {
    //   navigate('/createAdvertiserCampaigns')
    //   // window.location.href = '/createAdvertiserCampaigns'; // Adjust this to your actual route
    // }
    navigate('/createAdvertiserCampaigns')
  };
  useEffect(() => {
    return () => {
      dispatch(resetFiltersCamp());
    };
  }, [dispatch]);
  const fetchContent = () => {
    if (userSortid) {
      // Extract the last 5 characters dynamically
      let lastFiveCharacters = userSortid.slice(-5);
      const payload = {
        "mPubIds": [lastFiveCharacters]
      }
      lastFiveCharacters.length > 0 && filterAdvertiserSearch(payload, dispatch);
    } else {
      console.log("userSortid is not available in localStorage");
    }
  }
  useEffect(() => {
    if (location.pathname === '/advertiserCampaigns') {
      fetchContent()
    } else {
      dispatch(setfilterSearchFlag(false));
      dispatch(setCampFlag(false));
      setIsMouseOverDelete(false);
      setVerticalMouseOverDelete(false);
      if (!searchInput && searchFilterData.length === 0 && verticalFilter.length === 0) {
        dispatch(setAdvCampData([]));
        dispatch(setBlockScroll(false));
        dispatch(setScrollNext(false));
        setDebouncedInputValue('');
        // dispatch(setFrontEndSearchInput(''));
        fetchInitialCampData(dispatch);
        dispatch(setNoDataFlag(false));
        // fetchComCampApiData(dispatch);
      } else {
        // console.log('yesssssss')
        if ((searchFilterData.length !== 0 && searchInput === '') || (verticalFilter.length !== 0 && searchInput === '')) {
          dispatch(setSearchInput(''));
          setFilterSearchInput('')
          const debounceTimer = setTimeout(async () => {
            const payload = {
              "vertical": verticalFilter,
              "mPubIds": searchFilterData
            }
            // if (searchFilterData.length > 0) {
            //   await  filterAdvertiserSearch(payload, dispatch);
            //   FrontEndSearchInput && searchTable(FrontEndSearchInput);
            // }
            { (verticalFilter.length > 0 || searchFilterData.length > 0) && filterAdvertiserSearch(payload, dispatch) }
          }, 1000);

          // Clear the timer on every key press to restart the countdown
          return () => clearTimeout(debounceTimer);
        }
        else {
          const debounceTimer = setTimeout(() => {
            setDebouncedInputValue(searchInput);
          }, 600);
          // Clear the timer on every key press to restart the countdown
          return () => clearTimeout(debounceTimer);
        }
      }
    }
  }, [dispatch, searchInput, searchFilterData, isReload, verticalFilter]);

  useEffect(() => {
    dispatch(fetchVerticals())
    // dispatch(setSearchFilterData([]));
    dispatch(setSearchInput(''));
    fetchCompApiData(dispatch);
    // fetchAdvComCampApiData(dispatch);
  }, [])


  const fetchMoreData = () => {
    if ((searchFilterData.length > 0 || verticalFilter.length > 0) && !isLoading && hasMoreData && searchInput === '') {
      dispatch(setCampFlag(true));
      const payload = {
        vertical: verticalFilter,
        mPubIds: searchFilterData,
      };
      if (verticalFilter.length > 0 || searchFilterData.length > 0) {
        filterAdvertiserSearch(payload, dispatch, currentCursor);
      }
    } else if ((searchFilterData.length === 0 || verticalFilter.length === 0) && !isLoading && hasMoreData && searchInput === '') {
      dispatch(setCampFlag(true));
      fetchAdvertiserCampApiData(dispatch, currentCursor);
    } else if (!isLoading && hasMoreData && searchInput !== '') {
      dispatch(setCampFlag(true));
      fetchSearchResult(debouncedInputValue, dispatch, 'campaign', currentCursor)
    }
  };



  //   const hadleEditIcon=(campaign_id:string)=>{
  //     // console.log("Test edit button", advCampaigns)
  //     // console.log("campaign_id", campaign_id)
  // const selectedAdvertiserCampaigns=advCampaigns.filter((camp:any)=>{
  //   return camp.SORTID===campaign_id
  // })


  //    dispatch(updateStateForEditAction(selectedAdvertiserCampaigns[0]))
  //   }

  useEffect(() => {
    if (debouncedInputValue !== '' && debouncedInputValue !== null) {
      dispatch(setSearchFilterData([]));
      fetchSearchResult(debouncedInputValue, dispatch, "campaign", null)
    }
  }, [debouncedInputValue])


  const hadleEditIcon = async (campaign_id: string) => {
    try {
      setIsLoadingData(true);
      const selectedAdvertiserCampaigns = advCampaignsLimitData.filter((camp: any) => {
        return camp.SORTID === campaign_id;
      });
      if (selectedAdvertiserCampaigns.length > 0) {

        dispatch(updateStateForEditAction(selectedAdvertiserCampaigns[0]));

      } else {
        // Handle the case where data is still not available
        console.error("Data not available for editing");
        // You can add user feedback, such as a notification or alert
      }
    } catch (error) {
      console.error("Error fetching data for editing", error);
      // Handle the error, add user feedback if needed
    }
    finally {
      setIsLoadingData(false);
    }
  };

  const hadleCloneIcon = async (campaign_id: string) => {
    try {
      setIsLoadingData(true);
      const selectedAdvertiserCampaigns = advCampaignsLimitData.filter((camp: any) => {
        return camp.SORTID === campaign_id;
      });

      if (selectedAdvertiserCampaigns.length > 0) {
        dispatch(updateStateForCloneAction(selectedAdvertiserCampaigns[0]));
      } else {
        // Handle the case where data is still not available
        console.error("Data not available for editing");
        // You can add user feedback, such as a notification or alert
      }
    } catch (error) {
      console.error("Error fetching data for editing", error);
      // Handle the error, add user feedback if needed
    }
    finally {
      setIsLoadingData(false);
    }
  };

  function searchTable(input: string) {
    const userInput = input.toLocaleLowerCase();
    if (userInput) {
      var filteredDataa = filterSearchAdvData.filter(function (item: any) {
        return (
          item.campaign_id?.toString().toLowerCase().includes(userInput) ||
          item.campaign_name?.toLowerCase().includes(userInput) ||
          item.Adv_ID?.toString().toLowerCase().includes(userInput) ||
          item.Advertiser_Name?.toLowerCase().includes(userInput) ||
          item.vertical?.toLowerCase().includes(userInput) ||
          item.Brand_Name?.toLowerCase().includes(userInput) ||
          item.Status?.toString().toLocaleLowerCase().includes(userInput)
          // item.Created_on?.toString().toLocaleLowerCase().includes(userInput)
        );
      });
      // console.log('filteredDataa',filteredDataa)
      if (userInput && filteredDataa.length == 0) {
        dispatch(setNoDataFlag(true));
      } else { dispatch(setNoDataFlag(false)); }
      dispatch(setAdvCampData(filteredDataa));
    } else {
      dispatch(setAdvCampData(filterSearchAdvData));
      // dispatch(appendCampData(filteredData));
      dispatch(setNoDataFlag(false));

      // dispatch(setFullData(publisherTableData));
    }
  }

  const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setFilterSearchInput(value);
    // dispatch(setFrontEndSearchInput(value));
    searchTable(value)
  }

  const handelSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    // console.log("event", event.target.value)
    dispatch(setSearchInput(event.target.value));
  }

  const handleFilterSearch = () => {
    dispatch(setSearchInput(''));
  }

  const handleChipRemove = (advertiserID: number) => {
    updatedFilterData = searchFilterData.filter((id: number) => id !== advertiserID);
    dispatch(setSearchFilterData(updatedFilterData));
  };

  const handleFieldChange = (field: keyof typeof advCampaignState, value: any) => {
    dispatch(setFields({ field, value }));
  };

  const handleRemoveVertical = (id: string) => {
    const newVerticalFilter = verticalFilter.filter((verticalId) => verticalId !== id);
    handleFieldChange("verticalFilter", newVerticalFilter); // Update state
    setVerticalMouseOverDelete(false);
  };

  return (

    <React.Fragment>
      <Paper elevation={3} className="card">
        <Box display="flex" justifyContent="space-between" p={3} sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
          <Box>
            <Typography className="card-heading">Click Campaigns</Typography>
            <Typography className="card-sub-heading">
              Click Campaigns Summary
            </Typography>
          </Box>
          <Box display="flex" gap="16px" sx={{ flexDirection: { xs: 'column', sm: 'row' }, mt: { xs: '16px', md: '0' }, alignItems: { xs: 'flex-start', md: 'center' } }}>
            <Search value={filterSearchFlag ? filterSearchInput : searchInput} searchHandler={filterSearchFlag ? handleFilter : handelSearch} />
            {/* { location.pathname != "/advertiserCampaigns"  && <Link to='/createAdvertiserCampaigns' style={{ textDecoration: "none" }} state={{
              drawerHeader: "Create Advertiser Campaigns"
            }}>
               <Button onClick={openPopup} variant="contained" startIcon={<AddIcon />}   sx={{backgroundColor:"#0ae"}} >
                Create Campaign
              </Button>
            </Link>} */}
            {location.pathname != "/advertiserCampaigns" &&
              <Button onClick={openPopup} variant="contained" startIcon={<AddIcon />} sx={{ backgroundColor: "#0ae" }} >
                Create Campaign
              </Button>}
            <CreateCampaignPopup
              isOpen={isPopupOpen}
              onClose={closePopup}
              onProceed={proceedToLink}
            />
          </Box>
        </Box>
        {location.pathname != "/advertiserCampaigns" && <Box display="flex" gap="8px" >

          <FormControl className='campaign-vertical-select'>
            <InputLabel style={{ paddingLeft: '19px' }}>Filter by Vertical</InputLabel>
            <Select
              label="Filter by Vertical"
              multiple
              value={verticalFilter}
              onChange={(e) => {
                const selectedIDs = e.target.value as string[];
                handleFilterSearch();
                handleFieldChange("verticalFilter", selectedIDs);
              }}
              renderValue={(selected: any) => {
                const maxVisibleChips = 1;
                const displayed = selected.slice(0, maxVisibleChips);
                const hiddenCount = selected.length - maxVisibleChips;
                return (
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {displayed.map((id: string) => {
                      const option = verticalOptions.find((opt) => opt.id === id);
                      return (
                        <Chip
                          key={id}
                          label={option?.name}
                          onDelete={() => handleRemoveVertical(id)}
                          deleteIcon={
                            <IconButton
                              size="small"
                              onMouseEnter={() => setVerticalMouseOverDelete(true)}
                              onMouseLeave={() => setVerticalMouseOverDelete(false)}
                            >
                              <CloseIcon fontSize="small" />
                            </IconButton>
                          }
                        />
                      );
                    })}
                    {hiddenCount > 0 && (
                      <Chip
                        label={`+${hiddenCount} more`}
                        style={{ margin: "2px", backgroundColor: "#e0e0e0", cursor: "pointer" }}
                      />
                    )}
                  </div>
                )

              }}
              style={{ height: "50px" }}
              MenuProps={{
                style: {
                  maxHeight: "400px",
                  marginTop: "5px",
                },
              }}
              disabled={VerticalMouseOverDelete}
            >
              {verticalOptions.map((vertical) => (
                <MenuItem key={vertical.id} value={vertical.id}>
                  <Checkbox checked={verticalFilter.includes(vertical.id)} />
                  <ListItemText primary={vertical.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl className='campaign-pub-select'>
            <InputLabel style={{ paddingLeft: "5px" }} >Filter by Advertiser</InputLabel>
            <Select
              multiple
              label="Filter by Advertiser"
              value={searchFilterData}
              onChange={(event) => {
                handleFilterSearch();
                dispatch(setSearchFilterData(event.target.value as []));
              }}
              renderValue={(selected) => {
                const maxVisibleChips = 2; // Maximum chips to display
                const displayed = selected.slice(0, maxVisibleChips);
                const hiddenCount = selected.length - maxVisibleChips;

                return (
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {fullData
                      .filter((adv: any) => displayed.includes(adv.advertiserID))
                      .map((adv: any) => (
                        <Chip
                          key={adv.SORTID}
                          label={`${adv.advertiserName}`}
                          onDelete={() => handleChipRemove(adv.advertiserID)}
                          deleteIcon={
                            <IconButton
                              size="small"
                              onClick={() => handleChipRemove(adv.advertiserID)}
                              onMouseEnter={() => setIsMouseOverDelete(true)}
                              onMouseLeave={() => setIsMouseOverDelete(false)}
                            >
                              <CloseIcon fontSize="small" />
                            </IconButton>
                          }
                        />
                      ))}
                    {hiddenCount > 0 && (
                      <Chip
                        label={`+${hiddenCount} more`}
                        style={{ margin: "2px", backgroundColor: "#e0e0e0", cursor: "pointer" }}
                      />
                    )}
                  </div>
                );
              }}
              MenuProps={{
                style: {
                  maxHeight: "400px",
                  marginTop: "5px",
                },
              }}
              disabled={isMouseOverDelete}
              style={{ height: "50px" }}
            >
              {fullData
                .filter((adv: any) => adv.advertiserName)
                .sort((a: any, b: any) => a.advertiserName.localeCompare(b.advertiserName))
                .map((adv: any) => (
                  <MenuItem key={adv.advertiserID} value={adv.advertiserID}>
                    <Checkbox checked={searchFilterData.includes(adv.advertiserID)} />
                    <ListItemText primary={`${adv.advertiserName} - ${adv.advertiserID}`} />
                  </MenuItem>
                ))}
            </Select>

          </FormControl>
        </Box>}
        <MMTable
          tableData={advCampaignTableData}
          tableHeadCell={advCampaignHeadCells}
          isLoadingMore={isLoading}
          fetchMoreData={() => fetchMoreData()}
          hasMoreData={hasMoreData}
          fetchInitialData={fetchInitialCampData}
          editComponentNavigation="/editAdvertiserCampaign"
          cloneHandler={hadleCloneIcon}
          editHandler={hadleEditIcon}
          searchInput={debouncedInputValue}
        />
        {isLoadingData && <p style={{ fontSize: '50px' }}>Loading...</p>}
      </Paper>
    </React.Fragment>
  )
}

export default Campaigns


export const AdvCampActionIcons = React.memo((props: any) => {
  const { CamID, fetchInitialData, searchInputDe } = props;
  const dispatch = useAppDispatch();
  const fullData = useAppSelector((state) => state.advertiserCampApi.campaignDataWithLimits);
  const [openDeletePopup, setDeletePopup] = React.useState(false);

  const getRowDataById = (campId: string) => {
    return fullData.find((row: any) => row.SORTID === campId);
  };
  const rowData = getRowDataById(CamID);

  //  console.log('searchInputDe=========',searchInputDe);
  const fetchData = () => {
    fetchAdvertiserCampApiData(dispatch, null);
    // fetchAdvComCampApiData(dispatch);
  };

  // useEffect(() => {
  //   // console.log('editFlag:',advertiserState.editFlag);
  // }, [advertiserState.editFlag,advertiserState.editFlag]);


  const delPayload = rowData?.SORTID;

  return (<>
    {/* <Tooltip title="Clone"><ContentCopyOutlinedIcon style={{ color: '#1976D2' }}/></Tooltip>  */}
    {/* <Tooltip title="Delete"><DeleteIcon onClick={() =>  console.log("delete ",rowData)} color="error" /></Tooltip> */}
    <Tooltip title="Delete"><DeleteIcon style={{ marginLeft: '7px' }} onClick={() => setDeletePopup(true)} color="error" /></Tooltip>
    <DeleteIconPop
      openDeletePopup={openDeletePopup}
      setDeletePopup={setDeletePopup}
      rowData={rowData}
      delPayload={delPayload}
      fetchData={fetchData}
      searchInputDe={searchInputDe} />

  </>)
});

// <CalenderPage />