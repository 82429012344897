import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PublisherStagingJSON from "../../Mock/PublishersStaging.json";
import {
  Box,
  Button,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { IOSSwitch } from "../../Component/Table/MMTable";
import { useAppSelector } from "../../Store/hooks";
import { baseURLConfig } from "../../config/envConfig";
import axios from "axios";
import MuiAlert from "@mui/material/Alert";
import "../../Component/Table/table.css";
import { generateHeader } from "../../service/Auth";
// import {baseURLConfig} from '../../config/envConfig';

const baseURL = baseURLConfig.baseURl;
const API_BASE_URL = `${baseURL}/publishers`;

interface ImpartnerData {
  mediaMatcher: {
    include: boolean;
  };
  asdRfi: {
    include: boolean;
  };
  eddyLinkout: {
    include: boolean;
  };
  title: string;
  //   "id": string;
  asdLinkout: {
    include: boolean;
  };
  archerEducation: {
    include: boolean;
  };
  clicksNet: {
    include: boolean;
  };
}
interface ImpartnerDat {
  mediaMatcher: {
    include: boolean;
  };
  asdRfi: {
    include: boolean;
  };
  eddyLinkout: {
    include: boolean;
  };
  asdLinkout: {
    include: boolean;
  };
  archerEducation: {
    include: boolean;
  };
  clicksNet: {
    include: boolean;
  };
}

const headerCell = [
  {
    id: "mediaMatcher",
    key: "mediaMatcher",
    label: "Media Matcher",
  },
  {
    id: "archerEducation",
    key: "archerEducation",
    label: "Archer Education",
  },
  {
    id: "asdLinkout",
    key: "asdLinkout",
    label: "Asd Linkout",
  },
  // {
  //   id: 'asdRfi',
  //   key: 'asdRfi',
  //   label: 'Asd RFI',
  // },
  {
    id: "eddyLinkout",
    key: "eddyLinkout",
    label: "Eddy Linkout",
  },
];
const MPartnerConfig = () => {
  const fullPubAccData = useAppSelector(
    (state) => state.publisherAccApi.fullData
  );
  const [isSnackbarOpen, setSnackbarOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoadingData] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { mPubId, ress } = location.state;
  let userRole = localStorage.getItem("userRole");
  const configValue = fullPubAccData.filter(
    (publisherValue: { mPubID: any }) => {
      return publisherValue.mPubID === mPubId;
    }
  );

  const getRowDataById = (pubId: string) => {
    return fullPubAccData.find((row: any) => row.mPubID === pubId);
  };
  const rowData = getRowDataById(mPubId);

  const [mPartnerData, setMPartnerData] = useState<{
    [key: string]: ImpartnerData;
  }>({});
  const doMpartnersConfig = (campaigns: any) => {
    let excludeMpartners: any = {};
    if (configValue[0]?.values?.excludeMpartners) {
      let excludecampaignsItems = JSON.parse(
        JSON.stringify(configValue[0]?.values?.excludeMpartners.campaigns)
      );
      excludeMpartners = { ...configValue[0]?.values?.excludeMpartners };
      excludeMpartners["campaigns"] = {};
      campaigns.forEach((campaign: any) => {
        excludeMpartners["campaigns"][campaign.SORTID] = {
          title: campaign.campaignname,
        };
        headerCell.forEach((cell: any, index) => {
          if (cell.id) {
            if (excludecampaignsItems[campaign.SORTID]) {
              if (!excludecampaignsItems[campaign.SORTID][cell.id]) {
                if (!excludeMpartners[cell.id]) {
                  excludeMpartners[cell.id] = { include: false };
                }
                excludeMpartners["campaigns"][campaign.SORTID][cell.id] = {
                  include: false,
                };
              } else {
                excludeMpartners["campaigns"][campaign.SORTID][cell.id] =
                  excludecampaignsItems[campaign.SORTID][cell.id];
              }
            } else {
              excludeMpartners["campaigns"][campaign.SORTID][cell.id] = {
                include: false,
              };
              //  excludeMpartners["campaigns"][campaign.SORTID]["mediaMatcher"] = {include:true}
            }
          }
        });
        if (excludeMpartners["quinStreet"]) {
          excludeMpartners["quinStreet"] = { include: false };
        }
        if (excludeMpartners["mediaMatcher"]) {
          excludeMpartners["mediaMatcher"] = { include: true };
        }
      });
    } else {
      headerCell.forEach((cell) => {
        if (cell.id) {
          excludeMpartners[cell.id] = { include: false };
        }
      });
      if (!excludeMpartners["quinStreet"]) {
        excludeMpartners["quinStreet"] = { include: false };
      }
      excludeMpartners["campaigns"] = {};
      campaigns.forEach((campaign: any) => {
        excludeMpartners["campaigns"][campaign.SORTID] = {
          title: campaign.campaignname,
        };
        headerCell.forEach((cell, index) => {
          if (cell.id) {
            excludeMpartners["campaigns"][campaign.SORTID][cell.id] = {
              include: false,
            };
            //  excludeMpartners["campaigns"][campaign.SORTID]['mediaMatcher'] = {include:true}
          }
        });
        if (!excludeMpartners["quinStreet"]) {
          excludeMpartners["quinStreet"] = { include: false };
        }
      });
    }

    return excludeMpartners;
  };
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCampaigns = async () => {
      try {
        setLoading(true);
        const mPart = await doMpartnersConfig(ress);
        setMPartnerData(mPart.campaigns);
        const firstRowData = { ...mPart };
        delete firstRowData["campaigns"];
        setFirstRowData(firstRowData);
        setLoading(false);
      } catch (err) {
        console.log("err", err);
      }
    };

    fetchCampaigns();
  }, []);

  useEffect(() => {
    if (!rowData) {
      // history.push('/mPartner-activation');
      navigate("/mPartner-activation");
    }
  }, [navigate]);

  const [firstRowData, setFirstRowData] = useState<any>();

  const handleAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    // Update the first row data
    setFirstRowData((prevData: any) => ({
      ...prevData,
      [name]: { include: checked },
    }));

    // Update the campaign data
    setMPartnerData((prevData: any) => {
      const updatedData = { ...prevData };

      Object.keys(updatedData).forEach((key: string) => {
        updatedData[key] = {
          ...updatedData[key],
          [name]: { include: checked },
        };
      });

      return updatedData;
    });
  };

  const handelChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    const { name, checked } = event.target;

    setMPartnerData((prevMPartnerData: any) => {
      return Object.keys(prevMPartnerData).reduce((acc: any, key: string) => {
        if (prevMPartnerData[key].title === id) {
          acc[key] = {
            ...prevMPartnerData[key],
            [name]: {
              ...prevMPartnerData[key][name],
              include: checked,
            },
          };
        } else {
          acc[key] = { ...prevMPartnerData[key] };
        }
        return acc;
      }, {});
    });
  };

  useEffect(() => {
    const firstRowData1 = { ...firstRowData };
    for (const key in firstRowData1) {
      // Check if all campaigns have the key and its value is true
      const isTrueForAllCampaigns = Object.values(mPartnerData).every(
        (campaign: any) => {
          return campaign[key] && campaign[key].include === true;
        }
      );

      // Update resultObject with the result
      const newValue = { ...firstRowData1[key] };
      newValue.include = isTrueForAllCampaigns;
      firstRowData1[key] = newValue;
    }

    setFirstRowData(firstRowData1);
  }, [mPartnerData]);

  const handleSave = async () => {
    setIsLoadingData(true);
    let payload;
    if (rowData) {
      payload = {
        GroupName: rowData?.publisherName,
        ID: rowData?.ID,
        SORTID: rowData?.SORTID,
        createdOn: rowData?.createdOn,
        mPubID: rowData?.mPubID,
        status: rowData?.status,
        type: "Publisher",
        values: {
          // excludeMpartners: mPartnerData,
          excludeMpartners: {
            ...firstRowData,
            campaigns: { ...mPartnerData },
          },
          address: rowData.values?.address,
          city: rowData.values?.city,
          companyDescription: rowData.values?.companyDescription,
          companyName: rowData.values?.publisherName,
          enablePublisherEmail: rowData.values?.enablePublisherEmail,
          phNo: rowData.values?.phNo,
          primaryContact: rowData.values?.primaryContact,
          publisherEmail: rowData.values?.publisherEmail,
          state: rowData.values?.state,
          website: rowData.values?.website,
        },
      };
    }

    try {
      // Uncomment the line below to make the actual API call
      const headers = await generateHeader();
      await axios.put(`${API_BASE_URL}`, payload, { headers });
      setSuccessMessage("mPartner Updated Successfully");
      setTimeout(() => {
        handleBack();
      }, 2000);
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage("Failed to save mPartner data");
      setSnackbarOpen(false);
      setIsLoadingData(false);
    }
    setSnackbarOpen(true);
    setIsLoadingData(false);
    // console.log('mPartnerData..........', mPartnerData,payload);
  };

  const handleBack = () => {
    if (userRole == "Publisher") {
      navigate("/usermPartnerActivation");
    } else {
      navigate("/mPartner-activation");
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <div>
      <Box>
        {/* <p style={{color:'#151D48',fontSize:'20px', fontWeight:'600'}}>Update Publisher - {rowData?.publisherName} ({rowData?.SORTID})</p></Box> */}
        <p style={{ color: "#151D48", fontSize: "20px", fontWeight: "600" }}>
          Update Publisher - {rowData?.publisherName}
        </p>
      </Box>

      {(isLoading || loading) && (
        <div className="loading-border">
          <div className="loading-bar" />
        </div>
      )}
      <Paper
        className="mProgam-config"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Box className="no-more-tables responsive-tbl">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Campaigns</TableCell>
                {headerCell.map((item) => {
                  return <TableCell key={item.id}>{item.label}</TableCell>;
                })}
              </TableRow>
            </TableHead>
            {!loading && (
              <TableBody>
                <TableRow>
                  <TableCell>All Campaigns</TableCell>
                  {headerCell.map((item) => (
                    <TableCell data-title={item.key} key={item.id}>
                      <IOSSwitch
                        name={item.key}
                        onChange={handleAll}
                        checked={firstRowData[item.key].include && "checked"}
                      />
                    </TableCell>
                  ))}
                </TableRow>

                {Object.values(mPartnerData)?.map((data: any) => (
                  <TableRow key={data.id}>
                    <TableCell>{data.title}</TableCell>
                    {headerCell.map((item) => (
                      <TableCell data-title={item.key} key={item.id}>
                        <IOSSwitch
                          name={item.key}
                          checked={data[item.key]?.include}
                          onChange={(e) => handelChange(e, data.title)}
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </Box>
        <Box
          className="mProgram-btn-container"
          sx={{
            textAlign: "right",
            paddingRight: "30px",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          <Button
            onClick={handleBack}
            sx={{
              backgroundColor: "#FFF",
              border: "1px solid #0AE",
              boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
              borderRadius: "8px",
              color: "#00AAEE",
              width: "100px",
              height: "45px",
            }}
          >
            Back
          </Button>
          <Button
            onClick={handleSave}
            sx={{
              backgroundColor: " #00AAEE",
              "&.MuiButtonBase-root:hover": {
                bgcolor: "#00AAEE !important",
              },
              "&:hover": { backgroundColor: "#00AAEE" },
              border: "1px solid #0AE",
              boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
              borderRadius: "8px",
              color: "#ffff",
              height: "45px",
              marginLeft: "10px",
            }}
          >
            Save
          </Button>
        </Box>
      </Paper>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={isSnackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackbar}
          severity={successMessage ? "success" : "error"}
        >
          {successMessage || errorMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default MPartnerConfig;
