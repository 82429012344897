import { ThunkDispatch } from 'redux-thunk'; // Import the ThunkDispatch type
import { AnyAction } from '@reduxjs/toolkit';
import { baseURLConfig } from '../config/envConfig';
import { appendAdvToolData, setBlockScroll, setCurrentCursor, setFilterSearchAdvData, setFullData, setHasMoreData, setIsoLoading, setLoading, setNoDataFlag } from '../Store/Slices/advertiserTabApisData';
import { generateHeader } from '../service/Auth';
// import { appendData, setFullData, setLoading } from '../Store/Slices/PublisherTabApisData';
import { getDisplayName } from '../View/User Accounts/Vendors/Vendors';
import axios from 'axios';
type DispatchType = ThunkDispatch<{}, {}, AnyAction>;

const baseURL = baseURLConfig.baseURl;
const advertiAccApiUrl = `${baseURL}/advertisers?ID=advertiser&limit=25`;
const API_BASE_URL = `${baseURL}/advertisers`;
const advertiserCampApiUrl = `${baseURL}/advertisers/campaign?ID=campaign&limit=25`;


export const fetchAdvertiserBudgetCap = async (
  dispatch: DispatchType,
  currentCursor: string | null,
  location: any
) => {
  const baseURL = baseURLConfig.baseURl;
  try {
    dispatch(appendAdvToolData([]));
    const headers = await generateHeader();
    dispatch(setLoading(true));
    let url = advertiAccApiUrl;
    if (currentCursor !== null) {
      url += `&cursor=${currentCursor}`;
    }
    const response = await fetch(url, { headers });
    const jsonData = await response.json();
    const cursorEncode = encodeURIComponent(jsonData.result.meta.cursor);
    const filteredData = generateDataforAdvTools(jsonData.result.data)
    // console.log("Filtereed data " , )
    //  dispatch(setFullData(jsonData.result.data))
    //  dispatch(appendFullData(jsonData.result.data))  
    dispatch(appendAdvToolData(filteredData));
    dispatch(setCurrentCursor(cursorEncode));
    dispatch(setHasMoreData(jsonData.result.meta.hasMoreData));
  } catch (error) {
    console.error('Error fetching data:', error);
  } finally {
    dispatch(setLoading(false));
  }
};


export const fetchFullAdvertiser = async (dispatch: DispatchType) => {
  try {
    // dispatch(setLoading(true));
    const headers = await generateHeader();
    const response = await fetch(`${baseURL}/advertisers?ID=advertiser`, { headers });
    const jsonData = await response.json();
    // const compApiData = jsonData.result.data.map((item: any) => ({
    //   Status: item.status,
    //   Adv_ID: item.advertiserID,
    //   Advertiser_Name: item.advertiserName,
    //   Brand_Name: item.brand,
    //   vendorName: getDisplayName(item.vendorName),
    //   // Created_on: item.createdOn,

    // }));
    // dispatch(setApiFullData(compApiData));
    dispatch(setFullData(jsonData.result.data));
    // dispatch(setLoading(false));
  } catch (error) {
    throw new Error('Failed to fetch Api data');
  }

}
const generateDataforAdvTools = (data: any) => {
  const filteredData = data.map((item: any) => {
    const budgetInfo = item?.values?.budgetInfo || {};
    const clickCampInfo = item?.values?.capsFilter || {};
    // Count campaigns with budgetFilteringStatus true
    const activeBudgetCampaignCount = Object.values(budgetInfo).filter(
      (campaign: any) => campaign.budgetFilteringStatus
    ).length;


    const activeClicksCapCampaignCount = Object.values(clickCampInfo).filter(
      (campaign: any) => campaign.clickCapFilteringStatus

    ).length;

    console.log("active  activeBudgetCampaignCount ", activeBudgetCampaignCount)
    const mappedItem: any = {
      // Status: item.status,
      Adv_ID: item.advertiserID,
      Advertiser_Name: item.advertiserName,
      // Brand_Name: item.brand,
      vendorName: getDisplayName(item.vendorName),
      Vertical: item?.vertical?.verticalDisplay,
      budget_Status: activeBudgetCampaignCount > 0 ? "Active" : "Inactive", // Budget status
      active_Budget_Campaign_Count: activeBudgetCampaignCount, // Add active campaign count
      caps_active: activeClicksCapCampaignCount > 0 ? "Active" : "Inactive",
      caps_active_campaign_count: activeClicksCapCampaignCount
    };

    return mappedItem;
  });

  return filteredData;
}
export const searchAdvTool = async (
  inputValue: string,
  dispatch: DispatchType,
  ID: string,
  location: any,
  currentCursor: string | null
) => {
  try {
    dispatch(setIsoLoading(true));
    const headers = await generateHeader();
    const encodedValue: string = encodeURIComponent(inputValue);

    let url = `${baseURL}/search?ID=${ID}&key=${encodedValue}`;
    if (currentCursor !== null) {
      url += `&cursor=${currentCursor}`;
    }

    const response = await axios.get(url, { headers });
    const filteredData = generateDataforAdvTools(response.data.data);

    if (encodedValue && filteredData.length === 0) {
      dispatch(setNoDataFlag(true));
    } else {
      dispatch(setNoDataFlag(false));
      dispatch(setBlockScroll(true));
    }

    dispatch(appendAdvToolData(filteredData));
  } catch (error) {
    console.error('Error fetching advertiser tool data:', error);
    dispatch(setNoDataFlag(true));
  } finally {
    dispatch(setIsoLoading(false));
  }
};



export const filterByVerticalSearchAdvBudgetCap = async (palyoad: Object, dispatch: DispatchType) => {
  // dispatch(setfilterSearchFlag(true));
  dispatch(setIsoLoading(true));
  // const encodedValue: string = encodeURIComponent(inputValue);
  const headers = await generateHeader();
  const response = await axios.post(`${baseURL}/filterByVertical?ID=advertiser`, palyoad, { headers });
  // const compApiData = response.data.result.data.map((item: any) => ({
  //   Status: item.status,
  //   Adv_ID: item.mPubID,
  //   Advertiser_Name: item.GroupName,
  //   Brand_Name: item.brand,
  //   vendorName: getDisplayName(item.vendorName),
  //   vertical: item?.vertical?.verticalDisplay,
  //   // Created_on: item.createdOn,

  // }));
  const compApiData = generateDataforAdvTools(response.data.result.data)
  dispatch(appendAdvToolData(compApiData));
  dispatch(setFilterSearchAdvData(compApiData));
  if (palyoad && compApiData.length === 0) {
    dispatch(setNoDataFlag(true));
  } else {
    dispatch(setNoDataFlag(false));
    dispatch(setBlockScroll(true));
  }
  dispatch(setIsoLoading(false));
}