import React, { useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TableSortLabel,
  Box,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import { CircleLoader } from 'react-spinners';
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteMediaPartnerDialog from "./DeleteAlert";
import InfoIcon from "@mui/icons-material/Info";
import { deleteMediaPartner } from "../../../service/MediaPartner";
const theme = createTheme({
  components: {
    MuiTableHead: {
      styleOverrides: {
        root: {
          fontWeight: "bold",
          backgroundColor: "#EAECF0",
          color: "#737791",
        },
      },
    },
  },
});

const mediaPartnerHeadCell = [
  {
    id: "partnerName",
    label: "Media Partner",
    numeric: false,
    key: "partnerName",
  },
  {
    id: "ID",
    label: "Group",
    numeric: false,
    key: "ID",
  },
  {
    id: "publisherCount",
    label: "Publisher Count",
    numeric: false,
    key: "publisherCount",
  },
];

interface IMediaPartnerTable {
  data: any[];
  deleteHandler: (groupName: string) => void;
  refreshTable: () => void;
  editHandler: (mediaPartner: any) => void;
  doEdit?: true;
  loading: boolean
}

const MediaPartnerTable = (props: IMediaPartnerTable) => {
  const [deleteDOpen, setDeleteDOpen] = useState(false);

  const [groupName, setGroupName] = useState("");
  const [partnerName, setPartnerName] = useState("");
  const [deleteSuccess, setDeleteSuccess] = useState("");
  const [deleteError, setDeleteError] = useState<any>("");
  const [snackbarOpen, setSnackbarOpen] = useState<any>(false);

  const handleDelete = async (groupName: string, partnerName: string) => {
    setDeleteDOpen(true);
    setGroupName(groupName)
    setPartnerName(partnerName);
  };
  const handleConfirm = async () => {
    if (groupName) {
      try {
        const res = await deleteMediaPartner(groupName);
        setDeleteDOpen(false);
        setDeleteSuccess(`Media Partner - ${partnerName} Deleted Successfully`);
        setSnackbarOpen(true);
        setTimeout(() => {
          props.refreshTable();
        }, 900);
      } catch (error: any) {
        setDeleteDOpen(false);
        setDeleteError(error.message);
        setSnackbarOpen(true);
      }
    }
  };

  const modalCloseHandler = () => {
    setDeleteDOpen(false);
    setGroupName("");
    setSnackbarOpen(false);
    // props.refreshTable();
  };
  return (
    <ThemeProvider theme={theme}>
      <TableContainer component={Paper}>
        <Box className="no-more-tables responsive-tbl">
          <Table size="small">
            <TableHead>
              <TableRow>
                {mediaPartnerHeadCell.map((item, index) => {
                  const isCenterAligned = [
                    "Publisher count",
                    "User count",
                  ].includes(item.label);

                  return (
                    <TableCell
                      key={item.id}
                      align={isCenterAligned ? "center" : "left"}
                    >
                      <TableSortLabel>{item.label}</TableSortLabel>
                    </TableCell>
                  );
                })}

                {props.doEdit && <TableCell>Action</TableCell>}
              </TableRow>
            </TableHead>

            <TableBody>
              {props.loading ? (
                <TableRow>
                  <TableCell colSpan={5} style={{ textAlign: 'center' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '20px' }}>
                      <CircleLoader color="#00BFFF" loading={true} size={60} />
                    </div>
                  </TableCell>
                </TableRow>
              ) : props?.data?.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={5} style={{ textAlign: 'center', padding: '10px', margin: '0', fontSize: 'large' }}>
                    No Result Found
                  </TableCell>
                </TableRow>
              ) : (
                props?.data?.map((mediaPartner, index) => (
                  <TableRow key={index} className="userTables">
                    <TableCell data-title="Group Name">
                      {mediaPartner?.partnerName}
                    </TableCell>
                    <TableCell data-title="Group Name">
                      {mediaPartner?.ID}
                    </TableCell>
                    <TableCell data-title="adv count">
                      {mediaPartner?.values?.publisherList?.length || 0}
                    </TableCell>
                    <TableCell>
                      <IconButton size="small">
                        <Tooltip title="Info">
                          <InfoIcon
                            color="primary"
                            onClick={() => props.editHandler(mediaPartner)}
                          />
                        </Tooltip>
                      </IconButton>
                      <IconButton size="small">
                        <Tooltip title="Delete">
                          <DeleteIcon
                            color="error"
                            onClick={() => handleDelete(mediaPartner?.ID, mediaPartner.partnerName)}
                          />
                        </Tooltip>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>


          </Table>
        </Box>
      </TableContainer>

      <DeleteMediaPartnerDialog
        open={deleteDOpen}
        closeHandler={modalCloseHandler}
        submitHandler={handleConfirm}
        alertText={partnerName}
        successMessage={deleteSuccess}
        errorMessage={deleteError}
        isSnackbarOpen={snackbarOpen}
      />
    </ThemeProvider>
  );
};

export default MediaPartnerTable;
