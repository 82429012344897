import { Box, IconButton, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Papa from 'papaparse';
import { useAppDispatch, useAppSelector } from '../../Store/hooks';
import { campaignFormSelector, storeCampaignCSVData, storeErrorTrackURLmProgramId, storeProgramCSVFileName } from '../../Store/Slices/CreateAdvCampaignSlice';

interface csvFileData {
    "Area Study": string;
    id: string;
    mPrograms: string;
    mProgramID: string;
    CPC: string;
    Status: boolean;
    "tracking url": string;
    Description: string
}


export const customHeaders = [
    { label: "Status", key: "Status" },
    { label: "areaStudy", key: "id" },
    { label: "areaStudy Name", key: "Area Study" },
    { label: "mProgram", key: "mProgramID" },
    { label: "mProgram Name", key: "mPrograms" },
    { label: "CPC", key: "CPC" },

    { label: "Description", key: "Description" },
    { label: "tracking url", key: "tracking url" },

];
const FileUploadBtn = (props: any) => {
    const [csvData, setCsvData] = useState<any[]>([]);
    const dispatch = useAppDispatch()
    const advCampaignData = useAppSelector(campaignFormSelector);
    const [selectedFile, setSelectedFile] = useState<null | any>(null)
    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setSelectedFile(file.name)
            dispatch(storeProgramCSVFileName(file.name))
            Papa.parse(file, {
                complete: (result: any) => {
                    console.log("result", result)
                    const transformedData = result.data.map((item: any) => {
                        const transformedItem: csvFileData | any = {};
                        console.log("item", item)
                        customHeaders.forEach(header => {
                            // Map each key from customHeaders to corresponding key in item
                            if (header.key === 'Status') {
                                transformedItem[header.key] = item[header.label] === 'on' ? true : false;
                            } else {
                                transformedItem[header.key] = item[header.label];
                            }
                        });
                        return transformedItem;
                    });

                    console.log("transformed Data", transformedData)
                    const { updatedData, invalidProgramsList } = validateUrls(transformedData.filter((element: csvFileData) => element['Area Study'] !== ''))
                    dispatch(storeCampaignCSVData(updatedData))
                    dispatch(storeErrorTrackURLmProgramId(invalidProgramsList.filter(element => element !== undefined)))
                },
                header: true,
            });
        }

    };

    useEffect(() => {
        if (advCampaignData.errorTrackURLmProgramID.length > 0) {
            props.snackbarOpenHandler(true);
            props.snackbarTypeHandler('error')
            props.setFileError(true)
            props.snackbarMessageHandler("There are  some wrong track url in specific programs. please update the file or update on program targeting section")
        }
    }, [advCampaignData.errorTrackURLmProgramID])






    return (
        <Box className='upload-btn-container'   >
            <label htmlFor="file-input">
                <IconButton
                    color="primary"
                    aria-label="upload file"
                    component="span"
                    disabled={(advCampaignData.csvRadioSelected === 'remove')}
                >

                    <AttachFileIcon style={{ marginTop: "19px", fontSize: '30px' }} />
                </IconButton>
            </label>
            <input
                type="file"
                accept=".csv"
                id="file-input"
                style={{ display: "none" }}
                onChange={handleFileUpload}
            />
            <TextField
                // style={{ width: "70%" }}
                id="standard-helperText"
                label={selectedFile ? "" : "Upload CSV"}
                helperText="Select  files"
                variant="standard"
                value={advCampaignData.campaignProgramCSVFileName}
                disabled={(advCampaignData.csvRadioSelected === 'remove')}
            />
        </Box>
    )
}

export default FileUploadBtn





export const validateUrls = (data: any) => {
    const invalidProgramsList: string[] = [];
    const updatedData: any[] = [];
    for (const item of data) {
        const { mProgramID } = item;
        // console.log("Item", item);
        // console.log("mPrograms", mProgramID)
        // Basic URL format check

        console.log(" track url", item['tracking url'], Boolean(item['tracking url']))
        if (item['tracking url']) {


            if (!isValidUrlFormat(item['tracking url'])) {
                invalidProgramsList.push(mProgramID);
                const updatedItem = { ...item, trackUrlError: "Please add a valid URL" };
                updatedData.push(updatedItem);
            } else {
                updatedData.push(item);
            }
        } else {
            updatedData.push(item);
        }

    }


    return { updatedData, invalidProgramsList }

};


const isValidUrlFormat = (url: string): boolean => {
    // Basic URL format check, you can customize this based on your specific URL format requirements
    // const urlPattern = /^(https?):\/\/[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=%]*$/i;
    const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
    return urlPattern.test(url);
};