import axios from "axios";
import { baseURLConfig } from "../config/envConfig";
import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { setAreaMprogramData, setDegreeInterest, setLoading, setSeoApiFullData, setSeoFormTableData, setSeoTableData } from "../Store/Slices/SEOHandlerApisSlice";
import { generateHeader } from "../service/Auth";
import { setIsoLoading } from "../Store/Slices/advertiserTabApisData";

const baseURL = baseURLConfig.baseURl;

type DispatchType = ThunkDispatch<{}, {}, AnyAction>;


export const fetchSeoApiData = async (dispatch: DispatchType) => {
  try {
    dispatch(setSeoTableData([]));

    const headers = await generateHeader();
    //  dispatch(setIsoLoading(true));
    dispatch(setLoading(true))
    const response = await fetch(`${baseURL}/getAllCmsWebContentList`, { headers });
    const jsonData = await response.json();
    // console.log("jsonData",jsonData);
    const compApiData = jsonData.data.map((item: any) => ({
      website: item.websiteName,
      websiteUrl: item.websiteUrl,
      //   seoTitle: item.seoTitle,
      //  seoKeywords: item.seoKeywords.join(', ') 

    }));

    dispatch(setSeoTableData(compApiData));
    dispatch(setSeoApiFullData(jsonData.data));
    dispatch(setSeoFormTableData(compApiData));

  } catch (error) {
    console.error('Error fetching data:', error);
  }
  finally {
    // dispatch(setIsoLoading(false));
    dispatch(setLoading(false))
  }
};

export const createSEOConfig = async (payload: any) => {
  try {
    const headers = await generateHeader();
    const response = await axios.put(`${baseURL}/seo/handler`, payload, { headers });
    return response.data;
  } catch (error) {
    throw error;
  }
};


export const fetchDegreeInterestNew = async (dispatch: DispatchType) => {
  try {
    const headers = await generateHeader();
    //  dispatch(setIsoLoading(true));
    // dispatch(setLoading(true))
    const response = await fetch(`${baseURL}/getCmsWebContentDegreeInterest?id=degreeInterest`, { headers });
    const jsonData = await response.json();
    // console.log("jsonData",jsonData);
    dispatch(setDegreeInterest(jsonData.data[0]?.list))
  } catch (error) {
    console.error('Error fetching data:', error);
  }
  finally {
    // dispatch(setIsoLoading(false));
    // dispatch(setLoading(false))
  }
};

export const fetchAreaMprogramnew = async (dispatch: DispatchType) => {
  try {
    const headers = await generateHeader();
    //  dispatch(setIsoLoading(true));
    // dispatch(setLoading(true))
    const response = await fetch(`${baseURL}/getCmsWebContentAreaMprogram`, { headers });
    const jsonData = await response.json();
    // console.log("jsonData",jsonData);
    dispatch(setAreaMprogramData(jsonData.data))
  } catch (error) {
    console.error('Error fetching data:', error);
  }
  finally {
    // dispatch(setIsoLoading(false));
    // dispatch(setLoading(false))
  }
};



export const updateWebSiteContent = async (payload: any) => {
  try {
    const headers = await generateHeader();
    const response = await axios.put(`${baseURL}/update/cmsWebContent`, payload, { headers });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getSeoData = async (payload: any) => {
  try {
    const headers = await generateHeader();
    //  dispatch(setIsoLoading(true));
    const response = await fetch(`${baseURL}/getCmsWebContentSeo${payload}`, {
      headers,
    });
    const jsonData = await response.json();

    return jsonData.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    // console.log('error===',error)
  }
};


export const updateSeoData = async (payload: any) => {
  try {
    const headers = await generateHeader();
    const response = await axios.put(`${baseURL}/update/cmsWebContentSeo`, payload, { headers });
    return response.data;
  } catch (error) {
    throw error;
  }
};


export const getWebContent = async (payload: any) => {
  try {
    const headers = await generateHeader();
    //  dispatch(setIsoLoading(true));
    const response = await fetch(`${baseURL}/getCmsWebContentSetting${payload}`, {
      headers,
    });
    const jsonData = await response.json();

    return jsonData.data;
  } catch (error) {
    console.error('Error fetching data:', error);
  } finally {
    // dispatch(setIsoLoading(false));
    // dispatch(setLoading(false))
  }
};


export const updateWebContent = async (payload: any) => {
  try {
    const headers = await generateHeader();
    const response = await axios.put(`${baseURL}/update/cmsWebContentSetting`, payload, { headers });
    return response.data;
  } catch (error) {
    throw error;
  }
};


export const getBannerData = async (payload: any) => {
  try {
    const headers = await generateHeader();
    //  dispatch(setIsoLoading(true));
    const response = await fetch(`${baseURL}/getCmsWebContentBanner${payload}`, {
      headers,
    });
    const jsonData = await response.json();

    return jsonData.data;
  } catch (error) {
    console.error('Error fetching data:', error);
  } finally {
    // dispatch(setIsoLoading(false));
    // dispatch(setLoading(false))
  }
};


export const updateBannerData = async (payload: any) => {
  try {
    const headers = await generateHeader();
    const response = await axios.put(`${baseURL}/update/cmsWebContentBanner`, payload, { headers });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getFaqData = async (payload: any) => {
  try {
    const headers = await generateHeader();
    //  dispatch(setIsoLoading(true));
    const response = await fetch(`${baseURL}/getCmsWebContentFaq${payload}`, {
      headers,
    });
    const jsonData = await response.json();

    return jsonData.data;
  } catch (error) {
    console.error('Error fetching data:', error);
  } finally {
    // dispatch(setIsoLoading(false));
    // dispatch(setLoading(false))
  }
};


export const updateFaqData = async (payload: any) => {
  try {
    const headers = await generateHeader();
    const response = await axios.put(`${baseURL}/update/cmsWebContentFaq`, payload, { headers });
    return response.data;
  } catch (error) {
    throw error;
  }
};


export const updateAdvMBrandPortalData = async (payload: any) => {
  try {
    const headers = await generateHeader();
    const response = await axios.put(`${baseURL}/update/advMbrandPortal`, payload, { headers });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAdvMBrandData = async (id: any) => {
  try {
    const headers = await generateHeader();
    const response = await axios.get(
      `${baseURLConfig.baseURl}/getAdvMbrandPortal?mBrandId=${id}`,
      { headers }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching search results:', error);
  }
};

export const updateSEOandBannerContent = async (payload: any) => {
  // https://3xkv7zjm2i.execute-api.us-east-1.amazonaws.com/staging/v3/uploadCmsWebContent
  try {
    const headers = await generateHeader();
    const response = await axios.post(`${baseURL}/uploadCmsWebContent`, payload, { headers });
    return response.data;
  } catch (error) {
    throw error;
  }
}



export const getCMSfileData = async (qp: any) => {
  try {
    const headers = await generateHeader();
    //  dispatch(setIsoLoading(true));
    const response = await axios.get(baseURL + '/getCmsSeoBannerWebContent', { headers, params: qp })


    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  } finally {
    // dispatch(setIsoLoading(false));
    // dispatch(setLoading(false))
  }
}