// src/api/api.ts

import axios from 'axios';
import { baseURLConfig } from '../../config/envConfig';
import { fetchToken, generateHeader } from '../Auth';

export const fetchAllPublisherCampaignsData = async (path: string, queryParamsObject: any) => {
  //   const response = await axios.get(`/api/users/${userId}`);
  //   return response.data;
  const baseURL = baseURLConfig.baseURl
  const queryParams = new URLSearchParams(queryParamsObject).toString();
  const headers = await generateHeader();
  const finalURL = baseURL + `/${path}?${queryParams}`
  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: finalURL,
    headers
  };

  // console.log("Fetch all PUB CAMPAIGNS data URL", config)
  const response = await axios.request(config)
  // console.log("response ", response)
  return response;

};


export const fetchAllPublishersData = async (path: string, queryParamsObject: any) => {
  //   const response = await axios.get(`/api/users/${userId}`);
  //   return response.data;
  const baseURL = baseURLConfig.baseURl

  const headers = await generateHeader();
  const queryParams = new URLSearchParams(queryParamsObject).toString();
  const finalURL = baseURL + `/${path}?${queryParams}`
  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: finalURL,
    headers
  };

  // console.log("Fetch all PUB CAMPAIGNS data URL", config)
  const response = await axios.request(config)
  // console.log("response ", response)
  return response;
};



export const configurePublisherCampaignsAds = async (payload: any) => {
  const baseURL = baseURLConfig.baseURl

  const headers = await generateHeader();

  try {
    const response = await axios.put(`${baseURL}/update/publisherAdvCampaigns`, payload, { headers });
    return response.data;
  } catch (error) {
    return error
  }
}

export const getBrands = async () => {
  const baseURL = baseURLConfig.baseURl

  const headers = await generateHeader();
  try {
    const response = axios.get(`${baseURL}/getBrandMapping`, {
      headers: headers
    })
    return (await response).data
  } catch (error) {
    return error
  }


}
export const getBrandsByVertical = async (value: any) => {
  const baseURL = baseURLConfig.baseURl

  const headers = await generateHeader();
  try {
    const response = axios.get(`${baseURL}/getBrandMappingByVertical?vertical=${value}`, {
      headers: headers
    })
    return (await response).data
  } catch (error) {
    return error
  }


}
export const getVerticalByPublisher = async (value: any) => {
  const baseURL = baseURLConfig.baseURl

  const headers = await generateHeader();
  try {
    const response = axios.get(`${baseURL}/getVerticalByPublisher?mPubID=${value}`, {
      headers: headers
    })
    return (await response).data
  } catch (error) {
    return error
  }


}

export const getPubDashboardReports = async (path: string, queryParams: any) => {
  // const defaultStartDate=`2023-01-01`
  // const defaultEndDate=`2023-02-10`
  const baseURL = baseURLConfig.publisherDashboardBaseUrl;

  // const formattedCampaignIDs: string = queryParams.mPubCampaignID.split(', ').join(', ');
  // const formattedAreaStudy: string = queryParams.areaStudy?queryParams.areaStudy.split(', ').join(', '):""

  const myInit = {
    queryStringParameters: {
      publisherId: queryParams.mPubID ? queryParams.mPubID : '79557',
      mPubCampaignID: queryParams.mPubCampaignID ? queryParams.mPubCampaignID : '',

      areaStudy: queryParams.areaStudy ? queryParams.areaStudy : '',
      chartType: queryParams.chartType

    },
    // headers: {
    //   "X-COG-KEY": idToken.jwtToken
    // }
  };
  const response = await axios.get(baseURL + path, {
    params: myInit.queryStringParameters
  })
  // const reportsData = response; 
  console.log("response::::::::::::::::::", response.data)
  return response.data.data.filter((item: any) => item.campaignName !== "")


}


export const getPubDashboardOverallTableReports = async (path: string, queryParams: any) => {
  // const { idToken } = await Auth.currentSession();
  // const defaultStartDate=`2023-01-01`
  // const defaultEndDate=`2023-02-10`
  const baseURL = baseURLConfig.publisherDashboardBaseUrl;
  const myInit = {
    queryStringParameters: {
      publisherId: queryParams.mPubID ? queryParams.mPubID : '79557',
      mPubCampaignID: queryParams.mPubCampaignID ? queryParams.mPubCampaignID : '',

      areaStudy: queryParams.areaStudy ? queryParams.areaStudy : '',
      chartType: queryParams.chartType

    },
    // headers: {
    //   "X-COG-KEY": idToken.jwtToken
    // }
  };
  console.log("Base URL::::::::::::::::::", baseURL + path)
  const response = await axios.get(baseURL + path, {
    params: myInit.queryStringParameters
  })
  // const reportsData = response; 
  console.log("response::::::::::::::::::", response.data)

  return response.data


}



  export   const fetchPubUserCampaignByPubID=async (pubID:string)=>{
      const headers = await generateHeader();
      const res = await axios.get(`${baseURLConfig.baseURl}/publishers/campaign?publisherID=${pubID}`, { headers });
    return res.data;
    }




export    const fetchListing=async (queryParams:any)=>{
  const baseURL=baseURLConfig.listingBaseURL;
  const response=await axios.get(baseURL, {
    params:queryParams
  })


  return response.data;

}


export const fetchTCPAData = async (queryParams: any) => {
  // const baseURL=baseURLConfig.listingBaseURL;
  const baseURL = 'https://ssjqmmzbrk.execute-api.us-east-1.amazonaws.com/production/v3/tcpa/search'
  const response = await axios.get(baseURL, {
    params: queryParams
  })


  return response.data;

}



export function buildUrl(baseURL: string, queryParams: any) {
  // const baseURL=baseURLConfig.listingBaseURL;
  const queryString = new URLSearchParams(queryParams).toString();
  return `${baseURL}?${queryString}`;
}


export const getUserIp = async () => {
  const response = await axios.get('https://api.ipify.org?format=json')

  return response.data;

}