import  {programTargetingData,  }from '../ProgramTargetingData';

export const areaStudyDropdown=programTargetingData.map((item)=>{
    return {
        label:item.label,
        value:item.value
    }
})


interface Program{
    label:string,
    value:string
}

export interface ProgramsByCategory {
    [key: string]: Program[];
  }
export const mPrograms: ProgramsByCategory= {
    "1": [{ "label": "General Business", "value": "100" }, { "label": "Accounting", "value": "101" }, { "label": "Business Administration", "value": "102" }, { "label": "Business Analytics", "value": "103" }, { "label": "Communications", "value": "104" }, { "label": "Economics", "value": "105" }, { "label": "Finance", "value": "106" }, { "label": "Human Resources", "value": "107" }, { "label": "Management", "value": "108" }, { "label": "Digital Marketing", "value": "109" }, { "label": "Project Management", "value": "110" }, { "label": "Sports Management", "value": "111" }, { "label": "Healthcare Administration", "value": "112" },{ "label": "Entrepreneurship", "value": '113' }],
    "2": [{ "label": "General Criminal Justice", "value": "200" }, { "label": "Corrections", "value": "201" }, { "label": "Crime Scence Investigation", "value": "202" }, { "label": "Criminal Justice", "value": "203" }, { "label": "Criminology", "value": "204" }, { "label": "Cyber Security", "value": "205" }, { "label": "Forensic Science", "value": "206" }, { "label": "Homeland Security", "value": "207" }, { "label": "Law Enforcement", "value": "208" }, { "label": "Legal & Paralegal Studies", "value": "209" }, { "label": "Public Safety Administration", "value": "211" }, { "label": "Security", "value": "212" }, { "label": "Fire Science", "value": "213" }],
    "3": [{ "label": "General Psychology", "value": "300" }, { "label": "Addictions & Recovery", "value": "301" }, { "label": "Applied Psychology", "value": "302" }, { "label": "Behavioral Psychology", "value": "303" }, { "label": "Child and Adolescent Psychology", "value": "304" }, { "label": "Counseling", "value": "305" }, { "label": "Forensic Psychology", "value": "307" }, { "label": "Human Services", "value": "308" }, { "label": "Organizational Psychology", "value": "310" }, { "label": "Sociology", "value": "311" }],
    "4": [{ "label": "General Computers & IT", "value": "400" }, { "label": "Bootcamp Programs", "value": "401" }, { "label": "Computer Programming", "value": "402" }, { "label": "Data Science & Analytics", "value": "403" }, { "label": "Game Art Development", "value": "404" }, { "label": "Information Systems", "value": "405" }, { "label": "Information Technology", "value": "406" }, { "label": "IT Project Managment", "value": "407" }, { "label": "Network Security", "value": "408" }, { "label": "Product and Graphic Design", "value": "409" }, { "label": "Software Engineering", "value": "410" }, { "label": "Web Development", "value": "411" }, { "label": "CyberSecurity", "value": "412" }],
    "5": [{ "label": "General Healthcare", "value": "500" }, { "label": "Certified Nursing Assistant (CNA)", "value": "501" }, { "label": "Allied Health", "value": "502" }, { "label": "Emergency Management (EMT)", "value": "503" }, { "label": "Health Science", "value": "505" }, { "label": "Healthcare Administration", "value": "506" }, { "label": "Healthcare Management", "value": "507" }, { "label": "Pharmacy Technician", "value": "508" }, { "label": "Medical Billing & Coding", "value": "509" }, { "label": "Medical Office Assistant", "value": "510" }, { "label": "Nutition, Fitness, and Sports Science", "value": "511" }, { "label": "Public Health", "value": "512" },{ "label": "Veterinary Technician", "value": "513" },{ "label": "X-Ray/Radiologic Technician", "value": "514" },{ "label": "Dental Assistant", "value": "515" },{ "label": "Surgical Technician", "value": "516" }, { "label": "Medical Assisting", "value": "517" }],
    "6": [{ "label": "General Education", "value": "600" }, { "label": "Adult Education/Learning", "value": "601" }, { "label": "Early Childhood Development", "value": "602" }, { "label": "Educational Administration", "value": "603" }, { "label": "K-12 Education", "value": "604" }, { "label": "Social Studies", "value": "605" }, { "label": "Special Education", "value": "606" }, { "label": "Teacher Liscensure", "value": "607" }],
    "7": [{ "label": "General Trade, Vocational, Career", "value": "700" }, { "label": "Automotive focused", "value": "701" }, { "label": "Aviation focused", "value": "702" }, { "label": "Certified Nursing Assistant (CNA)", "value": "703" }, { "label": "Construction management", "value": "704" }, { "label": "Electrical Technican", "value": "705" }, { "label": "Fire Science", "value": "706" }, { "label": "Truck Driver (CDL)", "value": "707" }, { "label": "HVAC", "value": "708" }, { "label": "Welding", "value": "709" }],
    "8": [{ "label": "General Liberal Arts", "value": "800" }, { "label": "Creative Writing & English", "value": "801" }, { "label": "Communications", "value": "802" }, { "label": "History", "value": "803" }, { "label": "Religious Studies", "value": "804" }, { "label": "Socialogy", "value": "805" }, { "label": "Humanities", "value": "806" }, { "label": "Political Science", "value": "807" }],
    "9": [{ "label": "General", "value": "900" }],
    "10": [{ "label": "General Nursing", "value": "1000" }, { "label": "Entry Level", "value": "1001" }, { "label": "RN Degree Programs", "value": "1002" }, { "label": "RN to BSN Bridge Programs", "value": "1003" }, { "label": "MSN Programs", "value": "1004" }],
    "11": [
        {
          "label": "General Multi-Media & Communications",
          "value": "1100"
        },
        {
          "label": "Advertising",
          "value": "1101"
        },
        {
          "label": "Animation & Video Graphic",
          "value": "1102"
        },
        {
          "label": "Commercial & Advertising Art",
          "value": "1103"
        },
        {
          "label": "Computer Media Applications",
          "value": "1104"
        },
        {
          "label": "Design & Visual Communication",
          "value": "1105"
        },
        {
          "label": "Film/Video & Cinematography",
          "value": "1106"
        },
        {
          "label": "Graphic Design",
          "value": "1107"
        },
        {
          "label": "Intermedia/Multimedia",
          "value": "1108"
        },
        {
          "label": "Photography",
          "value": "1109"
        },
        {
          "label": "Recording Arts Technology",
          "value": "1110"
        },
        {
          "label": "Web Design",
          "value": "1111"
        }
      ],
      "12": [
        {
          "label": "General Visual Arts & Graphic Design",
          "value": "1200"
        },
        {
          "label": "Design & Visual Communications",
          "value": "1201"
        },
        {
          "label": "Graphic Design",
          "value": "1202"
        },
        {
          "label": "Industrial Design",
          "value": "1203"
        },
        {
          "label": "Interior Design",
          "value": "1204"
        },
        {
          "label": "Photography",
          "value": "1205"
        },
        {
          "label": "Visual Arts",
          "value": "1206"
        }
      ],
      "13": [
        {
          "label": "General Religious Studies",
          "value": "1300"
        },
        {
          "label": "Christian Studies",
          "value": "1301"
        },
        {
          "label": "Ministry",
          "value": "1302"
        },
        {
          "label": "Religious Studies",
          "value": "1303"
        },
        {
          "label": "Theology",
          "value": "1304"
        }
      ]
}

export const degreeInterest: Program[]=[
    {
        label:"Certificate",
        value:"1"
    },
    {
        label:"Associates",
        value:"2"
    },
    {
        label:"Bachelors",
        value:"3"
    },
    {
        label:"Graduate Programs(Certifications, Master & Doctorate)",
        value:"4"
    },
    {
        label:"Unknown ",
        value:"5"
    },
]

export const militaryAffiliation: Program[]=[
    {
        label:"None",
        value:"1"
    },
    {
        label:"Active Duty",
        value:"2"
    },
    {
        label:"Veteran",
        value:"3"
    },
    {
        label:"Guard",
        value:"4"
    },
    {
        label:"Spouse",
        value:"5"
    },
    {
        label:"Dependent",
        value:"6"
    },
    {
        label:"Unknown",
        value:"7"
    }
]

export const highestEducation: Program[]=[
    {
        label:"high school diploma",
        value:"1"
    },
    {
        label:"No high school diploma",
        value:"2"
    },
    {
        label:"GED",
        value:"3"
    },
    {
        label:"0-12 college credits",
        value:"4"
    },
    {
        label:"more than 12 college credits",
        value:"5"
    },
    {
        label:"associates degree",
        value:"6"
    },
    {
        label:"bachelors degree",
        value:"7"
    },
    {
        label:"Graduate Degree",
        value:"8"
    },
    {
        label:"Unknown",
        value:"10"
    }
]



export const campusType: Program[]=[
    {
        label:"Either",
        value:"1"
    },
    {
        label:"Online",
        value:"2"
    },
    {
        label:"Campus",
        value:"3"
    },
    {
        label:"Unknown",
        value:"4"
    }
]


export const startTimeframe: Program[]=[
    {
        label:"Immediately",
        value:"1"
    },
    {
        label:"1-3 Months",
        value:"2"
    },
    {
        label:"4-6 Months",
        value:"3"
    },
    {
        label:"7-12 Months",
        value:"4"
    },
    {
        label:"Later than 1 year",
        value:"5"
    },
    {
        label:"Unknown",
        value:"6"
    }
]



export const mediaTypeOptions:Program[]=[

    {
        label:"Social",
        value:"1"
    },
    {
        label:'Email',
        value:"2"
    },
    {
        label:"Organic",
        value: "3"
    },
    {
        label:"SEM",
        value: "4"
    },
    {
        label:"SMS",
        value: "5"
    },
    {
        label:"display",
        value: "6"
    },
    {
        label:"affiliate",
        value: "7"
    },
    {
      label:"Unknown",
      value: "8"
  }

]


export const inventoryTypeOptions:Program[]=[
    {
        label:'Thank you page',
        value:'1'
    },
    {
        label:"No Match",
        value:'2'
    },
    {
        label:"Content Page",
        value:"3"
    },
    {
        label:"Light Box",
        value:"4"
    },
    {
      label:"Unkonwn",
      value:"5"
  }
]