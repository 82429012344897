import { Dialog, Box, Paper, Typography, Button, CircularProgress, TextField } from '@mui/material';
// import { Box } from 'devextreme-react';
import React, { useCallback, useEffect, useState } from 'react'
// import CloseIcon from '@mui/icons-material/Close';
import { fetchAllAdvertiserData, getAdvCampaigns } from '../../../service/Advertiser';
import { useLocation, useNavigate } from 'react-router-dom'
import { generateMediaSourceCampaigns, removeDuplicateCampaigns, removeDeletedCampaigns } from '../../../Mock/MediaSourceData';
import MMTabs from '../../../Component/Tabs/MMTabs';
import { useAppDispatch, useAppSelector } from '../../../Store/hooks'
import { storeInitialExcludeAdvertiser } from '../../../Store/Slices/PublisherCampaignSlice';
import { configurePublisherCampaignsAds } from '../../../service/Publisher';
import SnackbarMessage from '../../../Component/Snackbar';
// import LoadingButton from '@mui/lab/LoadingButton';

const ConfigureMediaSource = (props: any) => {
  const dispatch = useAppDispatch();
  const selectedPubCampData = useAppSelector((state) => state.campaign)
  const location = useLocation()
  const navigate = useNavigate();
  const { rowData } = location.state;
  console.log("props row data media Source ", rowData);
  const advAPIPath = "advertisers?ID=advertiser"
  const [advertiserList, setAdvertiserList] = useState<any[]>([])
  // const [apiResponse, setApiResponse] = useState<any[]>([])
  const [contentLoading, setContentLoading] = useState(false)
  const [advCampaignsRes, setAdvCampaignRes] = useState<any[]>([])

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarType, setSnackbarType] = useState('error');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [responseLoading, setResponseLoading] = useState(false);
  //  const [excludeAdvertiser, setExcludeAdvertiser]=useState<any>({})


  const getAllAdv = async () => {
    Promise.all([fetchAllAdvertiserData(advAPIPath)]).then(([advRes]) => {
      const adbList = generateMediaSourceCampaigns(advRes.data.result.data)
      // setApiResponse(advRes.data.result.data)
      setAdvertiserList(adbList.sort((a: any, b: any) => a.label.localeCompare(b.label)))
      // setAdvCampaignRes(advCampaignsRes.data.result.data);
      // setContentLoading(false)

    })

  }

  const getCampaign = async () => {
    setContentLoading(true)
    getAllAdv()
    let allData: any[] = [];
    let cursor: string | null = null;

    try {
      do {
        const response: any = await getAdvCampaigns(cursor);
        console.log("response camp", response)
        allData = [...allData, ...response.result.data];
        cursor = response.result.meta.cursor;
      } while (cursor);
      setAdvCampaignRes(allData);
    } catch (error) {
      // setError('An error occurred while fetching data');
    } finally {
      let exAdCamp;
      if (!rowData?.values?.hasOwnProperty('excludeAdvertisers')) {
        exAdCamp = generateExcludePublisherData(allData)
        console.log("exAdCammmm::::::::::::::::::::::", exAdCamp)
        dispatch(storeInitialExcludeAdvertiser(exAdCamp))
      } else {
        exAdCamp = mergeExcludeAdvertiser(allData, rowData.values.excludeAdvertisers)
        const ogData = removeDuplicateCampaigns(allData, exAdCamp)
        // removeDeletedCampaigns(allData, exAdCamp)
        console.log("Og dAta", ogData)
        dispatch(storeInitialExcludeAdvertiser(ogData))
      }

      // setExcludeAdvertiser(exAdCamp)
      setContentLoading(false);

      // console.log('adv camp res alldata', exAdCamp);
    }
  }


  useEffect(() => {
    getCampaign()
  }, [])
  const generateTabContent = (key: string = "1") => {
    // console.log("state data ")
    if (advertiserList.length > 1) {
      const value = advertiserList[parseInt(key) - 1].value
      if (selectedPubCampData.excludeAdvertiser) {
        if (selectedPubCampData.excludeAdvertiser[value]) {
          return selectedPubCampData.excludeAdvertiser[value].campaigns
        } else {
          return []
        }
      }

    }

  }

  const handelSave = async () => {
    setResponseLoading(true)
    const requestPayload = {
      ...rowData,
      publisherName: rowData.publisher?.SORTID,
      values: {
        ...rowData.values,
        "excludeAdvertisers": selectedPubCampData.excludeAdvertiser
      }
    }
    console.log("request Payload", requestPayload)
    try {

      const response = await configurePublisherCampaignsAds(requestPayload);

      // alert("success")
      setSnackbarOpen(true);
      setSnackbarType('success')
      setSnackbarMessage("Publisher campaigns has been updated!")
      console.log("response", response);
      setResponseLoading(false)
    } catch (error) {
      console.log("err", error)
      setResponseLoading(true)
      setSnackbarOpen(true);
      setSnackbarType('error')
      setSnackbarMessage("Some issue on saving the campaigns")
    }

  }


  console.log("selectedPubCampData.excludeAdvertiser", selectedPubCampData.excludeAdvertiser)


  const handleCancel = () => {
    navigate(-1)
    // onClick={() => navigate(-1)}
  }
  return (
    <Paper elevation={3} className="card" >
      <Box display="flex" justifyContent="space-between" p={3} sx={{ flexDirection: { xs: 'column', md: 'row' } }} >
        <Box >
          <Typography className="card-heading" sx={{ border: "10px" }}>Edit Campaign</Typography>
          <Typography className="card-sub-heading">
            Fill the details below to edit campaign
          </Typography>
        </Box>

      </Box>
      <Box display="flex" alignItems="center" mb={2} sx={{ marginLeft: '10px', marginRight: '10px' }}>
        <TextField id="outlined-basic" label="Campaign Name" variant="outlined" sx={{ width: "100%" }} value={rowData.campaignname} disabled />
      </Box>
      {
        contentLoading ? <Box sx={{ display: 'flex', justifyContent: "center", alignItems: 'center' }} >
          <CircularProgress color="inherit" size={24} />

          <Typography className="card-sub-heading">
            We are fetching Advertiser campaigns, please wait
          </Typography>
        </Box> :
          <>
            <MMTabs tabContent={advertiserList} generateTabPanelContentHandler={generateTabContent} />

            <Box sx={{ display: "flex", justifyContent: "flex-end", gap: '10px', paddingBottom: '8px' }}>
              <Button onClick={handleCancel}>
                Cancel
              </Button>
              <Button onClick={handelSave} sx={{
                backgroundColor: "#0ae",

                "&.MuiButtonBase-root:hover": {
                  bgcolor: responseLoading ? "#fff" : "#1976d2 !important"
                },
                "&:hover": { backgroundColor: responseLoading ? "#fff" : "#1976d2" },
                border: "1px solid #0ae",
                boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                borderRadius: "4px",
                color: "#ffff",
                height: "45px",
                paddingLeft: "16px",
                paddingRight: "16px",
                minWidth: '100px'
              }}
                disabled={responseLoading}
              >
                {
                  responseLoading ? <CircularProgress color="inherit" size={24} /> :
                    "Save"
                }
              </Button>

            </Box>

          </>
      }
      <SnackbarMessage open={snackbarOpen} onClose={() => { setSnackbarOpen(false); }} type={snackbarType} message={snackbarMessage} />
    </Paper>

  )
}

export default ConfigureMediaSource





const generateExcludePublisherData = (advertiserCampaigns: any[]) => {
  const excludeAdvertiser: any = {
    // Your data object goes here...
  };
  if (advertiserCampaigns.length > 1) {
    advertiserCampaigns.forEach((campaigns: any) => {


      excludeAdvertiser[campaigns?.publisherID] = {
        title: campaigns?.publisherName + " - " + campaigns?.publisherID,
        //   campaigns: {},
        bid: 0,
        enable: true,
        campaigns: [],
        Adv_ID: campaigns?.publisherID

      }
      let filteredByPublisherCampsData = advertiserCampaigns.filter((pc) => {
        return pc.publisherID === campaigns.publisherID
      })
      filteredByPublisherCampsData.forEach((campaigns) => {

        //   excludeAdvertiser[campaigns.publisherID].campaigns[campaigns.SORTID] = {
        //     title: campaigns?.campaignname + "-" + campaigns?.SORTID,
        //     bid: 6,
        //     enable: false,
        //     SORTID: campaigns.SORTID
        //     // Adv_ID: campaigns?.Adv_ID
        //   }

        excludeAdvertiser[campaigns.publisherID].campaigns.push({
          title: campaigns?.campaignname + "-" + campaigns?.campaignID,
          bid: 0,
          enable: false,
          SORTID: campaigns?.campaignID,
          Adv_ID: campaigns.publisherID,
          advName: campaigns.publisherName
        })
      })

      // console.log("advertiserCampaigns single ", campaigns)


    })
  }

  return excludeAdvertiser
}

// generateTabPanelContentHandler={generateTabContent}



const mergeExcludeAdvertiser = (advertiserCampaigns: any[], existingExcludeAdvertiser: any) => {
  const excludeAdvertiser: any = {
    // Your data object goes here...
    ...existingExcludeAdvertiser
  };
  if (advertiserCampaigns.length > 1) {
    advertiserCampaigns.forEach((campaigns: any) => {
      let filteredByPublisherCampsData: any[] = advertiserCampaigns.filter((pc) => {
        return pc.publisherID === campaigns.publisherID
      })
      if (!excludeAdvertiser.hasOwnProperty(campaigns?.publisherID)) {
        excludeAdvertiser[campaigns?.publisherID] = {
          title: campaigns?.publisherName + " - " + campaigns?.publisherID,
          campaigns: [],
          bid: 0,
          enable: true,
          advName: campaigns.publisherName,
          Adv_ID: campaigns?.publisherID

        }

        filteredByPublisherCampsData.forEach((campaigns: any) => {



          excludeAdvertiser[campaigns.publisherID].campaigns.push({
            title: campaigns?.campaignname + "-" + campaigns?.campaignID,
            bid: 0,
            enable: false,
            SORTID: campaigns?.campaignID,
            Adv_ID: campaigns.publisherID,
            advName: campaigns.publisherName
          })
        })

      } else {
        // console.log("Worked  new campaigns", excludeAdvertiser[campaigns?.publisherID])
        // console.log("Campaigns based on each pub", filteredByPublisherCampsData)

        filteredByPublisherCampsData.forEach((campaignData) => {
          // console.log('campaign data on ms generate ep in 00 ', campaignData)
          const newPublisherData = {
            ...excludeAdvertiser[campaignData.publisherID]
          }

          const newCampaignsArray = [...excludeAdvertiser[campaignData.publisherID].campaigns]

          const foundItem = newCampaignsArray.find(item => item.SORTID === campaigns?.campaignID)
          // const index = newCampaignsArray.findIndex(item => item.SORTID === campaigns?.SORTID);
          if (!foundItem) {

            const newObj = {
              title: campaigns?.campaignname + "-" + campaigns?.campaignID,
              bid: 0,   ///changed on 19 dec for media source 0
              enable: false,
              SORTID: campaigns?.campaignID,
              Adv_ID: campaigns.publisherID,
              advName: campaigns.publisherName
            }

            newCampaignsArray.push(newObj);
            newPublisherData.campaigns = newCampaignsArray
            excludeAdvertiser[campaignData.publisherID] = newPublisherData
          } else {
            newCampaignsArray.forEach((item) => {
              return {
                ...item,
                title: campaigns?.campaignname + "-" + campaigns?.SORTID,

              }
            })
            newPublisherData.campaigns = newCampaignsArray
            excludeAdvertiser[campaignData.publisherID] = newPublisherData
          }
        })


      }
    })
  }

  return excludeAdvertiser
}
