import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { TCPA_Description } from './tcpa_Description';
import TCPAProgramTarg from './tcpaProgramTarg';
import TCPAAudienceTarg from './TCPAAudienceTarg';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../Store/hooks';
import { cleanTcpaCampNameError, resetTcpaState, setSnackbarOpen, setTcpaCampaignName, setTcpaNameError, storeSelectedTcpaAdvertiser } from '../../../Store/Slices/tcpaCampSlice';
import MediaSource from '../../Advertiser/Stepper Form/Step components/MediaSource';
import StateRules from '../../Advertiser/Stepper Form/Step components/StateRules';
import Scheduling from '../../Advertiser/Stepper Form/Step components/Scheduling';
import { Formik } from 'formik';
import AudienceTargeting from '../../Advertiser/Stepper Form/Step components/AudienceTargeting';
import TCPAAudiTarg from './TCPAAudienceTarg/indexNew';
import TcpaMediaSource from './tcpaMediaSource';
import { campaignFormSelector, resetState, setSelectedPopUpAdv, storeSelectedAdvertiser } from '../../../Store/Slices/CreateAdvCampaignSlice';
import TcpaStateRules from './tcpaGeoTarg/TcpaStateRules';
import TcpaScheduling from './tcpaScheduling/tcpaScheduling';
import SnackbarMessage from '../../../Component/Snackbar';
import CircularProgress from '@mui/material/CircularProgress';

// import TestPage from './testPage';

const steps = ['TCPA Description', 'Program Targeting', 'Audience Targeting', "Media Source", "Geo Targeting", "Scheduling"];

const SmallHorizontalActiveBar = styled('div')(({ theme }) => ({
  backgroundColor: '#34C759', // Change to your preferred color
  borderRadius: '100px',
  width: '100%',
  height: 4,
}));

const SmallHorizontalBar = styled('div')(({ theme }) => ({
  backgroundColor: '#C1C9D2;', // Change to your preferred color
  borderRadius: '100px',
  width: '100%',
  height: 4,
}));


const AddTcpaEditDetails = () => {
  const dispatch = useAppDispatch();
  const submitRef = useRef(() => { });
  const [currentStepperIndex, setCurrentStepperIndex] = useState(0);
  const mBrandData = useAppSelector((state) => state.mBrandHandlerApi.data);
  const tcpaCampState = useAppSelector((state) => state.tcpaCampSlice);
  const { snackbarMessage, snackbarOpen, snackbarType, tcpaEditFlag, tcpaApiLoading } = tcpaCampState
  const advCampaignData = useAppSelector(campaignFormSelector);
  const { selectedPopUpAdv } = advCampaignData
  // const [snackbarOpen, setSnackbarOpen] = useState(false);
  // const [snackbarType, setSnackbarType] = useState('');
  // const [snackbarMessage, setSnackbarMessage] = useState('');
  const location = useLocation()
  const navigate = useNavigate();

  const getCurrentStepperChild = () => {
    switch (currentStepperIndex) {
      case 0:
        return <TCPA_Description submitRef={submitRef} handleNext={handleNext} mBrandData={mBrandData} />;
        break;
      case 1:
        return <TCPAProgramTarg submitRef={submitRef} handleNext={handleNext} />;
        break;
      case 2:
        return <TCPAAudiTarg submitRef={submitRef} handleNext={handleNext} />
        // return <TCPAAudienceTarg submitRef={submitRef} handleNext={handleNext}  />;
        // return <AudienceTargeting  /> 
        break;
      case 3:
        return <MediaSource submitRef={submitRef} handleNext={handleNext} />;
        // return <TcpaMediaSource submitRef={submitRef} handleNext={handleNext}/>;
        break;
      case 4:
        return <TcpaStateRules submitRef={submitRef} handleNext={handleNext} />;
        break;
      case 5:
        return <TcpaScheduling submitRef={submitRef} handleNext={handleNext} />;
        break;

      default:
        return null;
    }
  };


  const handleNext = () => {
    if (currentStepperIndex === 5) {
      // navigate('/advertiser-campaigns')
      setTimeout(() => {
        navigate('/TCPA-campaigns');
      }, 2000);
    } else {
      setCurrentStepperIndex(currentStepperIndex + 1);
    }
  };
  const handleBack = () => {
    if (currentStepperIndex > 0) {
      setCurrentStepperIndex(currentStepperIndex - 1);
    } else {
      navigate("/TCPA-campaigns");
    }
  };

  useEffect(() => {
    console.log("selected PopUp adv ", selectedPopUpAdv)
    if (selectedPopUpAdv) {
      dispatch(storeSelectedTcpaAdvertiser(selectedPopUpAdv))
    }

    dispatch(resetState(''))
    if (location.pathname === '/editTcpaCamp') {
      if (!tcpaEditFlag) {
        navigate('/TCPA-campaigns')
      }
      dispatch(setSelectedPopUpAdv(""))
    }

    return () => {
      dispatch(resetTcpaState("test"));
    };
  }, [dispatch])



  // useEffect(() => {

  //   if( location.pathname==='/createTcpaCamp'){
  //          navigate('/advertiser-campaigns')
  //       }

  // }, [dispatch])

  const handelCampaignName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    dispatch(setTcpaCampaignName(inputValue));

    if (inputValue.length > 4) {
      // dispatch(cleanTcpaCampNameError(''));
      dispatch(setTcpaNameError(false));

    }
  };

  return (
    <>
      <Box className="card">
        <Box display="flex" mb={2.5}>
          <Box>
            <Typography className="card-heading">
              {tcpaEditFlag ? "Update" : 'Create'} TCPA Campaign
            </Typography>
            <Typography className="card-sub-heading">
              Fill the details below to {tcpaEditFlag ? "Update" : 'Create'} TCPA Campaign
            </Typography>
          </Box>
        </Box>
        {/* onChange={handelCampaignName} error={tcpaCampState.tcpaNameError} helperText={campaignNameError} disabled={isEdit} */}
        <Box display="flex" alignItems="center" mb={4}>
          <TextField id="outlined-basic" disabled={tcpaEditFlag || (currentStepperIndex > 0 && !tcpaEditFlag)} label="TCPA Campaign Name" helperText={tcpaCampState.tcpaNameError ? (<Box color="#db2424">Please enter TCPA campaign name</Box>) : null}
            variant="outlined" onChange={handelCampaignName} sx={{ width: "100%" }} required error={tcpaCampState.tcpaNameError} value={tcpaCampState.tcpaCampaignName} />

        </Box>
        <div className="stepper-container">
          <Stepper
            sx={{ overflow: 'auto', paddingBottom: '16px' }}
            activeStep={currentStepperIndex}
            alternativeLabel
          >

            {steps.map((label, index) => (
              <Step
                key={label}
                className="root-step-test"
                onClick={index => {
                  tcpaEditFlag &&
                    setCurrentStepperIndex(steps.indexOf(label));
                }}
              >
                <StepLabel
                  StepIconComponent={({ active }) =>
                    active ? (
                      <SmallHorizontalActiveBar />
                    ) : (
                      <SmallHorizontalBar />
                    )
                  }
                  className="step-label-test"
                  sx={{
                    fontFamily: 'Poppins',
                    fontSize: '14px',
                    fontWeight: '500',
                    lineHeight: '20px',
                    letterSpacing: '0px',
                    textAlign: 'center',
                  }}
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          <Box sx={{ marginTop: '10px' }}>{getCurrentStepperChild()}</Box>
          <Box
            className="mProgram-btn-container"
            sx={{
              textAlign: 'right',
              paddingRight: '30px',
              marginTop: '10px',
              marginBottom: '10px',
            }}
          >
            <Button
              onClick={handleBack}
              sx={{
                backgroundColor: '#FFF',
                border: '1px solid #0AE',
                boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                borderRadius: '8px',
                color: '#00AAEE',
                width: '100px',
                height: '45px',
              }}
            >
              Back
            </Button>

            <Button
              variant="contained"
              size="large"
              onClick={() => {
                submitRef.current();
              }}
              sx={{
                backgroundColor: '#1976d2',
                '&.MuiButtonBase-root:hover': {
                  bgcolor: '#1976d2 !important',
                },

                '&:hover': { backgroundColor: '#1976d2' },
                border: '1px solid #1976d2',
                boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                borderRadius: '8px',
                // color: '#ffff',
                // width:'110px',
                height: '45px',
                marginLeft: '10px',
              }}
            >
              {
                tcpaApiLoading ? <CircularProgress color="inherit" size={24} /> :

                  (currentStepperIndex === 5) ? "Finish" : "Save & Next"
              }


            </Button>
          </Box>
        </div>
      </Box>
      <SnackbarMessage open={snackbarOpen} onClose={
        () => {
          dispatch(setSnackbarOpen(false))
          // setApiLoading(false)
        }} type={snackbarType} message={snackbarMessage} />
    </>
  );
};

export default AddTcpaEditDetails;
