import React, { memo, useState, useEffect } from "react";
import {
  Paper,
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  FormControl,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { InputField } from "../CreateUser";
import CloseIcon from "@mui/icons-material/Close";
import { createMediaPartner } from "../../../service/MediaPartner";
import SnackbarMessage from "../../../Component/Snackbar";
import { updateVendorGroup } from "../../../service/Vendor";
import { CircleLoader } from "react-spinners";

const publisherHeadCell = [
  {
    id: "vertical",
    label: "Vertical",
    numeric: false,
    key: "verticals",
  },
  {
    id: "publisherName",
    label: "Publisher Name",
    numeric: false,
    key: "publisherName",
  },
  {
    id: "publisherID",
    label: "Publisher ID",
    numeric: false,
    key: "publisherID",
  },
];

const createGroup = memo((props: any) => {
  const [groupInfo, setGroupInfo] = useState({
    groupName: "",
    groupDescription: "",
    groupNameError: "",
  });

  const [pubData, setPubData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const { isEdit, rowData } = props;
    if (isEdit) {
      setGroupInfo((prev: any) => ({
        ...prev,
        groupName: rowData?.partnerName,
        groupDescription: rowData?.Description,
      }));
    } else {
      setGroupInfo({
        groupName: "",
        groupDescription: "",
        groupNameError: "",
      });
    }
    setPubData(props?.rowData?.values?.publisherList);
  }, [props.isEdit]);

  const { setSnackBarOpen, setSnackBarType, setSnackBarMessage } = props
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === "groupName") {
      const error = validateGroupName(value);
      setGroupInfo((prev) => ({
        ...prev,
        groupName: value,
        groupNameError: error,
      }));
    } else if (name === "Description") {
      setGroupInfo((prev) => ({
        ...prev,
        groupDescription: value,
      }));
    }
  };

  const handleCloseDialog = () => {
    setGroupInfo({
      groupName: "",
      groupDescription: "",
      groupNameError: "",
    });

    props.modalCloseHandler();
  };

  const closeHandler = (event: any, reason: any) => {
    if (reason && reason === "backdropClick" && "escapeKeyDown") return;

    handleCloseDialog();
  };
  const handleCreateGroup = async () => {
    // Validate the group name
    const groupNameError = validateGroupName(groupInfo.groupName);
    if (groupNameError) {
      setGroupInfo((prev) => ({
        ...prev,
        groupNameError,
      }));
      return; // Exit early if the group name is invalid
    }

    const params = {
      GroupName: `Media-Partner-${groupInfo.groupName}`,
      partnerName: groupInfo.groupName,
      Description: groupInfo.groupDescription,
      Precedence: 1,
    };

    try {
      const response = await createMediaPartner(params);

      // Display success or error message based on the response
      setSnackBarOpen(true);

      if (response.success) {
        setSnackBarType("success");
        setSnackBarMessage(response.message); // Display success message
      } else {
        setSnackBarType("error");
        setSnackBarMessage(response.message); // Display error message from the API
      }

      // Optionally, handle success actions
      setTimeout(() => {
        handleCloseDialog();
        props.refreshTable(); // Refresh the table after successful operation
      }, 1000);

    } catch (err) {
      console.error("Unexpected error:", err);
      setSnackBarOpen(true);
      setSnackBarType("error");
      setSnackBarMessage('An unexpected error occurred.');
    }
  };

  return (
    <>
      <Dialog open={props.open} onClose={closeHandler} fullWidth maxWidth="lg">
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <CircleLoader
              color="#00BFFF"
              loading={loading ? true : false}
              size={80}
            />
          </Box>
        ) : (
          <Paper elevation={3} className="card">
            <DialogTitle>
              {" "}
              {props.isEdit ? "Media Partner Info" : "Create Media Partner"}
            </DialogTitle>
            <div className="close-button" onClick={props.modalCloseHandler}>
              <CloseIcon style={{ fontSize: "32px" }} />
            </div>
            <DialogContent
              sx={{
                overflowY: { xs: "auto", md: "hidden" },
                maxHeight: { xs: "80vh", md: "none" },
              }}
            >
              <Grid container spacing={3.5}>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth style={{ marginBottom: "16px" }}>
                    <InputLabel id="demo-simple-select-label">Group</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={"Media Partner"}
                      label="Group"
                      name="groupType"
                      disabled={true}
                    >
                      <MenuItem value={"Media Partner"}>Media Partner</MenuItem>
                    </Select>
                  </FormControl>
                  <InputField
                    name="groupName"
                    value={groupInfo.groupName}
                    changeHandler={handleInputChange}
                    error={groupInfo.groupNameError}
                    hidden={props.isEdit ? true : false}
                    required={true}
                    label={"Group Name"}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <ListAdvDetails pubData={pubData} isEdit={props.isEdit} />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions style={{ padding: "15px" }}>
              <Button style={{ color: "red" }} onClick={handleCloseDialog}>
                CANCEL
              </Button>
              {!props.isEdit && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleCreateGroup}
                >
                  Create
                </Button>
              )}
            </DialogActions>
          </Paper>
        )}
      </Dialog>

    </>
  );
});

export default createGroup;

const validateGroupName = (name: string) => {
  if (name.length < 1 || name.length > 128) {
    return "Group name must be between 1 and 128 characters long.";
  }

  const pattern = /^[\p{L}\p{M}\p{S}\p{N}\p{P}]+$/u;
  if (!pattern.test(name)) {
    return "Group name contains invalid characters";
  }

  return "";
};

export interface PublisherRowProps {
  publisherName: any;
  publisherID: any;
  verticalName: any;
}

const PublisherRow: React.FC<PublisherRowProps> = React.memo(
  ({ verticalName, publisherName, publisherID }) => {
    return (
      <TableRow key={publisherID}>
        <TableCell data-title="Publisher ID">{verticalName}</TableCell>
        <TableCell data-title="Publisher Name">{publisherName}</TableCell>
        <TableCell data-title="Publisher ID">{publisherID}</TableCell>
      </TableRow>
    );
  }
);

interface IpubList {
  isEdit: boolean;
  pubData: any[];
}

const ListAdvDetails = (props: IpubList) => {
  const { isEdit, pubData } = props;

  return (
    <Paper elevation={3} className="card">
      <Box
        display="flex"
        justifyContent="center"
        p={3}
        sx={{ flexDirection: { xs: "column", md: "row" } }}
      >
        <Box display="flex" justifyContent="center">
          <Typography className="card-heading" sx={{ border: "10px" }}>
            Publishers
          </Typography>
        </Box>
      </Box>

      <Box
        className="no-more-tables responsive-tbl"
        sx={{ overflow: "auto", maxHeight: "400px" }}
      >
        <Table size="small">
          <TableHead
            sx={{
              fontWeight: "bold",
              backgroundColor: "#EAECF0",
              color: "#737791",
            }}
          >
            <TableRow>
              {publisherHeadCell.map((item) => {
                return <TableCell>{item.label}</TableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {pubData && pubData.length > 0 ? (
              pubData.map((publisher) => (
                <PublisherRow
                  key={publisher?.mPubID}
                  publisherName={publisher?.publisherName}
                  publisherID={publisher?.mPubID}
                  verticalName={publisher?.verticals[0]?.verticalName.split(' ')[0]}
                />
              ))
            ) : (
              <tr>
                <td colSpan={4} style={{ textAlign: "center", padding: "10px" }}>
                  No Data Available
                </td>
              </tr>
            )}
          </TableBody>
        </Table>
      </Box>
    </Paper>
  );
};
