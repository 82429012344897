// Fetch all adv action
// src/features/data/dataActions.ts
import { AppDispatch } from '..';
import { fetchAllPublisherCampaignsDataAsyncForMbrand, fetchAllPublisherDataAsyncForMbrand } from '../Slices/ExternalApiSlice';

import { fetchAdvDataAsync, fetchAllPublisherDataAsync, fetchAllPublisherCampaignsDataAsync } from '../Slices/CreateAdvCampaignSlice';
import { fetchTcpaDataAsync } from '../Slices/tcpaCampSlice';

export const FETCH_VERTICALS_REQUEST = 'FETCH_VERTICALS_REQUEST';
export const FETCH_VERTICALS_SUCCESS = 'FETCH_VERTICALS_SUCCESS';
export const FETCH_VERTICALS_FAILURE = 'FETCH_VERTICALS_FAILURE';
export const FETCH_MEDIAPARTNER_REQUEST = 'FETCH_MEDIAPARTNER_REQUEST';
export const FETCH_MEDIAPARTNER_SUCCESS = 'FETCH_MEDIAPARTNER_SUCCESS';
export const FETCH_MEDIAPARTNER_FAILURE = 'FETCH_MEDIAPARTNER_FAILURE';

export const fetchAdvertiserDataAction = (path: string) => async (dispatch: AppDispatch) => {
  const args = {
    path
  }
  try {
    await dispatch(fetchAdvDataAsync(args));
  } catch (error) {
    // Handle any errors here
    console.error('Error fetching user data:', error);
  }
};
export const fetchTcpaAction = (path: string, queryParamsObject: any) => async (dispatch: AppDispatch) => {
  const args = {
    path,
    queryParamsObject
  }
  try {
    await dispatch(fetchTcpaDataAsync(args));
  } catch (error) {
    // Handle any errors here
    console.error('Error fetching user data:', error);
  }
};

export const fetchMediaSourceDataAction = () => async (dispatch: AppDispatch) => {

  const args1 = {
    path: "publishers",
    queryParamsObject: {
      ID: "publisher"
    }
  }

  const arg2 = {
    path: "publishers/campaigns",
    queryParamsObject: {
      ID: "campaign"
    }
  }
  try {
    // Dispatch both thunks concurrently using Promise.all
    await Promise.all([

      dispatch(fetchAllPublisherCampaignsDataAsync(arg2)),
      dispatch(fetchAllPublisherDataAsync(args1)),
    ]);

    // Both API calls are complete
  } catch (error) {
    // Handle any errors that may occur during the API calls
    console.log("err", error)
  }
};




export const fetchMBrandMediaSourceDataAction = () => async (dispatch: AppDispatch) => {

  const args1 = {
    path: "publishers",
    queryParamsObject: {
      ID: "publisher"
    }
  }

  const arg2 = {
    path: "publishers/campaigns",
    queryParamsObject: {
      ID: "campaign"
    }
  }
  try {
    // Dispatch both thunks concurrently using Promise.all
    await Promise.all([

      dispatch(fetchAllPublisherCampaignsDataAsyncForMbrand(arg2)),
      dispatch(fetchAllPublisherDataAsyncForMbrand(args1)),
    ]);

    // Both API calls are complete
  } catch (error) {
    // Handle any errors that may occur during the API calls
    console.log("err", error)
  }
};