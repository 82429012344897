import { PayloadAction, createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { createSEOConfig } from "../../Services/seoApis";
import { createMbrand, deleteMbrand, fetchMBrandApiData, updateMbrand } from "../../Services/externalApis";
import { fetchAllAdvertiserData } from "../../service/Advertiser";
import { createTcpaCamp, deleteTcpaCamp, updateStatusTcpaCamp } from "../../Services/tcpaTabApis";
import { scheduling_jsonData } from "../../Mock/SchedulingData";

export interface StateData {
  id: number;
  name: string;
  key: string
}
const statesTargeting: StateData[] = [
  {
    id: 1,
    name: "Alabama (AL)",
    key: "AL"
  },
  {
    id: 2,
    name: "Alaska (AK)",
    key: 'AK'
  },
  {
    id: 3,
    name: "Arizona (AZ)",
    key: "AZ"
  },
  {
    id: 4,
    name: "Arkansas (Ar)",
    key: "AR"
  },
  {
    key: "CA",
    id: 5,
    name: "California (CA)"
  },
  {
    key: "CO",
    id: 7,
    name: "Colorado (CO)"
  },
  {
    key: "CT",
    id: 9,
    name: "Connecticut (CT)"
  },
  {
    key: "DE",
    id: 10,
    name: "Delaware (DE)"
  },
  {
    key: "DC",
    id: 11,
    name: "District of Columbia (DC)"
  },
  {
    key: "FL",
    id: 12,
    name: "Florida (FL)"
  },
  {
    key: "GA",
    id: 13,
    name: "Georgia (GA)"
  },
  {
    key: "HI",
    id: 14,
    name: "Hawaii (HI)"
  },
  {
    key: "ID",
    id: 15,
    name: "Idaho (ID)"
  },
  {
    key: "IL",
    id: 16,
    name: "Illinois (IL)"
  }, {
    key: "IA",
    id: 17,
    name: "Iowa (IA)"
  },
  {
    key: "KS",
    id: 18,
    name: "Kansas (KS)"
  },
  {
    key: "KY",
    id: 19,
    name: "Kentucky (KY)"
  },
  {
    key: "LA",
    id: 20,
    name: "Louisiana (LA)"
  },
  {
    key: "ME",
    id: 21,
    name: "Maine (ME)"
  },
  {
    key: "MD",
    id: 22,
    name: "Maryland (MD)"
  },
  {
    key: "IN",
    id: 53,
    name: "Indiana (IN)"
  },
  {
    key: "MA",
    id: 23,
    name: "Massachusetts (MA)"
  },
  {
    key: "MI",
    id: 24,
    name: "Michigan (MI)"
  },
  {
    key: "MN",
    id: 25,
    name: "Minnesota (MN)"
  }, {

    key: "MS",
    id: 26,
    name: "Mississippi (MS)"
  }, {
    key: "MO",
    id: 27,
    name: "Missouri (MO)"
  }, {
    key: "MT",
    id: 28,
    name: "Montana (MT)"
  }, {
    key: "NE",
    id: 29,
    name: "Nebraska (NE)"
  }, {
    key: "NV",
    id: 30,
    name: "Nevada (NV)"
  }, {
    key: "NH",
    id: 31,
    name: "New Hampshire (NH)"
  },

  {
    key: "NJ",
    id: 32,
    name: "New Jersey (NJ)"
  },
  {
    key: "NM",
    id: 33,
    name: "New Mexico (NM)"
  },
  {
    key: "NY",
    id: 34,
    name: "New York (NY)"
  },
  {
    key: "NC",
    id: 35,
    name: "North Carolina (NC)"
  },
  {
    key: "ND",
    id: 36,
    name: "North Dakota (ND)"
  },
  {
    key: "OH",
    id: 37,
    name: "Ohio (OH)"
  },
  {
    key: "OK",
    id: 38,
    name: "Oklahoma (OK)"
  },
  {
    key: "OR",
    id: 39,
    name: "Oregon (OR)"
  },
  {
    key: "PA",
    id: 40,
    name: "Pennsylvania (PA)"
  },
  {
    key: "RI",
    id: 41,
    name: "Rhode Island (RI)"
  },
  {
    key: "SC",
    id: 42,
    name: "South Carolina (SC)"
  },
  {
    key: "SD",
    id: 43,
    name: "South Dakota (SD)"
  },
  {
    key: "TN",
    id: 44,
    name: "Tennessee (TN)"
  },
  {
    key: "TX",
    id: 45,
    name: "Texas (TX)"
  },
  {
    key: "UT",
    id: 46,
    name: "Utah (UT)"
  },
  {
    key: "VT",
    id: 47,
    name: "Vermont (VT)"
  },

  {
    key: "VA",
    id: 48,
    name: "Virginia (VA)"
  },
  {
    key: "WA",
    id: 49,
    name: "Washington (WA)"
  },
  {
    key: "WV",
    id: 50,
    name: "West Virginia (WV)"
  }, {
    key: "WI",
    id: 51,
    name: "Wisconsin (WI)"
  },
  {
    key: "WY",
    id: 52,
    name: "Wyoming (WY)"
  },

]

export interface IGeoTargeting {
  [key: string]: {
    include: boolean
  };
}


const geoTargetingForPayload: IGeoTargeting = {

}
statesTargeting.forEach((item) => {
  geoTargetingForPayload[item.key] = {
    include: true
  }
});

export interface IScheduling {
  [key: string]: {
    [key: string]: {
      include: boolean
    }
  }
}


const defaultZip = {
  files: null,
  filterType: "include",
  uploadType: "addupdateZip",
  zipCode: null
}
const defaultGrad = {
  startYear: 2005,
  endYear: 2024,
  unknown: true,
}
interface TcpaCampIn {
  Adv_ID: string;
  Advertiser_Name: string;
  Status: string;
  tcpaCampaign_id: string;
  tcpaCampaign_name: string;
  tcpaCampaignId: string;
}
export interface ZipData {
  [key: string]: { include?: boolean, exclude?: boolean };
}

interface tcpaNameHandler {
  tcpaCampaignName: string;
  tcpaCampNameError: string;
  loading: boolean;
  tcpaLogo: string;
  tcpaBrand: string;
  sortId: string;
  advertisers: [];
  advertiserList: [];
  tcpaData: TcpaCampIn[];
  tcpaFullData: TcpaCampIn[];
  tcpaNameError: boolean;
  advertiserName: string;
  advertiserID: number;
  tcpaFlag: boolean;
  currentCursor: string | null;
  hasMoreData: boolean;
  collectTcpaPageValue: TcpaCampIn[];
  payloadTcpaId: string;
  tcpaEditJsonData: any;
  tcpaEditFlag: boolean;
  tcpaCampaignIdOnEdit: string;
  tcpaCreateJsonData: any;
  tcpaSearchInput: string;
  excludeStates: IGeoTargeting;
  stateRules?: {
    allState: boolean
  };
  anyState: boolean;
  scheduling: IScheduling;
  searchFilterData: any[];
  verticalFilter: string[];
  tcpaFilterSearchFlag: boolean;
  filterSearchTcpaData: [];
  snackbarOpen: boolean;
  snackbarType: string;
  snackbarMessage: string;
  tcpaApiLoading: boolean;
  zipRules?: {
    disabledZipRules: boolean;
    files: any,
    filterType: string,
    uploadType: string,
    zipCode?: ZipData | null
  } | any,
  gradYear: {
    startYear: number,
    endYear: number,
    unknown: boolean
  },
  startYearError: string,
  endYearError: string,

  csvContent: {
    fileName: string,
    fileData: any,
    radioValue: 'add' | 'remove'
  }
  tcpaVertical: string,
}


const initialTcpaState: tcpaNameHandler = {
  //   data: [],
  loading: false,
  tcpaLogo: "",
  tcpaBrand: "",
  advertisers: [],
  advertiserList: [],
  tcpaData: [],
  tcpaCampaignName: "",
  tcpaCampNameError: "",
  tcpaNameError: false,
  advertiserName: "",
  tcpaFlag: false,
  currentCursor: "",
  hasMoreData: false,
  tcpaFullData: [],
  collectTcpaPageValue: [],
  payloadTcpaId: "",
  tcpaEditJsonData: [],
  tcpaCreateJsonData: [],
  tcpaEditFlag: false,
  tcpaCampaignIdOnEdit: '',
  tcpaSearchInput: "",
  excludeStates: geoTargetingForPayload,
  stateRules: {
    allState: false
  },
  anyState: false,
  scheduling: scheduling_jsonData,
  searchFilterData: [],
  tcpaFilterSearchFlag: true,
  filterSearchTcpaData: [],
  snackbarOpen: false,
  snackbarType: "",
  snackbarMessage: "",
  tcpaApiLoading: false,
  zipRules: defaultZip,
  gradYear: defaultGrad,
  sortId: "",
  startYearError: '',
  endYearError: '',
  csvContent: {
    fileData: [],
    fileName: '',
    radioValue: 'remove'
  },
  advertiserID: 0,
  tcpaVertical: "",
  verticalFilter: []
}

// export const createTcpaCampAsync = createAsyncThunk(
//   'tcpaApi/createTcpaCamp',
//   async (payload:any, method,{ rejectWithValue }) => {
//     try {
//       const response = await createTcpaCamp(payload, method);
//       return response;
//     } catch (error:any) {
//       return rejectWithValue(error.message);
//     }
//   }
// );

//For status toggle switch not for edit icon.
export const statusUpdateTcpaCampAsync = createAsyncThunk(
  'TcpaCampApi/updateTcpaCamp',
  async ({ tcpaCampId, ID, requestData }: { tcpaCampId: any; ID: any, requestData: any }, { rejectWithValue }) => {
    try {
      const response = await updateStatusTcpaCamp(tcpaCampId, ID, requestData);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const createTcpaCampAsync = createAsyncThunk(
  'tcpaApi/createTcpaCamp',
  async ({ payload, method }: { payload: any; method: 'post' | 'put' }, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setTcpaApiLoading(true));
      const response = await createTcpaCamp(payload, method);
      return response;
    } catch (error: any) {
      dispatch(setTcpaApiLoading(false));
      return rejectWithValue(error.message);
    } finally {
      dispatch(setTcpaApiLoading(false));
    }
  }
);

export const deleteTcpaCampAsync = createAsyncThunk(
  'tcpaCampApi/deleteTcpaCamp',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await deleteTcpaCamp(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);


export const fetchTcpaDataAsync = createAsyncThunk(
  'data/fetchTcpaData',
  async ({ path, queryParamsObject }: { path: string, queryParamsObject: any }) => {
    const response = await fetchAllAdvertiserData(path)
    return response.data;
  }
);

const tcpaCampSliceData = createSlice({
  name: 'mBrandNameList',
  initialState: initialTcpaState,
  reducers: {
    setTcpaLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setFields: (state, action) => {
      const { field, value } = action.payload;
      (state as any)[field] = value;
    },
    setTcpaEditFlag: (state, action: PayloadAction<boolean>) => {
      state.tcpaEditFlag = action.payload;
    },
    setCurrentCursor: (state, action: PayloadAction<string | null>) => {
      state.currentCursor = action.payload;
    },
    setHasMoreData: (state, action: PayloadAction<boolean>) => {
      state.hasMoreData = action.payload;
    },
    setTcpaSearchInput: (state, action: PayloadAction<any>) => {
      state.tcpaSearchInput = action.payload;
    },
    resetTcpaSearchInput: (state) => {
      state.tcpaSearchInput = '';
    },
    resetverticalFilter: (state) => {
      state.verticalFilter = [];
    },
    setTcpaData: (state, action: PayloadAction<TcpaCampIn[]>) => {
      state.tcpaData = action.payload;
    },
    setTcpaCampaignName: (state, action: PayloadAction<string>) => {
      state.tcpaCampaignName = action.payload;
    },
    cleanTcpaCampNameError: (state, action) => {
      state.tcpaCampNameError = ''
    },
    setTcpaNameError: (state, action) => {
      state.tcpaNameError = action.payload;
    },
    setTcpaVertical: (state, action) => {
      state.tcpaVertical = action.payload;
    },
    setTcpaFlag: (state, action: PayloadAction<boolean>) => {
      state.tcpaFlag = action.payload;
    },
    collectTcpaPagesValue: (state: any, action: PayloadAction<TcpaCampIn[]>) => {
      state.collectTcpaPageValue = action.payload;
    },
    setPayloadTcpaId: (state, action) => {
      state.payloadTcpaId = action.payload;
    },
    setTcpaEditJson: (state: any, action) => {
      state.tcpaEditJsonData = action.payload;
      if (action.payload.csvContent) {
        console.log("CSV content exist!")
        state.csvContent = action.payload.csvContent
      }
    },
    setTcpaCreateJson: (state: any, action) => {
      state.tcpaCreateJsonData = action.payload;
    },
    updateStateForTCPAEditAction: (state, action) => {
      const { payload } = action;
      state.anyState = payload.anyState
      console.log("value of data ", payload)
      if (payload.excludeStates) {
        state.excludeStates = payload.excludeStates
      };

      if (payload.zipRules) {
        state.zipRules = payload.zipRules
      }
      state.stateRules = payload.stateRules;

      if (payload.configTimeOfDay) {
        state.scheduling = payload.configTimeOfDay
      }
      if (payload.gradYear) {
        state.gradYear = payload.gradYear
      }

    },
    updateGeoTargeting: (state, action) => {
      state.excludeStates = action.payload
    },
    updateStateRules: (state, action) => {
      state.stateRules = action.payload
    },
    anyStateToggle: (state: any) => {
      state.anyState = !state.anyState;
    },
    updateTcpaScheduling: (state, action) => {
      state.scheduling = action.payload
    },
    setSearchFilterData: (state, action: PayloadAction<[]>) => {
      state.searchFilterData = action.payload;
    },
    resetSearchFilterData: (state) => {
      state.searchFilterData = [];
    },
    setTcpafilterSearchFlag: (state, action: PayloadAction<boolean>) => {
      state.tcpaFilterSearchFlag = action.payload;
    },
    setTcpaFilterSearchData: (state, action: PayloadAction<[]>) => {
      state.filterSearchTcpaData = action.payload;
    },
    setSnackbarOpen: (state, action: PayloadAction<boolean>) => {
      state.snackbarOpen = action.payload;
    },
    setTcpaApiLoading: (state, action: PayloadAction<boolean>) => {
      state.tcpaApiLoading = action.payload;
    },
    setSnackbarType: (state, action) => {
      state.snackbarType = action.payload;
    },
    setSnackbarMessage: (state, action) => {
      state.snackbarMessage = action.payload;
    },
    updateTcpaZipRules: (state, action) => {
      state.zipRules = action.payload
    },
    updateTcpaZipRulesFilterType: (state, action) => {
      state.zipRules.filterType = action.payload
    },
    updateTcpaGradYear: (state, action) => {
      const { type, value } = action.payload;

      if (type === 'startYear') {
        state.gradYear.startYear = value
      }
      if (type === 'endYear') {
        state.gradYear.endYear = value
      }

      if (type === 'unknown') {
        state.gradYear.unknown = value
      }
    },
    storeTcpaStartYearError: (state, action) => {
      state.startYearError = action.payload;
    },
    storeTcpaEndYearError: (state, action) => {
      state.endYearError = action.payload;
    },

    setCsvFileName: (state, action) => {
      state.csvContent.fileName = action.payload;
    },
    setCsvData: (state, action) => {
      state.csvContent.fileData = action.payload;
    },

    setUploadRadioValue: (state, action) => {
      state.csvContent.radioValue = action.payload;
    },
    setTcpaCampaignIdOnEdit: (state, action: PayloadAction<string>) => {
      state.tcpaCampaignIdOnEdit = action.payload;
    },
    appendTcpaData: (state, action: PayloadAction<TcpaCampIn[]>) => {
      if (state.tcpaFlag) {
        state.tcpaData = [...state.tcpaData, ...action.payload];
      } else {
        state.tcpaData = [];
        // console.log('state.datacamp',state.datacamp);
        state.tcpaData = action.payload;
        // console.log('state.datacamp',state.datacamp);
      }
    },
    setTcpaFullData: (state, action: PayloadAction<TcpaCampIn[]>) => {
      state.tcpaFullData = action.payload;
    },
    appendTcpFullData: (state, action: PayloadAction<TcpaCampIn[]>) => {
      if (state.tcpaFlag) {
        state.tcpaFullData = [...state.tcpaFullData, ...action.payload];
      } else {
        state.tcpaFullData = [];
        // console.log('state.datacamp',state.datacamp);
        state.tcpaFullData = action.payload;
        // console.log('state.datacamp',state.datacamp);
      }
    },
    storeSelectedTcpaAdvertiser: (state, action) => {
      const advertiserList: any = current(state).advertiserList.filter((item: any) => item.advertiserID === action.payload);
      const advertiserProp: any = current(state).advertisers.filter((item: any) => item.advertiserID === action.payload);
      console.log('advertiserList', advertiserProp)
      state.tcpaBrand = advertiserList[0].brandName
      state.tcpaLogo = advertiserProp[0].primaryLogo
      state.advertiserName = advertiserProp[0].advertiserName
      state.sortId = advertiserProp[0].SORTID
      state.advertiserID = advertiserProp[0].advertiserID
    },

    resetTcpaState: (state, action) => {
      // state=INITIAL_STATE
      return initialTcpaState
    },
    cleanUp: (state) => {
      state.csvContent = {
        fileName: "",
        fileData: [],
        radioValue: 'remove'
      }
      state.tcpaEditJsonData = []
      //  state.tcpaEditJsonData = []
      state.tcpaCampaignIdOnEdit = ''
      state.tcpaEditFlag = false
      state.excludeStates = geoTargetingForPayload
      state.zipRules = defaultZip
      state.scheduling = scheduling_jsonData
      state.gradYear = defaultGrad
    }

  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTcpaDataAsync.pending, (state) => {
        // state.pageLoading = true;
      })
      .addCase(fetchTcpaDataAsync.fulfilled, (state, action) => {
        // state.pageLoading = false
        state.advertisers = action.payload.result.data;
        state.advertiserList = action.payload.result.data.map((item: any, index: number) => {
          return {
            advName: item.advertiserName,
            brandName: item.brand,
            advertiserID: item.advertiserID,
            SORTID: item.SORTID
          }
        })
      })
      .addCase(fetchTcpaDataAsync.rejected, (state, action) => {
        // state.loading = 'failed';
        // state.error = action.error.message || 'An error occurred';
        // state.pageLoading = false;
        // state.getError = action.error.message || 'An error occurred'
        // state.advertiserList = action.payload;
      })
  }
});


export const { setTcpaVertical, storeTcpaEndYearError, storeTcpaStartYearError, updateTcpaGradYear, updateTcpaZipRulesFilterType, updateTcpaZipRules, setTcpaApiLoading, setSnackbarOpen, setSnackbarType, setSnackbarMessage, setTcpaFilterSearchData, setTcpafilterSearchFlag, resetSearchFilterData, setSearchFilterData, updateTcpaScheduling, anyStateToggle, updateStateRules, updateGeoTargeting, updateStateForTCPAEditAction, setTcpaSearchInput, resetTcpaSearchInput, resetverticalFilter, storeSelectedTcpaAdvertiser, resetTcpaState, setTcpaData, setTcpaCampaignName, cleanTcpaCampNameError,
  setTcpaNameError, setTcpaFlag, appendTcpaData, setCurrentCursor, setHasMoreData, setTcpaLoading, setTcpaFullData, collectTcpaPagesValue,
  setPayloadTcpaId, setTcpaEditJson, setTcpaCreateJson, setTcpaEditFlag, appendTcpFullData, setTcpaCampaignIdOnEdit, setCsvData, setCsvFileName, setUploadRadioValue, cleanUp, setFields } = tcpaCampSliceData.actions;
export const tcpaCampSlice = tcpaCampSliceData.reducer;
