import React, { FC, useEffect, useMemo, useState } from 'react';
import PublisherStagingJSON from '../../Mock/PublishersStaging.json';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import MMTable from '../../Component/Table/MMTable';
import { useAppDispatch, useAppSelector } from '../../Store/hooks';
import { setData, setFlag } from '../../Store/Slices/PublisherTabApisData';
import {
  setNoDataFlag,
  setScrollNext,
  setSearchFilterData,
  setSearchInput,
} from '../../Store/Slices/advertiserTabApisData';
import Search from '../../Component/Search/Search';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import InvertColorsOffIcon from '@mui/icons-material/InvertColorsOff';
import { Editor, SelectionState } from 'react-draft-wysiwyg';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  EditorState,
  convertFromRaw,
  convertToRaw,
  ContentState,
  Modifier,
} from 'draft-js';
import { fetchCompApiData } from '../../Services/publisherTabApis';
import { generateHeader } from '../../service/Auth';
import axios from 'axios';
import { baseURLConfig } from '../../config/envConfig';
import { fetchAllPublisherCampaignsData } from '../../service/Publisher';

interface ReportHeadCell {
  numeric: boolean;
  id: string;
  label: string;
  key: string;
}

const frequencyType = [
  {
    value: 'yesterday',
    label: 'Previous Day',
    type: ['Clicks Report', 'Leads Report'],
  },
  { value: 'last-30-days', label: 'Last 30 Days', type: ['Quality Report'] },
  { value: 'last-60-days', label: 'Last 60 Days', type: ['Quality Report'] },
  { value: 'last-90-days', label: 'Last 90 Days', type: ['Quality Report'] },
  // { value: 'last-270-days', label: 'Last 270 Days', type: ['Quality Report'] },
  { value: 'month-to-date', label: 'Month to Date', type: ['Leads Report'] },
];
const reportType = [
  { id: 'Clicks Report', label: 'Clicks Report' },
  { id: 'Leads Report', label: 'Leads Report' },
  { id: 'Quality Report', label: 'Quality Report' },
];

const reportHeadCell: any = [
  {
    numeric: false,
    id: 'id',
    label: 'Id',
    key: 'id',
    isHidden: true,
  },
  {
    numeric: false,
    id: 'reportName',
    label: 'Report Name',
    key: 'reportName',
  },
  {
    numeric: false,
    id: 'reportType',
    label: 'Report Type',
    key: 'reportType',
  },
  {
    numeric: false,
    id: 'publisher',
    label: 'Publisher',
    key: 'publisher',
  },
  {
    numeric: false,
    id: 'vertical',
    label: 'Vertical',
    key: 'vertical',
  },
  {
    id: 'action',
    numeric: false,
    label: 'Action',
    key: '',
  },
];

const validationSchema = Yup.object().shape({
  reportName: Yup.string().required('Report Name is required'),
  reportType: Yup.string().required('Report Type is required'),
  vertical: Yup.object().required('Vertical is required'),
  mPubID: Yup.string().required('Publisher is required'),
  // campaign: Yup.object().required('Campaign is required'),
  campaigns: Yup.array()
    .min(1, 'At least one campaign must be selected') // Optional: Ensure at least one campaign is selected
    .required('Campaign is required'),
  subject: Yup.string().required('Subject is required'),
  description: Yup.string().required('Description is required'),
  frequency: Yup.string().required('Frequency is required'),
  emails: Yup.array()
    .of(
      Yup.string().email('Invalid email format').required('Email is required')
    )
    .min(1, 'At least one email is required'),
});

const CreateReport: React.FC<any> = props => {
  const { openPopup, setOpenPopup, verticalList, selectedRow, refetchData } =
    props;
  const [emailList, setEmailList] = useState<any>([]);
  const [emailInput, setEmailInput] = useState<any>('');
  const [isEmailValidate, setIsEmailValidate] = useState<boolean>(false);
  const [publisherList, setPublisherList] = useState<any>([]);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [campaignData, setCampaignData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFetchingPub, setIsFetchingPub] = useState<boolean>(false);
  const [isFetchingCampaign, setIsFetchingCampaign] = useState<boolean>(false);
  const updateReport = async (payload: any) => {
    try {
      const headers = await generateHeader();
      const response = await axios.put(
        `${baseURLConfig.emailReporting}/update/emailReport`,
        payload,
        { headers }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const addReportData = async (payload: any) => {
    try {
      const headers = await generateHeader();
      const response = await axios.post(
        `${baseURLConfig.emailReporting}/create/emailReport`,
        payload,
        { headers }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const formik: any = useFormik<any>({
    initialValues: {
      reportName: selectedRow?.reportName || '',
      vertical: selectedRow?.vertical || '',
      emails: selectedRow?.emails || [],
      mPubID: selectedRow?.mPubID || '',
      campaigns: selectedRow?.campaigns || [],
      reportType: selectedRow?.reportType || '',
      subject: selectedRow?.subject || '',
      frequency: selectedRow?.frequency?.value || '',
    },
    onSubmit: async values => {
      // delete values.frequency.type;
      setIsLoading(true);
      Object.assign(values, {
        publisherName: publisherList?.find(
          (val: any) => val?.value === values?.mPubID
        )?.label,
        frequency: frequencyType.find(
          (val: any) => val.value === values.frequency
        ),
      });
      if (selectedRow?.reportID) {
        await updateReport({ ...values, reportID: selectedRow?.reportID });
      } else {
        await addReportData(values);
      }
      refetchData();
      setOpenPopup(false);
      setIsLoading(false);
    },
    validationSchema,
  });

  // Validate email format
  const isValidEmail = (email: any) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  // Remove an email from the list
  const handleRemoveEmail = (emailToRemove: any) => {
    const newEmailList = emailList.filter(
      (email: any) => email !== emailToRemove
    );
    setEmailList(newEmailList);
    formik.setFieldValue('emails', newEmailList);
  };

  // Handle adding an email when the "Enter" key is pressed
  const handleAddEmail = (event: any) => {
    if (event.key === 'Enter' && isValidEmail(emailInput)) {
      setIsEmailValidate(false)
      setEmailList([...emailList, emailInput]);
      setEmailInput('');
      formik.setFieldValue('emails', [...emailList, emailInput]);
    }else{
      if(!event.target.value){
        setIsEmailValidate(false);
       }
       event.key === 'Enter' && setIsEmailValidate(true)
    }
  };

  const handleCancel = () => {
    setOpenPopup(false);
  };

  useEffect(() => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    rawContentState?.blocks.forEach(value => {
      if (value.text) {
        console.log(draftToHtml(rawContentState));
      }
    });

    formik.setFieldValue('description', draftToHtml(rawContentState));
  }, [editorState]);

  const CustomOption: FC<any> = ({ editorState, onChange }) => {
    const removeBackGroundColor = () => {
      const rawContentState = convertToRaw(editorState.getCurrentContent());
      let htmlContent = draftToHtml(rawContentState);
      htmlContent = htmlContent.replace(
        /(style="[^"]*)background-color:\s*[^;"]*;?([^"]*")/g,
        '$1$2'
      );
      const contentBlock = htmlToDraft(htmlContent);
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorStateFromHTML = EditorState.createWithContent(contentState);
      setEditorState(editorStateFromHTML);
    };

    return (
      <IconButton
        sx={{
          border: '1px solid #F1F1F1',
          borderRadius: '2px',
          height: '100%',
        }}
        onClick={removeBackGroundColor}
      >
        <InvertColorsOffIcon sx={{ fontSize: '14px' }} />
      </IconButton>
    );
  };

  const fetchData = async (pubID: string) => {
    setIsFetchingCampaign(true);
    const arg2 = {
      path: 'publishers/campaign',
      queryParamsObject: {
        publisherID: pubID,
        verticalID: formik?.values?.vertical?.verticalID,
      },
    };
    const response = await fetchAllPublisherCampaignsData(
      arg2.path,
      arg2.queryParamsObject
    );

    const filteredData = response?.data?.map((item: any) => {
      return {
        SORTID: item?.SORTID,
        campaignname: item?.campaignname,
      };
    });
    setCampaignData(filteredData);
    setIsFetchingCampaign(false);
    return response.data;
  };

  const getCampaignsData = (pubId: any) => {
    fetchData(pubId);
    // filterPublisherSearch({ mPubIds: [pubId] });
  };

  // useEffect(() => {
  //   console.log('errors', formik?.errors);
  // }, [formik?.errors]);

  const fetchPublishersDataByVertical = async (paylode: any) => {
    const headers = await generateHeader();
    const response = await axios.post(
      `${baseURLConfig.baseURl}/filterByVertical?ID=publisher`,
      paylode,
      { headers }
    );
    return response;
  };

  const handleVerticalChange = async (id: any) => {
    setIsFetchingPub(true);
    const pubLists = await fetchPublishersDataByVertical({
      vertical: [`${id}`],
    });

    const pubData = pubLists?.data?.result?.data?.map((pub: any) => ({
      label: `${pub.publisherName || pub.GroupName}`,
      value: `${pub.mPubID}`,
    }));

    pubData?.sort((a: any, b: any) => {
      const labelA = a.label.toUpperCase(); // Ignore upper and lowercase
      const labelB = b.label.toUpperCase(); // Ignore upper and lowercase
      if (labelA < labelB) {
        return -1;
      }
      if (labelA > labelB) {
        return 1;
      }

      // labels must be equal
      return 0;
    });

    setPublisherList(pubData);
    setIsFetchingPub(false);
  };

  // Function to load the editor initial value
  const loadInitialValue = (initialContent: string) => {
    if (initialContent) {
      const contentBlock = htmlToDraft(initialContent);
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorStateFromHTML = EditorState.createWithContent(contentState);
      setEditorState(editorStateFromHTML);
    }
  };
  useEffect(() => {
    if (selectedRow) {
      getCampaignsData(selectedRow?.mPubID);
      handleVerticalChange(selectedRow?.vertical?.verticalID);
      loadInitialValue(selectedRow?.description);
      setEmailList(selectedRow?.emails);
    }
  }, [selectedRow]);

  // useEffect(() => {
  //   console.log('values', formik.values.frequency);
  // }, [formik.values.frequency]);

  return (
    <>
      <Dialog open={openPopup} onClose={handleCancel} fullWidth maxWidth="md" >
        <DialogTitle>Email Reporting</DialogTitle>
        <div className="close-button" onClick={handleCancel}>
          <CloseIcon style={{ fontSize: '32px' }} />
        </div>
        <DialogContent className="Update-user">
          {' '}
          <Grid container spacing={2}>
            {' '}
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  sx={{ background: '#ffffff' }}
                  label="Report Name"
                  name="reportName"
                  value={formik?.values?.reportName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.reportName &&
                    Boolean(formik.errors.reportName)
                  }
                />
              </FormControl>
              {formik.touched.reportName && formik.errors.reportName && (
                <Box color="#db2424" mt={1}>
                  {formik.errors.reportName}
                </Box>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl
                fullWidth
                required
                error={
                  formik.touched.vertical && Boolean(formik?.errors?.vertical)
                }
              >
                <InputLabel>Select Vertical</InputLabel>
                <Select
                  label="Select Vertical"
                  value={formik?.values?.vertical?.verticalID}
                  name="vertical"
                  onChange={e => {
                    formik.setFieldValue('mPubID', '');
                    formik.setFieldValue('campaigns', []);
                    setPublisherList([]);
                    setCampaignData([]);
                    handleVerticalChange(e.target.value);
                    formik.setFieldValue(
                      'vertical',
                      verticalList.find(
                        (val: any) => val.verticalID === e.target.value
                      )
                    );
                  }}
                  // error={verticalNameError}
                >
                  {verticalList?.map((val: any) => (
                    <MenuItem key={val?.verticalID} value={val?.verticalID}>
                      {val?.verticalName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {formik.touched.vertical && formik.errors.vertical && (
                <Box color="#db2424" mt={1}>
                  {formik.errors.vertical}
                </Box>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl
                fullWidth
                required
                error={
                  formik.touched.mPubID &&
                  Boolean(formik.errors.mPubID) &&
                  !formik?.values?.mPubID
                }
              >
                <InputLabel>Select Publisher</InputLabel>
                <Select
                  label="Select Publisher"
                  value={formik?.values?.mPubID}
                  name="mPubID"
                  disabled={isFetchingPub}
                  onChange={e => {
                    // alert(e.target.value);
                    setCampaignData([]);
                    getCampaignsData(e.target.value);
                    formik.handleChange(e);
                    formik.setFieldValue('campaigns', []);
                  }}
                  endAdornment={
                    isFetchingPub ? (
                      <InputAdornment position="end">
                        <CircularProgress size={24} sx={{ color: 'gray' }} />
                      </InputAdornment>
                    ) : null
                  }
                  IconComponent={isFetchingPub ? () => null : undefined}
                >
                  {publisherList?.map((pub: any) => (
                    <MenuItem key={pub.value} value={pub.value}>
                      {pub.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {formik.touched.mPubID &&
                formik.errors.mPubID &&
                !formik?.values?.mPubID && (
                  <Box color="#db2424" mt={1}>
                    {formik.errors.mPubID}
                  </Box>
                )}
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl
                fullWidth
                required
                error={
                  formik.touched.campaigns && Boolean(formik.errors.campaigns)
                }
              >
                <InputLabel>Select Campaigns</InputLabel>
                <Select
                  label="Select Campaigns"
                  value={
                    formik?.values?.campaigns?.map((val: any) => val.SORTID) ||
                    []
                  }
                  disabled={isFetchingCampaign}
                  name="campaigns"
                  onChange={(e: any) => {
                    const selectedValues = e.target.value;
                    formik.setFieldValue(
                      'campaigns',
                      campaignData.filter((campaign: any) =>
                        selectedValues.includes(campaign.SORTID)
                      )
                    );
                  }}
                  multiple
                  renderValue={(selected: any) => {
                    const limit = 1;
                    const displayedItems = selected.slice(0, limit);
                    const remainingCount = selected.length - limit;

                    return (
                      <div
                        style={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          alignItems: 'center',
                        }}
                      >
                        {displayedItems.map((value: any) => {
                          const campaign = campaignData.find(
                            (item: any) => item.SORTID === value
                          );
                          return (
                            campaign && (
                              <Chip key={value} label={campaign.campaignname} />
                            )
                          );
                        })}
                        {remainingCount > 0 && (
                          <Chip
                            key="more"
                            label={`+${remainingCount} more`}
                            color="primary"
                            style={{ marginLeft: '5px' }}
                          />
                        )}
                      </div>
                    );
                  }}
                  endAdornment={
                    isFetchingCampaign ? (
                      <InputAdornment position="end">
                        <CircularProgress size={24} sx={{ color: 'gray' }} />
                      </InputAdornment>
                    ) : null
                  }
                  IconComponent={isFetchingCampaign ? () => null : undefined}
                >
                  {campaignData &&
                    campaignData.length > 0 &&
                    campaignData.map((val: any) => (
                      <MenuItem key={val.SORTID} value={val.SORTID}>
                        <ListItemText primary={val.campaignname} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              {formik.touched.campaigns && formik.errors.campaigns && (
                <Box color="#db2424" mt={1}>
                  {formik.errors.campaigns}
                </Box>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl
                fullWidth
                required
                error={
                  formik.touched.reportType &&
                  Boolean(formik.errors.reportType) &&
                  !formik?.values?.reportType
                }
              >
                <InputLabel>Report Type</InputLabel>
                <Select
                  label="Report Type"
                  value={formik?.values?.reportType}
                  name="reportType"
                  onChange={(e: any) => {
                    formik.handleChange(e);
                    formik.setFieldValue('frequency', '');
                  }}
                >
                  {reportType?.map((val: any) => (
                    <MenuItem value={val?.id} key={val?.id}>
                      {val?.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {formik.touched.reportType &&
                formik.errors.reportType &&
                !formik?.values?.reportType && (
                  <Box color="#db2424" mt={1}>
                    {formik.errors.reportType}
                  </Box>
                )}
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl
                fullWidth
                required
                error={
                  formik.touched.frequency && Boolean(formik?.errors?.frequency)
                }
              >
                <InputLabel>Select Frequency</InputLabel>
                <Select
                  label="Select Frequency"
                  value={formik.values.frequency}
                  name="frequency"
                  onChange={formik.handleChange}
                  // onChange={(e: any) => {
                  //   formik.setFieldValue(
                  //     'frequency',
                  //     frequencyType.find(
                  //       (val: any) => val.value === e.target.value
                  //     )
                  //   );
                  // }}
                >
                  {frequencyType
                    ?.filter((val: any) =>
                      val?.type?.includes(formik?.values?.reportType)
                    )
                    ?.map((val: any) => (
                      <MenuItem key={val.value} value={val?.value}>
                        {val.label}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              {formik.touched.frequency && formik.errors.frequency && (
                <Box color="#db2424" mt={1}>
                  {formik.errors.frequency}
                </Box>
              )}
            </Grid>
            <Grid item xs={12}>
              <Box
                className="chips-container"
                sx={{
                  borderColor:
                    formik.touched.emails && formik.errors.emails
                      ? '#d32f2f'
                      : '',
                }}
              >
                <label htmlFor="">Email Addresses *</label>
                <TextField
                  placeholder="Enter Email Addresses"
                  value={emailInput}
                  onChange={e => {
                 if(!e.target.value){
                  setIsEmailValidate(false);
                 }
                  
                    setEmailInput(e.target.value);}}
                  onKeyDown={handleAddEmail}
                  error={isEmailValidate}
                />

                {emailList.length > 0 && (
                  <Stack direction="row" flexWrap="wrap" gap={1} marginTop={2}>
                    {emailList.map((email: any, index: any) => (
                      <Chip
                        key={index}
                        label={email}
                        onDelete={() => handleRemoveEmail(email)}
                      />
                    ))}
                  </Stack>
                )}
              </Box>
              {formik.touched.emails && formik.errors.emails && (
                <Box color="#db2424" mt={1}>
                  {formik.errors.emails}
                </Box>
              )}
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  sx={{ background: '#ffffff' }}
                  label="Subject Line"
                  value={formik?.values?.subject}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name="subject"
                  error={
                    formik.touched.subject && Boolean(formik.errors.subject)
                  }
                />
              </FormControl>
              {formik.touched.subject && formik.errors.subject && (
                <Box color="#db2424" mt={1}>
                  {formik.errors.subject}
                </Box>
              )}
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Editor
                  editorState={editorState}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapper"
                  editorClassName="editor"
                  onEditorStateChange={(editorState: any) => {
                    setEditorState(editorState);
                  }}
                  onChange={() => {}}
                  onFocus={() => {}}
                  toolbar={{
                    options: [
                      'inline',
                      'blockType',
                      'fontSize',
                      'fontFamily',
                      'list',
                      'textAlign',
                      'colorPicker',
                      'link',
                      'emoji',
                      'remove',
                      'history',
                    ],
                  }}
                  toolbarCustomButtons={[<CustomOption />]}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ paddingBottom: "15px", paddingRight: "20px" }}>
          <Button onClick={handleCancel} style={{ color: 'red' }}>
            CANCEL
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              formik.handleSubmit();
            }}
            color="primary"
            disabled={isLoading}
          >
            {isLoading ? (
              <CircularProgress color="inherit" size={24} />
            ) : selectedRow?.reportID ? (
              'Update'
            ) : (
              'Create'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const DeleteIconPop: React.FC<any> = props => {
  const { openDeletePopup, closeDeletePopup, onDelete,reportName } = props;
  return (
    <Dialog open={openDeletePopup}>
      <DialogTitle className="dialogTitle">ALERT</DialogTitle>
      <DialogContent>
        <p>{`Are you sure you want to delete this report - ${reportName} ?`}</p>
      </DialogContent>
      <DialogActions className="dialogActions">
        <Button onClick={closeDeletePopup} color="primary">
          CANCEL
        </Button>
        <Button onClick={onDelete} color="error" variant="contained">
          CONFIRM
        </Button>
      </DialogActions>
    </Dialog>
  );
};
const EmailReporting = () => {
  const [openPopup, setOpenPopup] = React.useState(false);
  const [noDataFound, setNoDataFound] = React.useState<boolean>(false);
  const [isDataFetching, setIsDataFetching] = React.useState<boolean>(false);
  const [selectedRow, setSelectedRow] = React.useState<any>('');
  const [verticalList, setVerticalList] = React.useState<any>([]);
  const [tableRow, setTableRow] = useState<any>([]);
  const [reportList, setReportList] = useState<any>([]);
  const [openDeletePopup, setDeletePopup] = useState<boolean>(false);
  const [tostMessageHandler, setTostMessageHandler] = useState<any>({
    message: '',
    severity: '',
    snackbarOpen: false,
  });
  const dispatch = useAppDispatch();
  // const { data, isLoading } = useAppSelector((state) => state.publisherTabApisData);

  const getReportList = async () => {
    setIsDataFetching(true);
    try {
      const headers = await generateHeader();
      //  dispatch(setIsoLoading(true));
      const response = await fetch(
        `${baseURLConfig.emailReporting}/getEmailReportList`,
        {
          headers,
        }
      );
      const jsonData = await response.json();
      setReportList(jsonData?.data);
      const reportsList = jsonData?.data?.map((val: any) => ({
        id: val?.reportID,
        reportName: val?.reportName,
        reportType: val?.reportType,
        publisher: val?.publisherName,
        vertical: val?.vertical?.verticalName,
      }));
      setNoDataFound(!reportsList.length);
      setTableRow(reportsList);
      // return jsonData.data;
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsDataFetching(false);
      // dispatch(setIsoLoading(false));
      // dispatch(setLoading(false))
    }
  };
  const fetchVerticals = async () => {
    try {
      const headers = await generateHeader();
      const response = await axios.get(
        `${baseURLConfig.baseURl}/getVerticalList`,
        { headers }
      );
      setVerticalList(response?.data?.data);
    } catch (error) {
      const errorMessage =
        axios.isAxiosError(error) && error.response
          ? error.response.data.message || 'Failed to fetch verticals'
          : 'Network error';
    }
  };

  useEffect(() => {
    dispatch(setNoDataFlag(false));
    fetchVerticals();
  //  fetchCompApiData(dispatch);
    getReportList();
  }, []);

  const handleEdit = (row: any) => {
    const rowData = reportList?.find((val: any) => val?.reportID === row.id);
    setSelectedRow(rowData);
    setOpenPopup(true);
  };

  const deleteReport = async (payload: any) => {
    setDeletePopup(false);
    setIsDataFetching(true);
    try {
      const headers = await generateHeader();
      let config = {
        method: 'delete',
        maxBodyLength: Infinity,
        url: `${baseURLConfig.emailReporting}/delete/emailReport?reportID=${payload}`,
        headers: headers,
        data: payload.data,
      };
      const response = await axios.request(config);
      setTostMessageHandler({
        message: `Email Report - ${selectedRow?.reportName} Deleted Successfully`,
        severity: 'success',
        snackbarOpen: true,
      });
      setSelectedRow('');
      getReportList();
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const handleCloseSnackbar = () => {
    setTostMessageHandler({
      message: '',
      severity: '',
      snackbarOpen: false,
    });
  };

  return (
    <React.Fragment>
      <Paper elevation={3} className="card">
        <Box display="flex" justifyContent="space-between" p={3}>
          <Box>
            <Typography className="card-heading">Email Reporting</Typography>
            <Typography className="card-sub-heading">
              Email Reports Summary
            </Typography>
          </Box>
          <Box display="flex" gap="16px" alignItems="center">
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => {
                setSelectedRow('');
                setOpenPopup(true);
              }}
            >
              Create Report
            </Button>
          </Box>
        </Box>
        <MMTable
          tableData={tableRow}
          tableHeadCell={reportHeadCell}
          fetchMoreData={() => {}}
          hasMoreData={false}
          fetchInitialData={() => {}}
          hasEdit
          onEdit={handleEdit}
          hasDelete
          onDelete={(row: any) => {
            // deleteReport(row?.id);
            setSelectedRow(row);
            setDeletePopup(true);
          }}
          isDataFetching={isDataFetching}
          noDataFound={noDataFound}
        />
      </Paper>
      {openPopup && (
        <CreateReport
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          verticalList={verticalList}
          selectedRow={selectedRow}
          refetchData={() => {
            setTostMessageHandler({
              message: selectedRow?.reportID
                ? 'Email Report Updated Successfully '
                : 'Email Report Created Successfully',
              severity: 'success',
              snackbarOpen: true,
            });
            setSelectedRow('');
            getReportList();
          }}
        />
      )}
      {openDeletePopup && (
        <DeleteIconPop
          openDeletePopup={openDeletePopup}
          reportName={selectedRow?.reportName}
          closeDeletePopup={() => {
            setSelectedRow('');
            setDeletePopup(false);
          }}
          onDelete={() => {
            deleteReport(selectedRow?.id);
          }}
        />
      )}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={tostMessageHandler?.snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackbar}
          severity={tostMessageHandler?.severity}
        >
          {tostMessageHandler?.message}
        </MuiAlert>
      </Snackbar>
    </React.Fragment>
  );
};

export default EmailReporting;
