import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux'; // Update this import from redux directly, not from redux-toolkit
import axios from 'axios';
import { baseURLConfig } from '../config/envConfig';
import { FETCH_MEDIAPARTNER_FAILURE, FETCH_MEDIAPARTNER_REQUEST, FETCH_MEDIAPARTNER_SUCCESS } from '../Store/Actions';
import { RootState } from '../Store';
import { generateHeader } from '../service/Auth';

// Action creators
export const fetchMediaPartnerRequest = () => ({ type: FETCH_MEDIAPARTNER_REQUEST });
export const fetchMediaPartnerSuccess = (data: any[]) => ({ type: FETCH_MEDIAPARTNER_SUCCESS, payload: data });
export const fetchMediaPartnerFailure = (error: string) => ({ type: FETCH_MEDIAPARTNER_FAILURE, payload: error });

// Thunk function
export const fetchMediaPartner = () => {
    return async (dispatch: any) => {
        dispatch(fetchMediaPartnerRequest());

        try {
            const headers = await generateHeader();
            const response = await axios.get(`${baseURLConfig.baseURl}/getMediaPartners?ID=MediaPartner`, { headers });
            dispatch(fetchMediaPartnerSuccess(response?.data?.data));
        } catch (error) {
            const errorMessage =
                axios.isAxiosError(error) && error.response
                    ? error.response.data.message || 'Failed to fetch MediaPartner'
                    : 'Network error';
            dispatch(fetchMediaPartnerFailure(errorMessage));
        }
    };
};
