import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { CircleLoader } from "react-spinners";
import {
  areaStudyDropdown,
  mPrograms,
  degreeInterest,
  highestEducation,
  startTimeframe,
  campusType,
  militaryAffiliation,
  mediaTypeOptions,
  inventoryTypeOptions,
} from "../../../../../Mock/Tools data/testApi";
import BasicTextField from "../../../../../Component/Basic TextField/BasicInput";
import AreaCard from "../../../../../Component/Card";
import BasicSelect from "../../../../../Component/DropDowns/BasicSelect";
import {
  buildUrl,
  fetchAllPublisherCampaignsData,
  fetchListing,
  fetchAllPublishersData,
  getUserIp,
  fetchTCPAData,
  fetchPubUserCampaignByPubID,
} from "../../../../../service/Publisher";
import { getMBrandsDetails } from "../../../../../service/Advertiser";
//  import { IOSSwitch } from '../../Component/Table/MMTable';
import { IOSSwitch } from "../../../../../Component/Table/MMTable";
import { fetchTCPA_LISTING } from "../../../../../Services/tcpaTabApis";
import { baseURLConfig } from "../../../../../config/envConfig";
import { generateRandomId, timezoneOptions } from "../../../../../Utils/utils";
import { getStateByZip } from "../../../../../Mock/GeoTargetingData";
import SnackbarMessage from "../../../../../Component/Snackbar";
import { isValidEmail } from "../../../../User Accounts/CreateUser";
import axios from "axios";
import { generateHeader } from "../../../../../service/Auth";
// import { buildUrl, fetchAllPublisherCampaignsData, fetchListing } from '../../../../service/Publisher';
declare global {
  interface Window {
    myCallbackFunction: (token: string) => void;
  }
}
const phoneNumberRegex =
  /^(1\s?)?(\(\d{3}\)|\d{3})([\s\-]?)\d{3}([\s\-]?)\d{4}$/;
function capitalizeFirstLetter(str: string) {
  if (str.length === 0) return str; // Return empty string if the input is empty
  return str.charAt(0).toUpperCase() + str.slice(1);
}
const args1 = {
  path: "publishers",
  queryParamsObject: {
    ID: "publisher",
  },
};
const baseURL = baseURLConfig.TCPA_BASE_URL;
const { time1, time2, time3, time4, time5, time6, time7, time8, time9 } =
  timezoneOptions;
const timeZones = [
  { label: "Eastern Time (EST)", value: time1 },
  { label: "Mountain Time (MST)", value: time5 },
  { label: "Central Time (CST)", value: time2 },
  { label: "Pacific Time (PST)", value: time3 },
  { label: "Alaska Time (AKST)", value: time4 },
  { label: "Arizona Time (MST)", value: time6 },
  { label: "Atlantic Time (AST)", value: time7 },
  { label: "Hawaii Time (AST)", value: time8 },
  { label: "Indiana Time (EST)", value: time9 },
];

interface IuserFormValue {
  firstName: string;
  lastName: string;
  email: string;
  phNo: string;
  timeZone: string;
  checked: boolean;
  emailError: boolean;
  emailErrMsg: string;
  isTestAPI: string;
}

const AdminTCPA_API = (props: any) => {
  console.log("QWERTY", props);
  const [pageLoading, setPageLoading] = useState(true);
  const [listingLoading, setListingLoading] = useState(false);
  const defaultAreaStudyValue: string = areaStudyDropdown[0]?.value || "";
  const defaultProgramValue =
    mPrograms[defaultAreaStudyValue]?.[0]?.value || "";

  const [selectedAreaStudy, setSelectedAreaStudy] = useState<string>(
    defaultAreaStudyValue
  );
  const [selectedProgram, setSelectedProgram] =
    useState<string>(defaultProgramValue);
  const [programOptions, setProgramOptions] = useState<any[]>(
    mPrograms[selectedAreaStudy]
  );
  const [selectedCampusType, setSelectedCampusType] = useState<string>(
    campusType[0].value
  );
  const [selectedPublisher, setSelectedPublisher] = useState<string>("");
  const [publisherOptions, setPublisherOptions] = useState<any[]>([]);

  const [selectedCampaign, setSelectedCampaigns] = useState<string>("");
  const [campaignOptions, setCampaignOptions] = useState<any[]>([]);

  const [selectedDOI, setSelectedDOI] = useState<string>(
    degreeInterest[2].value
  );
  const [selectedHE, setSelectedHE] = useState<string>(
    highestEducation[0].value
  );
  const [selectedSTF, setSelectedSTF] = useState<string>(
    startTimeframe[0].value
  );
  const [gradYear, setGradYear] = useState<string>("2008");

  const [selectedMbrand, setSelectedMbrand] = useState<string>("");
  const [brandOptions, setBrandOptions] = useState<any[]>([]);
  const [selectedMilitary, setSelectedMilitary] = useState<string>(
    militaryAffiliation[0].value
  );
  const [sub1, setSub1] = useState<string>("Test-1");
  const [sub2, setSub2] = useState<string>("Test-2");
  const [sub3, setSub3] = useState<string>("Test-3");
  const [zipCode, setzipCode] = useState<string>("33303");

  const [isBrandMapping, setIsBrandMapping] = useState(false);
  const [isRedirect, setIsRedirect] = useState(false);
  const [adMax, setAdMax] = useState("100");
  const [schoolToExclude, setSchoolToExclude] = useState("");
  const [enableFilterByCPC, setEnableFilterByCPC] = useState(false);

  const [listingUrl, setListingUrl] = useState("");
  const [listingData, setListingData] = useState<any>();

  const listResultRef = useRef<null | HTMLDivElement>(null);
  const targetRef = useRef<null | HTMLDivElement>(null);

  const [isOpenOthers, setIsOpenOthers] = useState(false);
  const [mediaType, setMediaType] = useState(mediaTypeOptions[0].value);
  const [inventoryType, setInventoryType] = useState(
    inventoryTypeOptions[0].value
  );
  const [HSGPA, setHSGPA] = useState("");
  const [state, setState] = useState(getStateByZip("33303"));
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [age, setAge] = useState("");
  const [country, setCountry] = useState("");
  const [hasRN, setHasRN] = useState("");
  const [gender, setGender] = useState("");
  const [certificate, setCertificate] = useState("");
  const [userIP, setUserIP] = useState("");
  const [DOB, setDOB] = useState("");
  const [isCitizen, setIsCitizen] = useState("");
  const [userAgent, setUserAgent] = useState(navigator.userAgent);
  const [deviceType, setDeviceType] = useState("");
  const [webInitiatingURL, setWebInitiatingURL] = useState("");
  const [webLandingURL, setWebLandingURL] = useState("");
  const [leadToken, setLeadToken] = useState("");
  const [trustFormURL, setTrustFormURL] = useState("");

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarType, setSnackbarType] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");

  let testApiVerticalID: any;
  const [userFormValue, setUserFormValue] = useState<IuserFormValue>({
    firstName: "John",
    lastName: "Doe",
    email: "john-doe-123@gmail.com",
    phNo: "",
    timeZone: "",
    checked: false,
    emailError: false,
    emailErrMsg: "",
    isTestAPI: "1",
  });
  // const [emailErr, emailErr]=useState('')
  const handleUserInfo = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked } = event.target;
    if (name === "email") {
      setUserFormValue((prev) => ({
        ...prev,
        [name]: value,
      }));
      if (isValidEmail(value)) {
        setUserFormValue((prev) => ({
          ...prev,
          emailErrMsg: "",
          emailError: false,
        }));
      } else {
        setUserFormValue((prev) => ({
          ...prev,
          emailErrMsg: "Please provide valid email",
          emailError: true,
        }));
      }
    } else if (name === "checked") {
      setUserFormValue((prev) => ({
        ...prev,
        checked: checked,
      }));
    } else {
      setUserFormValue((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleTimeZone = (value: string) => {
    setUserFormValue((prev) => ({ ...prev, timeZone: value }));
  };
  // const [isTestAPI, setIsTestAPI]=useState("1")
  const handleTestAPI = (value: string) => {
    // setIsTestAPI(value)
    setUserFormValue((prev) => ({
      ...prev,
      isTestAPI: value,
    }));
  };

  const [userModalOpen, setUserModalOpen] = useState(false);
  const modalClose = () => {
    setUserModalOpen(false);
  };

  const [showData, setShowData] = useState(false);
  useEffect(() => {
    if (showData && targetRef.current) {
      setTimeout(() => {
        targetRef?.current?.scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
    if (listingData && listResultRef.current && !isOpenOthers) {
      setTimeout(() => {
        listResultRef?.current?.scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
  }, [showData, listingData]);

  const othersRef = useRef<null | HTMLDivElement>(null);
  const otherTargetRef = useRef<null | HTMLDivElement>(null);
  useEffect(() => {
    if (otherTargetRef.current) {
      setTimeout(() => {
        otherTargetRef?.current?.scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
    if (othersRef.current && !isOpenOthers) {
      setTimeout(() => {
        othersRef?.current?.scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
  }, [isOpenOthers]);

  const handleOthersSwitch = () => {
    setIsOpenOthers(!isOpenOthers);
  };

  const handleSub = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    switch (name) {
      case "sub1":
        setSub1(value);
        break;
      case "sub2":
        setSub2(value);
        break;
      case "sub3":
        setSub3(value);
        break;
      case "adMax":
        setAdMax(value);

        break;
      case "excludeSchool":
        setSchoolToExclude(value);
        break;
      case "isCitizen":
        setIsCitizen(value);
        break;
      case "userAgent":
        setUserAgent(value);
        break;
      case "DOB":
        setDOB(value);
        break;
      case "userIP":
        setUserIP(value);
        break;
      case "country":
        setCountry(value);
        break;
      case "hasRN":
        setHasRN(value);
        break;
      case "certificate":
        setCertificate(value);
        break;
      case "gender":
        setGender(value);
        break;
      case "state":
        setState(value);
        break;
      case "HSGPA":
        setHSGPA(value);
        break;

      case "age":
        setAge(value);
        break;

      case "city":
        setCity(value);
        setCityMessage("");
        break;
      case "address":
        setAddress(value);
        setAddressMessage("");
        break;

      default:
        setSub1(value);
        break;
    }
  };
  // const [doiOptions, setDOIOptions]=useState<any[]>([])
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  const handleMediaType = (value: string) => {
    setMediaType(value);
  };

  const handleInventoryType = (value: string) => {
    setInventoryType(value);
  };

  const handleCategoryChange = (value: string) => {
    setSelectedProgram(mPrograms[value]?.[0]?.value);
    setSelectedAreaStudy(value);
    setProgramOptions(mPrograms[value]);
  };
  const handleIsBrandMapping = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsBrandMapping(event?.target.checked);
  };

  const handleIsRedirect = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsRedirect(event?.target.checked);
  };

  const handleEnableFilterByCPC = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEnableFilterByCPC(event?.target.checked);
  };
  const handlePublisherChange = async (value: string) => {
    // console.log("selected Program", selectedProgram)
    setSelectedPublisher(value);
    setPageLoading(true);
    const campaignData = await fetchCampaigns(value);
    // console.log("campaign data, ", campaignData)
    setCampaignOptions(campaignData);

    campaignData.length && setSelectedCampaigns(campaignData[0].value);
    setPageLoading(false);
  };

  const handleProgramChange = (value: string) => {
    // console.log("selected Program", selectedProgram)
    setSelectedProgram(value);
    setPageLoading(false);
  };

  const handleMbrandChange = (value: string) => {
    setSelectedMbrand(value);
  };
  const handleDOIChange = (value: string) => {
    setSelectedDOI(value);
  };
  const handleZipChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setzipCode(event.target.value);

    const state = getStateByZip(event.target.value);
    setState(state);
  };
  const handleMilitaryChange = (value: string) => {
    setSelectedMilitary(value);
  };
  const handleSTFChange = (value: string) => {
    setSelectedSTF(value);
  };

  const handleCampusTypeChange = (value: string) => {
    setSelectedCampusType(value);
  };

  const handleGradYear = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGradYear(event.target.value);
  };
  const handleHighestEducationChange = (value: string) => {
    setSelectedHE(value);
  };

  const handleCampaignChange = (value: string) => {
    setSelectedCampaigns(value);
  };

  const fetchData = async (pubID: string) => {
    console.log("testapi-----------", testApiVerticalID);
    const arg2 = {
      path: "publishers/campaign",
      queryParamsObject: {
        publisherID: pubID,
        campaignClass: "tcpa",
        verticalID: testApiVerticalID,
      },
    };
    const response = await fetchAllPublisherCampaignsData(
      arg2.path,
      arg2.queryParamsObject
    );
    console.log(response.data);
    return response.data;
  };

  const fetchPublishersDataByVertical = async (paylode: any) => {
    const headers = await generateHeader();
    const response = await axios.post(
      `${baseURLConfig.baseURl}/filterByVertical?ID=publisher`,
      paylode,
      { headers }
    );
    return response;
  };

  const fetchCampaignData = async (payLode: any) => {
    const headers = await generateHeader();
    const response = await axios.post(
      `${baseURLConfig.baseURl}/filter?campaignType=publisherCampaign`,
      payLode,
      { headers }
    );
    return response?.data?.data;
  };

  const initializeData = async () => {
    const data = await getUserIp();
    setUserIP(data?.ip || "");
    try {
      setPageLoading(true); // Set loading state to true before fetching data
      const publisherData = localStorage.getItem("userSortid");

      let lastFiveCharacters = "";
      if (publisherData) {
        lastFiveCharacters = publisherData.slice(-5);
        const response: any = await fetchPubUserCampaignByPubID(
          lastFiveCharacters
        );

        testApiVerticalID = response[0]?.publisher?.vertical[0]?.verticalID;
      }
      const pubLists = await fetchData(lastFiveCharacters);
      const campaignData = pubLists.map((campaign: any) => ({
        label: campaign.campaignname + ` ${campaign.SORTID}`,
        value: `${campaign.SORTID}`,
      }));
      // console.log('campaignData::::Test API', campaignData)
      campaignData.sort((a: any, b: any) => {
        const labelA = a.label.toUpperCase(); // Ignore upper and lowercase
        const labelB = b.label.toUpperCase(); // Ignore upper and lowercase
        if (labelA < labelB) {
          return -1;
        }
        if (labelA > labelB) {
          return 1;
        }

        // labels must be equal
        return 0;
      });
      setPublisherOptions([
        {
          label: publisherData ? publisherData : "",
          value: lastFiveCharacters,
        },
      ]);

      setSelectedPublisher(lastFiveCharacters);
      //const campaignData = await fetchCampaigns(pubDatas[0].value);
      // const brandLists = await getMBrandsDetails()
      // console.log("brandLists", brandLists);
      // const brandMenus = brandLists.data.values.map((brand: any) => ({
      //   label: ` ${brand.mBrandName}`,
      //   value: `${brand.mBrandId}`,
      // }));

      setCampaignOptions(campaignData);
      //  setBrandOptions(brandMenus);
      console.log("campaignData[0].value", campaignData);
      setSelectedCampaigns(campaignData[0].value);
      setPageLoading(false); // Set loading state to false after fetching data
    } catch (error) {
      console.error("Error fetching data:", error);
      setPageLoading(false); // Set loading state to false if an error occurs
    }
  };
  //   console.log("CAMPAIGN options", campaignOptions)

  const fetchCampaigns = async (lastFiveCharacters: string) => {
    const publisherResponse = await fetchData(lastFiveCharacters);
    // const publisherResponse = await fetchCampaignData({vertical:[`${props?.verticalId}`],
    //   mPubIds:[`${lastFiveCharacters}`]});
    const campaignData = publisherResponse
      .map((campaign: any) => ({
        label: campaign.campaignname + ` ${campaign.SORTID}`,
        value: `${campaign.SORTID}`,
      }))
      .sort((a: any, b: any) => a.label.localeCompare(b.label));

    return campaignData;
  };

  useEffect(() => {
    const field: string = "xxTrustedFormCertUrl";
    const provideReferrer: boolean = false;

    // Create the script element
    const tf: HTMLScriptElement = document.createElement("script");
    tf.type = "text/javascript";
    tf.async = true;
    tf.src =
      "http" +
      ("https:" === document.location.protocol ? "s" : "") +
      "://api.trustedform.com/trustedform.js?provide_referrer=" +
      encodeURIComponent(provideReferrer.toString()) +
      "&field=" +
      encodeURIComponent(field) +
      "&l=" +
      new Date().getTime() +
      Math.random();

    // Append the script to the DOM
    const s: HTMLScriptElement | null =
      document.getElementsByTagName("script")[0];
    if (s && s.parentNode) {
      s.parentNode.insertBefore(tf, s);
    }

    // Set the URL to state when the script is loaded
    tf.onload = () => {
      setTrustFormURL(tf.src);
    };
  }, []);

  useEffect(() => {
    const script = document.createElement("script");
    script.id = "LeadiDscript_campaign";
    script.type = "text/javascript";
    script.async = true;
    script.src =
      "//create.lidstatic.com/campaign/d50a3557-9854-7b8f-792c-527750401f5f.js?snippet_version=2&f=reset&callback=myCallbackFunction";

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  // Callback function to handle the token
  window.myCallbackFunction = function (token) {
    setLeadToken(token);
  };

  useEffect(() => {
    initializeData();
  }, [props?.verticalId]);
  const [addressMesssage, setAddressMessage] = useState("");
  const [cityMesssage, setCityMessage] = useState("");

  const handleSubmit = async () => {
    setListingLoading(true);

    if (!address || !city) {
      if (!address && !city) {
        setCityMessage("Please provide the city");
        setAddressMessage("Please provide the address");
        setListingLoading(false);
        return;
      }
      if (!address) {
        setAddressMessage("Please provide the address");
        setListingLoading(false);
        return;
      }
      if (!city) {
        setCityMessage("Please provide the city");
        setListingLoading(false);
        return;
      }
    }
    try {
      let queryParams: any = {
        degreeInterest: selectedDOI,
        areaStudy: selectedAreaStudy,
        mProgram: selectedProgram,
        highestEducation: selectedHE,
        gradYear: gradYear,
        campusType: selectedCampusType,
        militaryAffiliation: selectedMilitary,
        startTimeframe: selectedSTF,
        sub1: sub1,
        sub2: sub2,
        sub3: sub3,
        zipCode: zipCode,
        mPubID: selectedPublisher,
        mPubCampaignID: selectedCampaign,
        address: address,
        city: city,
        state: state,
        // adsMax: adMax
      };

      if (selectedMbrand) {
        queryParams.mBrandID = selectedMbrand;
      }

      // if (schoolToExclude) {
      //   queryParams.excludeAdvertiser = schoolToExclude
      // }

      if (enableFilterByCPC) {
        queryParams.enableFilterByCPC = 1;
      }
      if (isBrandMapping) {
        queryParams.enableFilterByBrand = 1;
      }

      if (isOpenOthers) {
        queryParams.state = state;
        queryParams.age = age;
        queryParams.gender = gender;
        queryParams.hasRN = hasRN;
        queryParams.inventoryType = inventoryType;
        queryParams.mediaType = mediaType;
        queryParams.isCitizen = isCitizen ? 1 : 0;
        queryParams.dateBirth = DOB;
        queryParams.hsGPA = HSGPA;
        queryParams.userIP = userIP;
        queryParams.useragent = userAgent;
        queryParams.webInitiatingURL = webInitiatingURL;
        queryParams.webLandingURL = webLandingURL;
        queryParams.deviceType = deviceType;
      }
      const newQP = {
        ...queryParams,
        leadIdToken: leadToken,
        trustedFormUrl: trustFormURL,
        mSessionID: generateRandomId(),
      };

      // console.log("query params", newQP)

      if (!isRedirect) {
        const response = await fetchTCPA_LISTING(newQP);
        // console.log("response", response)

        setListingData(response);
        if (
          Object.keys(response).length === 0 &&
          response.constructor === Object
        ) {
          setSnackbarOpen(true);
          setSnackbarType("success");
          setSnackbarMessage("We are not getting results");
        } else {
          setUserModalOpen(true);
        }
      } else {
        setListingData("");
        setSnackbarOpen(true);
        setSnackbarType("success");
        setSnackbarMessage("success");
      }
      // console.log("new qp", newQP)

      const listingAPIURL = buildUrl(baseURL, newQP);

      setListingLoading(false);
      setListingUrl(listingAPIURL);

      // listResultRef?.current?.scrollIntoView({ behavior: 'smooth' });
    } catch (err: any) {
      console.log("err: step 1", err);
      setListingLoading(true);
      setSnackbarOpen(true);
      setSnackbarType("error");
      setListingLoading(false);

      setSnackbarMessage(err.response.data.message);
    }
  };

  const handleReset = () => {
    setSelectedAreaStudy(defaultAreaStudyValue);
    setSelectedProgram(defaultProgramValue);
    setProgramOptions(mPrograms[defaultAreaStudyValue]);
    setSelectedDOI(degreeInterest[0].value);
    setSelectedHE(highestEducation[0].value);
    setSelectedSTF(startTimeframe[0].value);
    setSelectedCampusType(campusType[0].value);
    setSelectedMilitary(militaryAffiliation[0].value);
    setSub1("Test-1");
    setSub2("Test-2");
    setSub3("Test-3");
    setzipCode("33303");
    setGradYear("2008");
    setListingUrl("");
    setSelectedMbrand("");
    setIsBrandMapping(false);
    setEnableFilterByCPC(false);
    setIsOpenOthers(false);
    setMediaType(mediaTypeOptions[0].value);
    setInventoryType(inventoryTypeOptions[0].value);
    setHSGPA("");
    setHSGPA("");
    setAge("");
    setCountry("");
    setHasRN("");
    setGender("");
    setCertificate("");
    setDOB("");
    setIsCitizen("");
    setDeviceType("");
    setWebInitiatingURL("");
    setWebLandingURL("");
    setListingUrl("");
  };

  const handleModalSubmit = async () => {
    const buildUrl = listingData?.postURL;
    const queryParams = {
      firstName: capitalizeFirstLetter(userFormValue.firstName),
      lastName: capitalizeFirstLetter(userFormValue.lastName),
      email: userFormValue.email,
      phone: userFormValue.phNo,
      tcpaCheck: userFormValue.checked,
      testingAPI: userFormValue.isTestAPI,
    };

    const parsedUrl = new URL(buildUrl);
    const params = new URLSearchParams(parsedUrl.search);
    params.set("firstName", userFormValue.firstName);
    params.set("lastName", userFormValue.lastName);
    params.set("email", userFormValue.email);
    params.set("phone", userFormValue.phNo);
    params.set("tcpaCheck", userFormValue.checked ? "1" : "0");
    params.set("testingAPI", userFormValue.isTestAPI);
    params.set("leadIDToken", leadToken);
    parsedUrl.search = params.toString();
    const newUrl = parsedUrl.toString();
    // console.log("newUrl", newUrl)
    try {
      const response = await axios.post(newUrl);
      setShowData(true);
      modalClose();
      setSnackbarOpen(true);
      setSnackbarType("success");
      setSnackbarMessage("success");
      setUserFormValue({
        firstName: "",
        lastName: "",
        email: "",
        phNo: "",
        emailErrMsg: "",
        emailError: false,
        checked: false,
        timeZone: "",
        isTestAPI: "1",
      });
    } catch (error: any) {
      console.log("Error", error);
      setSnackbarOpen(true);
      setSnackbarType("error");
      setSnackbarMessage(error?.response?.data.message);
    }
  };
  return (
    <>
      {pageLoading ? (
        <Box
          className="loading-spinner-container"
          sx={{
            minHeight: "500px",
          }}
        >
          <CircleLoader
            color="#00BFFF"
            loading={pageLoading ? true : false}
            size={60}
          />
        </Box>
      ) : (
        <Grid
          container
          spacing={2}
          sx={{ margin: "3px", width: "99%", padding: "5px" }}
        >
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={4} sm={6} md={3}>
                <BasicSelect
                  value={selectedPublisher}
                  menuOptions={publisherOptions}
                  setValue={handlePublisherChange}
                  selectLabel="Publisher"
                  isDisable={true}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={4} md={3}>
                <BasicSelect
                  value={selectedCampaign}
                  menuOptions={campaignOptions}
                  setValue={handleCampaignChange}
                  selectLabel="Publisher Campaign"
                />
              </Grid>
              {/* <Grid item xs={12} sm={6} md={3} lg={4} >
                  <BasicTextField name="zipCode" label="Zip Code" value={zipCode} changeHandler={handleZipChange} />
                </Grid> */}
              <Grid item xs={12} sm={6} md={3} lg={4}>
                <BasicSelect
                  value={selectedDOI}
                  menuOptions={degreeInterest}
                  setValue={handleDOIChange}
                  selectLabel="Degree of Interest"
                />
              </Grid>
            </Grid>
          </Grid>

          {/* 2nd row */}
          <Grid item xs={12} mt={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3} lg={4}>
                <BasicSelect
                  value={selectedMbrand}
                  menuOptions={brandOptions}
                  setValue={handleMbrandChange}
                  selectLabel="MBrandID"
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3} lg={4}>
                <BasicSelect
                  value={selectedAreaStudy}
                  menuOptions={areaStudyDropdown}
                  setValue={handleCategoryChange}
                  selectLabel="Area study"
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3} lg={4}>
                <BasicSelect
                  value={selectedProgram}
                  menuOptions={programOptions}
                  setValue={handleProgramChange}
                  selectLabel="M programs"
                />
              </Grid>
            </Grid>
          </Grid>
          {/* 3rd row */}
          <Grid item xs={12} mt={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3} lg={4}>
                <BasicSelect
                  value={selectedHE}
                  menuOptions={highestEducation}
                  setValue={handleHighestEducationChange}
                  selectLabel="Highest Education"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={4}>
                <BasicTextField
                  name="gradYear"
                  label="Grad Year"
                  value={gradYear}
                  changeHandler={handleGradYear}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={4}>
                <BasicSelect
                  value={selectedSTF}
                  menuOptions={startTimeframe}
                  setValue={handleSTFChange}
                  selectLabel="Start Time Frame"
                />
              </Grid>
            </Grid>
          </Grid>

          {/* 4th row */}
          <Grid item xs={12} mt={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3} lg={4}>
                <BasicSelect
                  value={selectedMilitary}
                  menuOptions={militaryAffiliation}
                  setValue={handleMilitaryChange}
                  selectLabel="Military"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={4}>
                <BasicSelect
                  value={selectedCampusType}
                  menuOptions={campusType}
                  setValue={handleCampusTypeChange}
                  selectLabel="Campus Type"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={4}>
                <BasicTextField
                  name="zipCode"
                  label="Zip Code"
                  value={zipCode}
                  changeHandler={handleZipChange}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* 5th row */}

          <Grid item xs={12} mt={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3} lg={4}>
                <BasicTextField
                  name="address"
                  label="Address"
                  value={address}
                  changeHandler={handleSub}
                  require={true}
                  error={addressMesssage.length > 1}
                  text={addressMesssage}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={4}>
                <BasicTextField
                  name="state"
                  label="State"
                  value={state}
                  changeHandler={handleSub}
                  require={true}
                  disabled={true}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3} lg={4}>
                <BasicTextField
                  name="city"
                  label="City"
                  value={city}
                  changeHandler={handleSub}
                  require={true}
                  error={cityMesssage.length > 1}
                  text={cityMesssage}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* 6th row */}

          <Grid item xs={12} mt={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3} lg={4}>
                <BasicTextField
                  name="sub1"
                  label="sub1"
                  value={sub1}
                  changeHandler={handleSub}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={4}>
                <BasicTextField
                  name="sub2"
                  label="sub2"
                  value={sub2}
                  changeHandler={handleSub}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={4}>
                {/* <BasicSelect value={selectedSTF} menuOptions={startTimeframe} setValue={handleSTFChange} selectLabel='Start Time Frame'/> */}
                <BasicTextField
                  name="sub3"
                  label="sub3"
                  value={sub3}
                  changeHandler={handleSub}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* 7th row */}

          {/* 8th row */}
          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                control={
                  <IOSSwitch
                    sx={{ m: 1 }}
                    checked={isOpenOthers}
                    //  value={iso}
                    onChange={handleOthersSwitch}
                  />
                }
                label="Others"
              />
            </FormGroup>
          </Grid>
        </Grid>
      )}
      {isOpenOthers && (
        <Grid
          container
          spacing={2}
          sx={{ margin: "3px", width: "99%", padding: "5px 5px 20px 5px" }}
          ref={othersRef}
        >
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {/* <Grid item xs={12} sm={6} md={3} lg={3}>

                  <BasicTextField name="state" label="State" value={state} changeHandler={handleSub} />
                </Grid> */}
              <Grid item xs={12} sm={6} md={3} lg={4}>
                <BasicSelect
                  value={mediaType}
                  setValue={handleMediaType}
                  menuOptions={mediaTypeOptions}
                  selectLabel="Media Type"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={4}>
                <BasicTextField
                  name="HSGPA"
                  label="High school GPA"
                  value={HSGPA}
                  changeHandler={handleSub}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={4}>
                <BasicTextField
                  name="age"
                  label="Age"
                  value={age}
                  changeHandler={handleSub}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} mt={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <BasicTextField
                  name="country"
                  label="country"
                  value={country}
                  changeHandler={handleSub}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <BasicTextField
                  name="hasRN"
                  label="hasRN"
                  value={hasRN}
                  changeHandler={handleSub}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <BasicTextField
                  name="certificate"
                  label="Teaching certificate"
                  value={certificate}
                  changeHandler={handleSub}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <BasicTextField
                  name="gender"
                  label="Gender"
                  value={gender}
                  changeHandler={handleSub}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} mt={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <BasicTextField
                  name="isCitizen"
                  label="isCitizen"
                  value={isCitizen}
                  changeHandler={handleSub}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <BasicSelect
                  value={inventoryType}
                  setValue={handleInventoryType}
                  menuOptions={inventoryTypeOptions}
                  selectLabel="Inventory Type"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <BasicTextField
                  name="DOB"
                  label="Date of Birth"
                  value={DOB}
                  changeHandler={handleSub}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <BasicTextField
                  name="userIP"
                  label="userIP"
                  value={userIP}
                  changeHandler={handleSub}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} mt={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <BasicTextField
                  name="webInitiatingURL"
                  label="webInitiatingURL"
                  value={webInitiatingURL}
                  changeHandler={(event: any) => {
                    setWebInitiatingURL(event.target?.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <BasicTextField
                  name="webLandingURL"
                  label="webLandingURL"
                  value={webLandingURL}
                  changeHandler={(event: any) => {
                    setWebLandingURL(event.target?.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <BasicTextField
                  name="deviceType"
                  label="Device Type"
                  value={deviceType}
                  changeHandler={(event: any) => {
                    setDeviceType(event.target?.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <BasicTextField
                  name="userAgent"
                  label="User agent"
                  value={userAgent}
                  changeHandler={handleSub}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3} lg={6}>

                  <BasicTextField name="userAgent" label="User agent" value={userAgent} changeHandler={handleSub} />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={6}>

                  <BasicTextField name="isCitizen" label="isCitizen" value={isCitizen} changeHandler={handleSub} />
                </Grid>

              </Grid>
            </Grid> */}
        </Grid>
      )}

      <Grid
        container
        sx={{ margin: "3px", width: "99%", padding: "5px 5px 10px 5px" }}
      >
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "end" }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#0ae",
              "&.MuiButton-root:hover": { bgcolor: "#0ae" },
            }}
            onClick={handleSubmit}
            disabled={listingLoading}
          >
            {listingLoading ? "Loading" : "Submit"}
          </Button>
          <Button
            variant="contained"
            style={{
              marginLeft: "10px",
              backgroundColor: "#F5F5F5",
              color: "black",
            }}
            onClick={handleReset}
          >
            Reset
          </Button>
        </Grid>
      </Grid>

      {showData && (
        <Box
          p={3}
          className="listing-request-container card"
          ref={listResultRef}
        >
          <Box className="api-status ">
            <Typography
              className="card-heading"
              sx={{ wordWrap: "break-word", marginBottom: "4px" }}
              component="div"
              variant="h5"
            >
              {listingUrl}
            </Typography>
          </Box>

          {Object.keys(listingData).length > 0 && (
            <Grid
              container
              className="list-card"
              spacing={2}
              sx={{ margin: "3px", width: "99%", padding: "5px" }}
            >
              <Grid item xs={12} lg={4} sm={6} md={3} sx={{ display: "flex" }}>
                <AreaCard
                  imageUrl={listingData.imageURL}
                  headerContent={listingData.headContent}
                  description={listingData.descriptionLong}
                />
              </Grid>
            </Grid>
          )}
        </Box>
      )}
      <TCPA_FormModal
        open={userModalOpen}
        closeHandler={modalClose}
        userFormValue={userFormValue}
        children={InputFlds}
        dialogTitle="Personal Information"
        formChangeHandler={handleUserInfo}
        timeZoneHandler={handleTimeZone}
        submitHandler={handleModalSubmit}
        checkHandler={handleTestAPI}
      />
      <SnackbarMessage
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        type={snackbarType}
        message={snackbarMessage}
      />
    </>
  );
};

export default AdminTCPA_API;

const option = [];

const InputFlds = (props: any) => {
  const option = [
    {
      label: "true",
      value: "1",
    },
    {
      label: "false",
      value: "0",
    },
  ];
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={3} lg={6}>
        <BasicTextField
          name="firstName"
          label="First Name"
          value={props.userFormValue.firstName}
          changeHandler={props.formChangeHandler}
          require={true}
          text=""
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3} lg={6}>
        <BasicTextField
          name="lastName"
          label="Last Name"
          value={props.userFormValue.lastName}
          changeHandler={props.formChangeHandler}
          require={true}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3} lg={6}>
        <BasicTextField
          name="email"
          label="Email"
          value={props.userFormValue.email}
          changeHandler={props.formChangeHandler}
          require={true}
          error={props.userFormValue.emailError}
          text={props.userFormValue.emailErrMsg}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3} lg={6}>
        <BasicTextField
          name="phNo"
          label="Phone"
          value={props.userFormValue.phNo}
          changeHandler={props.formChangeHandler}
          require={true}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={12}>
        <BasicSelect
          value={props.userFormValue.timeZone}
          menuOptions={timeZones}
          setValue={props.timeZoneHandler}
          selectLabel="TimeZone"
          isDisable={false}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3} lg={12}>
        <BasicSelect
          value={props.userFormValue.isTestAPI}
          menuOptions={option}
          setValue={props.checkHandler}
          selectLabel="Is Test API"
          isDisable={false}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3} lg={12}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={props.userFormValue.checked}
                onChange={props.formChangeHandler}
                name="checked"
              />
            }
            label="I would like to learn more about attending an online college through email."
          />
        </FormGroup>
      </Grid>
    </Grid>
  );
};

interface modalProps {
  open: boolean;
  children: (props: any) => JSX.Element;
  // openHandler: () => void;
  closeHandler: () => void;
  dialogTitle: string;
  userFormValue: IuserFormValue;
  formChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  timeZoneHandler: (value: string) => void;
  submitHandler: () => void;
  checkHandler: (value: string) => void;
}
const TCPA_FormModal = (props: modalProps) => {
  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.closeHandler}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        BackdropProps={{ style: { opacity: "1" } }}
        // onBackdropClick={handleBackdropClick}
      >
        <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>

        <DialogContent>
          <props.children {...props} />
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={props.closeHandler}>
            CANCEL
          </Button>
          <Button
            sx={{
              backgroundColor: "#0ae",

              "&.MuiButtonBase-root:hover": {
                bgcolor: "#1976d2 !important",
              },
              "&:hover": { backgroundColor: "#1976d2" },
              "&:disabled": { backgroundColor: "#ffff" },
              border: "1px solid #0ae",
              boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
              borderRadius: "8px",
              color: "#ffff",
              height: "45px",
            }}
            disabled={!props.userFormValue.checked}
            onClick={props.submitHandler}
          >
            Save
            {/* {props.isLoading ? <CircularProgress color="inherit" size={24} /> : "SAVE"} */}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
