import { Box, Button, FormControlLabel, FormGroup, Grid, Paper, TextField, Typography } from '@mui/material'
import React, { useState, useEffect, useRef } from 'react'
import { CircleLoader } from 'react-spinners';
import { areaStudyDropdown, mPrograms, } from '../../../Mock/Tools data/testApi'
import BasicTextField from '../../../Component/Basic TextField/BasicInput';
import BasicSelect from '../../../Component/DropDowns/BasicSelect';
import { fetchAllPublisherCampaignsData,  fetchAllPublishersData, getUserIp } from '../../../service/Publisher';
import { getMBrandsDetails } from '../../../service/Advertiser';
import { isValidEmail } from '../../User Accounts/CreateUser';
import { postEmail } from '../../../Services/EmailAPI';
import SnackbarMessage from '../../../Component/Snackbar';
import { setSnackbarType } from '../../../Store/Slices/tcpaCampSlice';
import { getStateByZip } from '../../../Mock/GeoTargetingData';
import { generateHeader } from '../../../service/Auth';
import { baseURLConfig } from '../../../config/envConfig';
import axios from 'axios';

const args1 = {
  path: "publishers",
  queryParamsObject: {
    ID: "publisher"
  }
}


// const stateOptionsArray = Object.keys(stateOptions).map(state => ({
//   label: state,
//   value: state
// }));

const emailRegex = /^(|[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;

const EmailAPI = (props:any) => {
  const [pageLoading, setPageLoading] = useState(true)
  const [listingLoading, setListingLoading] = useState(false);
  const defaultAreaStudyValue: string = areaStudyDropdown[0]?.value || '';
  const defaultProgramValue = mPrograms[defaultAreaStudyValue]?.[0]?.value || '';

  const [selectedAreaStudy, setSelectedAreaStudy] = useState<string>(defaultAreaStudyValue);
  const [selectedProgram, setSelectedProgram] = useState<string>(defaultProgramValue);
  const [programOptions, setProgramOptions] = useState<any[]>(mPrograms[selectedAreaStudy])

  const [selectedPublisher, setSelectedPublisher] = useState<string>('')
  const [publisherOptions, setPublisherOptions] = useState<any[]>([])
  // const [stateOptions, setOptions] = useState<any[]>([])
  const [selectedCampaign, setSelectedCampaigns] = useState<string>('')
  const [campaignOptions, setCampaignOptions] = useState<any[]>([])
  

  const [userIP, setUserIP] = useState('')

  const [selectedMbrand1, setSelectedMbrand1] = useState<string>('')

  const [selectedMbrand2, setSelectedMbrand2] = useState<string>('')

  const [brandOptions, setBrandOptions] = useState<any[]>([])

  const [sub1, setSub1] = useState<string>("Test-1")
  const [sub2, setSub2] = useState<string>("Test-2")
  const [sub3, setSub3] = useState<string>("Test-3")
  const [zipCode, setzipCode] = useState<string>("33303")
  const [state, setState] = useState( getStateByZip("33303"))

  const [userAgent, setUserAgent] = useState(navigator.userAgent)
  const [deviceType, setDeviceType] = useState('')
  const [webInitiatingURL, setWebInitiatingURL] = useState('')
  const [webLandingURL, setWebLandingURL] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')

  const [clickId, setClickID] = useState('')
  const [trackID, setTrackID] = useState('')
  const [sessionID, setSessionID] = useState('')

  const [mEmail_ID, setEmail_ID] = useState('')
  const [mEmailError, setEmailError] = useState(false)
  const [emailErrorMessage, setEmailErrorMessage] = useState('')

  const [emailError, setEmailEr] = useState(false)
  const [emailErrorMsg, setEmailErrorMsg] = useState('')

  const [snackbarOpen, setSnackbarOpen]=useState(false);
  const [snackbarType, setSnackbarType]=useState('')
  const [snackbarMessage, setSnackbarMessage]=useState('')
  const [emailAPIURL, setEmailAPIURL] = useState('')

  const [emailResponse, setEmailResponse]=useState<any>()
  const handleSub = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    switch (name) {
      case 'sub1':
        setSub1(value);
        break;
      case 'sub2':
        setSub2(value);
        break;
      case 'sub3':
        setSub3(value);
        break;

      case 'mClickID':
        setClickID(value);
        break;
      case 'trackingID':
        setTrackID(value);
        break;
      case 'sessionID':
        setSessionID(value);
        break;

      case 'webLandingURL':
        setWebLandingURL(value);
        break;
      case 'firstName':
        setFirstName(value);
        break;
      case 'lastName':
        setLastName(value);
        break;
        case 'state':
        setState(value);
        break;
        case 'userIP':
          setUserIP(value);
          break;
  
      case 'email':
        if (isValidEmail(value)) {
        setEmail(value);
        setEmailEr(false)
        setEmailErrorMsg('')
        }else{
          setEmail(value);
          setEmailEr(true)
          setEmailErrorMsg('Please provide valid email')
        }
        break;
      // case 'mEmail_ID':
      //   if (isValidEmail(value)) {
      //     setEmail_ID(value);
      //     setEmailError(false)
      //     setEmailErrorMessage('')
      //   } else {
      //     setEmail_ID(value);
      //     setEmailError(true)
      //     setEmailErrorMessage('Please provide valid email')
      //   }

        // break;

      default:
        setSub1(value)
        break

    }
  }


  const handleCategoryChange = (value: string) => {
    setSelectedProgram(mPrograms[value]?.[0]?.value);
    setSelectedAreaStudy(value);
    setProgramOptions(mPrograms[value])


  };

  const handlePublisherChange = async (value: string) => {
    // console.log("selected Program", selectedProgram)
    setSelectedPublisher(value);
    setPageLoading(true);
    const campaignData = await fetchCampaigns(value)
    // console.log("campaign data, ", campaignData)
    setCampaignOptions(campaignData);

    campaignData.length && setSelectedCampaigns(campaignData[0].value);
    setPageLoading(false);
  };

  const handleProgramChange = (value: string) => {
    // console.log("selected Program", selectedProgram)
    setSelectedProgram(value);
    setPageLoading(false);
  };

  const handleMbrand1Change = (value: string) => {
    setSelectedMbrand1(value);
  };
  const handleMbrand2Change = (value: string) => {
    setSelectedMbrand2(value);
  };

  const handleZipChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setzipCode(event.target.value);
   const state= getStateByZip(event.target.value)
   setState(state)
  };


  const handleCampaignChange = (value: string) => {
    setSelectedCampaigns(value)
  };

  const fetchData = async (pubID: string) => {
    const arg2 = {
      path: "publishers/campaign",
      queryParamsObject: {
        publisherID: pubID,
        campaignClass:'email',
        verticalID:props?.verticalId
      }
    }
    const response = await fetchAllPublisherCampaignsData(arg2.path, arg2.queryParamsObject)
    return response.data
  }


  const fetchPublishersDataByVertical = async (payLode:any) => {

    const headers = await generateHeader();
    const response = await axios.post(`${baseURLConfig.baseURl}/filterByVertical?ID=publisher`, payLode, { headers });
    return response;
  };

  const fetchCampaignData = async (payLode:any) => {

    const headers = await generateHeader();
    const response = await axios.post(`${baseURLConfig.baseURl}/filter?campaignType=publisherCampaign`, payLode, { headers });
    return response?.data?.data;
  };
  const initializeData = async () => {
    const data = await getUserIp();
    setUserIP(data?.ip || '');
    try {
      setPageLoading(true); // Set loading state to true before fetching data
      // fetch publisher data for all 
      // const publisherData = localStorage.getItem('userSortid');

      const pubLists = await fetchPublishersDataByVertical({vertical:[`${props?.verticalId}`]})
     // const pubLists = await fetchAllPublishersData(args1.path, args1.queryParamsObject)

      // console.log("pubLists", pubLists.data.result.data)
      const pubDatas = pubLists.data.result.data.map((pub: any) => ({
        label: ` ${pub.publisherName || pub.GroupName}`,
        value: `${pub.mPubID}`
      }));

      // console.log("PUBDATAS::::::::::::::::::::::::::::", pubDatas)
      pubDatas.sort((a: any, b: any) => {
        const labelA = a.label.toUpperCase(); // Ignore upper and lowercase
        const labelB = b.label.toUpperCase(); // Ignore upper and lowercase
        if (labelA < labelB) {
          return -1;
        }
        if (labelA > labelB) {
          return 1;
        }

        // labels must be equal
        return 0;
      });
      setPublisherOptions(pubDatas);
      // setPubData(pubDatas)
      setSelectedPublisher(pubDatas[0].value);
      const campaignData = await fetchCampaigns(pubDatas[0].value)
      const brandLists = await getMBrandsDetails()
      // console.log("brandLists", brandLists);
      const brandMenus = brandLists.data.values.map((brand: any) => (
        {
          label: ` ${brand.mBrandName}`,
          value: `${brand.mBrandId}`
        }
      ))?.sort((a: any, b: any) => a?.label?.localeCompare(b?.label));

      setCampaignOptions(campaignData);
      setBrandOptions(brandMenus)
      setSelectedCampaigns(campaignData[0].value);
      setPageLoading(false); // Set loading state to false after fetching data
    } catch (error) {
      console.error('Error fetching data:', error);
      setPageLoading(false); // Set loading state to false if an error occurs
    }
  };
  // console.log("CAMPAIGN options", campaignOptions)

  const fetchCampaigns = async (lastFiveCharacters: string) => {
    const publisherResponse =await fetchData(lastFiveCharacters);
    // const publisherResponse = await fetchCampaignData({vertical:[`${props?.verticalId}`],
    //   mPubIds:[`${lastFiveCharacters}`]});
    const campaignData = publisherResponse.map((campaign: any) => ({
      label: campaign.campaignname + ` ${campaign.SORTID}`,
      value: `${campaign.SORTID}`
    })).sort((a: any, b: any) => a.label.localeCompare(b.label));

    return campaignData
  }
  useEffect(() => {
    initializeData()
  }, [props?.verticalId])

  const handleSubmit = async () => {
    setListingLoading(true)
      if (!isValidEmail(email)) {
      
        setEmailEr(true)
        setEmailErrorMsg('Please provide valid email')
        setListingLoading(false)
        return ;
      }
    
    try {
      let queryParams: any = {
        // degreeInterest: selectedDOI,
        areaStudy: selectedAreaStudy,
        mProgram: selectedProgram,
        sub1: sub1,
        sub2: sub2,
        sub3: sub3,
        zipCode: zipCode,
        mPubID: selectedPublisher,
        mPubCampaignID: selectedCampaign,
        mEmail_ID: mEmail_ID,
        firstName: firstName,
        lastName: lastName,
        webLandingURL: webLandingURL,
        mTrackingID: trackID,
        deviceType: deviceType,
        state: state,
        email:email,
        excludeMatch_1: selectedMbrand1,
        excludeMatch_2: selectedMbrand2,
        ip:userIP,
        mClickID:clickId,
        mSessionID:sessionID
        // adsMax: adMax
      };



console.log("API BODY::::", queryParams)


const result=await postEmail(queryParams);
  // console.log("response", response)
  const {response, urlWithParams}=result
  setEmailAPIURL(urlWithParams)
  // console.log("type of respone", typeof response)
  setEmailResponse(response)
      setListingLoading(false)
      setSnackbarOpen(true);
      setSnackbarType('success')
      setSnackbarMessage(response.message)
      
     
    } catch (err:any) {
      console.log("error;;;;;;;;;;;;;;;", err)
      setSnackbarType('error')
      setListingLoading(false)
      setSnackbarOpen(true);
     
      setSnackbarMessage(err.message)

    }

  }


  const handleReset = () => {
    setEmailAPIURL('')
    setEmailResponse('')
    setSelectedAreaStudy(defaultAreaStudyValue);
    setSelectedProgram(defaultProgramValue);
    setProgramOptions(mPrograms[defaultAreaStudyValue])
    setState('')
    setSessionID('');
    setFirstName('');
    setTrackID('');
    setSub1('');
    setSub2('');
    setSub3('');
    setzipCode('33303');
    setClickID('');
    setEmail('');
    setEmailEr(false)
    setEmailErrorMsg('')
    setLastName('');
    setEmail_ID('');
    setEmailError(false)
    setEmailErrorMessage('')
setUserIP('')
setTrackID('')
setClickID('')
setSessionID('')
setSelectedMbrand1('')
setSelectedMbrand2('')
    setDeviceType('')
    setWebInitiatingURL('')
    setWebLandingURL('')
    setSelectedPublisher(publisherOptions[0].value)
    setSelectedCampaigns(campaignOptions[0].value);
  };
console.log("response ", emailResponse)

  return (
    <>
 
        {pageLoading ? <Box className="loading-spinner-container" sx={{
          minHeight: "500px"
        }}>

          <CircleLoader color="#00BFFF" loading={pageLoading ? true : false} size={60} />
        </Box> :
          <Grid container spacing={2} sx={{ margin: '3px', width: '99%', padding: "5px" }}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6} sm={6} md={3} >
                  <BasicSelect value={selectedPublisher} menuOptions={publisherOptions} setValue={handlePublisherChange} selectLabel='Publisher' isDisable={false} />
                </Grid>
                <Grid item xs={12} sm={6} lg={6} md={3} >
                  <BasicSelect value={selectedCampaign} menuOptions={campaignOptions} setValue={handleCampaignChange} selectLabel='Publisher Campaign' />
                </Grid>
                {/* <Grid item xs={12} sm={6} md={3} lg={4} >


                  <BasicTextField name="mEmail_ID" label="mEMail ID" value={mEmail_ID} changeHandler={handleSub} error={mEmailError} text={emailErrorMessage} require={true} />
                </Grid> */}

              </Grid>
            </Grid>

            {/* 2nd row */}
            <Grid item xs={12} mt={2}>
              <Grid container spacing={2}>

                <Grid item xs={12} sm={6} md={3} lg={4}>
                  <BasicTextField name="firstName" label="First Name" value={firstName} changeHandler={handleSub} />
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={4}>
                  <BasicTextField name="lastName" label="Last Name" value={lastName} changeHandler={handleSub} />
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={4}>
                  <BasicTextField name="email" label="Email" value={email} changeHandler={handleSub}  error={emailError} text={emailErrorMsg}  require={true}/>
                </Grid>
              </Grid>
            </Grid>
           
          

            <Grid item xs={12} mt={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3} lg={4}>
                  {/* <BasicSelect value={selectedHE} menuOptions={highestEducation} setValue={handleHighestEducationChange} selectLabel='Highest Education' /> */}
                  <BasicTextField name="sub1" label="sub1" value={sub1} changeHandler={handleSub} />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={4}>

                  <BasicTextField name="sub2" label="sub2" value={sub2} changeHandler={handleSub} />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={4}>
                  {/* <BasicSelect value={selectedSTF} menuOptions={startTimeframe} setValue={handleSTFChange} selectLabel='Start Time Frame'/> */}
                  <BasicTextField name="sub3" label="sub3" value={sub3} changeHandler={handleSub} />
                </Grid>

              </Grid>
            </Grid>
            {/* 6th row */}


        
            <Grid item xs={12} mt={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3} lg={3}>



                  <BasicSelect value={selectedMbrand1} menuOptions={brandOptions} setValue={handleMbrand1Change} selectLabel='Exclude brand 1' />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <BasicSelect value={selectedMbrand2} menuOptions={brandOptions} setValue={handleMbrand2Change} selectLabel='Exclude brand 2' />

                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <BasicSelect value={selectedAreaStudy} menuOptions={areaStudyDropdown} setValue={handleCategoryChange} selectLabel='Area study' />
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <BasicSelect value={selectedProgram} menuOptions={programOptions} setValue={handleProgramChange} selectLabel='M programs' />
                </Grid>


              </Grid>
            </Grid>

            {/* 7th row */}
            <Grid item xs={12} mt={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3} lg={6}>

                  <BasicTextField name="webLandingURL" label="Web Landing URL" value={webLandingURL} changeHandler={handleSub} />

                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={6}>
                  <BasicTextField name="userIP" label="userIP" value={userIP} changeHandler={handleSub} />

                </Grid>
               
              
              </Grid>
              </Grid>

            <Grid item xs={12} mt={2}>
              <Grid container spacing={2}>
                
                <Grid item xs={12} sm={6} md={3} lg={4}>
                  <BasicTextField name="deviceType" label="Device Type" value={deviceType} changeHandler={(event: any) => { setDeviceType(event.target?.value) }} />

                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={4}>
                <BasicTextField name="state" label="state" value={state} changeHandler={handleSub} />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={4}>
                  <BasicTextField name="zipCode" label="Zip Code" value={zipCode} changeHandler={handleZipChange} />
                </Grid>
              </Grid>


            </Grid>

            <Grid item xs={12} mt={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3} lg={4}>

                  <BasicTextField name="mClickID" label="mClick ID" value={clickId} changeHandler={handleSub} />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={4}>
                  <BasicTextField name="trackingID" label="Tracking ID" value={trackID} changeHandler={handleSub} />

                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={4}>

                  <BasicTextField name="sessionID" label="Session ID" value={sessionID} changeHandler={handleSub} />
                </Grid>
               
              </Grid>


            </Grid>



          </Grid>
        }
        <Grid container sx={{ margin: '3px', width: '99%', padding: "5px 5px 10px 5px" }} >
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'end' }} >
            <Button variant="contained" sx={{ backgroundColor: "#0ae", '&.MuiButton-root:hover': { bgcolor: '#0ae' } }} onClick={handleSubmit} disabled={listingLoading}>
              {listingLoading ? "Loading" : "Submit"}
            </Button>
            <Button variant="contained" style={{ marginLeft: '10px', backgroundColor: "#F5F5F5", color: "black" }} onClick={handleReset}>
              Reset
            </Button>
          </Grid>
        </Grid>


        <SnackbarMessage open={snackbarOpen} onClose={() => { setSnackbarOpen(false) }} type={snackbarType} message={snackbarMessage}/>

          {
            emailAPIURL && 
            <Box
            p={3}
            className="listing-request-container card"
            // ref={listResultRef}
          >
            <Box className='api-status '>
              <Typography className="card-heading" sx={{ wordWrap: 'break-word', marginBottom: "4px" }} component='div' variant='h5'>
                {emailAPIURL}
              </Typography>
           

            </Box>
            <Typography className="card-sub-heading">
                Email request ID: {emailResponse?.emailRequestID} 
              </Typography>
            </Box>
          }
 

    </>
  )
}

export default EmailAPI



