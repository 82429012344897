import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IUploadProps } from 'devextreme-react/cjs/file-manager';

interface IuploadcmsSlice{
  selectedData:any;
  websiteDomainName:string;
  csvContent:{
    fileData:[],
    fileName:string,
    selectedOption:'remove' | 'add'
  }
}
const initial_state={
  selectedData:{} ,
  websiteDomainName:'',
  csvContent:{
    fileData:[],
    fileName:'',
    selectedOption:'remove'
  }
}
export const uploadSeoCMCiSlice = createSlice({
    name: 'upload cms List',
    initialState:initial_state,
    reducers: {
      setTempData: (state, action: PayloadAction<any>) => {
       state.selectedData=action.payload;
      state.websiteDomainName=action.payload.websiteDomainName;
       if(action.payload.csvContent){
        // console.log("track 2")
        state.csvContent.fileName=action.payload.csvContent.fileName
        state.csvContent.fileData=action.payload.csvContent.fileData
        state.csvContent.selectedOption='remove'
       }
      },
      setFileName:(state,action)=>{
        // console.log("state ", state)
        // state.fileName=action.payload
        state.csvContent.fileName=action.payload
      },
      setFileData:(state,action)=>{
        state.csvContent.fileData=action.payload
      },
      setRadioOption:(state, action)=>{
        state.csvContent.selectedOption=action.payload
       },
       cleanUp:(state, action)=>{
        // state=initial_state;
        state.selectedData=action.payload
        state.csvContent={
          fileData:[],
          fileName:'',
          selectedOption:'remove'
        }
       }
      
    }
})


export const {
    setTempData,
    setFileData,
    setFileName,
    setRadioOption, 
    cleanUp
  } = uploadSeoCMCiSlice.actions;
  export const uploadCMSContent = uploadSeoCMCiSlice.reducer;