import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

interface seoWebsiteState {
  template: string;
  primaryColor: string;
  secondaryColor: string;
  ctaButtonColor: string;
  areaStudy: string;
  mProgram: string;
  degreeInterest: string;
  widgetButton: string;
  // bannerRequestToggle: boolean;
  bannerRequestInfo: string;
  onlineSchoolsMatching: boolean;
  requestInformation: boolean;
  footerContent: string;
  footerContentToggle: boolean;
  footerLinks: { key: any; value: any }[];
  footerLinksToggle: boolean;
  // copyright: string;
  // copyrightToggle: boolean;
  advertiserDisclosure: string;
  advertiserDisToggle: boolean;
  advertiserMessage: string;
  advertiserLink: string;
  pubId: string;
  pubCampaignId: string;
  // defaultURL: [];
  defaultURL: { key: any; value: any }[];
  footerLogo: string;
  favIcon: string;
  successMessage: string;
  errorMessage: string;
  snackbarOpen: boolean;
  currentStepperIndex: number;
  programContent: any;
  // const [successMessage, setSuccessMessage] = useState('');
  // const [errorMessage, setErrorMessage] = useState('');
  // const [isSnackbarOpen, setSnackbarOpen] = useState(false);
}

const initialWebsiteInfoState: any = {
 // template: '',
  // primaryColor: '#a8d507',
  // secondaryColor: '#e52a4f',
  // areaStudy: '',
  // mProgram: '',
  // degreeInterest: '',
  // widgetButton: '',
  // bannerRequestInfo: '',
  // onlineSchoolsMatching: false,
  // requestInformation: false,
  // footerContent: '',
  // footerContentToggle: true,

  // // copyright: '',
  // advertiserDisclosure: '',
  // advertiserMessage: '',
  // advertiserLink: '',
  // pubId: '',
  // pubCampaignId: '',
  // // defaultURL: [],
  // defaultURL: [
  //   {
  //     key: '',
  //     value: '',
  //   },
  // ],
  // footerLinksToggle: true,
  // // copyrightToggle: false,
  // advertiserDisToggle: true,
  // // uploadImage: '',
  // // bannerRequestToggle: false,
  successMessage: '',
  errorMessage: '',
  snackbarOpen: false,
  
  csvContent:{
    fileName:"",
    fileData:[],
    selectedOption:"add"
  },
 
 
};

const seoWebsiteInfoApiSlice = createSlice({
  name: 'seoWebsiteList',
  initialState: initialWebsiteInfoState,
  reducers: {
    setTempData: (state, action: PayloadAction<any>) => {
      const payload=action.payload;
      if(payload.csvContent){
        console.log("track;;;",)
      }
      return {...state,  ...action.payload}
    },

    setFileName:(state,action)=>{
      console.log("state ", state)
      // state.fileName=action.payload
      state.csvContent.fileName=action.payload
    },
    setFileData:(state,action)=>{
      state.csvContent.fileData=action.payload
    },
    
    setWebsiteInfoField: (
      state,
      action: PayloadAction<{
        field: keyof seoWebsiteState;
        value: string | boolean;
      }>
    ) => {
      const { field, value } = action.payload;
      (state as any)[field] = value;
    },
  setRadioOption:(state, action)=>{
   state.csvContent.selectedOption=action.payload
  }
  },
});

export const {
  // setTemplateData,
  // setPrimaryColorData,
  // setSecondaryColorData,
  // setToggleField,
  // setDefaultURL,
  // setSuccessMessage,
  // setSnackbarOpen,
  // setErrorMessage,
  // setWebsiteInfoField,
  // setFooterLinks,
  // setCurrentStepperIndex,
  setTempData,
  setFileData,
  setFileName,
  setRadioOption
} = seoWebsiteInfoApiSlice.actions;
export const websiteInfoApi = seoWebsiteInfoApiSlice.reducer;
