
import React, { useRef, useCallback, useState } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, TableSortLabel, FormGroup, FormControlLabel, Box
} from '@mui/material';
import { IOSSwitch } from './MMTable';
import { createTheme, ThemeProvider } from '@mui/material/styles';
interface HeadCell {
  numeric: boolean;
  id: string;
  label: string;
  key: string;
  [additionalProps: string]: any;  // Allow additional properties if needed
}
const theme = createTheme({
    components: {
      MuiTableHead: {
        styleOverrides: {
          root: {
            fontWeight: 'bold',
            backgroundColor: "#EAECF0",
            color: "#737791"
          },
  
        },
      },
    },
  });
  
  
interface TableProps {
  tableData: Record<string, any>[];
  tableHeadCell: HeadCell[];
  isLoading:boolean
//   hasMoreData: boolean;
//   isLoadingMore: boolean;
//   onActionClick: (row: Record<string, any>) => void;
}

type Order = 'asc' | 'desc';

const PresentationalTable: React.FC<TableProps> = ({
  tableData,
  tableHeadCell,
  isLoading
//   hasMoreData,
//   isLoadingMore,
//   onActionClick,
}) => {
  const observer = useRef<IntersectionObserver | null>(null);
  const [sortConfig, setSortConfig] = useState<{ key: string; order: Order }>({
    key: '',
    order: 'asc',
  });

  const sortedData = React.useMemo(() => {
    if (!sortConfig.key) return tableData;

    return [...tableData].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.order === 'asc' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.order === 'asc' ? 1 : -1;
      }
      return 0;
    });
  }, [tableData, sortConfig]);

  const handleSort = (columnKey: string) => {
    setSortConfig((prevConfig) => ({
      key: columnKey,
      order: prevConfig.key === columnKey && prevConfig.order === 'asc' ? 'desc' : 'asc',
    }));
  };

  // Infinite scroll observer
//   const lastRowRef = useCallback(
//     (node: HTMLTableRowElement | null) => {
//       if (isLoadingMore || !hasMoreData) return;
//       if (observer.current) observer.current.disconnect();

//       observer.current = new IntersectionObserver((entries) => {
//         if (entries[0].isIntersecting && hasMoreData) {
//           console.log('Load more data triggered');
//         }
//       });

//       if (node) observer.current.observe(node);
//     },
//     [isLoadingMore, hasMoreData]
//   );

  return (
    <ThemeProvider theme={theme} >
    <TableContainer component={Paper}  style={{ position: 'relative' }}>
    {isLoading && (
          <div className="loading-border">
            <div className="loading-bar" />
          </div>
        )}
          <Box className='no-more-tables responsive-tbl'>
      <Table size='small'>
        <TableHead>
          <TableRow>
            {tableHeadCell.map((column) => (
              <TableCell
                key={column.id}
                // align={column.numeric ? 'right' : 'left'}
                align={'left'}
                sortDirection={sortConfig.key === column.key ? sortConfig.order : false}
              >
                <TableSortLabel
                  active={sortConfig.key === column.key}
                  direction={sortConfig.key === column.key ? sortConfig.order : 'asc'}
                  onClick={() => handleSort(column.key)}
                >
                  {column.label}
                </TableSortLabel>
              </TableCell>
            ))}
            {/* Action column */}
            {/* <TableCell align="left">Action</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedData.map((row, index) => {
            return (
              <TableRow
                key={row[tableHeadCell[0]?.id] || index}
              >
                {tableHeadCell.map((column) => {
                    if(column.label==='Status'){
                        return  <TableCell key={column.label} style={{ padding: '4px', lineHeight: '1', paddingLeft: '15px' }} data-title={column.label}>
                        <FormGroup>
                          <FormControlLabel
                            control={<IOSSwitch sx={{ m: 1 }}
                               checked={row[column.key] === 'Active'}
                              //  value={iso}
                              onChange={() => {
                                console.log(row)
                              }} />}
                            label=""
                          />
                        </FormGroup>
                      </TableCell>
                                
                    }else{
                      return (
                        <TableCell
                          key={column.id}
                          align={'left'}
                          data-title={column.label}
                        >
                          {row[column.key]}
                        </TableCell>
                      )
                    }
                  
                })}
                {/* Action cell */}
                {/* <TableCell align="left">
                  <button onClick={() => onActionClick(row)}>Open</button>
                </TableCell> */}
              </TableRow>
            );
          })}
          {/* {isLoadingMore && (
            <TableRow>
              <TableCell colSpan={tableHeadCell.length + 1} align="center">
                <CircularProgress size={24} />
              </TableCell>
            </TableRow>
          )} */}
        </TableBody>
      </Table>
      </Box>
    </TableContainer>
    </ThemeProvider>
  );
};

export default PresentationalTable
