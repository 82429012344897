import { Box, Button, Card, CardMedia, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { SelectChangeEvent } from '@mui/material/Select';
import { advList } from '../../../../Mock/ADVData';
import { useAppDispatch, useAppSelector } from '../../../../Store/hooks';
import { campaignFormSelector, storeCampaignDisplayUrl, storeCampaignHeadContent, storeCampaignAdImageURL, storeCampaignAdImageFile, storeAdvertiserName, storeBulletInput1Value, storeBulletInput2Value, storeBulletInput3Value, storePreviewList, cleanDisplayURLError, cleanHeadContentError, cleanAdvertiserNameError, storeSelectedAdvertiser, storeTrackingUrlValue, storeTrackingURLError, storeSelectedAdvertiserForEditOperation, storeAdvStatus, storeHeadContentError } from '../../../../Store/Slices/CreateAdvCampaignSlice';

// import { useAppDispatch } from '../../../../Store/hooks';
import { fetchAdvertiserDataAction } from '../../../../Store/Actions';
import { CircleLoader } from 'react-spinners';
import { uploadToS3 } from '../../../../service/S3 Upload';
import { IOSSwitch } from '../../../../Component/Table/MMTable';
import SnackbarMessage from '../../../../Component/Snackbar';

const advAPIPath = "advertisers?ID=advertiser"
const AdCopy = () => {
  const dispatch = useAppDispatch();
  const campaignFormData = useAppSelector(campaignFormSelector)
  //console.log("ADcopy page state", campaignFormData)
  const { pageLoading, advertisers, advertiserList, isEdit, isClone } = campaignFormData
  useEffect(() => {
    dispatch(fetchAdvertiserDataAction(advAPIPath))
  }, [])

  const { brandName, imageUrl, headContent, } = campaignFormData.adCopy.adDescription

  const { previewList } = campaignFormData.adCopy.adContent


  return (
    <React.Fragment>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6} md={6} lg={9}>
          <Typography fontWeight='bold' fontSize='14' color='#151D48' mb={2} ml={1}>Ad Preview</Typography>
        </Grid>
        <Grid item xs={6} md={6} lg={3}>
          <Box height="100%" display="flex" justifyContent="flex-end" alignItems="center">
            <FormGroup>
              <FormControlLabel
                sx={{ mr: 2, mb: 2 }}
                control={
                  <IOSSwitch
                    sx={{ m: 1 }}
                    name='status'
                    checked={campaignFormData.status === 'Active'}
                    onChange={(event) => {
                      dispatch(storeAdvStatus(event.target.checked))
                    }}
                  />
                }
                label="Status"
              />
            </FormGroup>
          </Box>
        </Grid>
      </Grid>

      <Card className="ad-preview" sx={{ p: "16px" }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3} lg={2}>
            <Box className='info-1' height="100%" display="flex" justifyContent={{ xs: "flex-start", md: "center" }}>
              {imageUrl ? <CardMedia
                component="img"
                image={imageUrl}
              />
                : "No image"}
            </Box>
          </Grid>
          <Grid item xs={12} md={7} lg={8}>
            <Box className='info-2'>
              <Typography component="div" variant="h5" fontWeight="600" mb={1}>
                {brandName ? brandName : "Brand Name"}
              </Typography>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                component="p"
                lineHeight={2}
                fontWeight="600"
              >
                {headContent}
              </Typography>
              <ul style={{ marginBottom: '0' }}>
                {previewList.map((list, index) => (
                  <li key={index}>{list}</li>
                ))}
              </ul>
            </Box>
          </Grid>
          <Grid item xs={12} md={2} lg={2} mt={0}>
            <Box display="flex"
              justifyContent={{ xs: "flex-start", md: "center" }}
              alignItems="center"
              height="100%" >
              <Button
                style={{
                  padding: '13px 19px',
                  border: '1px solid burlywood',
                  backgroundColor: '#EEFCFF',
                  color: 'rgb(25, 118, 210)',
                  textAlign: 'center',
                  borderRadius: '4px'
                }}
              >
                Visit site
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Card>
      <Paper className="ad-copy-container">
        {pageLoading ?
          <Box className="loading-spinner-container">
            <CircleLoader color="#00BFFF" loading={pageLoading ? true : false} size={60} />
          </Box>
          :
          <Paper className='ad-campaign-form'>
            <Grid container spacing={3} mb={2}>
              <Grid item xs={12} lg={6} >
                <Box>
                  <Box mb={3}><Typography fontSize={18} fontWeight="600">Add Description</Typography></Box>
                  <InputFields
                    advertiserList={advertiserList}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} lg={6} >
                <Box>
                  <Box mb={3}><Typography fontSize={18} fontWeight="600">Add Content</Typography></Box>
                  <InputFieldRight />
                </Box>
              </Grid>
            </Grid>


          </Paper>
        }
      </Paper>


    </React.Fragment>
  )
}

export default AdCopy


const InputFields = (props: any) => {
  const campaignFormData = useAppSelector(campaignFormSelector)
  const { advertiserName, brandName, displayUrl, imageUrl, headContent, advertiserNameError, headContentError, displayURLError } = campaignFormData.adCopy.adDescription

  console.log("advertise Name", advertiserName);
  console.log("advertiseList", props.advertiseList)
  useEffect(() => {
    if (campaignFormData.isEdit) {
      if (campaignFormData.selectedAdvertiser[0]) {
        dispatch(storeSelectedAdvertiserForEditOperation(campaignFormData.selectedAdvertiser[0].SORTID))
      } else {
        if (props.advertiserList.length > 1) {
          const advSORTID = props.advertiserList.filter((item: any) => {
            return item.advertiserID === campaignFormData.mPubID
          })
          dispatch(storeSelectedAdvertiserForEditOperation(advSORTID[0].SORTID))

        }
      }

    }
    else if (campaignFormData.isClone) {

      if (campaignFormData.selectedAdvertiser[0]) {
        dispatch(storeSelectedAdvertiserForEditOperation(campaignFormData.selectedAdvertiser[0].SORTID))
      } else {
        if (props.advertiserList.length > 1) {

          const advSORTID = props.advertiserList.filter((item: any) => {
            return item.advertiserID === campaignFormData.mPubID
          })

          dispatch(storeSelectedAdvertiserForEditOperation(advSORTID[0].SORTID))

        }
      }

    }

  }, [props.advertiserList])
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const dispatch = useAppDispatch();

  const [headContentHelperText, setHeadContentHelperText] = useState<string>(`${headContent?.length}/300 characters`)

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;


    switch (name) {
      case "trackingUrl":
        const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
        if (urlPattern.test(value)) {
          dispatch(storeTrackingUrlValue(value))
          dispatch(storeTrackingURLError(""))
        } else {
          dispatch(storeTrackingURLError("PLease provide valid URL"))
          dispatch(storeTrackingUrlValue(value))
        }
        break;
      default:
        break;
    }
  };

  const handelHeadContentField = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHeadContentHelperText(`${event.target.value.length}/300 characters`)
    if (event.target.value.length < 300) {
      dispatch(storeCampaignHeadContent(event.target.value))

      dispatch(cleanHeadContentError(""))
    } else {
      dispatch(storeHeadContentError("length should be less than 300"))
    }

  }

  const handleChange = (event: { target: { value: string } }) => {
    //console.log("value of adv  name ", event.target.value)
    // dispatch(storeAdvertiserName(event.target.value))
    dispatch(cleanAdvertiserNameError(''))
    dispatch(storeSelectedAdvertiser(event.target.value))
  };


  const [imageValidationMessage, setImageValidationMessage] = useState("")
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarType, setSnackbarType] = useState("")
  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormControl error={advertiserNameError.length > 0} fullWidth>
            <InputLabel id="demo-simple-select-helper-label" required>Advertiser</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={advertiserName}
              onChange={handleChange}
              label="Advertiser*"
              // disabled={campaignFormData.isEdit || campaignFormData.isClone}
              disabled
            >
              {props.advertiserList.map((item: any, index: number) => {
                return (
                  <MenuItem value={item.advertiserID} key={item.advertiserID}>{item.advName}</MenuItem>
                )
              })}
            </Select>
            {advertiserNameError.length > 0 &&
              <FormHelperText>{advertiserNameError}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              label='Brand name'
              disabled
              value={brandName}
            />

          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              className='ad-content-trackingUrl'
              label='Tracking URL'
              name='trackingUrl'
              multiline
              maxRows={6}
              onChange={handleInputChange}
              required
              error={campaignFormData.adCopy.adContent.trackingUrlError.length > 1}
              helperText={campaignFormData.adCopy.adContent.trackingUrlError.length > 1 ? campaignFormData.adCopy.adContent.trackingUrlError : ""}
              value={campaignFormData.value.trackingUrl}
            />

          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              label='Head Content'
              onChange={handelHeadContentField}
              required
              value={headContent}
              error={headContentError.length > 0}
              helperText={headContentError.length > 0 ? headContentError : headContentHelperText}
              maxRows={4}

            />

          </FormControl>
        </Grid>
      </Grid>
      <SnackbarMessage open={snackbarOpen} onClose={() => { setSnackbarOpen(false) }} type={snackbarType} message={imageValidationMessage} />
    </Box>
  )
}


interface ComponentBProps {
  onInputValuesChange: (values: string[]) => void;
}
const InputFieldRight: React.FC = () => {
  const dispatch = useAppDispatch();

  const campaignFormData = useAppSelector(campaignFormSelector)
  const { bulletInput1, bulletInput2, bulletInput3 } = campaignFormData.adCopy.adContent
  const [input1HelperText, setInput1HelperText] = useState<string>(`${bulletInput1.length}/300 characters`)
  const [input2HelperText, setInput2HelperText] = useState<string>(`${bulletInput2.length}/300 characters`)
  const [input3HelperText, setInput3HelperText] = useState<string>(`${bulletInput3.length}/300 characters`)
  const [input1Error, setInput1Error] = useState<string>("")
  const [input2Error, setInput2Error] = useState<string>("")
  const [input3Error, setInput3Error] = useState<string>("")
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;


    switch (name) {
      case "Bullet-1":
        if (value.length < 300) {
          setInput1HelperText(`${value.length}/300 characters`)
          // setInput1Value(value);
          dispatch(storeBulletInput1Value(value))
          setInput1Error("")
        } else {
          setInput1Error("Length should be less than 300")
        }
        break;
      case "Bullet-2":
        if (value.length < 300) {
          setInput2HelperText(`${value.length}/300 characters`)
          // setInput2Value(value);
          dispatch(storeBulletInput2Value(value))
          setInput2Error('')
        } else {
          setInput2Error("Length should be less than 300")
        }
        break;
      case "Bullet-3":
        if (value.length < 300) {
          setInput3HelperText(`${value.length}/300 characters`)
          // setInput3Value(value);
          dispatch(storeBulletInput3Value(value))
          setInput3Error("")
        } else {
          setInput3Error("Length should be less than 300")
        }

        break;

      default:
        break;
    }



  };



  useEffect(() => {
    const values = [bulletInput1, bulletInput2, bulletInput3].filter((value) => value.trim() !== "");
    // onInputValuesChange(values);
    dispatch(storePreviewList(values))
  }, [bulletInput1, bulletInput2, bulletInput3]);

  return (
    <Box>
      <Grid container spacing={4.5}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              className='ad-content-textarea'
              label='Bullet 1'
              name='Bullet-1'
              multiline
              maxRows={4}
              helperText={input1Error.length > 0 ? input1Error : input1HelperText}
              onChange={handleInputChange}
              error={input1Error.length > 0}
              inputProps={{
                maxLength: 300
              }}
              value={bulletInput1}
            />

          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              className='ad-content-textarea'
              label='Bullet 2'
              name='Bullet-2'
              multiline
              maxRows={4}
              onChange={handleInputChange}
              // helperText={input2HelperText}
              helperText={input1Error.length > 0 ? input1Error : input2HelperText}
              value={bulletInput2}
              error={input2Error.length > 0}
            />

          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              className='ad-content-textarea'
              label='Bullet 3'
              name='Bullet-3'
              multiline
              maxRows={4}
              onChange={handleInputChange}
              // helperText={input3HelperText}
              value={bulletInput3}
              error={input3Error.length > 0}
              helperText={input3Error.length > 0 ? input3Error : input3HelperText}
            />

          </FormControl>
        </Grid>
        {/* <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
             className='ad-content-trackingUrl'
              label='Tracking URL'
              name='trackingUrl'
              multiline
              maxRows={6}
              onChange={handleInputChange}
              required
              error={campaignFormData.adCopy.adContent.trackingUrlError.length > 1}
              helperText={campaignFormData.adCopy.adContent.trackingUrlError.length > 1 ? campaignFormData.adCopy.adContent.trackingUrlError : ""}
              value={campaignFormData.value.trackingUrl}
            />

          </FormControl>
        </Grid> */}
      </Grid>





    </Box>
  )
}