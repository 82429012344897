import { Box, Button } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../Store/hooks';
import { campaignFormSelector, increaseActiveStep, decreaseActiveStep, storeCampaignNameError, storeAdvertiserNameError, storeHeadContentError, storeDisplayURLError, storeTrackingURLError, storeStartYearError, storeEndYearError } from '../../Store/Slices/CreateAdvCampaignSlice';
import { ADVERTISER_NAME_NAME_ERROR, CAMPAIGN_CREATE_SUCCESS, CAMPAIGN_NAME_FIELD_ERROR, CAMPAIGN_UPDATE_SUCCESS, DISPLAY_URL_FIELD_ERROR, END_YEAR_BLANK_ERROR, END_YEAR_FIELD_ERROR, HEAD_CONTENT_FIELD_ERROR, START_YEAR_BLANK_ERROR, START_YEAR_FIELD_ERROR, TRACKING_URL_FIELD_ERROR } from '../../Mock/MessageContstant';
import { generateRequiredObjectForAudienceTargeting } from '../../Utils/utils';
import { createAdvCampaigns, updateAllPubCamByAdvCamp } from '../../service/Advertiser';
import SnackbarMessage from '../Snackbar';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';

import { setAdvCampData } from '../../Store/Slices/advertiserTabApisData';

const StepButton = (props: any) => {
  const [stepVisited, setStepVisited] = useState<{ activeStep: boolean } | any>({});
  const [apiMethod, setAPIMethod] = useState("POST")
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState('');
  const [isApiLoading, setApiLoading] = useState(false);

  const [responseValue, setResponseValue] = useState<null | any>(null)
  const dispatch = useAppDispatch();
  const currentState = useAppSelector(campaignFormSelector)
  const { activeStep, isEdit, isClone } = currentState

  const navigate = useNavigate();
  const createCampaign = async (isValid: boolean) => {
    let requestPayload = {

    }
    switch (activeStep) {
      case 0:
        requestPayload = {
          "advertiser": currentState.advertiserProp[0],
          "campaignType": "advertiserCampaign",
          "campaignname": currentState.campaignName,
          "GroupName": currentState.selectedAdvertiser[0]?.SORTID,
          "mPubID": currentState.selectedAdvertiser[0]?.SORTID.slice(-5),
          "type": "Advertiser",
          "values": {
            "advertisername": currentState.selectedAdvertiser[0]?.SORTID,
            "Headline": "",
            // "bidPrice":currentState.value.bidPrice,
            // "source": "Test for v3  Description 1",
            "displayUrl": currentState.adCopy.adDescription.displayUrl,
            "trackingUrl": currentState.value.trackingUrl,
            // "bonusDesc": "Test for v3  bonus Desc..",
            "imageUrl": currentState.adCopy.adDescription.imageUrl,
            "status": currentState.advertiserStatus,

            "publisherName": currentState.selectedAdvertiser[0]?.SORTID,
            "brand": currentState.adCopy.adDescription.brandName,
            "headline": currentState.adCopy.adDescription.headContent,
            "source": currentState.adCopy.adContent.bulletInput1,
            "source1": currentState.adCopy.adContent.bulletInput2,
            "source2": currentState.adCopy.adContent.bulletInput3,
          },
          "publisherID": currentState.selectedAdvertiser[0]?.SORTID.slice(-5),
          "publisherName": currentState.selectedAdvertiser[0]?.SORTID,
          "status": currentState.status,
          "advertiserStatus": currentState.advertiserStatus
        }

        break;
      case 1:
        //   return <ProgramTargeting />
        //console.log("Step 1 :::: Program Targeting",)
        //console.log("Program targeting payload", currentState)
        requestPayload = {
          ...responseValue,
          values: {
            ...responseValue.values,
            mProgram: currentState.value.mPrograms
          }


        }
        break;
      case 2:
        //console.log("Request payload in case 2 before adding step2 page content ", requestPayload)
        //console.log("Step 2::::  Audience target",)
        //console.log("Audience payload", currentState.value.excludeAdjustment)
        const audiencPayload = generateRequiredObjectForAudienceTargeting(currentState.value.excludeAdjustment)
        //console.log("audiencPayload", audiencPayload)
        requestPayload = {
          ...responseValue,
          values: {
            ...responseValue.values,
            excludeAdjustment: audiencPayload,
            gradYear: currentState.value.gradYear
          }
        }

        //console.log("request payload for the audience targeting ", requestPayload)
        break;
      case 3:
        //console.log("Step3::::  media source",)
        //console.log(" media source payload", currentState.value.excludePublishers)
        requestPayload = {
          ...responseValue,
          values: {
            ...responseValue.values,
            excludePublishers: currentState.value.excludePublishers
          }
        }


        setApiLoading(true)
        const status = await updateAllPubCamByAdvCamp(requestPayload)
        // Here we make API call 

        if (status === 200) {
          console.log("SUCCESS!")
          setApiLoading(false)
          // setSnackbarOpen(true)
          // setSnackbarType("success")
          // setSnackbarMessage('Updated!!!')
        } else {
          return
        }

        //console.log("Request payload in case 3 before adding step3 page content ", requestPayload)
        break
      case 4:
        //   return <GeoTargeting />
        //console.log("Step4::::  GEO TARGETING",)
        // //console.log("Request payload in case 4 before adding step 4 page content ", requestPayload)
        //console.log(" Geo targeting payload", currentState.value.excludeStates)
        requestPayload = {
          ...responseValue,
          values: {
            ...responseValue.values,
            excludeStates: currentState.value.excludeStates,
            zipRules: currentState.value.zipRules,
            stateRules: currentState.value.stateRules,
            anyState: currentState.value.anyState
          }
        }

        break
      case 5:
        //   return <Scheduling />
        //console.log("Step5:::: Scheduling",)
        //console.log(" Scheduling payload", currentState.value.scheduling)
        // //console.log("Request payload in case 5 before adding step 5 page content ", requestPayload)
        requestPayload = {
          ...responseValue,
          values: {
            ...responseValue.values,
            configTimeOfDay: currentState.value.scheduling
          }
        }
        break
      default:
      //   return <Scheduling />;

    }
    if (!responseValue) {
      //  dispatch(increaseActiveStep(1))
      //console.log("Payload for API post", requestPayload)
      setApiLoading(true)
      if (isValid) {
        const { response, error } = await createAdvCampaigns(requestPayload, "POST")
        if (response) {
          setSnackbarOpen(true)
          setSnackbarType("success")
          setSnackbarMessage(CAMPAIGN_CREATE_SUCCESS)
          setResponseValue(response.data);
          setApiLoading(false)
          dispatch(increaseActiveStep(1))


        }

        if (error) {
          setSnackbarOpen(true)
          setApiLoading(true)
          setSnackbarType("error")
          setSnackbarMessage(error?.message)
        }

      } else {
        setApiLoading(false)
      }

    } else {
      if (isValid) {
        //console.log("Payload for API put", requestPayload)
        //console.log("Response value for new campaigns", responseValue)
        let newRequestPayload = {
          ...requestPayload,
          ID: responseValue.ID,
          SORTID: responseValue.SORTID,
          createdOn: responseValue.createdOn,
          updatedOn: responseValue.updatedOn
        }
        requestPayload = { ...newRequestPayload }
        setApiLoading(true)
        const { response, error } = await createAdvCampaigns(requestPayload, "PUT")
        if (response) {
          setSnackbarOpen(true)
          setSnackbarType("success")
          setSnackbarMessage(CAMPAIGN_UPDATE_SUCCESS)
          setResponseValue(response.data);
          setApiLoading(false)
          dispatch(increaseActiveStep(1))
        }

        if (error) {
          setSnackbarOpen(true)
          setSnackbarType("error")
          setSnackbarMessage(error?.message)
          setApiLoading(true)
        }
      } else {
        console.log('please fill mandatory fields');
      }
      // dispatch(increaseActiveStep(1))
    }
  }

  const updateCampaign = async () => {
    //console.log("Update campaigns", responseValue)
    // dispatch(increaseActiveStep(1))
    let requestPayload: any = {

    }
    //console.log("Values in state", currentState.value);
    const audiencPayload = generateRequiredObjectForAudienceTargeting(currentState.value.excludeAdjustment)

    console.log("currnet state ", currentState)
    let requestPayloadForEdit = {
      "advertiser": currentState.advertiserProp[0],
      "ID": "campaign",
      "campaignID": currentState.SORTID,
      "createdOn": currentState.createdOn,
      "updatedOn": "",
      "SORTID": currentState.SORTID,
      "campaignType": "advertiserCampaign",
      "campaignname": currentState.campaignName,
      "GroupName": currentState.selectedAdvertiser[0]?.SORTID,
      "mPubID": currentState.selectedAdvertiser[0]?.SORTID.slice(-5),
      "type": "Advertiser",
      values: {
        "advertisername": currentState.selectedAdvertiser[0]?.SORTID,
        "Headline": "",
        // "source": "Test for v3  Description 1",
        "displayUrl": currentState.adCopy.adDescription.displayUrl,
        "trackingUrl": currentState.value.trackingUrl,
        // "bonusDesc": "Test for v3  bonus Desc..",
        "imageUrl": currentState.adCopy.adDescription.imageUrl,
        "status": currentState.advertiserStatus,
        "excludeAdjustment": audiencPayload,
        "publisherName": currentState.selectedAdvertiser[0]?.SORTID,
        "brand": currentState.adCopy.adDescription.brandName,
        "headline": currentState.adCopy.adDescription.headContent,
        "source": currentState.adCopy.adContent.bulletInput1,
        "source1": currentState.adCopy.adContent.bulletInput2,
        "source2": currentState.adCopy.adContent.bulletInput3,
        "zipRules": currentState.value.zipRules,
        "mProgram": currentState.value.mPrograms,
        "gradYear": currentState.value.gradYear,
        "excludePublishers": currentState.value.excludePublishers,
        "excludeStates": currentState.value.excludeStates,
        "stateRules": currentState.value.stateRules,
        "anyState": currentState.value.anyState,
        "configTimeOfDay": currentState.value.scheduling,
        "programCSVConfig": {
          "programCSVData": currentState.campaignCSVData,
          "csvDataError": currentState.errorTrackURLmProgramID.length > 0,
          "csvFileName": currentState.campaignProgramCSVFileName
        }
      },
      "publisherID": currentState.selectedAdvertiser[0]?.SORTID.slice(-5),
      "publisherName": currentState.selectedAdvertiser[0]?.SORTID,
      "status": currentState.status,
      "advertiserStatus": currentState.advertiserStatus
    }

    // console.log("request payload for edit",requestPayloadForEdit )

    // if (requestPayloadForEdit?.values.mPrograms) {
    //   delete requestPayloadForEdit?.values.mPrograms
    // }

    if (currentState?.value.bidPrice) {
      // requestPayloadForEdit.values.bidPrice=currentState?.value.bidPrice
      const newPayload = {
        ...requestPayloadForEdit,
        values: {
          ...requestPayloadForEdit.values,
          bidPrice: currentState?.value.bidPrice,
          // configTimeOfDay:currentState.value.scheduling
        }
      }
      requestPayloadForEdit = newPayload
    }

    let csvError = false;
    const csvData = [];
    const programs: any = currentState.value.mPrograms
    for (let key in programs) {
      let csvData1 = {
        "Status": programs[key].enable,
        "Area Study": programs[key].areaStudy,
        "Description": programs[key].bonusDescription,
        "CPC": programs[key].bid,
        "tracking url": programs[key].trackingUrl,
        "mProgramID": programs[key].value,
        "mPrograms": programs[key].label,
        "trackUrlError": programs[key].trackUrlError,
        "id": programs[key].areaStudy
      }
      csvData.push(csvData1);
      if (programs[key]?.trackUrlError) {
        csvError = true;
        break;
      }
    }

    switch (activeStep) {
      case 0:
        // //console.log("request payload in update step-0 ", requestPayload)
        //console.log("request payload in update/edit step-0 ", requestPayloadForEdit)
        break;
      case 1:
        //console.log("Step 1 :::: Program Targeting",)
        // csvDataError

        requestPayloadForEdit.values.mProgram = currentState.value.mPrograms
        requestPayloadForEdit.values.programCSVConfig = {
          ...requestPayloadForEdit.values.programCSVConfig,
          csvDataError: csvError,
          programCSVData: csvData

        }
        //console.log("request payload in update/edit step-1 ", requestPayloadForEdit)
        break;
      case 2:
        //console.log("Request payload in case 2 before adding step2 page content ", requestPayload)
        requestPayloadForEdit.values.excludeAdjustment = audiencPayload;
        requestPayloadForEdit.values.gradYear = currentState.value.gradYear
        requestPayloadForEdit.values.programCSVConfig = {
          ...requestPayloadForEdit.values.programCSVConfig,
          csvDataError: csvError,
          programCSVData: csvData

        }
        //console.log("request payload in update/edit step-2 ", requestPayloadForEdit)
        break;
      case 3:
        //console.log("Step3::::  media source",)
        //console.log(" media source payload", currentState.value.excludePublishers)
        requestPayloadForEdit.values.excludePublishers = currentState.value.excludePublishers
        requestPayloadForEdit.values.programCSVConfig = {
          ...requestPayloadForEdit.values.programCSVConfig,
          csvDataError: csvError,
          programCSVData: csvData

        }
        setApiLoading(true)
        const status = await updateAllPubCamByAdvCamp(requestPayloadForEdit)
        // Here we make API call 

        if (status === 200) {
          console.log("SUCCESS!")
          setApiLoading(false)
          // setSnackbarOpen(true)
          // setSnackbarType("success")
          // setSnackbarMessage('Updated!!!')
        } else {
          return
        }
        break
      case 4:
        //   return <GeoTargeting />
        //console.log("Step4::::  GEO TARGETING",)
        // //console.log("Request payload in case 4 before adding step 4 page content ", requestPayload)
        //console.log(" Geo targeting payload", currentState.value.excludeStates)

        requestPayloadForEdit.values.excludeStates = currentState.value.excludeStates
        requestPayloadForEdit.values.zipRules = currentState.value.zipRules
        requestPayloadForEdit.values.stateRules = currentState.value.stateRules
        requestPayloadForEdit.values.programCSVConfig = {
          ...requestPayloadForEdit.values.programCSVConfig,
          csvDataError: csvError,
          programCSVData: csvData

        }

        break
      case 5:
        //console.log("Step5:::: Scheduling",)
        //console.log(" Scheduling payload", currentState.value.scheduling)
        if (currentState?.value.bidPrice) {
          // requestPayloadForEdit.values.bidPrice=currentState?.value.bidPrice
          const newPayload = {
            ...requestPayloadForEdit,
            values: {
              ...requestPayloadForEdit.values,
              bidPrice: { ...currentState?.value.bidPrice, configTimeOfDay: currentState.value.scheduling },

            }
          }
          requestPayloadForEdit = newPayload
        }
        requestPayloadForEdit.values.configTimeOfDay = currentState.value.scheduling
        requestPayloadForEdit.values.programCSVConfig = {
          ...requestPayloadForEdit.values.programCSVConfig,
          csvDataError: csvError,
          programCSVData: csvData

        }
        break

      default:
      //   return <Scheduling />;
    }
    //console.log("Payload for API put",  requestPayloadForEdit)
    setApiLoading(true)
    // // setApiLoading(false)
    // setSnackbarOpen(true)
    // setSnackbarType("error")
    // setSnackbarMessage("error tesrt")
    // // dispatch(increaseActiveStep(1));
    const { response, error } = await createAdvCampaigns(requestPayloadForEdit, "PUT")
    if (response) {
      setSnackbarOpen(true)
      setSnackbarType("success")
      setSnackbarMessage(CAMPAIGN_UPDATE_SUCCESS)
      setResponseValue(response.data);
      setApiLoading(false)
      dispatch(increaseActiveStep(1));


    }

    if (error) {
      setSnackbarOpen(true)
      setSnackbarType("error")
      setSnackbarMessage(error?.message)
      setApiLoading(true)
    }
    // //console.log("request payload put ", requestPayload)
  }

  const cloneCampaign = async (isValid: boolean) => {
    let requestPayload = {

    }
    const audiencPayload = generateRequiredObjectForAudienceTargeting(currentState.value.excludeAdjustment)
    switch (activeStep) {
      case 0:
        requestPayload = {
          "advertiser": currentState.advertiserProp[0],
          "ID": "campaign",
          "campaignID": currentState.SORTID,
          "createdOn": currentState.createdOn,
          "updatedOn": "",
          "SORTID": currentState.SORTID,
          "campaignType": "advertiserCampaign",
          "campaignname": currentState.campaignName,
          "GroupName": currentState.selectedAdvertiser[0]?.SORTID,
          "mPubID": currentState.selectedAdvertiser[0]?.SORTID.slice(-5),
          "type": "Advertiser",
          values: {
            "advertisername": currentState.selectedAdvertiser[0]?.SORTID,
            "Headline": "",
            // "source": "Test for v3  Description 1",
            "displayUrl": currentState.adCopy.adDescription.displayUrl,
            "trackingUrl": currentState.value.trackingUrl,
            // "bonusDesc": "Test for v3  bonus Desc..",
            "imageUrl": currentState.adCopy.adDescription.imageUrl,
            "status": currentState.advertiserStatus,
            "excludeAdjustment": audiencPayload,
            "publisherName": currentState.selectedAdvertiser[0]?.SORTID,
            "brand": currentState.adCopy.adDescription.brandName,
            "headline": currentState.adCopy.adDescription.headContent,
            "source": currentState.adCopy.adContent.bulletInput1,
            "source1": currentState.adCopy.adContent.bulletInput2,
            "source2": currentState.adCopy.adContent.bulletInput3,
            "zipRules": currentState.value.zipRules,
            "mProgram": currentState.value.mPrograms,
            "gradYear": currentState.value.gradYear,
            "excludePublishers": currentState.value.excludePublishers,
            "excludeStates": currentState.value.excludeStates,
            "stateRules": currentState.value.stateRules,
            "anyState": currentState.value.anyState,
            "configTimeOfDay": currentState.value.scheduling
          },
          "publisherID": currentState.selectedAdvertiser[0]?.SORTID.slice(-5),
          "publisherName": currentState.selectedAdvertiser[0]?.SORTID,
          "status": currentState.status,
          "advertiserStatus": currentState.advertiserStatus
        }

        break;
      case 1:
        //   return <ProgramTargeting />
        //console.log("Step 1 :::: Program Targeting",)
        //console.log("Program targeting payload", currentState)
        requestPayload = {
          ...responseValue,
          values: {
            ...responseValue.values,
            mProgram: currentState.value.mPrograms
          }


        }
        break;
      case 2:
        //console.log("Request payload in case 2 before adding step2 page content ", requestPayload)
        //console.log("Step 2::::  Audience target",)
        //console.log("Audience payload", currentState.value.excludeAdjustment)
        // const audiencPayload = generateRequiredObjectForAudienceTargeting(currentState.value.excludeAdjustment)
        //console.log("audiencPayload", audiencPayload)
        requestPayload = {
          ...responseValue,
          values: {
            ...responseValue.values,
            excludeAdjustment: audiencPayload,
            gradYear: currentState.value.gradYear
          }
        }

        //console.log("request payload for the audience targeting ", requestPayload)
        break;
      case 3:
        //console.log("Step3::::  media source",)
        //console.log(" media source payload", currentState.value.excludePublishers)
        requestPayload = {
          ...responseValue,
          values: {
            ...responseValue.values,
            excludePublishers: currentState.value.excludePublishers
          }
        }
        // requestPayloadForEdit.values.excludePublishers = currentState.value.excludePublishers
        setApiLoading(true)
        const status = await updateAllPubCamByAdvCamp(requestPayload)
        // Here we make API call 

        if (status === 200) {
          console.log("SUCCESS!")
          setApiLoading(false)
          // setSnackbarOpen(true)
          // setSnackbarType("success")
          // setSnackbarMessage('Updated!!!')
        } else {
          return
        }
        // //console.log("Request payload in case 3 before adding step3 page content ", requestPayload)
        break
      case 4:
        //   return <GeoTargeting />
        //console.log("Step4::::  GEO TARGETING",)
        // //console.log("Request payload in case 4 before adding step 4 page content ", requestPayload)
        //console.log(" Geo targeting payload", currentState.value.excludeStates)
        requestPayload = {
          ...responseValue,
          values: {
            ...responseValue.values,
            excludeStates: currentState.value.excludeStates,
            zipRules: currentState.value.zipRules,
            stateRules: currentState.value.stateRules,
            anyState: currentState.value.anyState
          }
        }

        break
      case 5:
        //   return <Scheduling />
        //console.log("Step5:::: Scheduling",)
        //console.log(" Scheduling payload", currentState.value.scheduling)
        // //console.log("Request payload in case 5 before adding step 5 page content ", requestPayload)
        requestPayload = {
          ...responseValue,
          values: {
            ...responseValue.values,
            configTimeOfDay: currentState.value.scheduling
          }
        }
        break
      default:
      //   return <Scheduling />;
    }
    if (!responseValue) {
      //  dispatch(increaseActiveStep(1))
      //console.log("Payload for API post", requestPayload)
      setApiLoading(true)
      if (isValid) {
        const { response, error } = await createAdvCampaigns(requestPayload, "POST")
        if (response) {
          setSnackbarOpen(true)
          setSnackbarType("success")
          setSnackbarMessage(CAMPAIGN_CREATE_SUCCESS)
          setResponseValue(response.data);
          setApiLoading(false)
          dispatch(increaseActiveStep(1))


        }

        if (error) {
          setSnackbarOpen(true)
          setApiLoading(true)
          setSnackbarType("error")
          setSnackbarMessage(error?.message)
        }

      } else {
        setApiLoading(false)
      }

    } else {
      if (isValid) {
        //console.log("Payload for API put", requestPayload)
        //console.log("Response value for new campaigns", responseValue)
        let newRequestPayload = {
          ...requestPayload,
          ID: responseValue.ID,
          SORTID: responseValue.SORTID,
          createdOn: responseValue.createdOn,
          updatedOn: responseValue.updatedOn
        }
        requestPayload = { ...newRequestPayload }
        setApiLoading(true)
        const { response, error } = await createAdvCampaigns(requestPayload, "PUT")
        if (response) {
          setSnackbarOpen(true)
          setSnackbarType("success")
          setSnackbarMessage(CAMPAIGN_UPDATE_SUCCESS)
          setResponseValue(response.data);
          setApiLoading(false)
          dispatch(increaseActiveStep(1))
        }

        if (error) {
          setSnackbarOpen(true)
          setSnackbarType("error")
          setSnackbarMessage(error?.message)
          setApiLoading(true)
        }
      } else {
        console.log('please fill mandatory fields');
      }
      // dispatch(increaseActiveStep(1))
    }
  }

  // const handleNext = async () => {
  //   const isValid = validateNextStep()
  //   if (isEdit) {
  //     if (isValid) {
  //       updateCampaign()
  //       dispatch(setAdvCampData([]));
  //     }
  //   } else if (isClone) {
  //     if (isValid) {
  //       cloneCampaign(isValid)
  //       dispatch(setAdvCampData([]));
  //     }
  //   } else {
  //     createCampaign(isValid)
  //   }
  // };
  const handleNext = async () => {
    const isValid = validateNextStep();

    if (isValid) {
      if (activeStep === 5) { // Check if it's the last step
        if (isEdit) {
          updateCampaign();
          setSnackbarOpen(true);
          setSnackbarType("success");
          setSnackbarMessage(CAMPAIGN_UPDATE_SUCCESS);
          dispatch(setAdvCampData([]));
        } else if (isClone) {
          cloneCampaign(isValid);
          setSnackbarOpen(true);
          setSnackbarType("success");
          setSnackbarMessage(CAMPAIGN_UPDATE_SUCCESS);
          dispatch(setAdvCampData([]));
        } else {
          createCampaign(isValid);
          setSnackbarOpen(true);
          setSnackbarType("success");
          setSnackbarMessage(CAMPAIGN_UPDATE_SUCCESS);
        }
      } else {
        if (isEdit) {
          updateCampaign();
          dispatch(setAdvCampData([]));
        } else if (isClone) {
          cloneCampaign(isValid);
          dispatch(setAdvCampData([]));
        } else {
          createCampaign(isValid);
        }
      }
    }
  };


  const handleBack = () => {
    if (activeStep === 0) {
      navigate("/advertiser-campaigns");
    } else {
      // setActiveStep((prevActiveStep) => prevActiveStep - 1);
      dispatch(decreaseActiveStep(1))
    }
  };

  const validateNextStep = () => {
    //console.log("current state", currentState)
    const { campaignName, adCopy: { adDescription: { advertiserName, brandName, displayUrl, headContent } }, value: { gradYear: { startYear, endYear } } } = currentState
    const trackingUrl = currentState.value.trackingUrl
    let isValid = true;
    // || !displayUrl
    if (!campaignName || !advertiserName || !headContent || !trackingUrl || currentState.adCopy.adContent.trackingUrlError.length || campaignName.includes(',') || !startYear || !endYear || startYear < 1900 || endYear > 2050) {
      isValid = false
      if (!campaignName) {
        dispatch(storeCampaignNameError(CAMPAIGN_NAME_FIELD_ERROR))
      }
      if (campaignName.includes(',')) {
        setSnackbarMessage('Comma is not allowed in Advertiser Camp Name');
        setSnackbarOpen(true);
      }
      if (!advertiserName) {
        dispatch(storeAdvertiserNameError(ADVERTISER_NAME_NAME_ERROR))
      }

      // if (!displayUrl) {
      //   dispatch(storeDisplayURLError(DISPLAY_URL_FIELD_ERROR))
      // }
      if (!headContent) {
        dispatch(storeHeadContentError(HEAD_CONTENT_FIELD_ERROR))
      }
      if (!trackingUrl) {

        dispatch(storeTrackingURLError(TRACKING_URL_FIELD_ERROR))
      }
      if (activeStep == 2 && (startYear < 1900 || startYear > 2050)) {

        dispatch(storeStartYearError(START_YEAR_FIELD_ERROR))
      }
      if (activeStep == 2 && (endYear < 1900 || endYear > 2050)) {

        dispatch(storeEndYearError(END_YEAR_FIELD_ERROR))
      }
      if (activeStep == 2 && !startYear) {

        dispatch(storeStartYearError(START_YEAR_BLANK_ERROR))
      }
      if (activeStep == 2 && !endYear) {

        dispatch(storeEndYearError(END_YEAR_BLANK_ERROR))
      }
      if (currentState.adCopy.adContent.trackingUrlError.length > 1) {
        isValid = false
      }
      return isValid
    }
    return true
  }


  //console.log("POST/PUT response", responseValue)
  return (
    <Box>
      <Button
        // disabled={activeStep === 0} 
        onClick={handleBack}
        sx={{
          backgroundColor: "#FFF",
          border: "1px solid #0AE",
          boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
          borderRadius: "4px",
          color: "#00AAEE",
          width: "100px",
          height: "45px",
          paddingLeft: "16px",
          paddingRight: "16px",
          mr: 2
        }}
      >
        Back
      </Button>
      <Button onClick={handleNext}
        sx={{
          backgroundColor: isApiLoading ? "#fff" : "#0ae",

          "&.MuiButtonBase-root:hover": {
            bgcolor: isApiLoading ? "#fff" : "#1976d2 !important"
          },
          "&:hover": { backgroundColor: isApiLoading ? "#fff" : "#1976d2" },
          border: "1px solid #0ae",
          boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
          borderRadius: "4px",
          color: "#ffff",
          height: "45px",
          paddingLeft: "16px",
          paddingRight: "16px",
        }}
        disabled={isApiLoading}
      >
        {
          isApiLoading ? <CircularProgress color="inherit" size={24} /> :

            (activeStep === props.steps.length - 1) ? "Finish" : "Save & Next"
        }
      </Button>
      <SnackbarMessage open={snackbarOpen} onClose={
        () => {
          setSnackbarOpen(false)
          setApiLoading(false)
        }} type={snackbarType} message={snackbarMessage} />

    </Box>
  )
}

export default StepButton
