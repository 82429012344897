import React, { useState } from 'react'
import { Box, Button, CardMedia, Chip, FormControl, Grid, Input, InputLabel, MenuItem, Paper, Select, TextField, Tooltip, Typography, IconButton } from '@mui/material'
import ImportExportIcon from '@mui/icons-material/ImportExport';
import BasicDialog from '../../../Component/Dialog/BasicDialog';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DownloadIcon from '@mui/icons-material/Download';
import Papa from 'papaparse';
import { useAppDispatch, useAppSelector } from '../../../Store/hooks';
import { setFileData, setFileName, setRadioOption, setTempData , cleanUp} from '../../../Store/Slices/globalUploadCMS';
import { getCMSfileData, updateWebSiteContent } from '../../../Services/seoApis';
import SnackbarMessage from '../../../Component/Snackbar';
import {updateSEOandBannerContent} from '../../../Services/seoApis';
import { setDialogOpen } from '../../../Store/Slices/globalUploadCMS';
export const customHeaders = [
  { label: "website domain", key: "websiteDomainName" },
  { label: "areaStudy", key: "id" },
  { label: "areaStudy Name", key: "Area Study" },
  { label: "mProgram", key: "mProgramID" },
  { label: "mProgram Name", key: "mPrograms" },
  { label: "SEO Title", key: "seoTitle" },

  // { label: "SEO Canonical URL", key: "seoCanonicalUrl" },
  { label: "SEO Description", key: "seoDescription" },
  { label: "SEO Keywords", key: "seoKeywords" },
  // { label: "OG: Type", key: "ogType" },
  { label: "OG: Title", key: "ogTitle" },
  // { label: "OG: Locale", key: "ogLocale" },
  { key: "bannerTitle_1", label: "Banner Title 1", },
  { key: "bannerTitle_2", label: "Banner Title 2", },
  { key: "bannerTitle_3", label: "Banner Title 3", },
  // {key:'Status', label:"Status"}
];
interface fileData{
  "websiteDomainName": string,
  "id": string,
  "Area Study":string,
  "AreaStudy"?:string;
  "mProgramID": string,
  "mPrograms": string,
  "seoTitle": string,
  "seoCanonicalUrl":string,
  "seoDescription": string,
  "seoKeywords": string,
  "ogTitle": string,
  //  "ogLocale": string,
  //  "ogType":string,
  "bannerTitle_1": string,
  "bannerTitle_2": string,
  "bannerTitle_3": string,
  // "Status":string
}

const GlobalUploadCMSController = (props: any) => {
  const [isLoading, setIsLoading] = useState(false);
 
  const dispatch = useAppDispatch()
  const websiteInfo = useAppSelector(state => state.globalUploadCMSContent);
  const [isDialogOpen, setIsDialogOpen] = useState(websiteInfo.globalModalOpen); 
  // console.log("website Info", websiteInfo)
  const [snackbarOpen,setSnackbarOpen]=useState(false)
  const [snackbarType, setSnackbarType]=useState('success')
  const [snackbarMessage , setSnackbarMessage]=useState('')
  const handleRadioChange = (value: string) => {
    // setSelectedOption(value);
    dispatch(setRadioOption(value));
  };

  // console.log()
  const { selectedOption , fileData,fileName} = websiteInfo.csvContent
  const handleSave = async () => {
    setIsLoading(true);
    let payload:any={...websiteInfo.selectedData}

let updatePayload:any[]=[]

if(selectedOption==='remove'){
  payload.csvContent={
    fileName:"",
    fileData:[],
    selectedOption:'remove'
  }
}else{
  updatePayload=fileData.map((item:fileData)=>{
    return{
  "websiteDomainName": item.websiteDomainName,
      "areaStudy": item.id,
      "mProgram": item.mProgramID,
      "seoTitle": item.seoTitle,
      "seoCanonicalUrl": item.seoCanonicalUrl?item.seoCanonicalUrl:'',
      "seoDescription": item.seoDescription,
      "seoKeywords": item.seoKeywords.split(","),
      // "ogType": item.ogType?item.ogType:'',
      // "ogLocale": item.ogLocale?item.ogLocale:'',
      "ogTitle": item.ogTitle,
      "bannerTitle_1": item.bannerTitle_1,
      "bannerTitle_2": item.bannerTitle_2,
      "bannerTitle_3": item.bannerTitle_3
    }
  })
  payload.csvContent={
    fileName:fileName,
    fileData:fileData,
    selectedOption:selectedOption
  }
try{
  const response=await updateSEOandBannerContent(updatePayload)
  setIsLoading(false);
  setSnackbarOpen(true)
  setSnackbarType('success')
  setSnackbarMessage('Successfully updated the content ')
  dispatch(setDialogOpen(false))
  dispatch(cleanUp({}))
  props.reload(true);

}catch(error:any){
  console.log(error)
       setIsLoading(false);
      setSnackbarOpen(true)
      setSnackbarType('error')
      dispatch(setDialogOpen(false))
      setSnackbarMessage(error.message)
      return error;
} 


}

  };

  const handleCancel = (event:any, reason:string) => {
    if (reason !== 'backdropClick') {
     dispatch(cleanUp({}))
     setIsDialogOpen(false);
     dispatch(setDialogOpen(false))
    }
   };
  // const handleExpo = () => {
  //   setIsDialogOpen(true);
  //   console.log("props,rowData::::cms", props.rowData)
  //   dispatch(setTempData(props.rowData))
  // }

 
  const handleClose=()=>{
    dispatch(cleanUp({}))
    setIsDialogOpen(false);
    dispatch(setDialogOpen(false))
  }
  return (
    <React.Fragment>
      {/* <Tooltip title={"upload File"}>
        <ImportExportIcon color={"primary"} onClick={handleExpo} />
      </Tooltip> */}

      <BasicDialog
        isLoading={isLoading}
        open={websiteInfo.globalModalOpen}
        cancelHandler={handleCancel}
        radioValue={selectedOption}
        radioChangeHandler={handleRadioChange}
        submitHandler={handleSave}
        dialogTitle={"Upload CMS Content CSV"}
        children={UploadCMS}
        closeHandler={handleClose}
      />

<SnackbarMessage 
open={snackbarOpen}
onClose={()=>setSnackbarOpen(false)}
type={snackbarType}
message={snackbarMessage}
/>

    </React.Fragment>
  )
}

export default GlobalUploadCMSController

const UploadCMS = () => {
  // const [selectedFile, setSelectedFile] = useState<null | any>(null)
  const [csvData, setCSVData] = useState<null | any>()
  const dispatch = useAppDispatch();
  const websiteInfo = useAppSelector(state => state.globalUploadCMSContent);
 console.log("website Info", websiteInfo)
  const { selectedOption, fileName, fileData } = websiteInfo.csvContent


  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      // setSelectedFile(file.name)

      dispatch(setFileName(file.name))
      Papa.parse(file, {
        complete: (result: any) => {
          console.log("result", result)
          const transformedData = result.data.map((item: any) => {
            const transformedItem: any = {};

            customHeaders.forEach(header => {
              // Map each key from customHeaders to corresponding key in item
              transformedItem[header.key] = item[header.label];
            });
            return transformedItem;
          });
          //  console.log("trans Formed data", transformedData)
          setCSVData(transformedData)
          dispatch(setFileData(transformedData))

        },
        header: true,
        skipEmptyLines: true
      })
    }
  }

console.log("fileName", fileName)
  const handleDownload=async()=>{
    //  console.log("File data",fileData)
    // We need to make API call and get data parse it to the JSON. 
    // if(websiteInfo.selectedData?.websiteDomainName){
    //   const qp={
  
    //     websiteDomainName:websiteInfo.selectedData?.websiteDomainName
    //     }
    // }

   const data=await getCMSfileData({})
   console.log("data",data)
     let file_Data:any[];
     if(data.data.length>0){
    file_Data= data.data.map((item:fileData)=>{
        return{
          "website domain":item["websiteDomainName"] ,
         "areaStudy":item.id,
         "areaStudy Name":item["AreaStudy"],
         "mProgram":item.mProgramID,
         "mProgram Name":item.mPrograms,
         "SEO Title":item.seoTitle,
        //   "SEO Canonical URL":item.seoCanonicalUrl,
          "SEO Description":item.seoDescription,
          "SEO Keywords":item.seoKeywords,
        //   "OG: Type":item.ogType,
         "OG: Title":item.ogTitle,
        //   "OG: Locale":item.ogLocale,
  "Banner Title 1":item.bannerTitle_1,
  "Banner Title 2":item.bannerTitle_2,
  "Banner Title 3":item.bannerTitle_3
        }
         
       })
     }else{
      file_Data=[]
     }
   
     var csv
     csv=Papa.unparse({
           fields:customHeaders.map((item)=>item.label),
           data:file_Data
        })
     
     const blob = new Blob([csv], { type: 'text/csv' });
     const link = document.createElement('a');
     link.href = URL.createObjectURL(blob);
     link.download = `$global-cms-program-config.csv`;
     link.click();

  }
  return (
    <>
      <Box className='upload-container' sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: "20px" }}>
        <Box className='upload-btn-container'   >
          <label htmlFor="file-input">
            <IconButton
              color="primary"
              aria-label="upload file"
              component="span"
              disabled={(selectedOption === 'remove')}
            >

              <AttachFileIcon style={{ marginTop: "19px", fontSize: '30px' }} />
            </IconButton>
          </label>
          <input
            type="file"
            accept=".csv"
            id="file-input"
            style={{ display: "none" }}
            onChange={handleFileUpload}
          />
          <TextField
            // style={{ width: "70%" }}
            id="standard-helperText"
            label={fileName ? "" : "Upload CSV"}
            helperText="Select  files"
            variant="standard"
            value={fileName}
            disabled={(selectedOption === 'remove')}
          />
        </Box>
        <Button
          title={fileName ? "Download last uploaded file" : "Sample file"}
          onClick={handleDownload}
          disabled={(selectedOption === 'remove')}
          
        >
          <DownloadIcon style={{ marginTop: "13px", fontSize: '40px' }} />

        </Button>
      </Box>
    </>
  )
}