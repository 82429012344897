import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { RootState } from '../../Store/index'; // Update the path to your Redux store
import './PublisherList.css'
import { Button, Checkbox, Chip, CircularProgress, FormControl, FormGroup, FormHelperText, IconButton, InputLabel, ListItemText, MenuItem, Paper, Radio, Select, Snackbar, TableCell, Tooltip, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import MMTable from '../../Component/Table/MMTable';
import { publiAccountHeadCells } from '../../Component/Table/tableInterface';
import Search from '../../Component/Search/Search';
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Switch, TextField } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import { toggleReportingEmail, setField, toggleStatus, resetForm, setEditFlag, toggleVertical, setRowData } from '../../Store/Slices/CreatePublisherSlice';
import { Box } from '@mui/system';
import { useAppDispatch, useAppSelector } from "../../Store/hooks"
import { setData, setLoading, setCurrentCursor, setHasMoreData, appendData, setFlag, setFullData, updatePublisherAsync, createPublisherAsync } from '../../Store/Slices/PublisherTabApisData';
import { fetchCompApiData, fetchPublisherApiData, filterByPublisherSearch, searchPublisherAccount } from '../../Services/publisherTabApis';
import MuiAlert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import { DeleteIconPop } from '../Advertiser/Action';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { setBlockScroll, setIsoLoading, setNoDataFlag, setScrollNext, setSearchFilterData, setSearchInput } from '../../Store/Slices/advertiserTabApisData';
import { fetchVerticals } from '../../Services/verticalList';
import { resetFilters, setFields } from '../../Store/Slices/PublisherCampaignSlice';
import SnackbarMessage from '../../Component/Snackbar';
import { fetchMediaPartner } from '../../Services/mediaPartnerList';

const PublisherAccount = () => {
  return (
    <PublisherDashboard />
  )
}

export default PublisherAccount

const PublisherDashboard = () => {
  const dispatch = useAppDispatch();
  const publisherTableData = useAppSelector((state) => state.publisherAccApi.data);
  const compApiData = useAppSelector((state) => state.publisherAccApi.compApiData);
  const isLoading = useAppSelector((state) => state.publisherAccApi.loading);
  const currentCursor = useAppSelector((state) => state.publisherAccApi.currentCursor);
  const hasMoreData = useAppSelector((state) => state.publisherAccApi.hasMoreData);
  const [openPopup, setOpenPopup] = React.useState(false);
  const [VerticalMouseOverDelete, setVerticalMouseOverDelete] = useState(false);
  const campaignState = useAppSelector((state: RootState) => state.campaign);
  const { verticalFilter } = campaignState
  // const [searchInput, setSearchInput] = useState<string>('');
  const searchInput = useAppSelector((state) => state.advertiserAccApi.searchInput);
  const [debouncedInputValue, setDebouncedInputValue] = useState<string>('');
  const { verticalList, loading, error } = useSelector((state: RootState) => state.verticals);
  const verticalOptions = verticalList.map((item) => ({
    id: item.verticalID,
    name: item.verticalName,
  }));

  useEffect(() => {
    return () => {
      dispatch(resetFilters());
      dispatch(setSearchInput(''));
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(setFlag(false));

    if (!searchInput && verticalFilter.length === 0) {
      dispatch(setBlockScroll(false));
      dispatch(setSearchInput(''));
      dispatch(setScrollNext(false));
      dispatch(setNoDataFlag(false));
      setDebouncedInputValue('');
      fetchInitialData();
    } else if (verticalFilter.length !== 0 && searchInput === '') {
      const debounceTimer = setTimeout(() => {
        const payload = { vertical: verticalFilter };
        filterByPublisherSearch(payload, dispatch);
      }, 1000);
      return () => clearTimeout(debounceTimer);
    } else {
      const debounceTimer = setTimeout(() => {
        setDebouncedInputValue(searchInput);
      }, 600);
      return () => clearTimeout(debounceTimer);
    }
  }, [dispatch, searchInput, verticalFilter,]);


  useEffect(() => {
    dispatch(fetchVerticals())
    dispatch(setSearchFilterData([]));
    dispatch(setSearchInput(''));
    fetchCompApiData(dispatch);
    dispatch(fetchMediaPartner())
  }, [])

  useEffect(() => {
    if (debouncedInputValue !== '' && debouncedInputValue !== null) {
      // searchAdvAccount(debouncedInputValue, dispatch, 'advertiser')
      searchPublisherAccount(debouncedInputValue, dispatch, 'publisher')
    }
  }, [debouncedInputValue])

  const fetchInitialData = () => {
    fetchPublisherApiData(dispatch, null).then(() => {
      dispatch(setScrollNext(true));
    });
  };

  const fetchMoreData = () => {
    if (!isLoading && hasMoreData && searchInput === '') {
      dispatch(setFlag(true));
      fetchPublisherApiData(dispatch, currentCursor);
    }
  };

  const handelSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    //console.log("event", event.target.value)
    // setSearchInput(event.target.value)
    dispatch(setSearchInput(event.target.value));
  }
  const handleFilterSearch = () => {
    dispatch(setSearchInput(''));
  }
  const handleFieldChange = (field: keyof typeof campaignState, value: any) => {
    dispatch(setFields({ field, value }));
  };

  const handleRemoveVertical = (id: string) => {
    const newVerticalFilter = verticalFilter.filter((verticalId) => verticalId !== id);
    handleFieldChange("verticalFilter", newVerticalFilter);
    setVerticalMouseOverDelete(false);
  };

  return (
    <React.Fragment>
      <Paper elevation={3} className="card">
        <Box display="flex" justifyContent="space-between" p={3} sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
          <Box>
            <Typography className="card-heading">Publisher List</Typography>
            <Typography className="card-sub-heading">
              Publisher Summary
            </Typography>
          </Box>
          <Box display="flex" gap="16px" sx={{ flexDirection: { xs: 'column', sm: 'row' }, mt: { xs: '16px', md: '0' }, alignItems: { xs: 'flex-start', md: 'center' } }}>
            <Search value={searchInput} searchHandler={handelSearch} />
            <Button variant="contained"
              startIcon={<AddIcon />}
              onClick={() => {
                dispatch(setEditFlag(false))
                setOpenPopup(true);
                dispatch(fetchVerticals())

                dispatch(setSearchInput(''));
              }}
              sx={{ backgroundColor: "#0ae" }}
            >
              Create Publisher
            </Button>
          </Box>

        </Box>
        <Box display="flex" gap="8px">
          <FormControl style={{ width: '98%', marginBottom: '0.4%', paddingLeft: '1%' }}>
            <InputLabel style={{ paddingLeft: '19px' }}>Filter by Vertical</InputLabel>
            <Select
              label="Filter by Vertical"
              multiple
              value={verticalFilter}
              onChange={(e) => {
                const selectedIDs = e.target.value as string[];
                handleFilterSearch();
                handleFieldChange("verticalFilter", selectedIDs);
              }}
              renderValue={(selected: any) => {
                const maxVisibleChips = 4;
                const displayed = selected.slice(0, maxVisibleChips);
                const hiddenCount = selected.length - maxVisibleChips;

                return (
                  <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                    {displayed.map((id: string) => {
                      const option = verticalOptions.find((opt) => opt.id === id);
                      return (
                        <Chip
                          key={id}
                          label={option?.name}
                          onDelete={() => handleRemoveVertical(id)}
                          deleteIcon={
                            <IconButton
                              size="small"
                              onMouseEnter={() => setVerticalMouseOverDelete(true)}
                              onMouseLeave={() => setVerticalMouseOverDelete(false)}
                            >
                              <CloseIcon fontSize="small" />
                            </IconButton>
                          }
                          style={{ margin: "2px" }}
                        />
                      );
                    })}
                    {hiddenCount > 0 && (
                      <Chip
                        label={`+${hiddenCount} more`}
                        style={{ margin: "2px", backgroundColor: "#e0e0e0", cursor: "pointer" }}
                      />
                    )}
                  </div>
                );
              }}
              style={{ height: "50px" }}
              MenuProps={{
                style: {
                  maxHeight: "400px",
                  marginTop: "5px",
                },
              }}
              disabled={VerticalMouseOverDelete}
            >
              {verticalOptions.map((vertical) => (
                <MenuItem key={vertical.id} value={vertical.id}>
                  <Checkbox checked={verticalFilter.includes(vertical.id)} />
                  <ListItemText primary={vertical.name} />
                </MenuItem>
              ))}
            </Select>

          </FormControl>

        </Box>
        <MMTable tableData={publisherTableData} tableHeadCell={publiAccountHeadCells} isLoadingMore={isLoading}
          fetchMoreData={() => fetchMoreData()} hasMoreData={hasMoreData} fetchInitialData={fetchInitialData}
        />
      </Paper>
      <CreatePublisher
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        fetchInitialData={fetchInitialData}
      />
    </React.Fragment>
  )
}


export const CreatePublisher: React.FC<any> = (props) => {

  const { openPopup, setOpenPopup, advDialHeader, setAdvDialHeader, fetchInitialData } = props;

  const [nameCharacterCount, setNameCharacterCount] = useState(0);
  const [descriptionCharacterCount, setDescriptionCharacterCount] = useState(0);
  const [addressCharacterCount, setAddressCharacterCount] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSnackbarOpen, setSnackbarOpen] = useState(false);
  const [publisherNameError, setPublisherNameError] = useState(false);
  const [publisherEmailError, setPublisherEmailError] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const fullData = useAppSelector((state) => state.publisherAccApi.fullData);
  const searchInput = useAppSelector((state) => state.advertiserAccApi.searchInput);
  const [emailTouched, setEmailTouched] = useState(false);
  const dispatch = useAppDispatch();
  const [selectedVertical, setSelectedVertical] = useState<any>([]);
  const publisherState = useSelector((state: RootState) => state.publisher);
  console.log("publisherState", publisherState)
  const rowData = publisherState.rowData;
  const { mediaPartnerList } = useSelector((state: RootState) => state.mediaPartner);

  const toggleReportingEmailHandler = () => {
    dispatch(toggleReportingEmail());

  };
  console.log("rowData:::props", rowData)
  // console.log("rowData:::redux store", rowData1)
  console.log("mediaPartnerList", mediaPartnerList)
  const [selectedMediaPartner, setSelectedMediaPartner] = React.useState('');
  const [mediaPartnerError, setMediaPartnerError] = React.useState(false);


  const handleMediaPartnerChange = (event: any) => {
    setSelectedMediaPartner(event.target.value as string);

    setMediaPartnerError(false);
  };

  const { verticalList, loading, error } = useSelector((state: RootState) => state.verticals);

  const handleFieldChange = useCallback((field: keyof typeof publisherState, value: string) => {
    let newValue = value;

    switch (field) {
      case 'publisherName':
        newValue = value.slice(0, 100);
        setNameCharacterCount(newValue.length);
        break;
      case 'companyDescription':
        newValue = value.slice(0, 400);
        setDescriptionCharacterCount(newValue.length);
        break;
      case 'address':
        newValue = value.slice(0, 300);
        setAddressCharacterCount(newValue.length);
        break;
      // ... (other cases)
      default:
        break;
    }
    dispatch(setField({ field, value: newValue }));
  }, [dispatch]);

  const isPublisherNameExists = fullData.some(
    (data: any) => data.publisherName === publisherState.publisherName
  );

  console.log("Selected media partner", selectedMediaPartner)
  const handleCreatePublisher = async () => {
    setSuccessMessage('');
    if (!publisherState.publisherName.trim() || ((publisherState.enablePublisherEmail == true) && (!publisherState.publisherEmail.trim()))) {
      setPublisherNameError(!publisherState.publisherName.trim());
      if (publisherState.enablePublisherEmail == true) {
        setPublisherEmailError(!publisherState.publisherEmail.trim());
      }
      return;
    } else if (selectedMediaPartner === '') {
      setMediaPartnerError(true);
      return;
    } else if (publisherState.publisherName.includes(',')) {
      setErrorMessage('Comma is not allowed in Publisher Name');
      setSnackbarOpen(true);
      return;
    }
    else if (publisherState.vertical.length === 0) {
      setErrorMessage('Please select vertical');
      setSnackbarOpen(true);
      return;
    }
    else {
      if (isPublisherNameExists && publisherState.editFlag == false) {
        setErrorMessage('Publisher with this name already exists');
        setSnackbarOpen(true);
        return;
      }

      setOpenPopup(false);
      dispatch(setIsoLoading(true));
      setPublisherNameError(false);
      setPublisherEmailError(false);

      const payload = {
        GroupName: publisherState.publisherName,
        status: publisherState.status,
        type: 'Publisher',
        partnerName: selectedMediaPartner,
        values: {
          address: publisherState.address,
          companyName: publisherState.publisherName,
          enablePublisherEmail: publisherState.enablePublisherEmail,
          publisherEmail: publisherState.publisherEmail,
        },
        vertical: verticalList
          .filter(vertical =>
            Array.isArray(publisherState.vertical)
              ? publisherState.vertical.includes(vertical.verticalID)
              : vertical.verticalID === publisherState.vertical
          )
          .map(vertical => ({
            verticalID: vertical.verticalID,
            verticalName: vertical.verticalName
          }))

      };
      let requestData
      if (rowData) {
        requestData = {
          GroupName: publisherState.publisherName,
          ID: rowData.ID,
          SORTID: rowData.SORTID,
          createdOn: rowData.createdOn,
          mPubID: rowData.mPubID,
          status: publisherState.status,
          partnerName: selectedMediaPartner,
          "oldPartnerName": publisherState.oldPartnerName,
          type: 'Publisher',
          values: {
            excludeMpartners: rowData.values.excludeMpartners ? rowData.values.excludeMpartners : {},
            address: publisherState.address,
            companyName: publisherState.publisherName,
            enablePublisherEmail: publisherState.enablePublisherEmail,
            publisherEmail: publisherState.publisherEmail,

          },
          vertical: verticalList
            .filter(vertical => publisherState.vertical.includes(vertical.verticalID))
            .map(vertical => ({
              verticalID: vertical.verticalID,
              verticalName: vertical.verticalName
            }))


        };
      }
      try {

        if (publisherState.editFlag) {
          try {
            // For editing an existing record, send a PUT request with the ID
            console.log("payload :::: publisher edit", requestData)
            await dispatch(updatePublisherAsync(requestData));
            // setSnackbarOpen(true)
            setSuccessMessage('Publisher Updated Successfully');

          } catch (error) {
            console.error('Error updating Publisher:', error);
          }

        } else {
          try {
            // For creating a new record, send a POST request
            await dispatch(createPublisherAsync(payload));
            setSuccessMessage('Publisher Created Successfully');

            fetchPublisherApiData(dispatch, null);
            fetchCompApiData(dispatch);
            fetchInitialData();
            dispatch(resetFilters())
            dispatch(setEditFlag(false))

          } catch (error) {
            console.error('Error Creating Publisher:', error);
          }
        }

        dispatch(setFlag(false));
        setSnackbarOpen(true);
        // fetchInitialData();
        fetchCompApiData(dispatch);
        // if (searchInput !== '' && searchInput !== null) {
        //   searchPublisherAccount(searchInput, dispatch, 'publisher')
        // }
      } catch (error) {
        console.error('Error:', error);
        setErrorMessage('Failed to create publisher');
        setSnackbarOpen(false);
      }
      dispatch(resetForm());
      // //console.log(publisherState);
      setAddressCharacterCount(0);
      setDescriptionCharacterCount(0);
      setNameCharacterCount(0);
      dispatch(setIsoLoading(false));
      // dispatch(setSearchInput(''));
      setTimeout(() => {
        dispatch(setSearchInput(''));
        dispatch(resetFilters())
      }, 1000);
    }

  };
  const cancelButton = () => {
    setSelectedMediaPartner('')
    setOpenPopup(false);
    dispatch(setEditFlag(false))
    dispatch(resetForm());
    setPublisherNameError(false);
    setPublisherEmailError(false);
    setAddressCharacterCount(0);
    setDescriptionCharacterCount(0);
    setNameCharacterCount(0);
    dispatch(setSearchInput(''));
  }

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const isValidEmail = (email: any) => {
    // Regular expression for a simple email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleRadioChange = (value: string) => {
    setSelectedVertical(value);
    console.log('value', value)

    dispatch(toggleVertical(value));
    console.log('publisherState.vertical[0]', publisherState.vertical[0])
  };
  useEffect(() => {
    setSelectedMediaPartner('');
    if (publisherState.editFlag) {
      if (publisherState?.partnerName) {
        setSelectedMediaPartner(publisherState.partnerName);
      }
    }
  }, [publisherState.partnerName]);

  return (
    <div>
      <Dialog fullWidth maxWidth="lg" open={openPopup} BackdropProps={{ style: advDialHeader ? { opacity: '0.2' } : { opacity: '1' } }}>
        <DialogTitle>{publisherState.editFlag ? "Update" : 'Create'} Publisher</DialogTitle>
        <div className="close-button" onClick={cancelButton}><CloseIcon style={{ fontSize: '32px' }} /></div>
        <DialogContent className='Update-user'>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <Grid item xs={12} lg={12} style={{ marginBottom: '20px' }}>
                <TextField label="Publisher Name*" fullWidth
                  disabled={publisherState.editFlag}
                  value={publisherState.publisherName}
                  onChange={(e) => {
                    setPublisherNameError(false);
                    handleFieldChange('publisherName', e.target.value)
                  }}
                  error={publisherNameError}
                  helperText={publisherNameError ? 'Publisher Name is mandatory' : ''} />
                {/* <div style={{ textAlign: 'right', marginTop: '4px', color: nameCharacterCount > 100 ? 'red' : 'inherit' }}>
                  {nameCharacterCount}/100
                </div> */}
              </Grid>
              <Grid item xs={12} lg={12} style={{ marginBottom: '20px' }}>
                <FormControl fullWidth error={mediaPartnerError}>
                  <InputLabel>Select Media Partner</InputLabel>
                  <Select
                    label="Select Media Partner"
                    value={selectedMediaPartner}
                    onChange={handleMediaPartnerChange}
                  >
                    {mediaPartnerList?.map((partner: any) => (
                      <MenuItem key={partner.ID} value={partner.ID}>
                        {partner.partnerName}
                      </MenuItem>
                    ))}
                  </Select>
                  {mediaPartnerError && (
                    <FormHelperText>Please select a media partner</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={12}>
                <TextField label="Reporting Email*" fullWidth
                  value={publisherState.publisherEmail}
                  onChange={(e) => {
                    setPublisherEmailError(false);
                    handleFieldChange('publisherEmail', e.target.value);
                    setEmailTouched(true);
                  }}
                  disabled={!publisherState.enablePublisherEmail}
                  error={publisherEmailError}
                  helperText={
                    publisherEmailError
                      ? 'mail is mandatory'
                      : publisherState.enablePublisherEmail &&
                        emailTouched &&
                        publisherState.publisherEmail.trim() !== '' &&
                        !isValidEmail(publisherState.publisherEmail)
                        ? (
                          <span style={{ color: 'red' }}>Invalid Email Format</span>
                        ) : ''
                  }
                />
              </Grid>

              <Grid item xs={12} lg={12} style={{ textAlign: 'end' }}>
                <FormControlLabel
                  control={<Switch checked={publisherState.enablePublisherEmail} onChange={toggleReportingEmailHandler} />}
                  style={{ marginBottom: "10px", marginRight: '0' }}
                  label={publisherState.enablePublisherEmail ? 'Enable Email' : 'Disable Email'}
                  labelPlacement="end"
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <TextField label="Publisher Address" fullWidth variant="outlined" rows={4} multiline
                  value={publisherState.address}
                  onChange={(e) => handleFieldChange('address', e.target.value)} />
                {/* <div style={{ textAlign: 'right', marginTop: '4px', color: addressCharacterCount > 300 ? 'red' : 'inherit' }}>
                  {addressCharacterCount}/300
                </div> */}
              </Grid>
              <Grid item lg={12} xs={12} style={{ textAlign: 'end' }}>
                <FormControlLabel
                  style={{ marginRight: '0' }}
                  label="Status"
                  labelPlacement="end" // You can change this to "start", "top", or "bottom" as needed
                  control={<Switch checked={publisherState.status === 'Active'} onChange={() => dispatch(toggleStatus())} />}
                />

              </Grid>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Paper className='card' elevation={3} style={{ padding: "16px" }}>
                <Grid item xs={12} lg={12}>
                  <FormGroup>
                    <label className='card-heading' style={{ fontWeight: 'bold', textAlign: 'center' }}>Verticals</label>
                    {loading ? (
                      <Box sx={{ display: 'flex', justifyContent: "center", alignItems: 'center', minHeight: '50px' }}>
                        <CircularProgress color="inherit" size={24} />
                        <Typography className="card-sub-heading" sx={{ paddingLeft: '20px' }}>
                          We are fetching Verticals, please wait
                        </Typography>
                      </Box>
                    ) : verticalList?.map((vertical) => {
                      const isChecked =
                        publisherState.editFlag && publisherState.vertical.length > 1
                          ? publisherState.vertical[1] === vertical.verticalID
                          : publisherState.editFlag && publisherState.vertical.length === 1
                            ? publisherState.vertical[0] === vertical.verticalID
                            : publisherState.vertical === vertical.verticalID;

                      return (
                        <FormControlLabel
                          key={vertical.verticalID}
                          control={<Radio disabled={publisherState.editFlag} checked={isChecked} onChange={() => handleRadioChange(vertical.verticalID)} />}
                          label={vertical.verticalName}
                        />
                      );
                    })}




                  </FormGroup>
                </Grid>
              </Paper>
            </Grid>
            {/* <Grid item xs={12} lg={6}>
              <TextField label="Contact Person Name" fullWidth
                value={publisherState.primaryContact}
                onChange={(e) => handleFieldChange('primaryContact', e.target.value)} />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextField label="City" fullWidth
                value={publisherState.city}
                onChange={(e) => handleFieldChange('city', e.target.value)} />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextField label="Phone Number" fullWidth
                value={publisherState.phNo}
                onChange={(e) => handleFieldChange('phNo', e.target.value)} />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextField label="State" fullWidth
                value={publisherState.state}
                onChange={(e) => handleFieldChange('state', e.target.value)} />
            </Grid>
            <Grid item xs={12}>
              <TextField label="Website" fullWidth
                value={publisherState.website}
                onChange={(e) => handleFieldChange('website', e.target.value)} />
            </Grid> */}

          </Grid>
        </DialogContent >
        <DialogActions style={{ paddingBottom: "15px", paddingRight: "20px" }}>
          <Button onClick={cancelButton} style={{ color: 'red' }}>CANCEL</Button>
          <Button variant="contained" color="primary" onClick={handleCreatePublisher}
            disabled={
              !publisherState.enablePublisherEmail
                ? false
                : !isValidEmail(publisherState.publisherEmail)
            }
          >{publisherState.editFlag ? "UPDATE" : 'CREATE'}</Button>
        </DialogActions>
      </Dialog>
      {/* <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isSnackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackbar}
          severity={successMessage ? 'success' : 'error'}
        >
          {successMessage || errorMessage}
        </MuiAlert>
      </Snackbar> */}
      <SnackbarMessage open={isSnackbarOpen} message={successMessage || errorMessage} type={successMessage ? "success" : "error"} onClose={handleCloseSnackbar} />
    </div>
  )
}

export const PubActionIcons = (props: any) => {
  const { PubID, fetchInitialData } = props;
  const [openPopup, setOpenPopup] = React.useState(false);
  const dispatch = useAppDispatch();
  const fullData = useAppSelector((state) => state.publisherAccApi.fullData);
  const [openDeletePopup, setDeletePopup] = React.useState(false);
  const publisherState = useAppSelector((state: RootState) => state.publisher);


  const getRowDataById = (pubId: string) => {
    return fullData.find((row: any) => row.mPubID === pubId);
  };
  const rowData = getRowDataById(PubID);

  const editFetch = () => {
    if (publisherState.editFlag && rowData) {
      const fieldsToUpdate = [
        'address',
        // 'city',
        // 'companyDescription',
        // 'enablePublisherEmail',
        // 'phNo',
        // 'primaryContact',
        'publisherEmail',
        // 'state',
        // 'website',
      ];

      fieldsToUpdate.forEach((field: any) => {
        dispatch(setField({ field, value: rowData.values?.[field] || '' }));
      });
      dispatch(setField({ field: "partnerName", value: rowData.partnerName ? rowData.partnerName : '' }))
      dispatch(setField({ field: "oldPartnerName", value: rowData.partnerName ? rowData.partnerName : '' }))
      dispatch(setRowData(rowData))
      dispatch(setField({ field: 'enablePublisherEmail', value: rowData.values?.enablePublisherEmail || false }));
      dispatch(setField({ field: 'publisherName', value: rowData.publisherName || '' }));
      dispatch(setField({ field: 'status', value: rowData.status || '' }));
      // Map vertical IDs from rowData to publisherState.vertical
      const selectedVerticalIds = rowData.vertical?.map((v: any) => v.verticalID) || [];
      dispatch(setField({ field: 'vertical', value: selectedVerticalIds }));
    }

  };

  const handleEditButtonClick = () => {
    dispatch(setEditFlag(true))
    if (publisherState.editFlag) {
      setOpenPopup(true);
      editFetch();
    }
  };

  const fetchData = () => {
    fetchPublisherApiData(dispatch, null);
    fetchCompApiData(dispatch);
  };

  let delPayload: { ID: any; mPubID: any; }
  delPayload = {
    ID: rowData?.ID,
    mPubID: rowData?.mPubID,
  }


  return (
    <div>
      <Tooltip title="Edit">
        <EditIcon onClick={handleEditButtonClick} color="primary" /></Tooltip>
      <Tooltip title="Delete"><DeleteIcon onClick={() => setDeletePopup(true)} color="error" /></Tooltip>
      <CreatePublisher openPopup={openPopup} setOpenPopup={setOpenPopup}
        rowData={rowData} fetchInitialData={fetchInitialData}
      />
      <DeleteIconPop
        openDeletePopup={openDeletePopup}
        setDeletePopup={setDeletePopup}
        rowData={rowData}
        delPayload={delPayload}
        fetchData={fetchData}
      />
    </div>
  )
}


