import { generateHeader } from "../Auth";
import { baseURLConfig } from '../../config/envConfig';
import axios from 'axios';
import AWS from 'aws-sdk';
export const fetchMediaPartnersData = async () => {
  const baseURL = baseURLConfig.baseURl
  const headers = await generateHeader();
  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: baseURL + `/getMediaPartners?ID=MediaPartner`,
    headers
  };
  const res = await axios.request(config)
  return res.data
}

export const createMediaPartner = async (params: any) => {
  const baseURL = baseURLConfig.baseURl;
  const headers = await generateHeader();

  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: baseURL + `/media-partner`,
    headers,
    data: params,
  };

  try {
    const res = await axios.request(config);
    
    // Handle success and return appropriate message
    // if (res.data.success) {
    //   return { success: true, message: 'Media Partner Created Successfully' };
    // } else {
    //   return { success: false, message: res.data.message || 'Failed to create Media Partner' };
    // }
    return { success: true, message: 'Media Partner Created Successfully' };
  } catch (err:any) {
    // Handle errors, including network errors, and return appropriate message
    console.error("Error in API request:", err);
    
    const errorMessage = err.response?.data?.error || 'Error occurred while creating media partner';
    return { success: false, message: errorMessage };
  }
};

export const deleteMediaPartner = async (params: any) => {

  const baseURL = baseURLConfig.baseURl
  const headers = await generateHeader();
  let config = {
    method: 'delete',
    maxBodyLength: Infinity,
    url: `${baseURL}/mediaPartner?ID=${params}`,
    headers: headers
  };


  await axios.request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
    })
    .catch((error) => {
      console.log(error);
    });


}
