import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Box } from '@mui/material';


interface basicOptions{
    label:string,
    value:string,
    status?:string
}
interface IbasicSelect{
    value:string,
    setValue:(value:string)=>void;
menuOptions:basicOptions[],
 selectLabel:string,
 isDisable?:boolean
}
export default function BasicSelect(props:IbasicSelect) {
    return (
        <FormControl fullWidth disabled={props.isDisable}>
          <InputLabel id="demo-simple-select-label">{props.selectLabel}</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={props.value}
             label={props.selectLabel}
            onChange={(event)=>{  props.setValue(event.target.value as string)}}
          >
            {
                props.menuOptions.map((item:basicOptions)=>{
                  return (
                    <MenuItem value={item.value}  >{item.label}</MenuItem>
                  )
                })
            }
           
           
          </Select>
        </FormControl>
    );
  }