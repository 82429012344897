import React, { useState, useEffect } from 'react'
import {
  Box,
  Button,
  Grid,
  Paper,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { timeLineDropDown, areaStudyDropdown, reportType } from '../../../../Mock/Dashboard Data/OverallData';
import CachedIcon from '@mui/icons-material/Cached';
import BasicSelect from '../../../../Component/DropDowns/BasicSelect';
import { getCampaignsByVendor, getAdvByVendor, getCampaignsByAdvertiser } from '../../../../service/Vendor'
import MultipleSelectDropDown from '../../../../Component/DropDowns';
import { programTargetingData, reportTypeData } from '../../../../Mock/ProgramTargetingData';
import SkeletonLoader from '../../../../Component/Skeleton Loader';
import { fetchMBrandApiData } from '../../../../Services/externalApis';
import { useAppDispatch, useAppSelector } from '../../../../Store/hooks';
import { updateSelectedMbrands } from '../../../../Store/Slices/ExternalApiSlice';
import { fetchRawReportTableData, generateRawDataReport } from '../../../../Services/dashBoardApi';
import MuiAlert from '@mui/material/Alert';
import { RootState } from '../../../../Store';
import MMTable from '../../../../Component/Table/MMTable';
import { rawReportHeadCell } from '../../../../Component/Table/tableInterface';
interface dropdownMenus {
  label: string;
  value: string
}
interface IRawReport {
  campaignDropDownMenus: dropdownMenus[]
  // defaultBarDataSet: any
  dashboardType?: 'advertiser' | 'publisher'
  advertiserDropDownMenus: dropdownMenus[]
  selectedAdvertiser: string;
  advertiserChangeHandler: (value: string) => void
  IsCampaignLoading: boolean;
  isAdvLoading: boolean
}

let userName = localStorage.getItem('userName');
const AdvRawReport: React.FC<IRawReport> = ({ campaignDropDownMenus, dashboardType, advertiserDropDownMenus, advertiserChangeHandler, selectedAdvertiser, isAdvLoading, IsCampaignLoading }) => {
  const [reportName, setReportName] = useState('');
  console.log("campaignDropDownMenus", campaignDropDownMenus)
  console.log("ADVDownMenus", advertiserDropDownMenus)
  console.log("Selected ADV", selectedAdvertiser)
  const [dateRange, setDateRange] = useState(timeLineDropDown[0].value);
  // const [pubId, setPubId] = useState('');
  const [reportNameError, setReportNameError] = useState(false);
  const [loading, setLoading] = useState(false)
  const rawReportdata = useAppSelector((state: RootState) => state.rawDataReportSlice.data);
  const latest = useAppSelector((state: RootState) => state.rawDataReportSlice.rawStatus);
  const [selectedAreaStudy, setSelectedAreaStudyValue] = useState(
    areaStudyDropdown.map(name => name.value)
  );

  const [selectedReportType, setSelectedReportValue] = useState(
    reportTypeData.map(name => name.value)
  );

  const [selectedAdvertiserCamp, setSelectedAdvertiserCamp] = useState<string[] | []>(campaignDropDownMenus.map((item) => item.value))
  const dispatch = useAppDispatch()
  const mBrandPageData = useAppSelector(state => state.mBrandHandlerApi);


  const handelSelectedMbrands = (value: string[]) => {
    dispatch(updateSelectedMbrands(value));
  };

  const handelSelectedCampaigns = (value: string[]) => {
    setSelectedAdvertiserCamp(value)
  }

  const handleRefresh = () => {
    console.log('payload==r==', payload);
    fetchRawReportTableData(dispatch, userName, payload);
  };

  let payload: any = {};
  const areaStudyLabels = selectedAreaStudy.map(value => {
    const target = programTargetingData.find(item => item.value === value);
    return target ? target.label : '';
  });

  //  console.log("selected Area Study", selectedAreaStudy);
  //  console.log("selectedReport type", selectedReportType);
  //  console.log("selectedAdvertiserCamp", selectedAdvertiserCamp);
  //  console.log("Raw report data", rawReportdata);
  payload = {
   mAdvId: selectedAdvertiser,
   mAdvCampaignId: selectedAdvertiserCamp.join(','),
    // areaStudy: selectedAreaStudy.join(','),
    areaStudy: selectedAreaStudy.join(','),
    timeFrame: dateRange,
    // reportType: selectedReportType.join(','),
    reportType: 'Customer Attribute Performance Report',
    reportName: reportName,
    //  mBrandId: mBrandPageData.selectedMbrand.join(','),
    chartType: 'rawReport',
    userName: userName,
  }
  const handleGenReport = async () => {
    if (!reportName.trim()) {
      setReportNameError(!reportName.trim());
    } else {

      function jsonToQueryString(json: any) {
        return Object.keys(json)
          .map(function (key) {
            return (
              encodeURIComponent(key) + '=' + encodeURIComponent(json[key])
            );
          })
          .join('&');
      }
      var queryString = jsonToQueryString(payload);

      try {
        setSnackbarOpen(true);
        setSuccessMessage('Report generation is in progress. Click the refresh icon to fetch the updated table data.');
        generateRawDataReport(dispatch, queryString, payload);
      } catch (error) {
        console.error('Error fetching generateRawDataReport:', error);
      }

      setTimeout(async () => {
        try {
          await fetchRawReportTableData(dispatch, userName, payload);
        } catch (error) {
          console.error('Error fetching fetchRawReportTableData', error);
        }
      }, 3000); // 3 seconds

      const intervalId = setInterval(async () => {

        try {
          const response: any = await fetchRawReportTableData(dispatch, userName, payload);
          // const  latestStatus = rawReportdata[0]?.rawStatus;
          //  console.log('get:', response[0]?.rawStatus);
          if (response.length && (response[0]?.rawStatus === 'Generated' || response[0]?.rawStatus === 'No Data Found')) {
            console.log('Status is "Generated". Clearing interval.');
            clearInterval(intervalId);
          }
        } catch (error) {
          console.error('Error in fetchRawReportTableData:', error);
        }

      }, 20000); // 30 seconds


    }
  };

  useEffect(() => {
    fetchMBrandApiData(dispatch);
  }, [])
  useEffect(() => {
    if (!IsCampaignLoading) {
      setSelectedAdvertiserCamp(campaignDropDownMenus.map(item => item.value))
    }
  }, [IsCampaignLoading])

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isSnackbarOpen, setSnackbarOpen] = useState(false);

  return (
    <>
      <Paper elevation={3} className="card">
        <Box
          display="flex"
          justifyContent="space-between"
          p={3}
          sx={{ flexDirection: { xs: 'column', md: 'row' } }}
        >
          <Box>
            <Typography className="card-heading">Raw Data Report</Typography>
            <Typography className="card-sub-heading">
              Raw Data Summary
            </Typography>
          </Box>
        </Box>
        {/* <Paper elevation={3} className="card">
    <h2>Raw Data Report</h2> */}
        <Grid container spacing={4} padding={2}>
          <Grid item xs={12} lg={4}>
            <Box sx={{ width: '100%' }}>
              <BasicSelect
                value={dateRange}
                setValue={setDateRange}
                menuOptions={timeLineDropDown}
                selectLabel={'Time Frame'}
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextField
              label="Create report name*"
              fullWidth
              // sx={{ width: 'auto' }}
              value={reportName}
              onChange={e => {
                setReportNameError(false);
                setReportName(e.target.value);
              }}
              error={reportNameError}
              helperText={reportNameError ? 'Report Name is mandatory' : ''}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <Box className="areaStudy-dropdown-container">
              <MultipleSelectDropDown
                dropdownLabel="Report Type"
                dropdownData={reportType}
                dropDownValue={selectedReportType}
                setDropDownValue={setSelectedReportValue}
              />
            </Box>
          </Grid>

          {/* Second row */}
          <Grid item xs={12} lg={4}>
            {isAdvLoading ? <SkeletonLoader variant={'rectangular'} width='100%' height='100%' /> :
              <Box className="campaigns-dropdown-container">

                <BasicSelect
                  value={selectedAdvertiser}
                  setValue={advertiserChangeHandler}
                  menuOptions={advertiserDropDownMenus}
                  selectLabel={"Advertisers"}
                />

              </Box>
            }
          </Grid>
          <Grid item xs={12} lg={4}>
            {IsCampaignLoading ? <SkeletonLoader variant={'rectangular'} width='100%' height='100%' /> :
              <Box className="campaigns-dropdown-container">

                <MultipleSelectDropDown
                  dropdownLabel="Campaigns"
                  dropdownData={campaignDropDownMenus}
                  dropDownValue={selectedAdvertiserCamp}
                  setDropDownValue={handelSelectedCampaigns}
                />
              </Box>
            }


          </Grid>
          <Grid item xs={12} lg={4}>
            <Box className="campaigns-dropdown-container">
              <MultipleSelectDropDown
                dropdownLabel="Area Study"
                dropdownData={areaStudyDropdown}
                dropDownValue={selectedAreaStudy}
                setDropDownValue={setSelectedAreaStudyValue}
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Box className="campaigns-dropdown-container">
              <MultipleSelectDropDown
                dropdownLabel="Brands"
                dropdownData={mBrandPageData.mBrandDropdownData}
                dropDownValue={mBrandPageData.selectedMbrand}
                setDropDownValue={handelSelectedMbrands}
              />
            </Box>
          </Grid>

          <Grid
            gap={5}
            display="flex"
            item
            xs={12}
            lg={12}
            style={{
              textAlign: 'center',
              justifyContent: 'center',
              // marginRight: '10%',
              padding: '30px',
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleGenReport}
            >
              Generate Report
            </Button>
            <Box
              className="refresh-button"
              sx={{ marginTop: 'auto' }}
              onClick={handleRefresh}
            >
              <Tooltip title="Refresh">
                <CachedIcon sx={{ color: 'primary' }} />
              </Tooltip>
            </Box>
          </Grid>
          {/* Add more Grid items as needed */}
        </Grid>
        {rawReportdata.length > 0 && (
          <MMTable
            tableData={rawReportdata}
            tableHeadCell={rawReportHeadCell}
          // isLoadingMore={isLoading}
          // fetchMoreData={() => fetchMoreData()}
          // hasMoreData={hasMoreData}
          // fetchInitialData={fetchInitialData}
          />
        )}
      </Paper>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isSnackbarOpen}
        autoHideDuration={20000}
        onClose={handleCloseSnackbar}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackbar}
          severity={successMessage ? 'success' : 'error'}
        >
          {successMessage || errorMessage}
        </MuiAlert>
      </Snackbar>
    </>
  )
}

export default AdvRawReport