import { ThunkDispatch } from 'redux-thunk'; // Import the ThunkDispatch type
import { AnyAction } from '@reduxjs/toolkit';
import { setLoading, setCurrentCursor, setHasMoreData, appendData, setFullData, setApiFullData, storeLimitCampaignsData, appendLimitCampaignsData, setNoDataFlag, setIsoLoading, setFilterSearchAdvData, setScrollNext, storeCompleteAdvAccountData, } from '../Store/Slices/advertiserTabApisData';
import axios from 'axios';
import { setCampLoading, setCurrentCampCursor, setHasCampMoreData, setBlockScroll, appendCampData, setFullCampData, setApiFullCampData, setFetchFlagAdvCamp } from '../Store/Slices/advertiserTabApisData';
import { baseURLConfig } from '../config/envConfig';
import { setfilterSearchFlag } from '../Store/Slices/PublisherTabApisData';
import { fetchToken, generateHeader } from '../service/Auth';
import { getCampaignStatus } from '../Utils/utils';
import { getDisplayName } from '../View/User Accounts/Vendors/Vendors';

type DispatchType = ThunkDispatch<{}, {}, AnyAction>;

const baseURL = baseURLConfig.baseURl;
const advertiAccApiUrl = `${baseURL}/advertisers?ID=advertiser&limit=25`;
const API_BASE_URL = `${baseURL}/advertisers`;
const advertiserCampApiUrl = `${baseURL}/advertisers/campaign?ID=campaign&limit=25`;



export const fetchAdvertAccApiData = async (
  dispatch: DispatchType,
  currentCursor: string | null,
  location: any
) => {
  const baseURL = baseURLConfig.baseURl;
  try {
    dispatch(appendData([]));
    const headers = await generateHeader();
    dispatch(setLoading(true));
    let url = advertiAccApiUrl;
    if (currentCursor !== null) {
      url += `&cursor=${currentCursor}`;
    }
    const response = await fetch(url, { headers });
    const jsonData = await response.json();
    const cursorEncode = encodeURIComponent(jsonData.result.meta.cursor);
    const filteredData = jsonData.result.data.map((item: any) => {

      const mappedItem: any = {
        Status: item.status,
        Adv_ID: item.advertiserID,
        Advertiser_Name: item.advertiserName,
        Brand_Name: item.brand,
        vendorName: getDisplayName(item.vendorName),
        Vertical: item?.vertical?.verticalDisplay
      }


      return mappedItem;
    });
    // const filteredData = jsonData.result.data.map((item: any) => {
    //   const budgetInfo = item?.values?.budgetInfo || {};

    //   // Count campaigns with budgetFilteringStatus true
    //   const activeBudgetCampaignCount = Object.values(budgetInfo).filter(
    //     (campaign: any) => campaign.budgetFilteringStatus
    //   ).length;

    //   console.log("active  activeBudgetCampaignCount ",  activeBudgetCampaignCount )
    //   const mappedItem: any = {
    //     Status: item.status,
    //     Adv_ID: item.advertiserID,
    //     Advertiser_Name: item.advertiserName,
    //     Brand_Name: item.brand,
    //     vendorName: getDisplayName(item.vendorName),
    //     Vertical: item?.vertical?.verticalDisplay,
    //     budget_Status: activeBudgetCampaignCount > 0 ? "Active" : "Inactive", // Budget status
    //     active_Budget_Campaign_Count: activeBudgetCampaignCount, // Add active campaign count
    //    caps_active:"",
    //    caps_active_campaign_count:0
    //   };

    //   return mappedItem;
    // });

    dispatch(setFullData(jsonData.result.data))
     dispatch(storeCompleteAdvAccountData(jsonData.result.data))  
    dispatch(appendData(filteredData));
    dispatch(setCurrentCursor(cursorEncode));
    dispatch(setHasMoreData(jsonData.result.meta.hasMoreData));
  } catch (error) {
    console.error('Error fetching data:', error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const createAdvertiser = async (payload: any) => {

  try {
    const headers = await generateHeader();
    const response = await axios.post(API_BASE_URL, payload, { headers });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateAdvertiser = async (payload: any) => {
  try {
    const headers = await generateHeader();
    const response = await axios.put(API_BASE_URL, payload, { headers });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteAdvertiser = async (payload: any) => {
  try {
    const headers = await generateHeader();
    let config = {
      method: 'delete',
      maxBodyLength: Infinity,
      url: API_BASE_URL,
      headers: headers,
      data: payload.data
    };
    const response = await axios.request(config)
    // const response = await axios.delete(API_BASE_URL, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

//for serch functionality fetching complete data withour limit and cursor.
export const fetchCompApiData = async (dispatch: DispatchType) => {
  try {
    const headers = await generateHeader();
    const response = await fetch(`${baseURL}/advertisers?ID=advertiser`, { headers });
    const jsonData = await response.json();
    const compApiData = jsonData.result.data.map((item: any) => ({
      Status: item.status,
      Adv_ID: item.advertiserID,
      Advertiser_Name: item.advertiserName,
      Brand_Name: item.brand,
      vendorName: getDisplayName(item.vendorName),
      // Created_on: item.createdOn,

    }));
    dispatch(storeCompleteAdvAccountData(jsonData.result.data))
    dispatch(setApiFullData(compApiData));
    dispatch(setFullData(jsonData.result.data));
  } catch (error) {
    throw new Error('Failed to fetch Api data');
  }
};

export const searchAdvAccount = async (
  inputValue: string,
  dispatch: DispatchType,
  ID: string,
  location: any,
  currentCursor: string | null
) => {
  try {
    dispatch(setIsoLoading(true));
    const headers = await generateHeader();
    const encodedValue: string = encodeURIComponent(inputValue);

    let url = `${baseURL}/search?ID=${ID}&key=${encodedValue}`;
    if (currentCursor !== null) {
      url += `&cursor=${currentCursor}`;
    }

    const response = await axios.get(url, { headers });

    const filteredData = response.data.data.map((item: any) => {
      const mappedItem: any = {
        Status: item.status,
        Adv_ID: item.mPubID,
        Advertiser_Name: item.GroupName,
        Brand_Name: item.brand,
        vendorName: getDisplayName(item.vendorName),
        Vertical: item?.vertical?.verticalDisplay,
      };
      return mappedItem;
    });

    if (encodedValue && filteredData.length === 0) {
      dispatch(setNoDataFlag(true));
    } else {
      dispatch(setNoDataFlag(false));
      dispatch(setBlockScroll(true));
    }

    dispatch(appendData(filteredData));
    dispatch(setCurrentCursor(response.data.cursor));
    dispatch(setHasMoreData(response.data.meta.hasMoreData));
  } catch (error) {
    console.error('Error fetching advertiser accounts:', error);
    dispatch(setNoDataFlag(true)); // Handle case when an error occurs
  } finally {
    dispatch(setIsoLoading(false)); // Ensure loading flag is cleared
  }
};


export const filterByVerticalSearch = async (palyoad: Object, dispatch: DispatchType) => {
  dispatch(setfilterSearchFlag(true));
  dispatch(setIsoLoading(true));
  // const encodedValue: string = encodeURIComponent(inputValue);
  const headers = await generateHeader();
  const response = await axios.post(`${baseURL}/filterByVertical?ID=advertiser`, palyoad, { headers });
  const compApiData = response.data.result.data.map((item: any) => ({
    Status: item.status,
    Adv_ID: item.mPubID,
    Advertiser_Name: item.GroupName,
    Brand_Name: item.brand,
    vendorName: getDisplayName(item.vendorName),
    vertical: item?.vertical?.verticalDisplay,
    // Created_on: item.createdOn,

  }));
  dispatch(appendData(compApiData));
  dispatch(setFilterSearchAdvData(compApiData));
  if (palyoad && compApiData.length === 0) {
    dispatch(setNoDataFlag(true));
  } else {
    dispatch(setNoDataFlag(false));
    dispatch(setBlockScroll(true));
  }
  dispatch(setIsoLoading(false));
}

////////////////adv camp

export const fetchAdvertiserCampApiData = async (
  dispatch: DispatchType,
  currentCursor: string | null
) => {
  try {
    const headers = await generateHeader();
    dispatch(setCampLoading(true));
    let url = advertiserCampApiUrl;
    if (currentCursor !== null) {
      url += `&cursor=${currentCursor}`;
    }
    const response = await fetch(url, { headers });
    const jsonData = await response.json();
    const filteredData = jsonData.result.data.map((item: any) => {

      const pauseStatus = getCampaignStatus(item.values.configTimeOfDay)
      return {
        Status: item.status,
        budgetFlag: item?.budgetFilteringLimit || false,
        campaign_id: item?.SORTID,
        capFlag: item?.clickCapFilteringLimit || false,
        campaign_name: item?.campaignname,
        Adv_ID: item.advertiser?.mPubID,
        Advertiser_Name: item.advertiser?.GroupName,
        vertical: item?.advertiser?.vertical.verticalDisplay,
        // Status: item.status,
        error: item.values?.programCSVConfig ? item.values.programCSVConfig.csvDataError : false,
        isOFF: pauseStatus === 'Active' ? false : true,


      }
    });
    dispatch(appendCampData(filteredData));
    dispatch(appendLimitCampaignsData(jsonData.result.data))
    // dispatch(storeLimitCampaignsData(jsonData.result.data))
    dispatch(setCurrentCampCursor(jsonData.result.meta.cursor));
    dispatch(setHasCampMoreData(jsonData.result.meta.hasMoreData));
  } catch (error) {
    console.error('Error fetching data:', error);
  } finally {
    dispatch(setCampLoading(false));
  }
};

export const deleteAdvertiserCamp = async (payload: any) => {
  try {
    const headers = await generateHeader();
    let config = {
      method: 'delete',
      maxBodyLength: Infinity,
      url: `${API_BASE_URL}/campaign?SORTID=${payload}`,
      headers: headers,
      //  data:payload.data
    };
    const response = await axios.request(config)
    return response.data;
  } catch (error) {
    throw error;
  }
};

//For status toggle switch not for edit icon.
export const updateStatusAdvCamp = async (SORTID: any, ID: any, requestData: any) => {
  try {
    const headers = await generateHeader();
    const response = await axios.put(`${baseURL}/update/status?ID=${ID}&SORTID=${SORTID}`, requestData, { headers });
    return response.data;
  } catch (error) {
    throw error;
  }
};
//  

//for serch functionality fetching complete data withour limit and cursor.
export const fetchAdvComCampApiData = async (dispatch: DispatchType) => {
  try {
    const headers = await generateHeader();
    const response = await fetch(`${baseURL}/advertisers/campaign?ID=campaign`, { headers });
    const jsonData = await response.json();
    const compApiData = jsonData.result.data.map((item: any) => {
      const pauseStatus = getCampaignStatus(item.values.configTimeOfDay)
      return {
        Status: item.status,
        budgetFlag: item?.budgetFilteringLimit || false,
        campaign_id: item?.SORTID,
        capFlag: item?.clickCapFilteringLimit || false,
        campaign_name: item?.campaignname,
        Adv_ID: item.advertiser?.mPubID,
        Advertiser_Name: item.advertiser?.GroupName,
        vertical: item?.advertiser?.vertical?.verticalDisplay,
        // Brand_Name: item?.advertiser?.brand ? item.advertiser.brand: item.values.brand,
        // Status: item.status,
        error: item.values?.programCSVConfig ? item.values.programCSVConfig.csvDataError : false,
        isOFF: pauseStatus === 'Active' ? false : true,

      }
    });
    dispatch(setApiFullCampData(compApiData));
    dispatch(setFullCampData(jsonData.result.data));
    dispatch(setFetchFlagAdvCamp(true));
  } catch (error) {
    throw new Error('Failed to fetch Api data');
  }
};


export const fetchSearchResult = async (inputValue: string, dispatch: DispatchType, ID: string, currentCursor: string | null) => {
  try {
    dispatch(setIsoLoading(true));
    dispatch(setHasMoreData(true));
    dispatch(setCampLoading(true));
    const headers = await generateHeader();
    const encodedValue: string = encodeURIComponent(inputValue);
    let url = `${baseURL}/search?ID=${ID}&key=${encodedValue}&campaignType=advertiserCampaign`
    if (currentCursor !== null) {
      url += `&cursor=${currentCursor}`;
    }
    const response = await axios.get(url, { headers });
    const filteredData = response.data.data.map((item: any) => {
      const advertiserName = item?.publisherName;
      const formattedAdvertiserName = advertiserName ? advertiserName.replace(/^ADV-|-ADM-\d+$/g, '') : '';
      const pauseStatus = getCampaignStatus(item.values.configTimeOfDay)
      return {
        Status: item.status,
        budgetFlag: item?.budgetFilteringLimit || false,
        campaign_id: item?.SORTID,
        capFlag: item?.clickCapFilteringLimit || false,
        campaign_name: item?.campaignname,
        Adv_ID: item.publisherID,
        Advertiser_Name: item?.advertiser.GroupName ? item?.advertiser?.GroupName : item?.advertiser?.advertiserName,
        vertical: item?.advertiser?.vertical?.verticalDisplay,
        // Brand_Name: item.values?.brand,
        // Status: item.status,
        error: item.values?.programCSVConfig ? item.values.programCSVConfig.csvDataError : false,
        isOFF: pauseStatus === 'Active' ? false : true,

      };
    });

    if (encodedValue && filteredData.length === 0) {
      dispatch(setNoDataFlag(true));
    } else {
      dispatch(setNoDataFlag(false));
      dispatch(setBlockScroll(false));
      dispatch(setScrollNext(true));
    }

    dispatch(setIsoLoading(false));
    dispatch(appendCampData(filteredData));
    dispatch(appendLimitCampaignsData(response.data.data));
    dispatch(setCurrentCampCursor(response.data.meta.cursor));
    dispatch(setHasCampMoreData(response.data.meta.hasMoreData));
  } catch (error) {
    console.error("Error fetching search results:", error);
    dispatch(setIsoLoading(false)); // Set loading flag to false in case of an error
    // You can dispatch additional actions or handle the error as needed
  } finally {
    dispatch(setIsoLoading(false));
    dispatch(setCampLoading(false));
  }
};

export const filterAdvertiserSearch = async (
  payload: Object,
  dispatch: DispatchType,
  currentCursor: string | null = null
) => {
  dispatch(setfilterSearchFlag(true));
  dispatch(setHasMoreData(true));

  try {
    const headers = await generateHeader();
    dispatch(setIsoLoading(true));
    dispatch(setCampLoading(true));
    let url = `${baseURL}/filter?campaignType=advertiserCampaign`;

    if (currentCursor) {
      url += `&cursor=${currentCursor}`;
    }

    const response = await axios.post(url, payload, { headers });
    const filteredData = response?.data?.data.map((item: any) => {
      const advertiserName = item?.publisherName;
      const formattedAdvertiserName = advertiserName
        ? advertiserName.replace(/^ADV-|-ADM-\d+$/g, '')
        : '';
      const pauseStatus = getCampaignStatus(item.values.configTimeOfDay);

      return {
        Status: item.status,
        budgetFlag: item?.budgetFilteringLimit || false,
        campaign_id: item?.SORTID,
        capFlag: item?.clickCapFilteringLimit || false,
        campaign_name: item?.campaignname,
        Adv_ID: item.publisherID,
        Advertiser_Name: item?.advertiser.GroupName ? item?.advertiser?.GroupName : item?.advertiser?.advertiserName,
        vertical: item?.advertiser?.vertical?.verticalDisplay,
        error: item.values?.programCSVConfig ? item.values.programCSVConfig.csvDataError : false,
        isOFF: pauseStatus === 'Active' ? false : true,
      };
    });

    if (payload && filteredData.length === 0) {
      dispatch(setNoDataFlag(true));
    } else {
      dispatch(setNoDataFlag(false));
      dispatch(setBlockScroll(false));
      dispatch(setScrollNext(true));
    }

    // Dispatch filtered data and cursor management actions
    dispatch(setFilterSearchAdvData(filteredData));
    dispatch(appendCampData(filteredData));
    dispatch(appendLimitCampaignsData(response.data.data));
    dispatch(setCurrentCampCursor(response.data.meta.cursor)); // Update cursor for pagination
    dispatch(setHasCampMoreData(response.data.meta.hasMoreData)); // Update hasMoreData flag for pagination

  } catch (error) {
    console.error("Error fetching search results:", error);
    dispatch(setfilterSearchFlag(false));
  } finally {
    dispatch(setIsoLoading(false));
    dispatch(setCampLoading(false));
  }
};




type DebouncedFunction<T extends (...args: any[]) => any> = (...args: Parameters<T>) => void;

export function debounce<T extends (...args: any[]) => any>(func: T, delay: number): DebouncedFunction<T> {
  let timeoutId: NodeJS.Timeout;

  return function (this: ThisParameterType<T>, ...args: Parameters<T>) {
    const context = this;

    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      func.apply(context, args);
    }, delay);
  };
}


