import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Snackbar,
  Switch,
  TableCell,
  TextField,
  // Tooltip,
  Typography,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { Button, Paper } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';
import InfoIcon from '@mui/icons-material/Info';
import { useLocation, useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import Search from '../../../Component/Search/Search';
import MMTable from '../../../Component/Table/MMTable';
import { useAppDispatch, useAppSelector } from '../../../Store/hooks';
import { fetchAdvertAccApiData, filterByVerticalSearch, searchAdvAccount } from '../../../Services/advertiserTabApis';
import {
  resetStore,
  setBlockScroll,
  setFlag,
  setFullData,
  setIsoLoading,
  setNoDataFlag,
  setScrollNext,
  setSearchInput,
} from '../../../Store/Slices/advertiserTabApisData';
import { generateHeader } from '../../../service/Auth';
import { baseURLConfig } from '../../../config/envConfig';
import { RootState } from '../../../Store';
import { setAdvertiserField, resetForm, resetFiltersAdv } from '../../../Store/Slices/CreateAdvButtonDialogSlice';
import { fetchVerticals } from '../../../Services/verticalList';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { fetchAdvertiserBudgetCap, fetchFullAdvertiser, filterByVerticalSearchAdvBudgetCap, searchAdvTool } from '../../../Services/budget-allocation';

const advAccountHeadCells: any[] = [
  // {
  //   numeric: false,
  //   id: 'Status',
  //   label: 'Status',
  //   key: 'Status'   ,
  // },
  {
    numeric: true,
    id: 'Adv_ID',
    label: 'Adv ID',
    key: 'Adv_id',
  },
  {
    id: 'Advertiser_Name',
    numeric: false,
    key: 'advertiser_name',
    label: 'Advertiser Name',
  },
  // {
  //   id: 'Brand_Name',
  //   numeric: false,
  //   key: 'Brand_Name',
  //   label: 'Brand Name',
  // },

  {
    id: 'vendorName',
    numeric: false,
    label: 'Vendor Name',
    key: '',
  },

  {
    id: 'vertical',
    numeric: false,
    label: 'Vertical',
    key: '',
  },
  {
    id: 'budget_status',
    numeric: false,
    label: 'Status',
    key: 'budget_status',
  },
  {
    id: 'active_Budget_Campaign_Count',
    numeric: false,
    label: 'Campaign Count',
    key: 'active_Budget_Campaign_Count',
  },
  {
    id: 'action',
    numeric: false,
    label: 'Action',
    key: '',
  },
];

const capsAdvHeadCells: any[] = [
  // {
  //   numeric: false,
  //   id: 'Status',
  //   label: 'Status',
  //   key: 'Status'   ,
  // },
  {
    numeric: true,
    id: 'Adv_ID',
    label: 'Adv ID',
    key: 'Adv_id',
  },
  {
    id: 'Advertiser_Name',
    numeric: false,
    key: 'advertiser_name',
    label: 'Advertiser Name',
  },
  // {
  //   id: 'Brand_Name',
  //   numeric: false,
  //   key: 'Brand_Name',
  //   label: 'Brand Name',
  // },

  {
    id: 'vendorName',
    numeric: false,
    label: 'Vendor Name',
    key: '',
  },

  {
    id: 'vertical',
    numeric: false,
    label: 'Vertical',
    key: '',
  },
  {
    id: 'caps_active',
    numeric: false,
    label: 'Status',
    key: 'budget_status',
  },
  {
    id: 'caps_active_campaign_count',
    numeric: false,
    label: 'Campaign Count',
    key: 'caps_active_campaign_count',
  },
  {
    id: 'action',
    numeric: false,
    label: 'Action',
    key: '',
  },
];


// const advertiserTableData: any[] = [
//   {
//     advertiserID: '33997',
//     advertiserName: 'All-Star Directories',
//     vertical: "EDU"
//     // createdOn: '11/6/2021 4:35:07 AM',
//     // cam1: {},
//     // cam2: {},
//   },
// ];

const BudgetList = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const advertiserState = useAppSelector((state: RootState) => state.createAdvButton);
  const { verticalFilter } = advertiserState
  const advertiserTableData = useAppSelector(
    (state: any) => state.advertiserAccApi.advToolData
  );
  const advertiserFullData = useAppSelector(
    (state: any) => state.advertiserAccApi.fullData
  );
  const isLoading = useAppSelector(
    (state: any) => state.advertiserAccApi.loading
  );
  const searchInput = useAppSelector(
    state => state.advertiserAccApi.searchInput
  );
  const hasMoreData = useAppSelector(
    state => state.advertiserAccApi.hasMoreData
  );
  const currentCursor = useAppSelector(
    state => state.advertiserAccApi.currentCursor
  );

  const [debouncedInputValue, setDebouncedInputValue] = useState<string>('');
  const [tableDate, setTableData] = useState<any>([]);
  // const verticalOptions: string[] = ["AUTO (Auto Insurance)", "EDU (Higher Education)"];
  const { verticalList, loading, error } = useSelector((state: RootState) => state.verticals);
  const verticalOptions = verticalList.map((item) => ({
    id: item.verticalID,
    name: item.verticalName,
  }));
  const [VerticalMouseOverDelete, setVerticalMouseOverDelete] = useState(false);
  const fetchMoreData = () => {
    if (!isLoading && hasMoreData && searchInput === '') {
      dispatch(setFlag(true));
      fetchAdvertiserBudgetCap(dispatch, currentCursor, location);
    } else if (!isLoading && hasMoreData && searchInput !== '') {
      searchAdvTool(debouncedInputValue, dispatch, 'advertiser', location, currentCursor)

    }
  };
  const handleFieldChange = (field: keyof typeof advertiserState, value: any) => {
    dispatch(setAdvertiserField({ field, value }));
  };
  useEffect(() => {
    return () => {
      dispatch(setSearchInput(''));
      dispatch(resetFiltersAdv());
    };
  }, [dispatch, location]);
  useEffect(() => {
    dispatch(setFlag(false));
    if (!searchInput && verticalFilter.length === 0) {
      dispatch(setBlockScroll(false));
      // If searchInput is empty, fetch the initial data
      dispatch(setScrollNext(false));
      setDebouncedInputValue('');
      fetchFullAdvertiser(dispatch)
      fetchInitialData();

      dispatch(setNoDataFlag(false));
    } else if (verticalFilter.length !== 0 && searchInput === '') {
      dispatch(setSearchInput(''));
      const debounceTimer = setTimeout(async () => {
        const payload = {
          "vertical": verticalFilter
        }
        verticalFilter.length > 0 && filterByVerticalSearchAdvBudgetCap(payload, dispatch);

      }, 1000);

      // Clear the timer on every key press to restart the countdown
      return () => clearTimeout(debounceTimer);
    }

    else {
      const debounceTimer = setTimeout(() => {
        setDebouncedInputValue(searchInput);
      }, 600);

      // Clear the timer on every key press to restart the countdown
      return () => clearTimeout(debounceTimer);
    }
  }, [dispatch, searchInput, verticalFilter]);

  const fetchInitialData = () => {
    fetchAdvertiserBudgetCap(dispatch, null, location).then(() => {
      dispatch(setScrollNext(true));
    });
  };

  const [tableHeaderCell, setTableHeaderCell] = useState<any[]>([])
  const [pageSubHeading, setPageSubHeading] = useState('')
  useEffect(() => {
    if (location.pathname === '/budget-allocation') {
      setPageSubHeading('Budget Allocation summary')
      setTableData(
        advertiserTableData?.map((item: any) => {
          return {
            // Status: item.Status,
            Adv_ID: item.Adv_ID,
            Advertiser_Name: item.Advertiser_Name,
            // Brand_Name: item.Brand_Name,
            vendorName: item.vendorName,
            Vertical: item?.Vertical,
            budget_Status: item.budget_Status,
            active_Budget_Campaign_Count: item.active_Budget_Campaign_Count
          }
        }))
      setTableHeaderCell(advAccountHeadCells);
    } else if (location.pathname === '/caps-filtering') {
      setPageSubHeading('Caps filter summary')
      setTableData(
        advertiserTableData?.map((item: any) => {
          return {
            // Status: item.Status,
            Adv_ID: item.Adv_ID,
            Advertiser_Name: item.Advertiser_Name,
            // Brand_Name: item.Brand_Name,
            vendorName: item.vendorName,
            Vertical: item?.Vertical,
            caps_active: item.caps_active,
            caps_active_campaign_count: item.caps_active_campaign_count
          }
        })
      );
      setTableHeaderCell(capsAdvHeadCells);
    }

    return () => {
      setTableData([])
      setTableHeaderCell([])
      // dispatch(setFullData([]))
      dispatch(resetStore())
    }

  }, [advertiserTableData, location.pathname]);

  // console.log('table data', tableDate)
  const getCampanig = async (palyoad: Object) => {
    try {

      const headers = await generateHeader();
      const response = await axios.post(
        `${baseURLConfig.baseURl}/filter?campaignType=advertiserCampaign`,
        palyoad,
        { headers }
      );
      const filteredData = response?.data?.data?.map((item: any) => {
        const advertiserName = item?.publisherName;
        const formattedAdvertiserName = advertiserName
          ? advertiserName.replace(/^ADV-|-ADM-\d+$/g, '')
          : '';
        return {
          ID: item?.ID,
          SORTID: item?.SORTID,
          campaignname: item?.campaignname,
          budgetSettings: item?.budgetSettings,
          clickCapSettings: item?.clickCapSettings,
          // clickCapFilteringLimit: item?.clickCapFilteringLimit || false,
          // budgetFilteringLimit: item?.budgetFilteringLimit || false,
        };
      });
      return filteredData;
    } catch (error) {
      console.error('Error fetching search results:', error);
    }
  };

  const handelSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    // console.log("event", event.target.value)
    // setSearchInput(event.target.value)
    dispatch(setSearchInput(event.target.value));
  };

  useEffect(() => {
    if (debouncedInputValue !== '' && debouncedInputValue !== null) {
      searchAdvTool(debouncedInputValue, dispatch, 'advertiser', location, null)
    }
  }, [debouncedInputValue])

  useEffect(() => {
    dispatch(fetchVerticals())
    dispatch(resetFiltersAdv());
    // fetchFullAdvertiser(dispatch)
    if (searchInput) {
      dispatch(setSearchInput(''));
    }
  }, []);
  const handleFilterSearch = () => {
    dispatch(setSearchInput(''));
  }
  const handleRemoveVertical = (id: string) => {
    const newVerticalFilter = verticalFilter.filter((verticalId) => verticalId !== id);
    handleFieldChange("verticalFilter", newVerticalFilter);
    setVerticalMouseOverDelete(false);
  };


  const handleEdit = async (val: RowData) => {
    try {
      dispatch(setIsoLoading(true));
      const campaign = await getCampanig({ mPubIds: [val?.Adv_ID] });
      dispatch(setIsoLoading(false));
      navigate(
        location.pathname === '/budget-allocation'
          ? '/edit-budget-caps'
          : '/edit-caps-filtering',
        {
          state: { advertiserData: val, campaignData: campaign, advertiserState: advertiserFullData },
        }
      );
    } catch (error) {
      console.error('Error fetching campaign data:', error);
      dispatch(setIsoLoading(false));
    }
  };
  return (
    <React.Fragment>
      <Paper elevation={3} className="card">
        <Box
          display="flex"
          justifyContent="space-between"
          p={3}
          sx={{ flexDirection: { xs: 'column', md: 'row' } }}
        >
          <Box>
            <Typography className="card-heading">Advertiser List</Typography>
            <Typography className="card-sub-heading">
              {pageSubHeading}
            </Typography>
          </Box>
          <Box
            display="flex"
            gap="16px"
            sx={{
              flexDirection: { xs: 'column', sm: 'row' },
              mt: { xs: '16px', md: '0' },
              alignItems: { xs: 'flex-start', md: 'center' },
            }}
          >
            <Search value={searchInput} searchHandler={handelSearch} />
          </Box>
        </Box>
        <Box display="flex" gap="8px">
          <FormControl style={{ width: '98%', marginBottom: '0.4%', paddingLeft: '1%' }}>
            <InputLabel style={{ paddingLeft: '19px' }}>Filter by Vertical</InputLabel>
            <Select
              label="Filter by Vertical"
              multiple
              value={verticalFilter}
              onChange={(e) => {
                const selectedIDs = e.target.value as string[];
                handleFilterSearch()
                handleFieldChange("verticalFilter", selectedIDs);
              }}
              renderValue={(selected: any) => {
                const maxVisibleChips = 4;
                const displayed = selected.slice(0, maxVisibleChips);
                const hiddenCount = selected.length - maxVisibleChips;

                return (
                  <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                    {displayed.map((id: string) => {
                      const option = verticalOptions.find((opt) => opt.id === id);
                      return (
                        <Chip
                          key={id}
                          label={option?.name}
                          onDelete={() => handleRemoveVertical(id)}
                          deleteIcon={
                            <IconButton
                              size="small"
                              onMouseEnter={() => setVerticalMouseOverDelete(true)}
                              onMouseLeave={() => setVerticalMouseOverDelete(false)}
                            >
                              <CloseIcon fontSize="small" />
                            </IconButton>
                          }
                          style={{ margin: "2px" }}
                        />
                      );
                    })}
                    {hiddenCount > 0 && (
                      <Chip
                        label={`+${hiddenCount} more`}
                        style={{ margin: "2px", backgroundColor: "#e0e0e0", cursor: "pointer" }}
                      />
                    )}
                  </div>
                );
              }}
              style={{ height: "50px" }}
              MenuProps={{
                style: {
                  maxHeight: "400px",
                  marginTop: "5px",
                },
              }}
              disabled={VerticalMouseOverDelete}
            >
              {verticalOptions.map((vertical) => (
                <MenuItem key={vertical.id} value={vertical.id}>
                  <Checkbox checked={verticalFilter.includes(vertical.id)} />
                  <ListItemText primary={vertical.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <MMTable
          tableData={tableDate}
          tableHeadCell={tableHeaderCell}
          isLoadingMore={isLoading}
          fetchMoreData={() => fetchMoreData()}
          hasMoreData={hasMoreData}
          fetchInitialData={fetchInitialData}
          editHandler={handleEdit}
        />
      </Paper>
    </React.Fragment>
  );
};

export default BudgetList;

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    // fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));



interface RowData {
  Adv_ID: string;
  campaignsCount: number; // Assuming this field indicates the number of campaigns
  isActive: boolean; // Indicates if the row is active
  [key: string]: any;
}

interface ActionIconsProps {
  rowData: RowData;
  // advertiserFullData: any;
  handleEdit: (data: any) => void
}

export const BudgetActionIcons: React.FC<ActionIconsProps> = ({ rowData, handleEdit }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();



  return (
    <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
      {/* Edit Icon */}
      {/* <Button variant="outlined" onClick={() => handleEdit(rowData)}>
        Edit
      </Button> */}
      <Tooltip title="Edit" color="primary">
        <EditIcon onClick={() => handleEdit(rowData)} />
      </Tooltip>
      {/* Details Icon with Tooltip */}
      {/* <HtmlTooltip
        title={
          rowData.isActive
            ? `${rowData.campaignsCount} campaign${rowData.campaignsCount !== 1 ? 's' : ''} active`
            : 'This row is inactive'
        }
      >
       
        <InfoIcon   color="primary"/>
      </HtmlTooltip> */}
    </div>
  );
};

// export default ActionIcons;
