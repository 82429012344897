import { TextField } from "@mui/material";

interface basicTextField{
    name:string;
    value:string;
    label:string;
    changeHandler:(event:React.ChangeEvent<HTMLInputElement>)=>void;
    require?:boolean;
    error?:boolean;
    text?:string
    disabled?:boolean
  }
  
  const BasicTextField:React.FC<basicTextField>=({name, value, label, changeHandler, require , error, text, disabled=false})=>{
    return <TextField  fullWidth name={name} label={label} value={value} onChange={changeHandler}  required={require} error={error} helperText={text} disabled={disabled}/>
  }

  export default BasicTextField;