import React, { useState, useEffect, useCallback } from "react";
import { Paper, Box, Typography, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CreateMediaPartner from "./CreateMediaPartner";
import { CircleLoader } from "react-spinners";
import MediaPartnerTable from "../MediaPartner/MediaPartnerTable";
import { fetchMediaPartnersData } from "../../../service/MediaPartner";
import SnackbarMessage from "../../../Component/Snackbar";
export function getDisplayName(groupName: string) {
  if (groupName) {
    return groupName.replace(/^MediaPartner-/, "");
  } else {
    return;
  }
}
const MediaPartners = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mediaPartners, setMediaPartners] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any>(null);
  const [selectedRow, setSelectedRow] = useState<any[]>([]);
  const [isEdit, setIsEdit] = useState(false);

  const editHandler = useCallback((mediaPartner: any) => {
    setIsModalOpen(true);
    setIsEdit(true);
    setSelectedRow(mediaPartner);
  }, []);
 const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarType, setSnackBarType] = useState("");
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const handleSnackBarClose = () => {
    setSnackBarOpen(false);
  };
  const handleModalClose = () => {
    setTimeout(() => {
      setIsEdit(false);
    }, 400);
    setIsModalOpen(false);
  };
  const refreshTable = async () => {
    await fetchData();
  };
  const handleModalOpen = () => {
    setSelectedRow([]);
    setIsEdit(false);
    setIsModalOpen(true);
  };
  const fetchData = async () => {
    try {
      setMediaPartners([]);
      setLoading(true);
      const response2 = await fetchMediaPartnersData();
      const parsedRes = response2?.data.map((item: any) => {
        return {
          ...item,
          displayName: getDisplayName(item.GroupName),
        };
      });
      setMediaPartners(parsedRes);
    } catch (error) {
      setError(error);
      console.error("Failed to fetch Media Partner groups:", error);
    } finally {
      setLoading(false);
    }
  };
  const deleteHandler = (groupName: string) => {};

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Paper elevation={3} className="card">
      <Box className="user_Container">
        <Box
          display="flex"
          justifyContent="space-between"
          p={3}
          sx={{ flexDirection: { xs: "column", md: "row" } }}
        >
          <Box>
            <Typography className="card-heading">Media Partner List</Typography>
            <Typography className="card-sub-heading">
              Media Partner Summary
            </Typography>
          </Box>
          <Box
            display="flex"
            gap="16px"
            sx={{
              flexDirection: { xs: "column", sm: "row" },
              mt: { xs: "16px", md: "0" },
              alignItems: { xs: "flex-start", md: "center" },
            }}
          >
            <Box className="ip-box"></Box>
            <Box className="create-user-icon">
              <Button
                sx={{ padding: "6px 40px 6px 12px", backgroundColor: "#0ae" }}
                variant="contained"
                startIcon={<AddIcon />}
                onClick={handleModalOpen}
              >
                Create Media Partner
              </Button>
            </Box>
          </Box>
        </Box>
        <Box className="user_body">
          
          
              <MediaPartnerTable
                data={mediaPartners}
                deleteHandler={deleteHandler}
                refreshTable={refreshTable}
                editHandler={editHandler}
                doEdit={true}
                loading={loading}
              />
           
        </Box>
      </Box>

      {isModalOpen && (
        <CreateMediaPartner
          modalCloseHandler={handleModalClose}
          open={isModalOpen}
          isEdit={isEdit}
          rowData={selectedRow}
          refreshTable={refreshTable}
          setSnackBarOpen={(val:boolean)=>setSnackBarOpen(val)}
          setSnackBarMessage={(message:string)=>setSnackBarMessage(message)}
          setSnackBarType={(type:string)=>setSnackBarType(type)}
        />
      )}

<SnackbarMessage
        open={snackBarOpen}
        onClose={handleSnackBarClose}
        type={snackBarType}
        message={snackBarMessage}
      />
    </Paper>
  );
};

export default MediaPartners;

// const fetchData=async()=>{
//     const vendor= await fetchVendorGroups()
// return vendor;
// }
