import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux'; // Update this import from redux directly, not from redux-toolkit
import axios from 'axios';
import { baseURLConfig } from '../config/envConfig';
import { FETCH_VERTICALS_FAILURE, FETCH_VERTICALS_REQUEST, FETCH_VERTICALS_SUCCESS } from '../Store/Actions';
import { RootState } from '../Store';
import { generateHeader } from '../service/Auth';

// Action creators
export const fetchVerticalsRequest = () => ({ type: FETCH_VERTICALS_REQUEST });
export const fetchVerticalsSuccess = (data: any[]) => ({ type: FETCH_VERTICALS_SUCCESS, payload: data });
export const fetchVerticalsFailure = (error: string) => ({ type: FETCH_VERTICALS_FAILURE, payload: error });

// Thunk function
export const fetchVerticals = () => {
    return async (dispatch: any) => {
        dispatch(fetchVerticalsRequest());

        try {
            const headers = await generateHeader();
            const response = await axios.get(`${baseURLConfig.baseURl}/getVerticalList`, { headers });
            dispatch(fetchVerticalsSuccess(response?.data?.data));
        } catch (error) {
            const errorMessage =
                axios.isAxiosError(error) && error.response
                    ? error.response.data.message || 'Failed to fetch verticals'
                    : 'Network error';
            dispatch(fetchVerticalsFailure(errorMessage));
        }
    };
};
