import {
    FETCH_VERTICALS_REQUEST,
    FETCH_VERTICALS_SUCCESS,
    FETCH_VERTICALS_FAILURE,
} from "../Actions";


interface VerticalState {
    verticalList: any[];
    loading: boolean;
    error: string | null;
}

const initialState: VerticalState = {
    verticalList: [],
    loading: false,
    error: null,
};

const verticalsReducer = (state = initialState, action: any): VerticalState => {
    switch (action.type) {
        case FETCH_VERTICALS_REQUEST:
            return { ...state, loading: true, error: null };
        case FETCH_VERTICALS_SUCCESS:
            return { ...state, loading: false, verticalList: action.payload };
        case FETCH_VERTICALS_FAILURE:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

export default verticalsReducer;
