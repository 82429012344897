import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Typography , FormHelperText, FormControlLabel, FormGroup} from '@mui/material'
import React, { useState, useEffect } from 'react'
import { InputField, isValidEmail } from './CreateUser'
import { UserData } from './PublisherUser'
import { updateUserService ,  fetchVendor, fetchPublisher, verifyUserEmail} from '../../service/User'
import SnackbarMessage from '../../Component/Snackbar'
import { USER_CREATE_SUCCESS, USER_UPDATE_SUCCESS } from '../../Mock/MessageContstant'
import CloseIcon from '@mui/icons-material/Close';
import { SelectChangeEvent } from '@mui/material/Select';
import { getDisplayName } from './Vendors/Vendors'
import { CircleLoader } from 'react-spinners';
import { IOSSwitch } from '../../Component/Table/MMTable'
import VerifyOTPModal from './VerifyOTPModal'

interface updateUser {
  data: UserData,
  modalCloseHandler: () => void;
  refreshHandler: () => void;
  snackBarDataHandler:(type:string, isOpen:boolean, message:string)=>void;
}

const UpdateUser: React.FC<updateUser> = ({ data, modalCloseHandler, refreshHandler, snackBarDataHandler }) => {
   console.log("props", data);
  const initialValue = {
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    userId: data.userName,
    userRole:data.userRole


  }
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    ddError:''

  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState('');
  const [userFormValues, setUserFormValues] = useState(initialValue)
  const [loading, setLoading] = useState(false);
  const [advData, setAdvData] = useState([]);
  const [pubData, setPubData] = useState([])
  const [dropDown2Menus, setDropdown2Menus] = useState([]);
  const [dropDown2Value, setDropdown2Value] = useState('')
 const [currentUserRole, setCurrentUserRole]=useState('')
  const [groupType, setGroupType]=useState(data.userRole)
  const [isUpdateEmail, setUpdateEmail]=useState(false)

  console.log("Updated user ", )

  const isAdmin = data.userRole === 'admin';
  const isPublisher = data.userRole === 'publisher';
  const isVendor = data.userRole === 'vendor';

  useEffect(() => {
    if (isPublisher) {
      // setDropdownValue('publisherType');
      setGroupType("Publisher")
      setDropdown2Value(data.cognitoGroup)
    } else if (isVendor) {
      // setDropdownValue('vendorType');
      setGroupType("Vendor")
      setDropdown2Value(data.cognitoGroup)
    } else {
      // setDropdownValue('');
    }

    // console.log("Updated user  isdmin", isAdmin )
  }, [isPublisher, isVendor]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    let error = ''
    if (name === 'firstName' && !value.trim()) {
      error = 'First Name is required';
    } else if (name === 'lastName' && !value.trim()) {
      error = 'Last Name is required';
    } else if (name === 'email') {
      if (!value.trim()) {
        error = 'Email is required';
      } else if (!isValidEmail(value)) {
        error = 'Enter a valid email address';
      }
      // error = 'Email is required';
    }

    setErrors((prev) => ({ ...prev, [name]: error }));
    setUserFormValues((prev) => {
      return {
        ...prev, [name]: value
      }
    })
  }

  console.log("DropDown menu ", dropDown2Menus)

  console.log("DropDown value ", dropDown2Value)

  const handleSelectChange = (event: SelectChangeEvent) => {
    const { name, value } = event.target
     setGroupType(value)
    if (value === "Publisher") {
      setDropdown2Menus(pubData)
    } else {
      setDropdown2Menus(advData)
    }
  }

console.log("pub data", pubData)
console.log("adv data", advData)
  const handelSecondDropdown = (event: SelectChangeEvent) => {
    if (event.target.value) {
      setDropdown2Value(event.target.value)
      setErrors((prev) => ({ ...prev, ddError: '' }));
    } else {
      setErrors((prev) => ({ ...prev, ddError: "Please Select one options" }));
    }

  }

  const handelUpdateUser = async () => {
   const oldEmail=data.email;
   
   if(!isAdmin){
    if (!dropDown2Value) {
       
      setErrors((prev) => ({ ...prev, ddError: 'Please Select one option' }));
      return ;
    }
   }
    
    const requestPayload = {
      "Username": userFormValues.userId,
      "existingGroupName":data.cognitoGroup,
      // 'newGroupName':dropDown2Value,
      newGroupName: dropDown2Value || data.cognitoGroup,
      "UserAttributes": [
        {
          "Name": "email",
          "Value": userFormValues.email
        },
        {
          "Name": "custom:first_name",
          "Value": userFormValues.firstName
        },
        {
          "Name": "custom:last_name",
          "Value": userFormValues.lastName
        },
        {
          "Name": "custom:group",
          "Value": dropDown2Value || data.cognitoGroup
        },
        {
          "Name": "custom:groupType",
          "Value":groupType
        }
      ]
    }
    updateUserService(requestPayload).then((res) => {
      //   if(oldEmail!==userFormValues.email){
      //     // alert()
      //     snackBarDataHandler("success", true,  "Verification code sent to the user’s email. ")
      //     // setOtpModalOpen(true)

      //   }
      // else{
        snackBarDataHandler("success", true,  USER_UPDATE_SUCCESS)
        refreshHandler()
        modalCloseHandler()
      // }
      
      
    }).catch(err => {
      console.log(err)
      snackBarDataHandler("error", true, err.response.data.error)
    })
  }


  const fetchData = async () => {
    setLoading(true)

    Promise.all([fetchVendor(),fetchPublisher()]).then(([ advRes, pubRes]) => {

      const pub = pubRes.result.data.map((item: any) => {
        return {
          label: item.publisherName,
          value: item.SORTID
        }
      })
      const adv = advRes.result.map((item: any) => {
        return {
          label: getDisplayName(item?.GroupName),
          value: item?.GroupName
        }
      })
      setPubData(pub)
      setAdvData(adv)
      setLoading(false)
      setDropdown2Menus(adv)

      if (groupType=== "Publisher") {
        setDropdown2Menus(pub)
        setDropdown2Value(data.cognitoGroup)
      } else {
        setDropdown2Menus(adv)
        const selectedOption = advRes.result.find((option:any) => option.GroupName === data.cognitoGroup);
      if(selectedOption){
        setDropdown2Value(selectedOption.GroupName)
      }else{
        setDropdown2Value('')
      }
      
      }
    
      // setDropdown2Value(pub[0].value)
       setLoading(false)
    }).catch((error) => {
      console.log("error",error)
      setLoading(true)
    })
  }


  useEffect(()=>{

    const userRole=localStorage.getItem('userRole');
    if(userRole){
      setCurrentUserRole(userRole)
    }
    
  fetchData()
  },[])
  return (<>
    <Dialog open={true} onClose={modalCloseHandler} fullWidth>
    {
          loading ? <Box className="loading-spinner-container" sx={{
            minHeight: "500px"
          }}>

            <CircleLoader color="#00BFFF" loading={loading ? true : false} size={60} />
          </Box> :
  <Box >
                <DialogTitle>Update User</DialogTitle>
              <div className="close-button" onClick={modalCloseHandler}>
         <CloseIcon style={{ fontSize: '32px' }} />
          </div>
              <DialogContent className='Update-user'>
              <Grid container spacing={3.5}>
              <Grid item xs={12} lg={6}>
             <InputField label="First Name" name='firstName' value={userFormValues.firstName}
                changeHandler={handleChange} error={errors.firstName} hidden={false} required={true} />

            </Grid>
            <Grid item xs={12} lg={6}>
           <InputField label="Last Name" name='lastName' value={userFormValues.lastName}
                changeHandler={handleChange} error={errors.lastName} hidden={false} required={true} />
            </Grid>
                <Grid item xs={12}>
                <Box className="row-2">
               <InputField label="Email" name='email' value={userFormValues.email}
                  changeHandler={handleChange} error={errors.email} hidden={!isUpdateEmail} required={true} 
                  
                  />
                </Box>
                </Grid>
              
                <Grid item xs={12}>
                <Box className="row-3">
                  <InputField label="User ID" name='userId' value={userFormValues.userId}
                  changeHandler={handleChange} error={''} hidden={true} required={true} />
                </Box>
                </Grid>

                {isAdmin && 
           <Grid item xs={12}>       
  <Box className="row-4">
                
                  <FormGroup sx={{width: "169px", p: 1, }} className='select-all-toggle-container'>
                    <FormControlLabel
                      control={<IOSSwitch sx={{ m: 1 }} name='isAdmin' checked={isAdmin} />}
                      label="Admin"
                      className='select-all-toggle-container-label'
                      sx={{ flexDirection: "row" }}
                      // hidden={props.userRole==='Publisher'?true:false}
                    disabled={true}
                    />
                  </FormGroup>


                </Box>
</Grid>
}

              {
                !isAdmin &&

                <>
                <Grid item xs={12} lg={6}>
                    <FormControl fullWidth  >
                      <InputLabel id="demo-simple-select-label">Group</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={groupType}
                        //   label="Age"
                        onChange={handleSelectChange}
                        label="Group"
                        name='groupType'
                         disabled={(userFormValues.userRole==='admin' || userFormValues.userRole==='Super-Admin') ?true:false}
                      >
                        <MenuItem value={"Publisher"}>Publisher</MenuItem>
                        <MenuItem value={"Vendor"}>Vendor</MenuItem>

                      </Select>
                    </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                    <FormControl fullWidth error={errors.ddError.length > 1}>
                      <InputLabel id="demo-simple-select-label">{groupType === "Publisher" ? "Publisher*" : "Vendor*"}</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={dropDown2Value}
                        onChange={handelSecondDropdown}
                        label="Group"
                       error={errors.ddError.length>1}
                      // helperText={errors.ddError}
                      disabled={(userFormValues.userRole==='admin' || userFormValues.userRole==='Super-Admin') ?true:false}
                       >
                        {dropDown2Menus.map((item: any) => {
                          return (
                            <MenuItem value={item.value}>{item.label}</MenuItem>
                          )
                        })}

                      </Select>
                      {errors.ddError.length > 1 && <FormHelperText>{errors.ddError}</FormHelperText>}
                    </FormControl>
                    </Grid>
                </>
              }
                </Grid>
              </DialogContent>
              <DialogActions style={{ padding: "15px" }}>
                 <Button onClick={() => { modalCloseHandler() }} style={{ color: 'red' }} >CANCEL</Button>
          <Button variant="contained" onClick={() => { handelUpdateUser() }} color='primary'>UPDATE</Button>
              </DialogActions>
            </Box>
}
    </Dialog>
    <SnackbarMessage open={snackbarOpen} onClose={() => { setSnackbarOpen(false); modalCloseHandler() }} type={snackbarType} message={snackbarMessage} />


 
  </>
  )
}

export default UpdateUser