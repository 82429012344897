import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createSEOConfig } from "../../Services/seoApis";
import { createMbrand, deleteMbrand, fetchMBrandApiData, updateMbrand } from "../../Services/externalApis";
import { fetchAllPublisherCampaignsData, fetchAllPublishersData } from "../../service/Publisher";
///////////////////////for BRAND Mapping page
interface MBrandIn {
  mBrandID?: number;
  mBrandId?: number;
  mBrandName: string;
  // Created_on: string;
  primaryLogo: string;
  secondaryLogo: string;
  vertical: any,
  description: string,
  onlineOption: {
    online100: boolean,
    onlineCampus: boolean,
  },
  rating: number,
  status: string
}

interface mBrandNameHandler {
  mBrandName: string;
  mBrandID: string;
  data: MBrandIn[];
  loading: boolean;
  currentCursor: string | null;
  isoLoading: boolean;
  searchInput: string;
  mBrandTableData: MBrandIn[];
  compData: MBrandIn[];
  mBrandEditFlag: boolean;
  logo1: string;
  logo2: string;
  searchMbrandInput: string;
  selectedMbrand: any[];
  mBrandDropdownData: any[],
  publisherList: [] | null,
  publisherListLoader: boolean,
  getError: string,
  tabLoaderPublisherCampaigns: boolean,
  publisherCampaignslists: [] | null,
  excludePublisher: any,
  vertical: any,
  description: string,
  onlineOption: {
    online100: boolean,
    onlineCampus: boolean,
  },
  rating: number,
  verticalFilter: any[],
  status: string;

}

const initialMbrandState: mBrandNameHandler = {
  mBrandName: "",
  data: [],
  loading: false,
  currentCursor: null,
  isoLoading: false,
  searchInput: "",
  mBrandID: "",
  mBrandTableData: [],
  mBrandEditFlag: true,
  logo1: "",
  logo2: "",
  searchMbrandInput: "",
  selectedMbrand: [],
  mBrandDropdownData: [],
  compData: [],
  publisherList: [],
  publisherListLoader: false,
  getError: '',
  tabLoaderPublisherCampaigns: false,
  publisherCampaignslists: [],
  excludePublisher: {},
  vertical: "",
  description: "",
  onlineOption: {
    online100: false,
    onlineCampus: false
  },
  rating: 0,
  verticalFilter: [],
  status: "Inactive"
};

export const fetchAllPublisherCampaignsDataAsyncForMbrand = createAsyncThunk(
  'data/fetchAllPublisherCampaignDataForBrand',
  async ({ path, queryParamsObject }: { path: string, queryParamsObject: any }) => {
    const response = await fetchAllPublisherCampaignsData(path, queryParamsObject)
    return response.data;
  }
);



export const fetchAllPublisherDataAsyncForMbrand = createAsyncThunk(
  'data/fetchAllPublisherDataForBrand',
  async ({ path, queryParamsObject }: { path: string, queryParamsObject: any }) => {
    const response = await fetchAllPublishersData(path, queryParamsObject)
    return response.data;
  }
);


export const createMbrandAsync = createAsyncThunk(
  'mBrandHandlerApi/createMbrand',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await createMbrand(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateMbrandAsync = createAsyncThunk(
  'mBrandHandlerApi/updateMbrand',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await updateMbrand(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteMbrandAsync = createAsyncThunk(
  'mBrandHandlerApi/deleteMbrand',
  async (payload: any, { rejectWithValue }) => {
    try {
      // console.log('payload===1', payload)
      const response = await deleteMbrand(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const mBrandHandlerApiSlice = createSlice({
  name: 'mBrandNameList',
  initialState: initialMbrandState,
  reducers: {
    updateSelectedMbrands: (state, action) => {
      state.selectedMbrand = action.payload
    },
    setMBrandData: (state, action: PayloadAction<MBrandIn[]>) => {
      state.data = action.payload;

      const ddData: any = action.payload.map((item: any) => {
        return {
          label: item.mBrandName,
          value: item.mBrandID
        }
      })
      state.selectedMbrand = ddData.map((item: any) => item.value)
      state.mBrandDropdownData = ddData

    },
    setMBrandLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setMBrandTableData: (state, action: PayloadAction<any>) => {
      state.mBrandTableData = action.payload;

    },
    setCopmleteMbrandData: (state, action: PayloadAction<any>) => {
      state.compData = action.payload;

    },
    setMBrandEditFlag: (state, action: PayloadAction<boolean>) => {
      state.mBrandEditFlag = action.payload;
    },
    setMBrandField: (state, action: PayloadAction<{ field: keyof mBrandNameHandler; value: any }>) => {
      const { field, value } = action.payload;
      (state as any)[field] = value;
    },
    toggleStatus: (state) => {
      state.status = state.status === 'Active' ? 'Inactive' : 'Active';
    },
    resetMBrandForm: (state) => {
      state.mBrandID = '';
      state.mBrandName = '';
      state.logo1 = '';
      state.logo2 = '';
      state.mBrandEditFlag = true;
      state.vertical = "";
      state.description = "";
      state.onlineOption = {
        online100: false,
        onlineCampus: false
      };
      state.rating = 0
    },
    setSearchMbrandInput: (state, action: PayloadAction<any>) => {
      state.searchMbrandInput = action.payload;
    },
    storeBrandLogo1ImageURL: (state, action) => {
      state.logo1 = action.payload
    },
    storeBrandLogo2ImageURL: (state, action) => {
      state.logo2 = action.payload
    },
    setOnlineOption: (state, action: PayloadAction<{ field: keyof mBrandNameHandler['onlineOption']; value: boolean }>) => {
      const { field, value } = action.payload;
      state.onlineOption[field] = value; // Dynamically set the specified field in onlineOption
    },
    setRating: (state, action) => {
      state.rating = action.payload;
    },
    resetFiltersBrand: (state) => {
      state.verticalFilter = [];
    }
    // setSuccessMessage: (state, action: PayloadAction<any>) => {
    //     state.successMessage = action.payload;
    // },
    // setErrorMessage: (state, action: PayloadAction<any>) => {
    //     state.errorMessage = action.payload;
    // },
    // setSnackbarOpen: (state, action: PayloadAction<any>) => {
    //     state.snackbarOpen = action.payload;
    // },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllPublisherDataAsyncForMbrand.pending, (state) => {
      // state.pageLoading = true;
      // console.log("TRack state  PublisherDataAsync ")
      state.publisherListLoader = true
    })
      .addCase(fetchAllPublisherDataAsyncForMbrand.fulfilled, (state, action) => {

        state.publisherList = action.payload.result.data;

        state.publisherListLoader = false

      })
      .addCase(fetchAllPublisherDataAsyncForMbrand.rejected, (state, action) => {

        state.publisherListLoader = true
        state.getError = action.error.message || 'An error occurred'
        // state.advertiserList = action.payload;
      })

      .addCase(fetchAllPublisherCampaignsDataAsyncForMbrand.pending, (state) => {
        // state.pageLoading = true;
        // console.log("TRack state PublisherCampaignsDataAsync")
        state.tabLoaderPublisherCampaigns = true
      })
      .addCase(fetchAllPublisherCampaignsDataAsyncForMbrand.fulfilled, (state, action) => {
        // const currentState = current(state);
        state.publisherCampaignslists = action.payload.result.data;
        // console.log("current exlclude ",currentState.value.excludePublishers)

        // if (!currentState.value.excludePublishers) {

        // //   state.value.excludePublishers = generateExcludePublisherData(action.payload.result.data)
        // }
        state.excludePublisher = generateExcludePublisherData(action.payload.result.data)

        // if (currentState.isEdit) {
        //   const testMediaSourceData = generateExcludePublisherData00(action.payload.result.data, currentState.value.excludePublishers);
        //  console.log("testMediaSourceData", testMediaSourceData)
        //   const removedDeletedCamps=removeDuplicateNewCampaigns(action.payload.result.data, testMediaSourceData)
        // console.log("removedDeletedCamps", removedDeletedCamps)
        //   state.value.excludePublishers = removedDeletedCamps
        // }
        // if (currentState.isClone) {
        //   const testMediaSourceData = generateExcludePublisherData00(action.payload.result.data, currentState.value.excludePublishers);
        //   //  console.log("testMediaSourceData", testMediaSourceData)
        //   const removedDeletedCamps=removeDuplicateNewCampaigns(action.payload.result.data, testMediaSourceData)
        //   // console.log("removedDeletedCamps", removedDeletedCamps)
        //   state.value.excludePublishers = removedDeletedCamps
        // }

        // state.mediaSourceTabLoaderPublisherCampaigns = false

      })
      .addCase(fetchAllPublisherCampaignsDataAsyncForMbrand.rejected, (state, action) => {
        // state.loading = 'failed';
        // state.error = action.error.message || 'An error occurred';
        // state.mediaSourceTabLoaderPublisherCampaigns = true

        state.getError = action.error.message || 'An error occurred'
        // state.advertiserList = action.payload;
      })


  }
});




const generateExcludePublisherData = (publisherCampaigns: any[]) => {
  const excludePublisher: any = {
    // Your data object goes here...
  };
  //   const publisherCampaigns = publisherCampaign.filter((cam) => {
  //   return cam.publisherName.indexOf("PUB") != -1
  // })

  // console.log("publisherCampaigns", publisherCampaigns)
  if (publisherCampaigns.length > 1) {
    publisherCampaigns.forEach((campaigns: any) => {


      excludePublisher[campaigns?.publisherID] = {
        title: campaigns?.publisherName + " - " + campaigns?.publisherID,
        campaigns: {},
        bid: 0,
        enable: true,
        newCampaigns: [],
        publisherID: campaigns?.publisherID

      }
      let filteredByPublisherCampsData = publisherCampaigns.filter((pc) => {
        return pc.publisherID === campaigns.publisherID
      })
      filteredByPublisherCampsData.forEach((campaigns) => {
        excludePublisher[campaigns.publisherID].newCampaigns.push({
          title: campaigns?.campaignname + "-" + campaigns?.SORTID,
          bid: 0,
          enable: false,
          SORTID: campaigns.SORTID,
          publisherId: campaigns.publisherID
        })
      })

      // console.log("publisherCampaigns single ", campaigns)


    })
  }

  return excludePublisher
}

export const { setRating, setOnlineOption, setMBrandData, setMBrandLoading, toggleStatus, setCopmleteMbrandData, setMBrandTableData, setSearchMbrandInput, updateSelectedMbrands, storeBrandLogo1ImageURL, storeBrandLogo2ImageURL, setMBrandEditFlag, setMBrandField, resetMBrandForm, resetFiltersBrand } = mBrandHandlerApiSlice.actions;
export const mBrandHandlerApi = mBrandHandlerApiSlice.reducer;