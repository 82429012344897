import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Box,
  Button,
  CardMedia,
  Chip,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  DialogActions,
  CircularProgress,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { FC, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { seoValidation, websiteNames } from '../../../validation';
import { useFormik } from 'formik';
import { uploadSEOToS3 } from '../../../../../service/S3 Upload';
import { setApiLoading, setSeoDialogInfoField, setSnackbarOpen, setSuccessMessage } from '../../../../../Store/Slices/SEOHandlerApisSlice';
import { useAppDispatch, useAppSelector } from '../../../../../Store/hooks';
import { getSeoData, updateSeoData } from '../../../../../Services/seoApis';
import { CircleLoader } from 'react-spinners';

interface FormValues {
  seoTitle: string;
  seoCanonicalUrl: string;
  seoDescription: string;
  seoImage: any;
  seoKeywords: string[];
  ogType: string;
  ogLocale: string;
  ogTitle: string;
  twitterCard: string;
  twitterTitle: string;
  twitterSite: string;
  twitterDescription: string;
  twitterImage: any;
  websiteDomainName: string;
  areaStudy: any;
  mProgram: any;
}

const AddEditSEOContent: FC<any> = ({
  openPopup,
  setDialogOpen,
  selectedValue,
}) => {
  const handleClose = () => setDialogOpen(false);
  const [seoAlertOpen, setSeoAlertOpen] = useState(false);
  const [twitterAlertOpen, setTwitterAlertOpen] = useState(false);
  const [chips, setChips] = useState<string[]>([]);
  const [seoImage, setSeoImage] = useState<string>('');
  const [inputValue, setInputValue] = useState<string>('');
  //const [seoImage, setSeoImage] = useState('');
  const [twitterImage, setTwitterImage] = useState<string>('');
  const [dataFlag, setDataFlag] = useState<boolean>(true);
  const apiLoading = useAppSelector((state) => state.seoHandlerApi.apiLoading);
  const dispatch = useAppDispatch();
  

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    isValid,
    dirty,
    setFieldValue,
    setFieldTouched,
    resetForm,
    setValues,
  } = useFormik<FormValues>({
    initialValues: {
      websiteDomainName: selectedValue?.websiteDomainName,
      areaStudy: selectedValue?.areaStudy,
      mProgram: selectedValue?.mProgram,
      seoTitle: '',
      seoCanonicalUrl: '',
      seoDescription: '',
      seoImage: '',
      seoKeywords: [],
      ogType: '',
      ogLocale: '',
      ogTitle: '',
      twitterCard: '',
      twitterTitle: '',
      twitterSite: '',
      twitterDescription: '',
      twitterImage: '',
    },

    onSubmit: values => {
      dispatch(setApiLoading(true));
      const updateData = async () => {
        try {
          const getData = await updateSeoData(values);
          setDialogOpen(false)
          dispatch(setSuccessMessage('Updated SEO Configuration'));
          dispatch(setSnackbarOpen(true));
        } catch (error) {
          console.error('Error fetching data:', error);
          setDialogOpen(false);
          dispatch(setApiLoading(false));
        }
        dispatch(setApiLoading(false));
      };
      updateData();
      // dispatch(setApiLoading(false));
    },

    // validationSchema: seoValidation,
  });

  const handleSEOFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSeoAlertOpen(false);
    const fileInput = event.target;
    const file = fileInput.files && fileInput.files[0];

    if (file) {
      if (file.type === 'image/jpeg' || file.type === 'image/png') {
        const reader = new FileReader();

        reader.onloadend = () => {
          const image = new Image();
          image.src = reader.result as string;

          image.onload = () => {
            const width = image.width;
            const height = image.height;

            if (width <= 500 && height <= 250) {
              const objectURL = uploadSEOToS3(
                reader.result,
                `${new Date().getTime()}`,
                file
              );
              objectURL.then((res: any) => {
                setFieldValue('seoImage', res);
                // console.log('file details....', res);
                setSeoImage(res as any);
              });
            } else {
              event.target.value = '';
              // Display an error alert
              setSeoAlertOpen(true);
              // Reset the file input to clear the selected file
              fileInput.value = '';
            }
          };
        };

        reader.readAsDataURL(file);
      } else {
        // Display an error alert
        alert('Please select a jpeg or png image file');
        // Reset the file input to clear the selected file
        fileInput.value = '';
      }
    }
  };

  const handleSeoAlertClose = () => {
    setSeoAlertOpen(false);
  };

  const handleTwitterAlertClose = () => {
    setTwitterAlertOpen(false);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);

    // Check if the input value is empty, and reset it to the placeholder
    if (!event.target.value.trim()) {
      setInputValue('');
    }
  };

  const handleInputKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === 'Enter' && inputValue.trim() !== '') {
      setChips(prevChips => [...prevChips, inputValue.trim()]);
      setInputValue('');
    }
  };

  const removeChip = (index: number) => {
    setChips(prevChips => prevChips.filter((_, i) => i !== index));
  };

  const handleTwitterFileChange = (event: any) => {
    //const file = event.currentTarget.files[0];

    setTwitterAlertOpen(false);
    const fileInput = event.currentTarget;
    const file = fileInput.files && fileInput.files[0];

    if (file) {
      if (file.type === 'image/jpeg' || file.type === 'image/png') {
        const reader = new FileReader();

        reader.onloadend = () => {
          const image = new Image();
          image.src = reader.result as string;

          image.onload = () => {
            const width = image.width;
            const height = image.height;

            if (width <= 500 && height <= 250) {
              // setFieldValue('twitterImage', file);

              // setTwitterImage(file.name);
              const objectURL = uploadSEOToS3(
                reader.result,
                `${new Date().getTime()}`,
                file
              );
              objectURL.then((res: any) => {
                setFieldValue('twitterImage', res);
                // console.log('file details....', res);
                setTwitterImage(res as any);
              });
            } else {
              event.target.value = '';
              // Display an error alert
              setTwitterAlertOpen(true);
              // Reset the file input to clear the selected file
              fileInput.value = '';
            }
          };
        };

        reader.readAsDataURL(file);
      } else {
        // Display an error alert
        alert('Please select a jpeg or png image file');
        // Reset the file input to clear the selected file
        fileInput.value = '';
      }
    }

    if (file) {
    }
  };

  useEffect(() => {

    const getData = async () => {
      try {
        const getData = await getSeoData(
          `?websiteDomainName=${selectedValue?.websiteDomainName}&areaStudy=${selectedValue?.areaStudy}&mProgram=${selectedValue?.mProgram}`
        );
        if (getData?.length) {
          getData[0].seoKeywords = getData[0]?.seoKeywords?.filter((keyword:any) => keyword !== "");
          setValues(getData[0]);
          setChips(getData[0]?.seoKeywords)
          setDataFlag(false);
        }else{
          setDataFlag(false);
        }
      } catch (error) {
        console.log('error====page',error);
        console.error('Error fetching data:', error);
        setDataFlag(false);
      }
    };
    getData();
  }, []);



  return (
    <>
      <Dialog
        open={openPopup}
        onClose={handleClose}
        BackdropProps={{ style: { opacity: '1' } }}
      >
        {dataFlag ? (
      <Box className="loading-spinner-container" sx={{
        minHeight: "500px",
        minWidth:'500px',
      }}>
        <CircleLoader color="#00BFFF" loading={dataFlag ? true : false} size={60} />
      </Box>
    ) : (<Box>
        <DialogTitle>SEO Configuration</DialogTitle>
        <div className="close-button" onClick={handleClose}>
          <CloseIcon style={{ fontSize: '32px' }} />
        </div>
        <DialogContent>
          <Box display="flex" flexDirection="column" alignItems="center">
            <form className="seo-form" id="seoForm" onSubmit={() => {}}>
              <Box>
                <Grid container spacing={4} alignItems="flex-start">
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      sx={{ background: '#ffffff' }}
                      label="SEO Title *"
                      name="seoTitle"
                      onChange={handleChange}
                      value={values.seoTitle}
                      onBlur={handleBlur}
                    />
                    {errors.seoTitle && touched.seoTitle ? (
                      <Box color="#db2424">{errors.seoTitle} </Box>
                    ) : null}
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      sx={{ background: '#ffffff' }}
                      label="SEO Canonical URL *"
                      name="seoCanonicalUrl"
                      onChange={handleChange}
                      value={values.seoCanonicalUrl}
                      onBlur={handleBlur}
                    />
                    {errors.seoCanonicalUrl && touched.seoCanonicalUrl ? (
                      <Box color="#db2424">{errors.seoCanonicalUrl} </Box>
                    ) : null}
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <TextField
                      fullWidth
                      className="ad-content-textarea"
                      sx={{ background: '#ffffff' }}
                      label="SEO Description *"
                      name="seoDescription"
                      onChange={handleChange}
                      value={values.seoDescription}
                      onBlur={handleBlur}
                      multiline
                      maxRows={4}
                    />
                    {errors.seoDescription && touched.seoDescription ? (
                      <Box color="#db2424">{errors.seoDescription} </Box>
                    ) : null}
                  </Grid>

                  <Grid item xs={12}>
                    <Input
                      type="file"
                      id="seoImage"
                      inputProps={{ accept: 'image/*' }}
                      style={{ display: 'none' }}
                      onChange={handleSEOFileChange}
                      name='seoImage'
                    />
                    <Box display="flex" flexDirection="column" gap={2}>
                      <Box display="flex" gap={3}>
                        <Tooltip title={seoImage || ''} arrow>
                          <TextField
                            value={values?.seoImage}
                            variant="outlined"
                            fullWidth
                            label="SEO Image *"
                            helperText="Dimension (500*250)"
                            name='seoImage'
                          />
                        </Tooltip>
                        <Button
                          variant="contained"
                          size="large"
                          sx={{
                            backgroundColor: '#1976d2',
                            '&.MuiButtonBase-root:hover': {
                              bgcolor: '#1976d2 !important',
                            },

                            '&:hover': { backgroundColor: '#1976d2' },
                            border: '1px solid #1976d2',
                            boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                            borderRadius: '8px',
                            // color: '#ffff',
                            height: '45px',
                            margin: '5px',
                          }}
                          onClick={() => {
                            // Trigger the hidden file input
                            const fileInput = document.getElementById(
                              'seoImage'
                            ) as HTMLInputElement;
                            fileInput.click();
                          }}
                        >
                          <Typography>Upload</Typography>
                        </Button>
                      </Box>
                      {seoAlertOpen && (
                        <MuiAlert
                          elevation={6}
                          variant="filled"
                          severity="error"
                          onClose={handleSeoAlertClose}
                          sx={{ marginTop: '10px' }}
                        >
                          Image dimensions must be 500x250 pixels or smaller.
                        </MuiAlert>
                      )}
                      {errors.seoImage &&
                      touched.seoImage &&
                      typeof errors.seoImage === 'string' ? (
                        <Box color="#db2424">{errors.seoImage}</Box>
                      ) : null}
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box height="50%" width="50%" display="flex">
                      {values?.seoImage ? (
                        <CardMedia component="img" image={values?.seoImage} />
                      ) : (
                        'No image'
                      )}
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Box className="chips-container">
                      <label htmlFor="">SEO Keywords *</label>
                      <TextField
                        id="seoKeywords"
                        value={inputValue}
                        onChange={handleInputChange}
                        onKeyPress={handleInputKeyPress}
                        onBlur={() => {
                          setFieldTouched('seoKeywords', true);
                          setFieldValue('seoKeywords', chips);
                        }}
                        placeholder="Add SEO Keywords"
                        className="tag-input"
                        rows={4}
                        multiline
                      />
                      <Box display="flex" flexWrap="wrap" gap={1}>
                        {chips.map((chip, index) => (
                          <Chip
                            key={index}
                            label={chip}
                            onDelete={() => removeChip(index)}
                            className="chip"
                          />
                        ))}
                      </Box>
                    </Box>
                    {chips.length < 1 &&
                    errors.seoKeywords &&
                    touched.seoKeywords ? (
                      <Box color="#db2424">{errors.seoKeywords} </Box>
                    ) : null}
                  </Grid>
                </Grid>
              </Box>
              <Box mb={3} mt={5}>
                <Typography variant="h6" fontWeight={500} gutterBottom>
                  OG Settings
                </Typography>
              </Box>
              <Grid container item xs={12} spacing={4} alignItems="flex-start">
             

                <Grid item xs={12} md={6}>
                  {/* ogTitle */}
                  <TextField
                    fullWidth
                    sx={{ background: '#ffffff' }}
                    label="OG Title *"
                    name="ogTitle"
                    onChange={handleChange}
                    value={values.ogTitle}
                    onBlur={handleBlur}
                  />
                  {errors.ogTitle && touched.ogTitle ? (
                    <Box color="#db2424">{errors.ogTitle} </Box>
                  ) : null}
                </Grid>
              </Grid>
              <Box mb={3} mt={5}>
                <Typography variant="h6" fontWeight={500} gutterBottom>
                  Twitter Settings
                </Typography>
              </Box>
              <Grid container item xs={12} spacing={4} alignItems="flex-start">
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    sx={{ background: '#ffffff' }}
                    label="Twitter Card *"
                    name="twitterCard"
                    onChange={handleChange}
                    value={values.twitterCard}
                    onBlur={handleBlur}
                  />
                  {errors.twitterCard && touched.twitterCard ? (
                    <Box color="#db2424">{errors.twitterCard} </Box>
                  ) : null}
                </Grid>
                <Grid item xs={12} md={6}>
                  {/* ogType */}
                  <TextField
                    fullWidth
                    sx={{ background: '#ffffff' }}
                    label="Twitter Title *"
                    name="twitterTitle"
                    onChange={handleChange}
                    value={values.twitterTitle}
                    onBlur={handleBlur}
                  />
                  {errors.twitterTitle && touched.twitterTitle ? (
                    <Box color="#db2424">{errors.twitterTitle} </Box>
                  ) : null}
                </Grid>
                <Grid item xs={12} md={6}>
                  {/* ogType */}
                  <TextField
                    fullWidth
                    sx={{ background: '#ffffff' }}
                    label="Twitter Site *"
                    name="twitterSite"
                    onChange={handleChange}
                    value={values.twitterSite}
                    onBlur={handleBlur}
                  />
                  {errors.twitterSite && touched.twitterSite ? (
                    <Box color="#db2424">{errors.twitterSite} </Box>
                  ) : null}
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    sx={{ background: '#ffffff' }}
                    label="Twitter Description*"
                    name="twitterDescription"
                    onChange={handleChange}
                    value={values.twitterDescription}
                    onBlur={handleBlur}
                  />
                  {errors.twitterDescription && touched.twitterDescription ? (
                    <Box color="#db2424">{errors.twitterDescription} </Box>
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  <Input
                    type="file"
                    id="twitterImage"
                    inputProps={{ accept: 'image/*' }}
                    style={{ display: 'none' }}
                    onChange={handleTwitterFileChange}
                    name='twitterImage'
                  />
                  <Box display="flex" flexDirection="column" gap={2}>
                    <Box display="flex" gap={3}>
                      <Tooltip title={twitterImage || ''} arrow>
                        <TextField
                          fullWidth
                          value={values?.twitterImage}
                          label="Twitter Image *"
                          variant="outlined"
                          helperText="Dimension (500*250)"
                          name='twitterImage'
                        />
                      </Tooltip>
                      <Button
                        variant="contained"
                        size="large"
                        sx={{
                          backgroundColor: '#1976d2',
                          '&.MuiButtonBase-root:hover': {
                            bgcolor: '#1976d2 !important',
                          },

                          '&:hover': { backgroundColor: '#1976d2' },
                          border: '1px solid #1976d2',
                          boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                          borderRadius: '8px',
                          // color: '#ffff',
                          height: '45px',
                          margin: '5px',
                        }}
                        onClick={() => {
                          // Trigger the hidden file input
                          const fileInput = document.getElementById(
                            'twitterImage'
                          ) as HTMLInputElement;
                          fileInput.click();
                        }}
                      >
                        Upload
                      </Button>
                    </Box>
                    {twitterAlertOpen && (
                      <MuiAlert
                        elevation={6}
                        variant="filled"
                        severity="error"
                        onClose={handleTwitterAlertClose}
                        sx={{ marginTop: '10px' }}
                      >
                        Image dimensions must be 500x250 pixels or smaller.
                      </MuiAlert>
                    )}

                    {errors.twitterImage &&
                    touched.twitterImage &&
                    typeof errors.twitterImage === 'string' ? (
                      <Box color="#db2424">{errors.twitterImage}</Box>
                    ) : null}
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box height="50%" width="50%" display="flex">
                    {values?.twitterImage ? (
                      <CardMedia component="img" image={values?.twitterImage} />
                    ) : (
                      'No image'
                    )}
                  </Box>
                </Grid>
              </Grid>

              {/* <Box
                display="flex"
                justifyContent="flex-end"
                gap="16px"
                mt={7}
                pt={5}
                borderTop="1px solid #999"
              >
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={{
                    backgroundColor: '#1976d2',

                    '&.MuiButtonBase-root:hover': {
                      bgcolor: '#1976d2 !important',
                    },
                    '&:hover': { backgroundColor: '#1976d2' },
                    border: '1px solid #1976d2',
                    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',

                    color: '#ffff',

                    textTransform: 'none',
                    fontSize: '18px',
                    borderRadius: '8px',
                    minWidth: '150px',
                  }}
                >
                  Submit
                </Button>
              </Box> */}
            </form>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>CANCEL</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleSubmit();
            }}
          >
             {
          apiLoading ? <CircularProgress color="inherit" size={24} /> : 'UPDATE'
        }
          </Button>
        </DialogActions>
        </Box> )}
      </Dialog>
    </>
  );
};

export default AddEditSEOContent;
