import React, { FC, useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import MuiAlert from "@mui/material/Alert";
import MMTable from "../../../Component/Table/MMTable";

import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";

import { generateHeader } from "../../../service/Auth";
import axios from "axios";
import { baseURLConfig } from "../../../config/envConfig";
import UploadFile from "./uploadFile";
import { fetchPublisher } from "../../../service/User";
import { fetchVendorsData } from "../../../service/Vendor";

const reportHeadCell: any = [
  {
    numeric: false,
    id: "id",
    label: "Id",
    key: "id",
    isHidden: true,
  },
  {
    numeric: false,
    id: "date",
    label: "Date",
    key: "date",
  },
  {
    numeric: false,
    id: "vendor",
    label: "Vendor",
    key: "vendor",
  },
  {
    numeric: false,
    id: "vertical",
    label: "Vertical",
    key: "vertical",
  },
  {
    numeric: false,
    id: "advertiser",
    label: "Advertiser",
    key: "advertiser",
  },
  {
    numeric: false,
    id: "fileType",
    label: "File Type",
    key: "fileType",
  },
  // {
  //   numeric: false,
  //   id: 'bucket',
  //   label: 'Bucket',
  //   key: 'bucket',
  // },
  {
    numeric: false,
    id: "action",
    label: "Action",
    key: "action",
  },
];

const DeleteIconPop: React.FC<any> = (props) => {
  const { openDeletePopup, closeDeletePopup, onDelete } = props;
  return (
    <Dialog open={openDeletePopup}>
      <DialogTitle className="dialogTitle">ALERT</DialogTitle>
      <DialogContent>
        <p>{`Are you sure you want to delete this file`}</p>
      </DialogContent>
      <DialogActions className="dialogActions">
        <Button onClick={closeDeletePopup} color="primary">
          CANCEL
        </Button>
        <Button onClick={onDelete} color="error" variant="contained">
          CONFIRM
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const DataUpload = () => {
  const [tableRow, setTableRow] = useState<any>([]);
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [vendorList, setVendorList] = useState<any>([]);
  const [verticalList, setVerticalList] = React.useState<any>([]);
  const [advFileList, setAdvFileList] = React.useState<any>([]);
  const [selectedRow, setSelectedRow] = React.useState<any>("");
  const [noDataFound, setNoDataFound] = React.useState<boolean>(false);
  const [isDataFetching, setIsDataFetching] = React.useState<boolean>(false);
  const [openDeletePopup, setDeletePopup] = useState<boolean>(false);
  const [tostMessageHandler, setTostMessageHandler] = useState<any>({
    message: "",
    severity: "",
    snackbarOpen: false,
  });

  const fetchVerticals = async () => {
    try {
      const headers = await generateHeader();
      const response = await axios.get(
        `${baseURLConfig.baseURl}/getVerticalList`,
        { headers }
      );
      const list = response?.data?.data?.map((val: any) => ({
        verticalID: val.verticalID,
        verticalName: val.verticalName,
      }));
      setVerticalList(list);
    } catch (error) {
      const errorMessage =
        axios.isAxiosError(error) && error.response
          ? error.response.data.message || "Failed to fetch verticals"
          : "Network error";
    }
  };

  const getList = async () => {
    setIsDataFetching(true);
    try {
      const headers = await generateHeader();
      //  dispatch(setIsoLoading(true));
      const response = await fetch(
        `${baseURLConfig.fileUploadURL}/getAdvFileRecordList`,
        {
          headers,
        }
      );
      const jsonData = await response.json();
      setAdvFileList(jsonData?.data || []);
      const fileList =
        jsonData?.data?.map((val: any) => ({
          id: val?.advFileRecordID,
          data: format(new Date(val?.createdDate), "MM-dd-yyyy HH:mm:ss"),
          vendor: val?.vendor?.GroupName,
          vertical: val?.vertical?.verticalName,
          advertiser: val?.advertiser?.advertiserName,
          fileType:
            val?.fileType == "qualityBillable"
              ? "Lead Quality"
              : "Lead Billable",
        })) || [];
      setNoDataFound(!Boolean(fileList.length));
      setTableRow(fileList);
      // return jsonData.data;
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsDataFetching(false);
    }
  };
  useEffect(() => {
    fetchVerticals();
    getList();
    Promise.all([fetchVendorsData()])
      .then(([vendor]) => {
        const vendorList = vendor?.map((val: any) => ({
          advertiserCount: val?.advertiserCount,
          GroupName: val?.GroupName,
        }));
        setVendorList(vendorList);
      })
      .catch((error) => {});
  }, []);

  const handleEdit = (row: any) => {
    const rowData = advFileList?.find(
      (val: any) => val?.advFileRecordID === row.id
    );
    setSelectedRow(rowData);
    setOpenPopup(true);
  };

  const deleteAdvFile = async (payload: any) => {
    setDeletePopup(false);
    setIsDataFetching(true);
    try {
      const headers = await generateHeader();
      let config = {
        method: "delete",
        maxBodyLength: Infinity,
        url: `${baseURLConfig.fileUploadURL}/deleteAdvFileRecord?advFileRecordID=${payload}`,
        headers: headers,
        // data: payload.data,
      };
      const response = await axios.request(config);
      // setTostMessageHandler({
      //   message: `Email Report - ${selectedRow?.reportName} Deleted Successfully`,
      //   severity: 'success',
      //   snackbarOpen: true,
      // });
      setSelectedRow("");
      getList();
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const handleCloseSnackbar = () => {
    setTostMessageHandler({
      message: "",
      severity: "",
      snackbarOpen: false,
    });
  };

  return (
    <>
      <Paper elevation={3} className="card">
        <Box display="flex" justifyContent="space-between" p={3}>
          <Box>
            <Typography className="card-heading">
              Advertiser Quality Upload
            </Typography>
            <Typography className="card-sub-heading">
              Advertiser Quality Upload Summary
            </Typography>
          </Box>
          <Box display="flex" gap="16px" alignItems="center">
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => {
                setOpenPopup(true);
              }}
            >
              Upload File
            </Button>
          </Box>
        </Box>
        <MMTable
          tableData={tableRow}
          tableHeadCell={reportHeadCell}
          fetchMoreData={() => {}}
          hasMoreData={false}
          fetchInitialData={() => {}}
          hasEdit
          onEdit={handleEdit}
          hasDelete
          onDelete={(row: any) => {
            setSelectedRow(row);
            setDeletePopup(true);
          }}
          isDataFetching={isDataFetching}
          noDataFound={noDataFound}
        />
      </Paper>
      {openPopup && (
        <UploadFile
          openPopup={openPopup}
          closePopup={() => {
            setSelectedRow("");
            setOpenPopup(false);
          }}
          vendorList={vendorList}
          verticalList={verticalList}
          selectedRow={selectedRow}
          refetchData={() => {
            setTostMessageHandler({
              message: "File Upload Successfully",
              severity: "success",
              snackbarOpen: true,
            });
            getList();
            setSelectedRow("");
          }}
        />
      )}

      {openDeletePopup && (
        <DeleteIconPop
          openDeletePopup={openDeletePopup}
          closeDeletePopup={() => {
            setSelectedRow("");
            setDeletePopup(false);
          }}
          onDelete={() => {
            deleteAdvFile(selectedRow?.id);
          }}
        />
      )}

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={tostMessageHandler?.snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackbar}
          severity={tostMessageHandler?.severity}
        >
          {tostMessageHandler?.message}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default DataUpload;
