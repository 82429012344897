import AWS from "aws-sdk";
import { awsConfig } from "../../config/envConfig";



// import { Buffer } from 'node:buffer';
AWS.config.update({
  // accessKeyId: "AKIAUNBASYMEHMLEPHZ3",
  //   secretAccessKey: "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",
  accessKeyId: awsConfig.Auth.accessKeyID,
  secretAccessKey: awsConfig.Auth.secretKeyID,
  region: 'us-east-1',
});

const s3 = new AWS.S3();


export const uploadToS3=async(img:any, filename:any, selectedImage:any)=> {
   let base64 = img;
  // const base64Data = Buffer.from(
  //   base64.replace(/^data:image\/\w+;base64,/, ""),
  //   "base64"
  // );
    const imgtype = base64.split(";")[0].split("/")[1]; // getting the image type from the base64 string
    const params = {
      // Bucket: `mm-portal-media-v3/advertiser/images`,
       Bucket:(process.env.REACT_APP_BUILD_ENV === "PROD" ? "mm-pixel-v3/advertiser" : "mm-portal-media-v3/advertiser/images"),
      Key: `${filename}.${imgtype}`,
      Body:selectedImage,
      ACL: "public-read",
      ContentEncoding: "base64", // required
      ContentType: `image/${imgtype}`,
    };
    try {
      const stored = await s3.upload(params).promise();
      return stored.Location
    } catch (err) {
      console.log(err);
    }
  }

  export const uploadSEOToS3=async(img:any, filename:any, selectedImage:any)=> {
    let base64 = img;
   // const base64Data = Buffer.from(
   //   base64.replace(/^data:image\/\w+;base64,/, ""),
   //   "base64"
   // );
     const imgtype = base64.split(";")[0].split("/")[1]; // getting the image type from the base64 string
     const params = {
      //  Bucket: `mm-portal-media-v3/seo/images`,
       Bucket:(process.env.REACT_APP_BUILD_ENV === "PROD" ? "mm-pixel-v3/CMS" : "mm-portal-media-v3/seo/images"),
       Key: `${filename}.${imgtype}`,
       Body:selectedImage,
       ACL: "public-read",
       ContentEncoding: "base64", // required
       ContentType: `image/${imgtype}`,
     };
     try {
       const stored = await s3.upload(params).promise();
       return stored.Location
     } catch (err) {
       console.log(err);
     }
   }

   export const uploadPrimaryLogoToS3=async(img:any, filename:any, selectedImage:any)=> {
    let base64 = img;
   // const base64Data = Buffer.from(
   //   base64.replace(/^data:image\/\w+;base64,/, ""),
   //   "base64"
   // );
     const imgtype = base64.split(";")[0].split("/")[1]; // getting the image type from the base64 string
     const params = {
       Bucket:(process.env.REACT_APP_BUILD_ENV === "PROD"? "mm-pixel-v3/brand/primary-logo" : "mm-portal-media-v3/primary-logo"),
        // Bucket: `mm-pixel-v3/brand/primary-logo`,
       Key: `${filename}.${imgtype}`,
       Body:selectedImage,
       ACL: "public-read",
       ContentEncoding: "base64", // required
       ContentType: `image/${imgtype}`,
     };
     try {
       const stored = await s3.upload(params).promise();
       return stored.Location
     } catch (err) {
       console.log(err);
     }
   }

   export const uploadSecondaryLogoToS3=async(img:any, filename:any, selectedImage:any)=> {
    let base64 = img;
   // const base64Data = Buffer.from(
   //   base64.replace(/^data:image\/\w+;base64,/, ""),
   //   "base64"
   // );
     const imgtype = base64.split(";")[0].split("/")[1]; // getting the image type from the base64 string
     const params = {
        // Bucket: `mm-pixel-v3/brand/secondary-logo`,
       Bucket:(process.env.REACT_APP_BUILD_ENV === "PROD" ? "mm-pixel-v3/brand/secondary-logo" : "mm-portal-media-v3/secondary-logo"),
       Key: `${filename}.${imgtype}`,
       Body:selectedImage,
       ACL: "public-read",
       ContentEncoding: "base64", // required
       ContentType: `image/${imgtype}`,
     };
     try {
       const stored = await s3.upload(params).promise();
       return stored.Location
     } catch (err) {
       console.log(err);
     }
   }

   export const uploadFileToS3=async(file:any,bucketName:any, fileExtension:any)=> {
    const params = {
      // Bucket: `mm-pixel-v3/brand/secondary-logo`,
     Bucket:(process.env.REACT_APP_BUILD_ENV === "PROD" ? `mm-advertiser-file-uploads-prod/${bucketName}` : `mm-advertiser-file-uploads/${bucketName}`),
     Key: `${new Date().getTime()}.${fileExtension}`,
     Body:file,
     ACL: "public-read",
    //  ContentEncoding: "base64", // required
     ContentType: file.type,
   };
   try {
    const stored = await s3.upload(params).promise();
    return stored.Key
  } catch (err) {
    console.log(err);
  }
   }

    // console.log("kjahkhhfdshfd---",awsConfig.Auth.environment === "PROD" ? "mm-pixel-v3/brand/secondary-logo" : "mm-portal-media-v3/secondary-logo",awsConfig)