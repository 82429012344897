import {
    FETCH_MEDIAPARTNER_REQUEST,
    FETCH_MEDIAPARTNER_SUCCESS,
    FETCH_MEDIAPARTNER_FAILURE,
} from "../Actions";


interface MediaPartnerState {
    mediaPartnerList: any[];
    loading: boolean;
    error: string | null;
}

const initialState: MediaPartnerState = {
    mediaPartnerList: [],
    loading: false,
    error: null,
};

const mediaPartnerReducer = (state = initialState, action: any): MediaPartnerState => {
    switch (action.type) {
        case FETCH_MEDIAPARTNER_REQUEST:
            return { ...state, loading: true, error: null };
        case FETCH_MEDIAPARTNER_SUCCESS:
            return { ...state, loading: false, mediaPartnerList: action.payload };
        case FETCH_MEDIAPARTNER_FAILURE:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

export default mediaPartnerReducer;
